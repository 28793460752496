<script setup lang="ts">
import { computed, nextTick, ref } from "vue";

import Input from "~/components/dumb/Input.vue";
import { CheckIcon, XIcon } from "~/icons";
import type { ValidationFunction } from "~/shared/types";

const props = defineProps<{
  disabled?: boolean;
  value: string;
  label: string;
  block?: boolean;
  validate?: ValidationFunction;
}>();

const emit = defineEmits<{
  save: [newValue: string];
}>();

const editing = ref(false);
const input = ref<InstanceType<typeof Input> | null>(null);
const showButtons = computed(() => input.value?.value !== props.value);

const startEditing = () => {
  if (props.disabled) {
    return;
  }

  editing.value = true;
  nextTick(() => {
    input.value?.focus();
  });
};

const stopEditing = () => {
  editing.value = false;
};

const stopIfNoChanges = () => {
  if (showButtons.value) {
    return;
  }
  stopEditing();
};

const onSave = () => {
  if (!input?.value?.isValid) {
    return;
  }

  emit("save", input.value.value);
  stopEditing();
};

defineExpose({
  stopEditing,
});
</script>

<template>
  <div class="relative h-[42px]">
    <button
      v-if="!editing"
      type="submit"
      class="h-full rounded focus-ring-std"
      :class="[block ? 'w-full' : 'w-48', disabled ? 'cursor-not-allowed' : 'cursor-pointer']"
      aria-label="Edit"
      @click="startEditing">
      <slot />
    </button>
    <template v-else>
      <Input
        ref="input"
        :init-value="value"
        :placeholder="props.label"
        :label="props.label"
        hide-label
        :class="[block ? 'w-full' : 'w-48']"
        input-classes="!text-base"
        :validate="validate"
        @keydown.esc.stop.prevent="stopEditing"
        @enter="onSave"
        @finalize="stopIfNoChanges" />
      <div v-if="showButtons" class="absolute right-0 top-full mt-2 flex items-center gap-2">
        <button
          type="button"
          title="Discard"
          class="inline-flex items-center rounded bg-danger-base p-1 text-center text-sm font-normal hover-bg-danger text-oncolor focus-ring-std"
          aria-label="Discard"
          @click="stopEditing">
          <XIcon class="icon-md" aria-hidden="true" />
        </button>
        <button
          type="button"
          class="inline-flex items-center rounded bg-success-base px-2 py-1 text-center text-sm font-normal text-oncolor focus-ring-std"
          :class="input?.isValid ? 'hover-bg-success' : 'cursor-not-allowed opacity-50'"
          :disabled="!input?.isValid"
          @click="onSave">
          <CheckIcon class="-ml-1 mr-2 icon-md" aria-hidden="true" />
          Save
        </button>
      </div>
    </template>
  </div>
</template>
