import moment from "moment";

import { UNCOLORED_PSEUDO_COLOR_BY } from "~/common/colorBy";
import { getDefaultBoardGroupBy, getDefaultListGroupBy } from "~/common/groupBy";
import {
  DashboardKind,
  FilterConnector,
  IconKind,
  LayoutKind,
  PageKind,
  StoreDataPreservationMode,
  SubtaskDisplayMode,
  SummaryStatisticKind,
  UserRole,
  ViewKind,
} from "~/shared/enums";
import type {
  DartboardUpdate,
  Dashboard,
  DashboardUpdate,
  Filter,
  FilterGroup,
  FolderUpdate,
  Layout,
  LayoutUpdate,
  Page,
  PageUpdate,
  TransactionResponse,
  UserDartboardLayout,
  UserDartboardLayoutCreate,
  View,
  ViewUpdate,
} from "~/shared/types";
import { makeRandomColorHex } from "~/utils/color";
import { deepCopy, filterInPlace, getNextTitleInSequence, isString, makeDuid, randomSample } from "~/utils/common";
import { makeDashboardComparator, viewComparator } from "~/utils/comparator";
import { getOrdersBetween } from "~/utils/orderManager";

import type { PiniaActionAdaptor, PiniaGetterAdaptor } from "../shared";
import type { DataStore } from ".";

export type Getters = {
  viewList: View[];
  getViewByDuid: (duid: string) => View | undefined;
  getViewByKind: (kind: ViewKind.SEARCH | ViewKind.MY_TASKS | ViewKind.TRASH) => View;
  layoutList: Layout[];
  getLayoutByDuid: (duid: string) => Layout | undefined;
  getLayoutByDartboardDuid: (dartboardDuid: string) => Layout | undefined;
  dashboardList: Dashboard[];
  getDashboardByDuid: (duid: string) => Dashboard | undefined;
};

export type Actions = {
  /** VIEWS */
  /** Create a new view. */
  createView: (
    order: string,
    partialView?: Partial<View>,
    partialLayout?: Partial<Layout>,
    options?: { awaitBackend?: boolean }
  ) => Promise<View>;
  /** Update an existing view. */
  updateView: (update: ViewUpdate, options?: { awaitBackend?: boolean }) => Promise<View | undefined>;
  /* Update accessors for a view */
  updateViewAccessors: (
    viewDuid: string,
    accessorDuids: string[],
    add?: boolean,
    options?: { awaitBackend?: boolean }
  ) => Promise<View | undefined>;
  /** Favorite or unfavorite a view. */
  updateViewFavorite: (
    viewDuid: string,
    favorite: boolean,
    options?: { awaitBackend?: boolean }
  ) => Promise<View | undefined>;
  /* Replicate a view. */
  replicateView: (
    view: View,
    order: string,
    partialView?: Partial<View>,
    options?: { awaitBackend?: boolean; maintainTitle?: boolean }
  ) => Promise<View | undefined>;
  /** Delete a view. */
  deleteView: (view: View, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Sort views.
   * @PRIVATE */
  $sortViews: () => void;
  /** Set views initially.
   * @PRIVATE */
  $setViews: (views: View[]) => void;
  /** Create or update view from WS.
   * @PRIVATE */
  $createOrUpdateView: (view: View) => void;
  /** Delete view from WS.
   * @PRIVATE */
  $deleteView: (view: View) => void;
  /* PAGES */
  /** Update a page. */
  updatePage: (update: PageUpdate, pageKind: PageKind, options?: { awaitBackend?: boolean }) => Promise<void>;
  /* Add users with access to a page. */
  updatePageAccessors: (
    page: Page,
    accessorDuids: string[],
    add?: boolean,
    options?: { awaitBackend?: boolean }
  ) => Promise<void>;
  /** Replicate a page. */
  replicatePage: (page: Page, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Delete a page. */
  deletePage: (page: Page, options?: { awaitBackend?: boolean }) => Promise<void>;

  /** LAYOUTS */
  /** Make a new layout and persist locally but don't send to server yet */
  makeLayoutNoBackend: (partialLayout?: Partial<Layout>) => Layout;
  /** Create a new layout. */
  createLayoutForDartboard: (
    dartboardDuid: string,
    filters: Filter[],
    options?: { awaitBackend?: boolean }
  ) => Promise<Layout | undefined>;
  /** Update a layout. */
  updateLayout: (
    update: LayoutUpdate,
    options?: { noBackend?: boolean; awaitBackend?: boolean }
  ) => Promise<Layout | undefined>;
  /**
   * Remove entity duids from filters in all layouts.
   * Or remove all filters if removeAllFilters is true.
   */
  $updateLayoutsRemoveEntityDuidOrFiltersNoBackend: (
    entityDuids: string[],
    propertyDuid: string[],
    options?: { includeBackend?: boolean; removeAllFilters?: boolean }
  ) => LayoutUpdate[];
  /** Update layouts to new dartboard duid */
  $updateLayoutsRemapDartboardFiltersNoBackend: (dartboardDuidRemap: Map<string, string>) => LayoutUpdate[];
  /** Replicate layouts for a dartboard.
   * @PRIVATE */
  $replicateLayoutsForDartboardNoBackend: (
    oldDartboardDuid: string,
    newDartboardDuid: string
  ) => Promise<{
    layoutUpdates: LayoutUpdate[];
    layoutCreates: Layout[];
    userDartboardLayouts: UserDartboardLayout[];
    userDartboardLayoutCreates: UserDartboardLayoutCreate[];
  }>;
  /** Set layout initially.
   * @PRIVATE */
  $setLayouts: (layouts: Layout[], preservationMode: StoreDataPreservationMode) => void;
  /** Create or update layout from WS.
   * @PRIVATE */
  $createOrUpdateLayout: (layout: Layout) => void;
  /** Delete layout from WS.
   * @PRIVATE */
  $deleteLayout: (layout: Layout) => void;

  /** DASHBOARD */
  /** Create a new dashboard. */
  createDashboard: (
    order: string,
    partialDashboard?: Partial<Dashboard>,
    partialLayout?: Partial<Layout>,
    options?: { awaitBackend?: boolean }
  ) => Promise<Dashboard>;
  /** Update an existing dashboard. */
  updateDashboard: (update: DashboardUpdate, options?: { awaitBackend?: boolean }) => Promise<Dashboard | undefined>;
  /* Update accessors for a dashboard */
  updateDashboardAccessors: (
    dashboardDuid: string,
    accessorDuids: string[],
    add?: boolean,
    options?: { awaitBackend?: boolean }
  ) => Promise<Dashboard | undefined>;
  /** Favorite or unfavorite a dashboard. */
  updateDashboardFavorite: (
    dashboardDuid: string,
    favorite: boolean,
    options?: { awaitBackend?: boolean }
  ) => Promise<Dashboard | undefined>;
  /* Replicate a dashboard. */
  replicateDashboard: (
    dashboard: Dashboard,
    order: string,
    partialDashboard?: Partial<Dashboard>,
    options?: { awaitBackend?: boolean; maintainTitle?: boolean }
  ) => Promise<Dashboard | undefined>;
  /** Delete a dashboard. */
  deleteDashboard: (dashboard: Dashboard, options?: { awaitBackend?: boolean }) => Promise<void>;
  /** Sort dashboards.
   * @PRIVATE */
  $sortDashboards: () => void;
  /** Set dashboards initially.
   * @PRIVATE */
  $setDashboards: (dashboards: Dashboard[]) => void;
  /** Create or update dashboard from WS.
   * @PRIVATE */
  $createOrUpdateDashboard: (dashboard: Dashboard) => void;
  /** Delete dashboard from WS.
   * @PRIVATE */
  $deleteDashboard: (dashboard: Dashboard) => void;
};

const getters: PiniaGetterAdaptor<Getters, DataStore> = {
  viewList() {
    return Array.from(this._duidsToViews.values());
  },
  getViewByDuid() {
    return (duid) => this._duidsToViews.get(duid);
  },
  getViewByKind() {
    return (kind) => {
      const view = this.viewList.filter((e) => e.kind === kind);
      if (view.length !== 1) {
        throw new Error(`Expected exactly one view of kind ${kind}`);
      }
      return view[0];
    };
  },
  layoutList() {
    return Array.from(this._duidsToLayouts.values());
  },
  getLayoutByDuid() {
    return (duid) => this._duidsToLayouts.get(duid);
  },
  getLayoutByDartboardDuid() {
    return (dartboardDuid) => {
      const dartboard = this.getDartboardByDuid(dartboardDuid);
      if (!dartboard) {
        return undefined;
      }
      const userDuid = this.$useUserStore().duid;
      const duid = dartboard.userDuidsToLayoutDuids.find((e) => e.userDuid === userDuid)?.layoutDuid;
      if (!duid) {
        return undefined;
      }
      return this.getLayoutByDuid(duid);
    };
  },
  dashboardList() {
    return Array.from(this._duidsToDashboards.values());
  },
  getDashboardByDuid() {
    return (duid) => this._duidsToDashboards.get(duid);
  },
};

const actions: PiniaActionAdaptor<Actions, DataStore> = {
  async createView(order, partialView, partialLayout, options = {}) {
    const { awaitBackend = false } = options;
    const appStore = this.$useAppStore();
    const userStore = this.$useUserStore();

    const newLayout = this.makeLayoutNoBackend(partialLayout);

    const newView: View = {
      kind: ViewKind.CUSTOM,
      accessibleByTeam: false,
      accessibleByUserDuids: [userStore.duid],
      public: false,
      title: "",
      description: "",
      iconKind: IconKind.NONE,
      iconNameOrEmoji: randomSample(appStore.pageIconOptions)[0].name,
      colorHex: makeRandomColorHex(),
      favoritedByUserDuids: [],
      alwaysShownPropertyDuids: [],
      alwaysHiddenPropertyDuids: [],
      propertyOrderDuids: [],
      ...partialView,
      duid: makeDuid(),
      order,
      layoutDuid: newLayout.duid,
      pageKind: PageKind.VIEW,
    };
    this.$createOrUpdateView(newView);

    const backendAction = this.$backend.view.create(newView, newLayout);
    if (awaitBackend) {
      await backendAction;
    }
    return newView;
  },
  async updateView(update, options = {}) {
    const { awaitBackend = false } = options;
    const view = this.getViewByDuid(update.duid);
    if (!view) {
      return undefined;
    }

    Object.assign(view, update);
    this.$sortViews();

    const backendAction = this.$backend.view.update(update);
    if (awaitBackend) {
      await backendAction;
    }

    return view;
  },
  async updateViewAccessors(duid, accessorDuids, add, options = {}) {
    const { awaitBackend = false } = options;
    const view = this.getViewByDuid(duid);
    if (!view) {
      return undefined;
    }

    let backendAction: Promise<TransactionResponse>;

    if (add) {
      view.accessibleByUserDuids.push(...accessorDuids);
      backendAction = this.$backend.view.updateListAdd({
        duid: view.duid,
        accessibleByUserDuids: accessorDuids,
      });
    } else {
      filterInPlace(view.accessibleByUserDuids, (e) => !accessorDuids.includes(e));
      backendAction = this.$backend.view.updateListRemove({
        duid: view.duid,
        accessibleByUserDuids: accessorDuids,
      });
    }

    if (awaitBackend) {
      await backendAction;
    }

    this.$sortViews();

    return view;
  },
  async updateViewFavorite(viewDuid, favorite, options = {}) {
    const { awaitBackend = false } = options;
    const view = this.getViewByDuid(viewDuid);
    if (!view) {
      return undefined;
    }

    const userStore = this.$useUserStore();
    let backendAction: Promise<TransactionResponse>;

    if (favorite) {
      view.favoritedByUserDuids.push(userStore.duid);
      backendAction = this.$backend.view.updateListAdd({
        duid: view.duid,
        favoritedByUserDuids: [userStore.duid],
      });
    } else {
      filterInPlace(view.favoritedByUserDuids, (e) => e !== userStore.duid);
      backendAction = this.$backend.view.updateListRemove({
        duid: view.duid,
        favoritedByUserDuids: [userStore.duid],
      });
    }

    if (awaitBackend) {
      await backendAction;
    }

    this.$sortViews();

    return view;
  },
  async replicateView(view, order, partialView, options = {}) {
    const { awaitBackend = false, maintainTitle = false } = options;
    const appStore = this.$useAppStore();

    const layout = this.getLayoutByDuid(view.layoutDuid);
    if (!layout) {
      return undefined;
    }

    const newLayout = {
      ...JSON.parse(JSON.stringify(layout)),
      duid: makeDuid(),
    };

    this.$createOrUpdateLayout(newLayout);

    const now = new Date().toISOString();
    const newView: View = {
      ...JSON.parse(JSON.stringify(view)),
      duid: makeDuid(),
      createdAt: now,
      updatedAt: now,
      kind: ViewKind.CUSTOM,
      order,
      layoutDuid: newLayout.duid,
      ...partialView,
    };
    if (!maintainTitle && partialView?.title === undefined) {
      newView.title = getNextTitleInSequence(
        view.title,
        this.viewList.map((e) => e.title)
      );
    }

    // TODO do a better icon here
    if (view.kind === ViewKind.MY_TASKS) {
      newView.iconKind = IconKind.ICON;
      newView.iconNameOrEmoji = randomSample(appStore.pageIconOptions)[0].name;
    }

    this.$createOrUpdateView(newView);

    const backendAction = this.$backend.view.create(newView, newLayout);
    if (awaitBackend) {
      await backendAction;
    }

    return newView;
  },
  async deleteView(view, options = {}) {
    const { awaitBackend = false } = options;
    this.$deleteView(view);

    const backendAction = this.$backend.view.delete(view.duid);

    if (awaitBackend) {
      await backendAction;
    }
  },
  $sortViews() {
    this._duidsToViews = new Map([...this._duidsToViews].sort((a, b) => viewComparator(a[1], b[1])));
  },
  $setViews(views) {
    this._duidsToViews = new Map(views.map((e) => [e.duid, { ...e, pageKind: PageKind.VIEW }]));
    this.$sortViews();
  },
  $createOrUpdateView(view) {
    /* Shared */
    const currentView = this.getViewByDuid(view.duid);

    // TODO remove this check when we are filtering out spurious updates on BE of WS
    if (
      !(
        (this.$useUserStore().isRoleGreaterOrEqual(UserRole.MEMBER) && view.accessibleByTeam) ||
        view.accessibleByUserDuids.includes(this.$useUserStore().duid)
      )
    ) {
      if (currentView) {
        this.$deleteView(view);
      }
      return;
    }

    // eslint-disable-next-line no-param-reassign
    view.pageKind = PageKind.VIEW;

    if (!currentView) {
      this._duidsToViews.set(view.duid, view);
    } else {
      Object.assign(currentView, view);
    }

    this.$sortViews();
  },
  $deleteView(view) {
    const deletingCurrentPage = view.duid === this.$useAppStore().currentPage?.duid;

    this._duidsToViews.delete(view.duid);

    if (deletingCurrentPage) {
      this.$routerUtils.goHome();
    }
  },
  async updatePage(pagePartial, pageKind, options = {}) {
    switch (pageKind) {
      case PageKind.DARTBOARD: {
        await this.updateDartboard(pagePartial as DartboardUpdate, options);
        break;
      }
      case PageKind.DASHBOARD: {
        await this.updateDashboard(pagePartial as DashboardUpdate, options);
        break;
      }
      case PageKind.DOC: {
        await this.updateDocs([pagePartial], options);
        break;
      }
      case PageKind.FOLDER: {
        await this.updateFolder(pagePartial as FolderUpdate, options);
        break;
      }
      case PageKind.FORM: {
        await this.updateForm(pagePartial, options);
        break;
      }
      case PageKind.SPACE: {
        await this.updateSpace(pagePartial, options);
        break;
      }
      case PageKind.VIEW: {
        await this.updateView(pagePartial, options);
        break;
      }
      default: {
        throw new Error(`Unknown page kind: ${pageKind}`);
      }
    }
  },
  async updatePageAccessors(page, accessorDuids, add, options = {}) {
    const { pageKind } = page;
    switch (pageKind) {
      case PageKind.SPACE: {
        await this.updateSpaceAccessors(page.duid, accessorDuids, add, options);
        break;
      }
      case PageKind.VIEW: {
        await this.updateViewAccessors(page.duid, accessorDuids, add, options);
        break;
      }
      case PageKind.DASHBOARD: {
        await this.updateDashboardAccessors(page.duid, accessorDuids, add, options);
        break;
      }
      default: {
        throw new Error(`Unknown page kind: ${pageKind}`);
      }
    }
  },
  async replicatePage(page, options = {}) {
    const { pageKind } = page;
    // TODO get a better order here
    const order = getOrdersBetween(undefined, page.order)[0];
    switch (pageKind) {
      case PageKind.DARTBOARD: {
        await this.replicateDartboard(page, order, {}, options);
        break;
      }
      case PageKind.DOC: {
        await this.replicateDoc(page, order, {}, options);
        break;
      }
      case PageKind.FOLDER: {
        await this.replicateFolder(page, order, {}, options);
        break;
      }
      case PageKind.SPACE: {
        await this.replicateSpace(page, order, {}, options);
        break;
      }
      case PageKind.VIEW: {
        await this.replicateView(page, order, {}, options);
        break;
      }
      case PageKind.DASHBOARD: {
        await this.replicateDashboard(page, order, {}, options);
        break;
      }
      default: {
        throw new Error(`Unknown page kind: ${pageKind}`);
      }
    }
  },
  async deletePage(page, options = {}) {
    const { pageKind } = page;
    switch (pageKind) {
      case PageKind.DARTBOARD: {
        this.deleteDartboard(page, options);
        break;
      }
      case PageKind.DOC: {
        await this.trashDoc(page, options);
        break;
      }
      case PageKind.FOLDER: {
        await this.deleteFolder(page, options);
        break;
      }
      case PageKind.SPACE: {
        this.deleteSpace(page);
        break;
      }
      case PageKind.VIEW: {
        this.deleteView(page, options);
        break;
      }
      case PageKind.DASHBOARD: {
        this.deleteDashboard(page, options);
        break;
      }
      default: {
        throw new Error(`Unknown page kind: ${pageKind}`);
      }
    }
  },
  makeLayoutNoBackend(partialLayout) {
    const now = new Date().toISOString();

    const newLayout: Layout = {
      kind: LayoutKind.LIST,
      createdAt: now,
      updatedAt: now,
      filterGroup: {
        filters: [],
        connector: FilterConnector.AND,
      },
      sorts: [],
      kindConfigMap: {
        [LayoutKind.LIST]: {
          subtaskDisplayMode: SubtaskDisplayMode.INDENTED,
          showAbsentees: true,
          groupBy: getDefaultListGroupBy(),
          collapsedGroups: [],
          hideEmptyGroups: false,
        },
        [LayoutKind.BOARD]: {
          subtaskDisplayMode: SubtaskDisplayMode.INDENTED,
          showAbsentees: true,
          groupBy: getDefaultBoardGroupBy(),
          collapsedGroups: [],
          hideEmptyGroups: false,
        },
        [LayoutKind.CALENDAR]: {
          subtaskDisplayMode: SubtaskDisplayMode.INDENTED,
          showAbsentees: true,
          displayPeriod: "month",
          displayPeriodCount: 1,
          colorBy: UNCOLORED_PSEUDO_COLOR_BY,
        },
        [LayoutKind.ROADMAP]: {
          subtaskDisplayMode: SubtaskDisplayMode.INDENTED,
          showAbsentees: true,
          pxPerDay: 10,
          taskListWidthPx: 550,
          colorBy: UNCOLORED_PSEUDO_COLOR_BY,
        },
      },
      summaryStatisticKind: SummaryStatisticKind.TOTAL_COUNT,
      duid: makeDuid(),
      ...partialLayout,
    };

    this.$createOrUpdateLayout(newLayout);

    return newLayout;
  },
  async createLayoutForDartboard(dartboardDuid, filters, options = {}) {
    const { awaitBackend = false } = options;
    const dartboard = this.getDartboardByDuid(dartboardDuid);
    if (!dartboard) {
      return undefined;
    }

    const userDuid = this.$useUserStore().duid;
    const newLayout = this.makeLayoutNoBackend({ filterGroup: { filters, connector: FilterConnector.AND } });
    dartboard.userDuidsToLayoutDuids.push({
      userDuid,
      layoutDuid: newLayout.duid,
    });

    const userDartboardLayout = {
      userDuid,
      dartboardDuid,
      layoutDuid: newLayout.duid,
    };
    const backendAction = this.$backend.layout.create(newLayout, userDartboardLayout);
    if (awaitBackend) {
      await backendAction;
    }

    return newLayout;
  },
  async updateLayout(update, options = {}) {
    const { noBackend = false, awaitBackend = false } = options;

    const layout = this.getLayoutByDuid(update.duid);
    if (!layout) {
      return undefined;
    }

    Object.assign(layout, update);
    layout.updatedAt = new Date().toISOString();

    let backendAction: Promise<TransactionResponse> | undefined;
    if (!noBackend && !this.$usePageStore().isPublicView) {
      this.$backend.layout.update(update);
    }

    if (awaitBackend && backendAction) {
      await backendAction;
    }

    return layout;
  },
  $updateLayoutsRemoveEntityDuidOrFiltersNoBackend(
    entityDuids,
    propertyDuids,
    { includeBackend = false, removeAllFilters = false } = {}
  ) {
    const layoutUpdates: LayoutUpdate[] = [];

    // Go through the filter groups and remove the entity duid
    this.layoutList.forEach((layout) => {
      // Use a deep copy because we don't want to modify the original layout just yet
      const updatedFilterGroup: FilterGroup = deepCopy(layout.filterGroup);

      let changed = false;
      const indicesOfFiltersToRemove: number[] = [];

      // We're only removing the entity duid from the filter values
      updatedFilterGroup.filters
        .filter((e) => new Set(propertyDuids).has(e.propertyDuid))
        .forEach((filter, filterIndex) => {
          const indicesOfEntityDuids = entityDuids
            .map((entityDuid) => filter.values.indexOf(entityDuid))
            .filter((index) => index !== -1);

          indicesOfEntityDuids.sort((a, b) => b - a); // Sort in desc to avoid index shifting issues when removing items

          indicesOfEntityDuids.forEach((index) => {
            filter.values.splice(index, 1);
            changed = true;
          });
          // if the filter is empty, remove it - or else it causes a weird visual bug
          if (filter.values.length === 0 || removeAllFilters) {
            indicesOfFiltersToRemove.push(filterIndex);
          }
        });

      if (indicesOfFiltersToRemove.length > 0) {
        indicesOfFiltersToRemove.sort((a, b) => b - a);

        indicesOfFiltersToRemove.forEach((index) => {
          updatedFilterGroup.filters.splice(index, 1);
        });
        changed = true;
      }

      if (changed) {
        layoutUpdates.push({
          duid: layout.duid,
          filterGroup: updatedFilterGroup,
        });
      }
    });

    layoutUpdates.forEach((update) => this.updateLayout(update, { noBackend: !includeBackend }));
    return layoutUpdates;
  },
  $updateLayoutsRemapDartboardFiltersNoBackend(dartboardDuidRemap) {
    const layoutUpdates: LayoutUpdate[] = [];

    // Go through the filter groups and update the dartboard duid
    const dartboardPropertyDuid = this.defaultDartboardProperty.duid;
    this.layoutList.forEach((layout) => {
      // Use a deep copy because we don't want to modify the original layout just yet
      const updatedFilterGroup = deepCopy(layout.filterGroup);

      let changed = false;
      updatedFilterGroup.filters
        .filter((e) => e.propertyDuid === dartboardPropertyDuid)
        .forEach((filter) => {
          filter.values.forEach((item, index) => {
            if (!isString(item)) {
              return;
            }
            const newValue = dartboardDuidRemap.get(item);
            if (!newValue) {
              return;
            }
            // eslint-disable-next-line no-param-reassign
            filter.values[index] = newValue;
            changed = true;
          });
        });

      if (changed) {
        layoutUpdates.push({
          duid: layout.duid,
          filterGroup: updatedFilterGroup,
        });
      }
    });

    layoutUpdates.forEach((update) => this.updateLayout(update, { noBackend: true }));
    return layoutUpdates;
  },
  async $replicateLayoutsForDartboardNoBackend(oldDartboardDuid, newDartboardDuid) {
    const res = {
      layoutUpdates: [] as LayoutUpdate[],
      layoutCreates: [] as Layout[],
      userDartboardLayouts: [] as UserDartboardLayout[],
      userDartboardLayoutCreates: [] as UserDartboardLayoutCreate[],
    };

    const oldDartboard = this.getDartboardByDuid(oldDartboardDuid);
    const newDartboard = this.getDartboardByDuid(newDartboardDuid);
    if (!oldDartboard) {
      return res;
    }

    // Create a map of user duids to layout duids for the new dartboard
    const newDartboardUserDuidToLayoutDuidMap = new Map(
      (newDartboard?.userDuidsToLayoutDuids ?? []).map((e) => [e.userDuid, e.layoutDuid])
    );

    const layoutPromises = oldDartboard.userDuidsToLayoutDuids.map(async (userDartboardLayout) => {
      const { userDuid, layoutDuid } = userDartboardLayout;
      const oldLayout = this.getLayoutByDuid(layoutDuid);
      if (!oldLayout) {
        return;
      }

      const oldLayoutWithoutDuid = deepCopy(oldLayout) as Partial<Layout>;
      delete oldLayoutWithoutDuid.duid;

      // If the user already has a layout for the new dartboard update the layout
      const existingLayoutDuid = newDartboardUserDuidToLayoutDuidMap.get(userDuid);
      if (existingLayoutDuid) {
        const updatedLayout = await this.updateLayout(
          { ...oldLayoutWithoutDuid, duid: existingLayoutDuid },
          { noBackend: true }
        );
        if (!updatedLayout) {
          return;
        }

        res.layoutUpdates.push(updatedLayout);
        return;
      }

      // Otherwise create a new layout for the user
      const newLayout = this.makeLayoutNoBackend(oldLayoutWithoutDuid);
      res.layoutCreates.push(newLayout);

      res.userDartboardLayouts.push({
        userDuid,
        layoutDuid: newLayout.duid,
      });

      res.userDartboardLayoutCreates.push({
        userDuid,
        dartboardDuid: newDartboardDuid,
        layoutDuid: newLayout.duid,
      });
    });
    await Promise.all(layoutPromises);

    return res;
  },
  $setLayouts(layouts, preservationMode) {
    const recentCutoff = moment().subtract(30, "second");

    const newLayouts: Layout[] = [];
    layouts.forEach((newLayout) => {
      const oldLayout = this.getLayoutByDuid(newLayout.duid);
      if (!oldLayout) {
        newLayouts.push(newLayout);
        return;
      }
      if (preservationMode === StoreDataPreservationMode.NONE) {
        Object.assign(oldLayout, newLayout);
        newLayouts.push(oldLayout);
        return;
      }
      if (preservationMode === StoreDataPreservationMode.ALL) {
        newLayouts.push(oldLayout);
        return;
      }
      if (moment(oldLayout.updatedAt).isAfter(recentCutoff)) {
        newLayouts.push(oldLayout);
        return;
      }
      Object.assign(oldLayout, newLayout);
      newLayouts.push(oldLayout);
    });
    const newLayoutDuids = new Set(newLayouts.map((e) => e.duid));
    newLayouts.push(
      ...[...this._duidsToLayouts.values()].filter(
        (e) => !newLayoutDuids.has(e.duid) && moment(e.createdAt).isAfter(recentCutoff)
      )
    );
    this._duidsToLayouts = new Map(newLayouts.map((e) => [e.duid, e]));
  },
  $createOrUpdateLayout(layout) {
    const currentLayout = this.getLayoutByDuid(layout.duid);

    if (!currentLayout) {
      // TODO handle bleeding of hidden views' layouts into the store
      this._duidsToLayouts.set(layout.duid, layout);
    } else {
      Object.assign(currentLayout, layout);
    }
  },
  $deleteLayout(layout) {
    this._duidsToLayouts.delete(layout.duid);
  },
  async createDashboard(order, partialDashboard, partialLayout, options = {}) {
    const { awaitBackend = false } = options;
    const appStore = this.$useAppStore();
    const userStore = this.$useUserStore();

    const newLayout = this.makeLayoutNoBackend(partialLayout);

    const newDashboard: Dashboard = {
      kind: DashboardKind.DASHBOARD,
      accessibleByTeam: false,
      accessibleByUserDuids: [userStore.duid],
      title: "",
      description: "",
      iconKind: IconKind.NONE,
      iconNameOrEmoji: randomSample(appStore.pageIconOptions)[0].name,
      colorHex: makeRandomColorHex(),
      favoritedByUserDuids: [],
      charts: [],
      ...partialDashboard,
      duid: makeDuid(),
      order,
      layoutDuid: newLayout.duid,
      pageKind: PageKind.DASHBOARD,
    };
    this.$createOrUpdateDashboard(newDashboard);

    const backendAction = this.$backend.dashboard.create(newDashboard, newLayout);
    if (awaitBackend) {
      await backendAction;
    }
    return newDashboard;
  },
  async updateDashboard(update, options = {}) {
    const { awaitBackend = false } = options;
    const dashboard = this.getDashboardByDuid(update.duid);
    if (!dashboard) {
      return undefined;
    }

    Object.assign(dashboard, update);
    this.$sortDashboards();

    const backendAction = this.$backend.dashboard.update(update);
    if (awaitBackend) {
      await backendAction;
    }

    return dashboard;
  },
  async updateDashboardAccessors(duid, accessorDuids, add, options = {}) {
    const { awaitBackend = false } = options;
    const dashboard = this.getDashboardByDuid(duid);
    if (!dashboard) {
      return undefined;
    }

    let backendAction: Promise<TransactionResponse>;

    if (add) {
      dashboard.accessibleByUserDuids.push(...accessorDuids);
      backendAction = this.$backend.dashboard.updateListAdd({
        duid: dashboard.duid,
        accessibleByUserDuids: accessorDuids,
      });
    } else {
      filterInPlace(dashboard.accessibleByUserDuids, (e) => !accessorDuids.includes(e));
      backendAction = this.$backend.dashboard.updateListRemove({
        duid: dashboard.duid,
        accessibleByUserDuids: accessorDuids,
      });
    }

    if (awaitBackend) {
      await backendAction;
    }

    this.$sortDashboards();

    return dashboard;
  },
  async updateDashboardFavorite(dashboardDuid, favorite, options = {}) {
    const { awaitBackend = false } = options;
    const dashboard = this.getDashboardByDuid(dashboardDuid);
    if (!dashboard) {
      return undefined;
    }

    const userStore = this.$useUserStore();
    let backendAction: Promise<TransactionResponse>;

    if (favorite) {
      dashboard.favoritedByUserDuids.push(userStore.duid);
      backendAction = this.$backend.dashboard.updateListAdd({
        duid: dashboard.duid,
        favoritedByUserDuids: [userStore.duid],
      });
    } else {
      filterInPlace(dashboard.favoritedByUserDuids, (e) => e !== userStore.duid);
      backendAction = this.$backend.dashboard.updateListRemove({
        duid: dashboard.duid,
        favoritedByUserDuids: [userStore.duid],
      });
    }

    if (awaitBackend) {
      await backendAction;
    }

    this.$sortDashboards();

    return dashboard;
  },
  async replicateDashboard(dashboard, order, partialDashboard, options = {}) {
    const { awaitBackend = false, maintainTitle = false } = options;

    const oldLayout = this.getLayoutByDuid(dashboard.layoutDuid);
    if (!oldLayout) {
      return undefined;
    }
    const newLayout = {
      ...JSON.parse(JSON.stringify(oldLayout)),
      duid: makeDuid(),
    };
    this.$createOrUpdateLayout(newLayout);

    const now = new Date().toISOString();
    const newDashboard: Dashboard = {
      ...JSON.parse(JSON.stringify(dashboard)),
      duid: makeDuid(),
      createdAt: now,
      updatedAt: now,
      kind: DashboardKind.DASHBOARD,
      order,
      layoutDuid: newLayout.duid,
      ...partialDashboard,
    };
    if (!maintainTitle && partialDashboard?.title === undefined) {
      newDashboard.title = getNextTitleInSequence(
        dashboard.title,
        this.dashboardList.map((e) => e.title)
      );
    }

    this.$createOrUpdateDashboard(newDashboard);

    const backendAction = this.$backend.dashboard.create(newDashboard, newLayout);
    if (awaitBackend) {
      await backendAction;
    }

    return newDashboard;
  },
  async deleteDashboard(dashboard, options = {}) {
    const { awaitBackend = false } = options;
    this.$deleteDashboard(dashboard);

    const backendAction = this.$backend.dashboard.delete(dashboard.duid);

    if (awaitBackend) {
      await backendAction;
    }
  },
  $sortDashboards() {
    const dashboardComparator = makeDashboardComparator();
    this._duidsToDashboards = new Map([...this._duidsToDashboards].sort((a, b) => dashboardComparator(a[1], b[1])));
  },
  $setDashboards(dashboards) {
    this._duidsToDashboards = new Map(
      dashboards.map((e) => [e.duid, { ...e, pageKind: PageKind.DASHBOARD, kind: DashboardKind.DASHBOARD }])
    );
    this.$sortDashboards();
  },
  $createOrUpdateDashboard(dashboard) {
    /* Shared */
    const currentDashboard = this.getDashboardByDuid(dashboard.duid);

    // TODO remove this check when we are filtering out spurious updates on BE of WS
    if (
      !(
        (this.$useUserStore().isRoleGreaterOrEqual(UserRole.MEMBER) && dashboard.accessibleByTeam) ||
        dashboard.accessibleByUserDuids.includes(this.$useUserStore().duid)
      )
    ) {
      if (currentDashboard) {
        this.$deleteDashboard(dashboard);
      }
      return;
    }

    // eslint-disable-next-line no-param-reassign
    dashboard.pageKind = PageKind.DASHBOARD;

    if (!currentDashboard) {
      this._duidsToDashboards.set(dashboard.duid, dashboard);
    } else {
      Object.assign(currentDashboard, dashboard);
    }

    this.$sortDashboards();
  },
  $deleteDashboard(dashboard) {
    const deletingCurrentPage = dashboard.duid === this.$useAppStore().currentPage?.duid;

    this._duidsToDashboards.delete(dashboard.duid);

    if (deletingCurrentPage) {
      this.$routerUtils.goHome();
    }
  },
};

export { actions, getters };
