<script setup lang="ts">
import { computed } from "vue";

import TaskCard from "~/components/dumb/TaskCard.vue";
import { DuplicatesRelationshipIcon, OpenExternalLinkIcon } from "~/icons";
import { EventActor } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useEnvironmentStore } from "~/stores";
import { getItemCountText } from "~/utils/common";

const environmentStore = useEnvironmentStore();

const props = defineProps<{
  tasks: Task[];
}>();

const emit = defineEmits<{
  markDuplicate: [task: Task];
  clear: [];
}>();

const singular = computed(() => props.tasks.length === 1);

const openOtherTask = (task: Task) => {
  window.open(environmentStore.getTaskUrl(task), "_blank");
};
</script>

<template>
  <div class="flex flex-col gap-2 p-3">
    <div class="flex items-center gap-3">
      <DuplicatesRelationshipIcon class="text-warning-base icon-md" aria-hidden="true" />
      <span class="text-base font-semibold text-md">Potential duplicate task{{ singular ? "" : "s" }}</span>
    </div>
    <span class="text-sm font-normal text-md">
      {{ EventActor.DART_AI }} automatically detected {{ getItemCountText(tasks.length, "task") }} that
      {{ singular ? "is" : "are" }} similar to this one.
    </span>

    <div
      v-for="task in tasks"
      :key="task.duid"
      class="group/dup-card relative flex flex-col gap-2 rounded border p-2 border-md">
      <TaskCard :task="task" include-dartboard />
      <div
        class="absolute inset-0 z-10 hidden cursor-pointer gap-2 bg-gray-200/90 p-2 text-sm text-md group-hover/dup-card:flex dark:bg-zinc-700/90">
        <div
          class="flex h-full w-1/2 flex-col items-center justify-center gap-1 rounded hover:bg-hvy"
          @click="emit('markDuplicate', task)"
          @keydown="emit('markDuplicate', task)">
          <DuplicatesRelationshipIcon class="icon-sm" />
          <span class="text-sm">Mark duplicate</span>
        </div>
        <div
          class="flex h-full w-1/2 flex-col items-center justify-center gap-1 rounded hover:bg-hvy"
          @click="openOtherTask(task)"
          @keydown="openOtherTask(task)">
          <OpenExternalLinkIcon class="icon-sm" />
          <span class="text-sm">View task</span>
        </div>
      </div>
    </div>

    <button
      type="button"
      class="cursor-pointer self-end rounded border border-transparent bg-primary-base px-3 py-1 text-sm font-normal hover-bg-primary text-oncolor"
      @click="emit('clear')">
      Clear
    </button>
  </div>
</template>
