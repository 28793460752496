<script setup lang="ts">
import { computed, onUnmounted, ref } from "vue";

import { useAppStore, usePageStore } from "~/stores";

import PanArea from "../PanArea.vue";
import { getDiffPx, getIntervalPx, getPixelsTo, makeTimeForPixelsToNowRef } from "./common";
import { TASK_ROW_HEIGHT, ZOOM_MULTIPLIER_PER_SCROLL_Y } from "./constants";
import DependencyLines from "./DependencyLines.vue";
import RoadmapTasks from "./RoadmapTasks.vue";
import type { PreviewRange, RangeDefinition, RoadmapConfig, RoadmapValues } from "./shared";

const props = defineProps<{
  roadmapConfig: RoadmapConfig;
  roadmapValues: RoadmapValues;
}>();

const appStore = useAppStore();
const pageStore = usePageStore();

const emit = defineEmits<{
  activeChange: [activeDuid: string | null];
  scroll: [];
  zoom: [pxPerDay: number];
  updatePreviewRange: [previewRange: PreviewRange];
}>();

const definition = computed<RangeDefinition>(() => props.roadmapValues.definition);

/* Scrolling */
const panAreaRef = ref<InstanceType<typeof PanArea> | null>(null);
const scrollContainer = computed<HTMLElement | null>(() => panAreaRef.value?.wrapper ?? null);
const scrollTo = (x?: number, y?: number): void => {
  if (!scrollContainer.value) {
    return;
  }

  const maximum = scrollContainer.value.scrollWidth - scrollContainer.value.clientWidth;
  scrollContainer.value.scrollTo({
    left: x ? Math.min(Math.max(0, x), maximum) : undefined,
    top: y,
  });
};

/* Range */
const timeline = computed(() => [
  {
    value: props.roadmapConfig.startDate,
    width: `${getPixelsTo(props.roadmapConfig, props.roadmapValues.timelineDates[0])}px`,
    left: "0px",
  },
  ...props.roadmapValues.timelineDates.map((value) => ({
    value,
    width: `${getIntervalPx(props.roadmapConfig, value, definition.value.timelineInterval)}px`,
    left: `${getDiffPx(props.roadmapConfig, props.roadmapConfig.startDate, value)}px`,
  })),
]);

/* Today line */
const todayPixels = makeTimeForPixelsToNowRef(computed(() => props.roadmapConfig));
const todayLine = computed<string>(() => `${todayPixels.value.pixels}px`);

/* Scroll wheel zoom */
const zoom = (event: WheelEvent): void => {
  if (!(pageStore.isMac ? event.metaKey : event.ctrlKey)) {
    return;
  }

  event.preventDefault();
  event.stopPropagation();

  emit("zoom", props.roadmapConfig.pxPerDay / ZOOM_MULTIPLIER_PER_SCROLL_Y ** event.deltaY);
};

const deselectAll = () => {
  appStore.getActiveVisualization().deselectAll();
};

onUnmounted(() => {
  todayPixels.value.destroy();
});

defineExpose({
  scrollTo,
  scrollContainer,
});
</script>

<template>
  <PanArea
    ref="panAreaRef"
    v-scroll-sync:roadmap.x.y
    class="flex flex-1 items-center"
    @click="deselectAll"
    @keydown.enter="deselectAll"
    @wheel="zoom"
    @scroll="emit('scroll')">
    <div
      class="absolute inset-0 flex size-full flex-1 items-center"
      :style="{
        minWidth: `${roadmapValues.scrollWidth}px`,
        maxWidth: `${roadmapValues.scrollWidth}px`,
        minHeight: `${roadmapValues.scrollHeight + TASK_ROW_HEIGHT}px`,
      }">
      <!-- Sections -->
      <div
        v-for="unit in timeline"
        :id="`timeline-${unit.value}`"
        :key="unit.value"
        class="absolute inset-y-0 h-full border-r border-lt"
        :style="{ left: unit.left, minWidth: unit.width, maxWidth: unit.width }" />

      <DependencyLines :roadmap-config="roadmapConfig" :roadmap-values="roadmapValues" />

      <!-- Today line -->
      <div class="absolute inset-y-0 w-0.5 bg-primary-base" :style="{ left: todayLine }" />
      <div
        v-if="definition.exact"
        class="absolute top-0 -mt-1.5 ml-[-5px] size-3 rounded-full bg-primary-base"
        :style="{ left: todayLine }" />

      <!-- Tasks -->
      <RoadmapTasks
        :roadmap-config="roadmapConfig"
        :roadmap-values="roadmapValues"
        @active-change="(e) => emit('activeChange', e)"
        @scroll-to="(x, y) => scrollTo(x, y)"
        @update-preview-range="(e) => emit('updatePreviewRange', e)" />
    </div>
  </PanArea>
</template>
