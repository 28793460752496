<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="m18 17-5-5 5-5m-7 10-5-5 5-5"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
