import { type Component } from "vue";

import FormNumberInput from "~/components/dumb/FormNumberInput.vue";
import FormTextInput from "~/components/dumb/FormTextInput.vue";
// TODO refactor and rename this chip, & StatusChip & UserChip
import SizeChip from "~/components/dumb/SizeChip.vue";
import {
  UNASSIGNED_ASSIGNEE_LABEL,
  UNASSIGNED_PSEUDO_ASSIGNEE_KEY,
  UNKNOWN_USER_LABEL,
  UNSET_USER_LABEL,
} from "~/components/visualization/constants";
import { NUMBER_SIZE_TO_TSHIRT_SIZE } from "~/constants/size";
import { DART_AI_PSEUDO_USER_KEY } from "~/constants/user";
import {
  AttachmentFieldIcon,
  CalendarIcon,
  CheckboxFieldIcon,
  DartboardFieldIcon,
  DatesFieldIcon,
  DescriptionFieldIcon,
  MultiselectFieldIcon,
  NumberFieldIcon,
  PriorityFieldIcon,
  SelectFieldIcon,
  SizeFieldIcon,
  StatusFieldIcon,
  TaskTypeFieldIcon,
  TextFieldIcon,
  TimeTrackingFieldIcon,
  TitleFieldIcon,
  UserFieldIcon,
} from "~/icons";
import { EditorMode, Entitlement, LayoutKind, PropertyKind, TaskSourceType } from "~/shared/enums";
import type {
  DateRange,
  Position,
  Property,
  PropertyConfig,
  PropertyValue,
  Task,
  TimeTracking,
  TypeaheadOption,
} from "~/shared/types";
import type { useAppStore, usePageStore, useUserStore } from "~/stores";
import type { DataStore } from "~/stores/DataStore";
import { getPageDisplayName, getText } from "~/utils/common";
import {
  checkboxComparator,
  dateComparator,
  makeDateOrDatesComparator,
  makePropertyComparator,
  numberMaybeComparator,
  optionComparator,
  optionsComparator,
  positionComparator,
  priorityComparator,
  sign,
  sizeComparator,
  statusComparator,
  stringComparator,
  timeTrackingComparator,
  userComparator,
  usersComparator,
} from "~/utils/comparator";
import { getRelativeTimeForDatesDate, getTimeTrackingDurationText } from "~/utils/time";

import * as Filter from "./filter";
import * as GroupBy from "./groupBy";
import * as SmartMatch from "./smartMatch";
import * as Typeahead from "./typeahead";

type AppStore = ReturnType<typeof useAppStore>;
type PageStore = ReturnType<typeof usePageStore>;
type UserStore = ReturnType<typeof useUserStore>;

const ALL_LAYOUTS = Object.values(LayoutKind);
const FINAL_COLUMN_ORDER_OVERRIDES = new Map(
  [PropertyKind.DEFAULT_TAGS, PropertyKind.DEFAULT_ASSIGNEES, PropertyKind.DEFAULT_STATUS].map((e, i) => [e, i])
);

// TODO remove all usages of this for unused types
const DummyComponent = null as unknown as Component;

let dataStore: DataStore;
let appStore: AppStore;
let pageStore: PageStore;
let userStore: UserStore;

let AdtlDates: Component;
let AdtlNumber: Component;
let AdtlSelect: Component;
let AdtlStatus: Component;
let AdtlUser: Component;
let CheckboxCellEditor: Component;
let CheckboxChip: Component;
let CheckboxEditor: Component;
let DartboardEditor: Component;
let DatesCellEditor: Component;
let DatesChip: Component;
let DatesEditor: Component;
let FormAttachmentEditor: Component;
let FormEditor: Component;
let MultiselectChip: Component;
let NumberCellEditor: Component;
let NumberChip: Component;
let NumberEditor: Component;
let OptionCellEditor: Component;
let OptionsEditor: Component;
let PriorityCellEditor: Component;
let PriorityChip: Component;
let PriorityEditor: Component;
let SelectCellEditor: Component;
let SelectEditor: Component;
let SizeCellEditor: Component;
let SizeEditor: Component;
let StatusCellEditor: Component;
let StatusChip: Component;
let StatusEditor: Component;
let TaskTypeEditor: Component;
let TaskKindCellEditor: Component;
let TextCellEditor: Component;
let TextChip: Component;
let TextEditor: Component;
let TimeTrackingCellEditor: Component;
let TimeTrackingEditor: Component;
let UserCellEditor: Component;
let UserChip: Component;
let UserEditor: Component;

export const init = (
  newDataStore: DataStore,
  adtlDates: Component,
  adtlNumber: Component,
  adtlSelect: Component,
  adtlStatus: Component,
  adtlUser: Component,
  avatar: Component,
  checkboxCellEditor: Component,
  checkboxChip: Component,
  checkboxEditor: Component,
  dartboardEditor: Component,
  datesCellEditor: Component,
  datesChip: Component,
  datesEditor: Component,
  formAttachmentEditor: Component,
  formEditor: Component,
  multiselectChip: Component,
  numberCellEditor: Component,
  numberChip: Component,
  numberEditor: Component,
  optionCellEditor: Component,
  optionsEditor: Component,
  pageIcon: Component,
  priorityCellEditor: Component,
  priorityChip: Component,
  priorityEditor: Component,
  selectCellEditor: Component,
  selectEditor: Component,
  sizeCellEditor: Component,
  sizeEditor: Component,
  statusCellEditor: Component,
  statusChip: Component,
  statusEditor: Component,
  statusIcon: Component,
  taskKindEditor: Component,
  taskKindCellEditor: Component,
  textCellEditor: Component,
  textChip: Component,
  textEditor: Component,
  timeTrackingEditor: Component,
  timeTrackingCellEditor: Component,
  userCellEditor: Component,
  userChip: Component,
  userEditor: Component
) => {
  dataStore = newDataStore;
  appStore = dataStore.$useAppStore();
  pageStore = dataStore.$usePageStore();
  userStore = dataStore.$useUserStore();

  Filter.init(dataStore, avatar, pageIcon, statusIcon);
  GroupBy.init(dataStore, avatar, pageIcon, statusIcon);
  SmartMatch.init(dataStore);
  Typeahead.init(dataStore, avatar, pageIcon, statusIcon);

  AdtlDates = adtlDates;
  AdtlNumber = adtlNumber;
  AdtlSelect = adtlSelect;
  AdtlStatus = adtlStatus;
  AdtlUser = adtlUser;
  CheckboxCellEditor = checkboxCellEditor;
  CheckboxChip = checkboxChip;
  CheckboxEditor = checkboxEditor;
  DartboardEditor = dartboardEditor;
  DatesCellEditor = datesCellEditor;
  DatesChip = datesChip;
  DatesEditor = datesEditor;
  FormAttachmentEditor = formAttachmentEditor;
  FormEditor = formEditor;
  MultiselectChip = multiselectChip;
  NumberCellEditor = numberCellEditor;
  NumberChip = numberChip;
  NumberEditor = numberEditor;
  OptionCellEditor = optionCellEditor;
  OptionsEditor = optionsEditor;
  PriorityCellEditor = priorityCellEditor;
  PriorityChip = priorityChip;
  PriorityEditor = priorityEditor;
  SelectCellEditor = selectCellEditor;
  SelectEditor = selectEditor;
  SizeCellEditor = sizeCellEditor;
  SizeEditor = sizeEditor;
  StatusCellEditor = statusCellEditor;
  StatusChip = statusChip;
  StatusEditor = statusEditor;
  TaskTypeEditor = taskKindEditor;
  TaskKindCellEditor = taskKindCellEditor;
  TextCellEditor = textCellEditor;
  TextChip = textChip;
  TextEditor = textEditor;
  TimeTrackingEditor = timeTrackingEditor;
  TimeTrackingCellEditor = timeTrackingCellEditor;
  UserCellEditor = userCellEditor;
  UserChip = userChip;
  UserEditor = userEditor;
};

const getOptionsValuePretty = (duids: string[]) =>
  dataStore
    .getOptionsByDuidsOrdered(duids)
    .map((e) => {
      let curr = e;
      const res = [];
      for (let i = 0; i < 100; i += 1) {
        res.unshift(curr.title);
        const parent = curr.parentDuid ? dataStore.getOptionByDuid(curr.parentDuid) : null;
        if (!parent) {
          break;
        }
        curr = parent;
      }
      return res.join(" / ");
    })
    .join(", ");

const getDateValuePretty = (date: string | null) => {
  if (!date) {
    return "";
  }
  return `${date.split(".")[0]}Z`;
};

const getDatesValuePretty = (startAt: string | null, dueAt: string | null, isRange: boolean) => {
  if (!isRange) {
    if (!startAt) {
      return "";
    }
    return startAt.split("T")[0];
  }
  if (!startAt && !dueAt) {
    return "";
  }
  return `${startAt?.split("T")[0] ?? ""} -> ${dueAt?.split("T")[0] ?? ""}`;
};

const createPropertyConfig = <K extends PropertyKind>(
  kind: K,
  config: Omit<PropertyConfig<K>, "kind">
): [K, PropertyConfig<K>] => [kind, { ...config, kind }];

const PROPERTY_DEFAULT_KIND = createPropertyConfig(PropertyKind.DEFAULT_KIND, {
  label: "Type",
  isDefault: true,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: TaskTypeFieldIcon,
  getValue: (_property, task) => task.kindDuid,
  getValuePretty: (_property, task) => dataStore.getTaskKindByDuid(task.kindDuid)?.title ?? "",
  getPartialTask: (_property, _task, value) => ({ kindDuid: value }),
  typeahead: Typeahead.KIND_TYPEAHEAD,
  smartMatch: SmartMatch.KIND_SMART_MATCH,
  groupBy: GroupBy.KIND_GROUP_BY,
  filter: Filter.KIND_FILTER,
  chip: () => ({
    component: TaskTypeEditor,
    getValues: (property, task) => ({
      value: task.kindDuid,
      property,
      tasks: [task],
      editorMode: EditorMode.CHIP,
    }),
  }),
  contextMenu: () => ({
    component: TaskTypeEditor,
    getValues: (property, _task, selectedTasks) => ({
      property,
      tasks: selectedTasks,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: TaskTypeEditor,
    getValues: (property, _field, value, onUpdate) => ({
      property,
      tasks: [],
      editorMode: EditorMode.FORM,
      value,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: TaskTypeEditor,
    getValues: (property, task) => ({ property, tasks: [task], value: task.kindDuid }),
  }),
  listColumns: () => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: TaskKindCellEditor,
    cellRendererParams: {
      editorMode: EditorMode.LIST,
    },
    sortable: true,
    comparatorFn: (a, b) => stringComparator(a, b),
    suppressHeaderMenuButton: true,
    valueGetter: (params) => params.data?.kindDuid ?? dataStore.defaultTaskKind.duid,
    minWidth: 100,
    maxWidth: 100,
    resizable: false,
    getSearchValue: (_value, task) => dataStore.getTaskKindByDuid(task.kindDuid)?.title ?? "",
  }),
});

const PROPERTY_DEFAULT_TITLE = createPropertyConfig(PropertyKind.DEFAULT_TITLE, {
  label: "Title",
  isDefault: true,
  alwaysShowForLayouts: ALL_LAYOUTS,
  lockPosition: "left",
  icon: TitleFieldIcon,
  getValue: (_property, task) => task.title,
  getValuePretty: (_property, task) => task.title,
  getPartialTask: () => ({}),
  typeahead: null,
  smartMatch: null,
  groupBy: null,
  filter: Filter.TITLE_FILTER,
  chip: () => ({
    component: DummyComponent,
    getValues: () => ({}),
  }),
  contextMenu: () => ({
    component: DummyComponent,
    getValues: () => ({}),
  }),
  formField: () => ({
    component: FormTextInput,
    getValues: (property, _field, value, onUpdate) => ({
      property,
      value,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: DummyComponent,
    getValues: () => ({}),
  }),
  listColumns: () => ({}),
});

const PROPERTY_DEFAULT_DESCRIPTION = createPropertyConfig(PropertyKind.DEFAULT_DESCRIPTION, {
  label: "Description",
  isDefault: true,
  icon: DescriptionFieldIcon,
  getValue: (_property, task) => task.description,
  getValuePretty: (_property, task) => getText(task.description),
  getPartialTask: () => ({}),
  typeahead: null,
  smartMatch: null,
  groupBy: null,
  filter: Filter.DESCRIPTION_FILTER,
  chip: () => ({
    component: DummyComponent,
    getValues: () => ({}),
  }),
  contextMenu: () => ({
    component: DummyComponent,
    getValues: () => ({}),
  }),
  formField: () => ({
    component: FormEditor,
    getValues: (property, _field, value, onUpdate) => ({
      property,
      value,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: DummyComponent,
    getValues: () => ({}),
  }),
  listColumns: () => ({}),
});

const PROPERTY_DEFAULT_DARTBOARD = createPropertyConfig(PropertyKind.DEFAULT_DARTBOARD, {
  label: "Dartboard",
  isDefault: true,
  icon: DartboardFieldIcon,
  getValue: (_property, task) => task.dartboardDuid,
  getValuePretty: (_property, task) =>
    getPageDisplayName(dataStore.getDartboardByDuid(task.dartboardDuid), dataStore.getSpaceByDuid),
  getPartialTask: (_property, _task, value) => ({ dartboardDuid: value }),
  typeahead: Typeahead.DARTBOARD_TYPEAHEAD,
  smartMatch: SmartMatch.DARTBOARD_SMART_MATCH,
  groupBy: GroupBy.DARTBOARD_GROUP_BY,
  filter: Filter.DARTBOARD_FILTER,
  chip: () => ({
    component: DummyComponent,
    getValues: () => ({}),
  }),
  contextMenu: () => ({
    component: DummyComponent,
    getValues: () => ({}),
  }),
  formField: () => ({
    component: DartboardEditor,
    getValues: (_property, _field, value, onUpdate, isSubmitMode) => ({
      tasks: [],
      editorMode: EditorMode.FORM,
      value,
      hideUnset: isSubmitMode,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: DummyComponent,
    getValues: () => ({}),
  }),
  listColumns: () => ({}),
});

const PROPERTY_DEFAULT_STATUS = createPropertyConfig(PropertyKind.DEFAULT_STATUS, {
  label: PropertyKind.STATUS,
  isDefault: true,
  alwaysShowForLayouts: ALL_LAYOUTS,
  lockPosition: "right",
  entitlement: Entitlement.STATUSES,
  icon: StatusFieldIcon,
  settingsComponent: () => AdtlStatus,
  getValue: (_property, task) => task.statusDuid,
  getValuePretty: (_property, task) => dataStore.getStatusByDuid(task.statusDuid)?.title ?? "",
  getPartialTask: (_property, _task, value) => ({ statusDuid: value }),
  typeahead: Typeahead.STATUS_TYPEAHEAD,
  smartMatch: SmartMatch.STATUS_SMART_MATCH,
  groupBy: GroupBy.STATUS_GROUP_BY,
  filter: Filter.STATUS_FILTER,
  chip: () => ({
    component: StatusChip,
    getValues: (property, task) => ({
      value: task.statusDuid,
      property,
    }),
  }),
  contextMenu: () => ({
    component: StatusEditor,
    getValues: (property, _task, selectedTasks) => ({
      property,
      tasks: selectedTasks,
      locked: false,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: StatusEditor,
    getValues: (property, _field, value, onUpdate, isSubmitMode) => ({
      property,
      tasks: [],
      value,
      hideUnset: isSubmitMode,
      editorMode: EditorMode.FORM,
      showText: true,
      onSelect: onUpdate,
    }),
  }),
  editor: () => ({
    component: StatusEditor,
    getValues: (property, task) => ({
      property,
      tasks: [task],
      showText: true,
    }),
  }),
  listColumns: () => ({
    cellRenderer: StatusCellEditor,
    cellRendererParams: {
      editorMode: EditorMode.LIST,
    },
    sortable: true,
    comparatorFn: (a: string, b: string) =>
      statusComparator(dataStore.getStatusByDuid(a), dataStore.getStatusByDuid(b)),
    suppressHeaderMenuButton: true,
    valueGetter: (params) => params.data?.statusDuid ?? dataStore.defaultDefaultUnstartedStatus.duid,
    minWidth: 60,
    maxWidth: 60,
    resizable: false,
    getSearchValue: (value: string) => {
      const status = dataStore.getStatusByDuid(value);
      if (!status) {
        const defaultStatus = dataStore.defaultDefaultUnstartedStatus;
        return `${defaultStatus.title} ${defaultStatus.kind}`;
      }
      return `${status.title} ${status.kind}`;
    },
  }),
});

const PROPERTY_DEFAULT_ASSIGNEES = createPropertyConfig(PropertyKind.DEFAULT_ASSIGNEES, {
  label: PropertyKind.USER,
  isDefault: true,
  icon: UserFieldIcon,
  settingsComponent: () => AdtlUser,
  getValue: (_property, task) => task.assigneeDuids,
  getValuePretty: (_property, task) =>
    dataStore
      .getUsersByDuidsOrdered(task.assigneeDuids)
      .map((e) => e.name || e.email)
      .join(", "),
  getPartialTask: (_property, _task, value) => {
    const valueNorm = Array.isArray(value) ? value : value ? [value] : [];
    if (valueNorm.length !== 0 && valueNorm[0] === DART_AI_PSEUDO_USER_KEY) {
      return { assignedToAi: true };
    }
    if (valueNorm.length !== 0 && valueNorm[0] === UNASSIGNED_PSEUDO_ASSIGNEE_KEY) {
      return { assigneeDuids: [] };
    }
    return { assigneeDuids: valueNorm };
  },
  typeahead: Typeahead.USER_TYPEAHEAD,
  smartMatch: SmartMatch.USER_SMART_MATCH,
  groupBy: GroupBy.USER_GROUP_BY,
  filter: Filter.USER_FILTER,
  chip: () => ({
    component: UserChip,
    getValues: (property, task) => ({
      value: task.assigneeDuids,
      isMultiple: property.adtl.isMultiple,
      property,
    }),
  }),
  contextMenu: () => ({
    component: UserEditor,
    getValues: (property, _task, selectedTasks) => ({
      property,
      tasks: selectedTasks,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: UserEditor,
    getValues: (property, _field, values, onUpdate) => ({
      property,
      tasks: [],
      showText: true,
      editorMode: EditorMode.FORM,
      values,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: UserEditor,
    getValues: (property, task) => ({
      property,
      tasks: [task],
    }),
  }),
  listColumns: (property) => ({
    cellRenderer: UserCellEditor,
    cellRendererParams: {
      editorMode: EditorMode.LIST,
    },
    sortable: true,
    comparatorFn: (a: string[], b: string[]) =>
      usersComparator(dataStore.getUsersByDuidsOrdered(a), dataStore.getUsersByDuidsOrdered(b)),
    suppressHeaderMenuButton: true,
    valueGetter: (params) => params.data?.assigneeDuids ?? [],
    minWidth: property.adtl.isNameShown ? 120 : 100,
    maxWidth: property.adtl.isNameShown ? 120 : 100,
    resizable: false,
    getSearchValue: (_: string[], task) => {
      const orderedUsers = dataStore.getUsersByDuidsOrdered(task.assigneeDuids ?? []);
      const settingsAwareUsers = property.adtl.isMultiple ? orderedUsers : orderedUsers.slice(0, 1);
      return settingsAwareUsers.length > 0
        ? settingsAwareUsers.map((e) => `${e.name} ${e.email}${e.duid === userStore.duid ? " me" : ""}`).join(" ")
        : UNASSIGNED_ASSIGNEE_LABEL;
    },
  }),
});

const PROPERTY_DEFAULT_DATES = createPropertyConfig(PropertyKind.DEFAULT_DATES, {
  label: "Date",
  isDefault: true,
  alwaysShowForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: DatesFieldIcon,
  settingsComponent: () => AdtlDates,
  getValue: (_property, task) => [task.startAt, task.dueAt] satisfies DateRange,
  getValuePretty: (property, task) => getDatesValuePretty(task.startAt, task.dueAt, property.adtl.isRange),
  getPartialTask: (_property, _task, value) =>
    Array.isArray(value) ? { startAt: value[0], dueAt: value[1] } : { dueAt: value },
  typeahead: Typeahead.DATES_TYPEAHEAD,
  smartMatch: SmartMatch.DATES_SMART_MATCH,
  groupBy: null,
  filter: Filter.DATES_FILTER,
  chip: () => ({
    component: DatesChip,
    getValues: (property, task) => ({
      property,
      value: [task.startAt, task.dueAt],
      statusDuid: task.statusDuid,
      recurrence: task.recurrence,
      showIcon: true,
    }),
  }),
  contextMenu: () => ({
    component: DatesEditor,
    getValues: (property, _task, selectedTasks) => ({
      property,
      tasks: selectedTasks,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: DatesEditor,
    getValues: (property, _field, value, onUpdate) => ({
      property,
      tasks: [],
      showText: true,
      editorMode: EditorMode.FORM,
      value: value ?? [null, null],
      onUpdate,
    }),
  }),
  editor: () => ({
    component: DatesEditor,
    getValues: (property, task) => ({
      property,
      tasks: [task],
    }),
  }),
  listColumns: (property) => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: DatesCellEditor,
    cellRendererParams: {
      editorMode: EditorMode.LIST,
    },
    sortable: true,
    comparatorFn: makeDateOrDatesComparator(property.adtl.isRange),
    suppressHeaderMenuButton: true,
    minWidth: property.adtl.isRange ? (property.adtl.showDuration ? 240 : 180) : 100,
    maxWidth: property.adtl.isRange ? (property.adtl.showDuration ? 240 : 180) : 100,
    resizable: false,
    getSearchValue: (_value, task) => {
      let search = "";
      if (property.adtl.isRange && task.startAt) {
        search = `start ${getRelativeTimeForDatesDate(task.startAt)}`;
      }
      if (task.dueAt) {
        search = `${search} due ${getRelativeTimeForDatesDate(task.dueAt)}`;
      }
      return search.trim();
    },
  }),
});

const PROPERTY_DEFAULT_PRIORITY = createPropertyConfig(PropertyKind.DEFAULT_PRIORITY, {
  label: PropertyKind.SELECT,
  isDefault: true,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: PriorityFieldIcon,
  getValue: (_property, task) => task.priority,
  getValuePretty: (_property, task) => task.priority ?? "",
  getPartialTask: (_property, _task, value) => ({ priority: value }),
  typeahead: Typeahead.PRIORITY_TYPEAHEAD,
  smartMatch: SmartMatch.PRIORITY_SMART_MATCH,
  groupBy: GroupBy.PRIORITY_GROUP_BY,
  filter: Filter.PRIORITY_FILTER,
  chip: () => ({
    component: PriorityChip,
    getValues: (property, task) => ({
      value: task.priority,
      showIcon: true,
      showBorder: true,
      property,
    }),
  }),
  contextMenu: () => ({
    component: PriorityEditor,
    getValues: (property, _task, selectedTasks) => ({
      property,
      tasks: selectedTasks,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: PriorityEditor,
    getValues: (property, _field, value, onUpdate) => ({
      property,
      tasks: [],
      editorMode: EditorMode.FORM,
      value,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: PriorityEditor,
    getValues: (property, task) => ({
      property,
      tasks: [task],
    }),
  }),
  listColumns: () => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: PriorityCellEditor,
    sortable: true,
    comparatorFn: (a, b) => priorityComparator(a, b),
    suppressHeaderMenuButton: true,
    minWidth: 100,
    maxWidth: 100,
    resizable: false,
    getSearchValue: (value: string | null) => value ?? "(unprioritized)",
  }),
});

const PROPERTY_DEFAULT_TAGS = createPropertyConfig(PropertyKind.DEFAULT_TAGS, {
  label: PropertyKind.MULTISELECT,
  isDefault: true,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: MultiselectFieldIcon,
  settingsComponent: () => AdtlSelect,
  getValue: (_property, task) => task.tagDuids,
  getValuePretty: (_property, task) => getOptionsValuePretty(task.tagDuids),
  getPartialTask: (_property, _task, value) => ({ tagDuids: Array.isArray(value) ? value : value ? [value] : [] }),
  typeahead: Typeahead.MULTISELECT_TYPEAHEAD,
  smartMatch: SmartMatch.MULTISELECT_SMART_MATCH,
  groupBy: GroupBy.OPTION_GROUP_BY,
  filter: Filter.OPTION_FILTER,
  chip: () => ({
    component: MultiselectChip,
    getValues: (property, task) => ({
      value: task.tagDuids,
      property,
    }),
  }),
  contextMenu: () => ({
    component: OptionsEditor,
    getValues: (property, _task, selectedTasks) => ({
      property,
      tasks: selectedTasks,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: OptionsEditor,
    getValues: (property, _field, values, onUpdate) => ({
      property,
      tasks: [],
      editorMode: EditorMode.FORM,
      values,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: OptionsEditor,
    getValues: (property, task) => ({
      property,
      tasks: [task],
      class: "dark:text-zinc-700",
    }),
  }),
  listColumns: () => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: OptionCellEditor,
    sortable: true,
    comparatorFn: (a: string[], b: string[]) =>
      optionsComparator(dataStore.getOptionsByDuidsOrdered(a), dataStore.getOptionsByDuidsOrdered(b)),
    suppressHeaderMenuButton: true,
    valueGetter: (params) => params.data?.tagDuids ?? [],
    minWidth: 160,
    maxWidth: 160,
    resizable: false,
    getSearchValue: (value: string[]) => value.map((e) => dataStore.getOptionByDuid(e)?.title).join(" "),
  }),
});

const PROPERTY_DEFAULT_SIZE = createPropertyConfig(PropertyKind.DEFAULT_SIZE, {
  label: PropertyKind.NUMBER,
  isDefault: true,
  isNumeric: true,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: SizeFieldIcon,
  getValue: (_property, task) => task.size,
  getValuePretty: (_property, task) => task.size?.toString() ?? "",
  getPartialTask: (_property, _task, value) => ({ size: value }),
  typeahead: Typeahead.SIZE_TYPEAHEAD,
  smartMatch: SmartMatch.SIZE_SMART_MATCH,
  groupBy: GroupBy.SIZE_GROUP_BY,
  filter: Filter.SIZE_FILTER,
  chip: () => ({
    component: SizeChip,
    getValues: (property, task) => ({
      value: task.size,
      showIcon: true,
      showBorder: true,
      property,
    }),
  }),
  contextMenu: () => ({
    component: SizeEditor,
    getValues: (property, _task, selectedTasks) => ({
      property,
      tasks: selectedTasks,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: SizeEditor,
    getValues: (property, _field, value, onUpdate) => ({
      property,
      tasks: [],
      editorMode: EditorMode.FORM,
      value,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: SizeEditor,
    getValues: (property, task) => ({
      property,
      tasks: [task],
      showText: true,
    }),
  }),
  listColumns: () => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: SizeCellEditor,
    sortable: true,
    comparatorFn: sizeComparator,
    suppressHeaderMenuButton: true,
    minWidth: 80,
    maxWidth: 80,
    resizable: false,
    getSearchValue: (value) => {
      if (!value) {
        return "(unsized)";
      }
      return `${value} ${NUMBER_SIZE_TO_TSHIRT_SIZE.get(value)}`;
    },
  }),
});

const PROPERTY_DEFAULT_TIME_TRACKING = createPropertyConfig(PropertyKind.DEFAULT_TIME_TRACKING, {
  label: "Time tracking",
  isDefault: true,
  isNumeric: true,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: TimeTrackingFieldIcon,
  getValue: (_property, task) => task.timeTracking,
  getValuePretty: (_property, task) =>
    task.timeTracking.length > 0 ? getTimeTrackingDurationText(task.timeTracking) : "",
  getPartialTask: (_property, _task, value) => ({ timeTracking: value }),
  typeahead: null,
  smartMatch: null,
  groupBy: null,
  filter: Filter.TIME_TRACKING_FILTER,
  chip: () => ({
    component: TimeTrackingEditor,
    getValues: (property, task) => ({
      tasks: [task],
      property,
      editorMode: EditorMode.CHIP,
    }),
  }),
  contextMenu: () => ({
    component: TimeTrackingEditor,
    getValues: (property, task, selectedTasks) => ({
      tasks: selectedTasks,
      property,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: TimeTrackingEditor,
    getValues: (property, _field, value, onUpdate) => ({
      property,
      tasks: [],
      editorMode: EditorMode.FORM,
      value,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: TimeTrackingEditor,
    getValues: (property, task) => ({
      tasks: [task],
      property,
    }),
  }),
  listColumns: () => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: TimeTrackingCellEditor,
    cellRendererParams: {
      editorMode: EditorMode.LIST,
    },
    sortable: true,
    comparatorFn: timeTrackingComparator,
    suppressHeaderMenuButton: true,
    valueGetter: (params) => params.data?.timeTracking ?? [],
    minWidth: 80,
    maxWidth: 80,
    resizable: false,
    getSearchValue: (value) => getTimeTrackingDurationText(value),
  }),
});

const PROPERTY_DEFAULT_ATTACHMENTS = createPropertyConfig(PropertyKind.DEFAULT_ATTACHMENTS, {
  label: "Attachments",
  isDefault: true,
  icon: AttachmentFieldIcon,
  getValue: (_property, task) => task.attachmentDuids,
  getValuePretty: (_property, task) => task.attachmentDuids.join(", "),
  getPartialTask: (_property, _task, value) => ({ attachmentDuids: value }),
  typeahead: null,
  smartMatch: null,
  groupBy: null,
  filter: null,
  chip: () => ({
    component: DummyComponent,
    getValues: () => ({}),
  }),
  contextMenu: () => ({
    component: DummyComponent,
    getValues: () => ({}),
  }),
  formField: () => ({
    component: FormAttachmentEditor,
    getValues: (_property, _field, value, onUpdate, isSubmitMode) => ({
      attachmentDuids: value ?? [],
      onUpdate,
      isSubmitMode,
    }),
  }),
  editor: () => ({
    component: DummyComponent,
    getValues: () => ({}),
  }),
  listColumns: () => ({}),
});

const PROPERTY_DEFAULT_CREATED_AT = createPropertyConfig(PropertyKind.DEFAULT_CREATED_AT, {
  label: "Date",
  isDefault: true,
  readOnly: true,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: CalendarIcon,
  getValue: (_property, task) => task.createdAt,
  getValuePretty: (_property, task) => getDateValuePretty(task.createdAt),
  getPartialTask: () => ({}),
  typeahead: null,
  smartMatch: null,
  groupBy: null,
  filter: Filter.DATE_FILTER,
  chip: () => ({
    component: DatesChip,
    getValues: (property, task) => ({
      property,
      value: [null, task.createdAt],
      statusDuid: null,
      recurrence: null,
      showIcon: true,
    }),
  }),
  contextMenu: null,
  formField: () => ({
    component: DatesEditor,
    getValues: (property, _field, value, onUpdate) => ({
      property,
      tasks: [],
      editorMode: EditorMode.FORM,
      value: value ?? [null, null],
      onUpdate,
    }),
  }),
  editor: () => ({
    component: DatesEditor,
    getValues: (property, task) => ({
      property,
      tasks: [task],
    }),
  }),
  listColumns: (property) => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: DatesCellEditor,
    cellRendererParams: {
      editorMode: EditorMode.LIST,
    },
    sortable: true,
    comparatorFn: dateComparator,
    suppressHeaderMenuButton: true,
    valueGetter: (params) => [null, params.data?.createdAt ?? null],
    minWidth: 100,
    maxWidth: 100,
    resizable: false,
    getSearchValue: (_value, task) => `${property.title.toLowerCase()} ${getRelativeTimeForDatesDate(task.createdAt)}`,
  }),
});

const PROPERTY_DEFAULT_CREATED_BY = createPropertyConfig(PropertyKind.DEFAULT_CREATED_BY, {
  label: PropertyKind.USER,
  isDefault: true,
  readOnly: true,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: UserFieldIcon,
  settingsComponent: () => AdtlUser,
  getValue: (_property, task) => task.createdByDuid,
  getValuePretty: (_property, task) => {
    if (!task.createdByDuid) {
      return "";
    }
    const user = dataStore.getUserByDuid(task.createdByDuid);
    if (!user) {
      return "";
    }
    return user.name || user.email;
  },
  getPartialTask: () => ({}),
  typeahead: null,
  smartMatch: null,
  groupBy: GroupBy.USER_GROUP_BY,
  filter: null,
  chip: () => ({
    component: UserChip,
    getValues: (property, task) => ({
      value: [task.createdByDuid],
      ai: task.sourceType === TaskSourceType.RECOMMENDATION,
      isMultiple: false,
      editorMode: EditorMode.BOARD,
      unsetLabel: UNKNOWN_USER_LABEL,
      property,
    }),
  }),
  contextMenu: null,
  formField: () => ({
    component: UserEditor,
    getValues: (property, _field, values, onUpdate) => ({
      property,
      tasks: [],
      showText: true,
      editorMode: EditorMode.FORM,
      values,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: UserEditor,
    getValues: (property, task) => ({
      property,
      tasks: [task],
    }),
  }),
  listColumns: () => ({
    cellRenderer: UserCellEditor,
    cellRendererParams: {
      editorMode: EditorMode.LIST,
    },
    sortable: true,
    comparatorFn: (a, b) =>
      userComparator(a ? dataStore.getUserByDuid(a) : undefined, b ? dataStore.getUserByDuid(b) : undefined),
    suppressHeaderMenuButton: true,
    valueGetter: (params) => params.data?.createdByDuid ?? null,
    minWidth: 80,
    maxWidth: 80,
    resizable: false,
    getSearchValue: (_, task) => {
      const { createdByDuid } = task;
      if (!createdByDuid) {
        return "";
      }
      const user = dataStore.getUserByDuid(createdByDuid);
      if (!user) {
        return "";
      }
      return `${user.name} ${user.email}${user.duid === userStore.duid ? " me" : ""}`;
    },
  }),
});

const PROPERTY_DEFAULT_UPDATED_AT = createPropertyConfig(PropertyKind.DEFAULT_UPDATED_AT, {
  label: "Date",
  isDefault: true,
  readOnly: true,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: CalendarIcon,
  getValue: (_property, task) => task.updatedAt,
  getValuePretty: (_property, task) => getDateValuePretty(task.updatedAt),
  getPartialTask: () => ({}),
  typeahead: null,
  smartMatch: null,
  groupBy: null,
  filter: Filter.DATE_FILTER,
  chip: () => ({
    component: DatesChip,
    getValues: (property, task) => ({
      property,
      value: [null, task.updatedAt],
      statusDuid: null,
      recurrence: null,
      showIcon: true,
    }),
  }),
  contextMenu: null,
  formField: () => ({
    component: DatesEditor,
    getValues: (property, _field, value, onUpdate) => ({
      property,
      tasks: [],
      editorMode: EditorMode.FORM,
      value,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: DatesEditor,
    getValues: (property, task) => ({
      property,
      tasks: [task],
    }),
  }),
  listColumns: (property) => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: DatesCellEditor,
    cellRendererParams: {
      editorMode: EditorMode.LIST,
    },
    sortable: true,
    comparatorFn: dateComparator,
    suppressHeaderMenuButton: true,
    valueGetter: (params) => [null, params.data?.updatedAt ?? null],
    minWidth: 100,
    maxWidth: 100,
    resizable: false,
    getSearchValue: (_value, task) => `${property.title.toLowerCase()} ${getRelativeTimeForDatesDate(task.updatedAt)}`,
  }),
});

const PROPERTY_DEFAULT_UPDATED_BY = createPropertyConfig(PropertyKind.DEFAULT_UPDATED_BY, {
  label: PropertyKind.USER,
  isDefault: true,
  readOnly: true,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: UserFieldIcon,
  settingsComponent: () => AdtlUser,
  getValue: (_property, task) => task.updatedByDuid,
  getValuePretty: (_property, task) => {
    if (!task.updatedByDuid) {
      return "";
    }
    const user = dataStore.getUserByDuid(task.updatedByDuid);
    if (!user) {
      return "";
    }
    return user.name || user.email;
  },
  getPartialTask: () => ({}),
  typeahead: null,
  smartMatch: null,
  groupBy: GroupBy.USER_GROUP_BY,
  filter: null,
  chip: () => ({
    component: UserChip,
    getValues: (property, task) => ({
      value: [task.updatedByDuid],
      isMultiple: false,
      editorMode: EditorMode.BOARD,
      unsetLabel: UNKNOWN_USER_LABEL,
      property,
    }),
  }),
  contextMenu: null,
  formField: () => ({
    component: UserEditor,
    getValues: (property, _field, values, onUpdate) => ({
      property,
      tasks: [],
      showText: true,
      editorMode: EditorMode.FORM,
      values,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: UserEditor,
    getValues: (property, task) => ({
      property,
      tasks: [task],
    }),
  }),
  listColumns: () => ({
    cellRenderer: UserCellEditor,
    cellRendererParams: {
      editorMode: EditorMode.LIST,
    },
    sortable: true,
    comparatorFn: (a, b) =>
      userComparator(a ? dataStore.getUserByDuid(a) : undefined, b ? dataStore.getUserByDuid(b) : undefined),
    suppressHeaderMenuButton: true,
    valueGetter: (params) => params.data?.updatedByDuid ?? null,
    minWidth: 80,
    maxWidth: 80,
    resizable: false,
    getSearchValue: (_, task) => {
      const { updatedByDuid } = task;
      if (!updatedByDuid) {
        return "";
      }
      const user = dataStore.getUserByDuid(updatedByDuid);
      if (!user) {
        return "";
      }
      return `${user.name} ${user.email}${user.duid === userStore.duid ? " me" : ""}`;
    },
  }),
});

const PROPERTY_SELECT = createPropertyConfig(PropertyKind.SELECT, {
  label: PropertyKind.SELECT,
  isDefault: false,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: SelectFieldIcon,
  settingsComponent: () => AdtlSelect,
  getValue: (property, task) => (task.properties[property.duid] as string | undefined) ?? null,
  getValuePretty: (property, task) => {
    const optionDuid = task.properties[property.duid] as string | undefined;
    return getOptionsValuePretty(optionDuid ? [optionDuid] : []);
  },
  getPartialTask: (property, task, value) => ({ properties: { ...task.properties, [property.duid]: value } }),
  typeahead: Typeahead.SELECT_TYPEAHEAD,
  smartMatch: SmartMatch.SELECT_SMART_MATCH,
  groupBy: GroupBy.OPTION_GROUP_BY,
  filter: Filter.OPTION_FILTER,
  chip: () => ({
    component: MultiselectChip,
    getValues: (property, task) => {
      const value = task.properties[property.duid] ?? null;
      return {
        value: value ? [value] : [],
        property,
      };
    },
  }),
  contextMenu: () => ({
    component: SelectEditor,
    getValues: (property, _task, selectedTasks) => ({
      property,
      tasks: selectedTasks,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: SelectEditor,
    getValues: (property, _field, value, onUpdate) => ({
      property,
      tasks: [],
      editorMode: EditorMode.FORM,
      value,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: SelectEditor,
    getValues: (property, task) => ({ property, tasks: [task] }),
  }),
  listColumns: (property) => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: SelectCellEditor,
    sortable: true,
    comparatorFn: (a, b) =>
      optionComparator(a ? dataStore.getOptionByDuid(a) : undefined, b ? dataStore.getOptionByDuid(b) : undefined),
    suppressHeaderMenuButton: true,
    valueGetter: (params) => params.data?.properties[property.duid] ?? [],
    minWidth: 160,
    maxWidth: 160,
    resizable: false,
    getSearchValue: (_value, task) => {
      const value = task.properties[property.duid] as string | undefined;
      if (!value) {
        return "";
      }
      return dataStore.getOptionByDuid(value)?.title ?? "";
    },
  }),
});

const PROPERTY_MULTISELECT = createPropertyConfig(PropertyKind.MULTISELECT, {
  label: PropertyKind.MULTISELECT,
  isDefault: false,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: MultiselectFieldIcon,
  settingsComponent: () => AdtlSelect,
  getValue: (property, task) => (task.properties[property.duid] as string[] | undefined) ?? [],
  getValuePretty: (property, task) =>
    getOptionsValuePretty((task.properties[property.duid] as string[] | undefined) ?? []),
  getPartialTask: (property, task, value) => ({
    properties: { ...task.properties, [property.duid]: Array.isArray(value) ? value : value ? [value] : [] },
  }),
  typeahead: Typeahead.MULTISELECT_TYPEAHEAD,
  smartMatch: SmartMatch.MULTISELECT_SMART_MATCH,
  groupBy: GroupBy.OPTION_GROUP_BY,
  filter: Filter.OPTION_FILTER,
  chip: () => ({
    component: MultiselectChip,
    getValues: (property, task) => ({
      value: task.properties[property.duid] ?? [],
      property,
    }),
  }),
  contextMenu: () => ({
    component: OptionsEditor,
    getValues: (property, _task, selectedTasks) => ({
      property,
      tasks: selectedTasks,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: OptionsEditor,
    getValues: (property, _field, values, onUpdate) => ({
      property,
      tasks: [],
      editorMode: EditorMode.FORM,
      values,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: OptionsEditor,
    getValues: (property, task) => ({
      property,
      tasks: [task],
    }),
  }),
  listColumns: (property) => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: OptionCellEditor,
    sortable: true,
    comparatorFn: (a: string[], b: string[]) =>
      optionsComparator(dataStore.getOptionsByDuidsOrdered(a), dataStore.getOptionsByDuidsOrdered(b)),
    suppressHeaderMenuButton: true,
    valueGetter: (params) => params.data?.properties[property.duid] ?? [],
    minWidth: 160,
    maxWidth: 160,
    resizable: false,
    getSearchValue: (_value, task) =>
      ((task.properties[property.duid] as string[] | undefined) ?? [])
        .map((e) => dataStore.getOptionByDuid(e)?.title)
        .join(" "),
  }),
});

const PROPERTY_STATUS = createPropertyConfig(PropertyKind.STATUS, {
  label: PropertyKind.STATUS,
  isDefault: false,
  entitlement: Entitlement.STATUSES,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: StatusFieldIcon,
  settingsComponent: () => AdtlStatus,
  getValue: (property, task) =>
    (task.properties[property.duid] as string | undefined) ?? dataStore.defaultUnstartedStatus(property).duid,
  getValuePretty: (property, task) => {
    const statusDuid = task.properties[property.duid] as string | undefined;
    if (!statusDuid) {
      return dataStore.defaultUnstartedStatus(property).title;
    }
    const status = dataStore.getStatusByDuid(statusDuid);
    if (!status) {
      return dataStore.defaultUnstartedStatus(property).title;
    }
    return status.title;
  },
  getPartialTask: (property, task, value) => ({ properties: { ...task.properties, [property.duid]: value } }),
  typeahead: Typeahead.STATUS_TYPEAHEAD,
  smartMatch: SmartMatch.STATUS_SMART_MATCH,
  groupBy: GroupBy.STATUS_GROUP_BY,
  filter: Filter.STATUS_FILTER,
  chip: () => ({
    component: StatusChip,
    getValues: (property, task) => ({
      value: task.properties[property.duid] ?? dataStore.defaultUnstartedStatus(property).duid,
      property,
    }),
  }),
  contextMenu: () => ({
    component: StatusEditor,
    getValues: (property, _task, selectedTasks) => ({
      property,
      tasks: selectedTasks,
      locked: false,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: StatusEditor,
    getValues: (property, field, value, onUpdate, isSubmitMode) => ({
      property,
      locked: field.locked,
      tasks: [],
      editorMode: EditorMode.FORM,
      showText: true,
      value,
      hideUnset: isSubmitMode,
      onSelect: onUpdate,
    }),
  }),
  editor: () => ({
    component: StatusEditor,
    getValues: (property, task) => ({
      property,
      tasks: [task],
      showText: true,
    }),
  }),
  listColumns: (property) => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: StatusCellEditor,
    cellRendererParams: {
      editorMode: EditorMode.LIST,
    },
    sortable: true,
    comparatorFn: (a: string, b: string) =>
      statusComparator(dataStore.getStatusByDuid(a), dataStore.getStatusByDuid(b)),
    suppressHeaderMenuButton: true,
    valueGetter: (params) => params.data?.properties[property.duid] ?? dataStore.defaultUnstartedStatus(property).duid,
    minWidth: 80,
    maxWidth: 80,
    resizable: false,
    getSearchValue: (_value, task) => {
      const defaultStatus = dataStore.defaultUnstartedStatus(property);
      const value = task.properties[property.duid] as string | undefined;
      if (!value) {
        return `${defaultStatus.title} ${defaultStatus.kind}`;
      }
      const status = dataStore.getStatusByDuid(value);
      if (!status) {
        return `${defaultStatus.title} ${defaultStatus.kind}`;
      }
      return `${status.title} ${status.kind}`;
    },
  }),
});

const PROPERTY_USER = createPropertyConfig(PropertyKind.USER, {
  label: PropertyKind.USER,
  isDefault: false,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: UserFieldIcon,
  settingsComponent: () => AdtlUser,
  getValue: (property, task) => (task.properties[property.duid] as string[] | undefined) ?? [],
  getValuePretty: (property, task) =>
    dataStore
      .getUsersByDuidsOrdered((task.properties[property.duid] as string[] | undefined) ?? [])
      .map((e) => e.name || e.email)
      .join(", "),
  getPartialTask: (property, task, value) => ({
    properties: { ...task.properties, [property.duid]: Array.isArray(value) ? value : value ? [value] : [] },
  }),
  typeahead: Typeahead.USER_TYPEAHEAD,
  smartMatch: null,
  groupBy: GroupBy.USER_GROUP_BY,
  filter: Filter.USER_FILTER,
  chip: () => ({
    component: UserChip,
    getValues: (property, task) => ({
      value: task.properties[property.duid] ?? [],
      isMultiple: property.adtl.isMultiple,
      editorMode: EditorMode.BOARD,
      unsetLabel: UNSET_USER_LABEL,
      property,
    }),
  }),
  contextMenu: () => ({
    component: UserEditor,
    getValues: (property, _task, selectedTasks) => ({
      property,
      tasks: selectedTasks,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: UserEditor,
    getValues: (property, _field, values, onUpdate) => ({
      property,
      tasks: [],
      showText: true,
      editorMode: EditorMode.FORM,
      values,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: UserEditor,
    getValues: (property, task) => ({
      property,
      tasks: [task],
    }),
  }),
  listColumns: (property) => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: UserCellEditor,
    cellRendererParams: {
      editorMode: EditorMode.LIST,
    },
    sortable: true,
    comparatorFn: (a: string[], b: string[]) =>
      usersComparator(dataStore.getUsersByDuidsOrdered(a), dataStore.getUsersByDuidsOrdered(b)),
    suppressHeaderMenuButton: true,
    valueGetter: (params) => params.data?.properties[property.duid] ?? [],
    minWidth: 100,
    maxWidth: 100,
    resizable: false,
    getSearchValue: (_value, task) => {
      const orderedUsers = dataStore.getUsersByDuidsOrdered((task.properties[property.duid] as string[]) ?? []);
      const settingsAwareUsers = property.adtl.isMultiple ? orderedUsers : orderedUsers.slice(0, 1);
      return settingsAwareUsers.length > 0
        ? settingsAwareUsers.map((e) => `${e.name} ${e.email}${e.duid === userStore.duid ? " me" : ""}`).join(" ")
        : UNKNOWN_USER_LABEL;
    },
  }),
});

const PROPERTY_DATES = createPropertyConfig(PropertyKind.DATES, {
  label: "Date",
  isDefault: false,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: DatesFieldIcon,
  settingsComponent: () => AdtlDates,
  getValue: (property, task) => (task.properties[property.duid] as DateRange) ?? [null, null],
  getValuePretty: (property, task) => {
    const [startAt, endAt] = (task.properties[property.duid] as DateRange) ?? [null, null];
    return getDatesValuePretty(startAt, endAt, property.adtl.isRange);
  },
  getPartialTask: (property, task, value) => ({ properties: { ...task.properties, [property.duid]: value } }),
  typeahead: Typeahead.DATES_TYPEAHEAD,
  smartMatch: SmartMatch.DATES_SMART_MATCH,
  groupBy: null,
  filter: Filter.DATES_FILTER,
  chip: () => ({
    component: DatesChip,
    getValues: (property, task) => ({
      property,
      value: task.properties[property.duid] ?? [null, null],
      statusDuid: null,
      recurrence: null,
      showIcon: true,
    }),
  }),
  contextMenu: () => ({
    component: DatesEditor,
    getValues: (property, _task, selectedTasks) => ({
      property,
      tasks: selectedTasks,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: DatesEditor,
    getValues: (property, _field, value, onUpdate) => ({
      property,
      tasks: [],
      editorMode: EditorMode.FORM,
      value: value ?? [null, null],
      onUpdate,
    }),
  }),
  editor: () => ({
    component: DatesEditor,
    getValues: (property, task) => ({
      property,
      tasks: [task],
    }),
  }),
  listColumns: (property) => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: DatesCellEditor,
    cellRendererParams: {
      editorMode: EditorMode.LIST,
    },
    sortable: true,
    comparatorFn: makeDateOrDatesComparator(property.adtl.isRange),
    suppressHeaderMenuButton: true,
    valueGetter: (params) => params.data?.properties[property.duid] ?? [null, null],
    minWidth: property.adtl.isRange ? 180 : 100,
    maxWidth: property.adtl.isRange ? 180 : 100,
    resizable: false,
    getSearchValue: (_value, task) => {
      const [startAt, endAt] = (task.properties[property.duid] as DateRange) ?? [null, null];
      let search = "";
      if (property.adtl.isRange && startAt) {
        search = `start ${getRelativeTimeForDatesDate(startAt)}`;
      }
      if (endAt) {
        search = `${search} end ${getRelativeTimeForDatesDate(endAt)}`;
      }
      return search.trim();
    },
  }),
});

const PROPERTY_TIME_TRACKING = createPropertyConfig(PropertyKind.TIME_TRACKING, {
  label: PropertyKind.TIME_TRACKING,
  isDefault: false,
  isNumeric: true,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: TimeTrackingFieldIcon,
  getValue: (property, task) => (task.properties[property.duid] as TimeTracking | undefined) ?? [],
  getValuePretty: (property, task) => {
    const val = (task.properties[property.duid] as TimeTracking | undefined) ?? [];
    return val.length > 0 ? getTimeTrackingDurationText(val) : "";
  },
  getPartialTask: (property, task, value) => ({ properties: { ...task.properties, [property.duid]: value } }),
  typeahead: null,
  smartMatch: null,
  groupBy: null,
  filter: Filter.TIME_TRACKING_FILTER,
  chip: () => ({
    component: TimeTrackingEditor,
    getValues: (property, task) => ({
      tasks: [task],
      property,
      editorMode: EditorMode.CHIP,
    }),
  }),
  contextMenu: () => ({
    component: TimeTrackingEditor,
    getValues: (property, task, selectedTasks) => ({
      tasks: selectedTasks,
      property,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: TimeTrackingEditor,
    getValues: (property, _field, value, onUpdate) => ({
      property,
      tasks: [],
      editorMode: EditorMode.FORM,
      value,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: TimeTrackingEditor,
    getValues: (property, task) => ({
      tasks: [task],
      property,
    }),
  }),
  listColumns: (property) => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: TimeTrackingCellEditor,
    cellRendererParams: {
      editorMode: EditorMode.LIST,
    },
    sortable: true,
    comparatorFn: timeTrackingComparator,
    suppressHeaderMenuButton: true,
    valueGetter: (params) => params.data?.properties[property.duid] ?? [],
    minWidth: 80,
    maxWidth: 80,
    resizable: false,
    getSearchValue: (value) => getTimeTrackingDurationText(value),
  }),
});

const PROPERTY_TEXT = createPropertyConfig(PropertyKind.TEXT, {
  label: PropertyKind.TEXT,
  isDefault: false,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: TextFieldIcon,
  getValue: (property, task) => (task.properties[property.duid] as string | undefined) ?? "",
  getValuePretty: (property, task) => (task.properties[property.duid] as string | undefined) ?? "",
  getPartialTask: (property, task, value) => ({ properties: { ...task.properties, [property.duid]: value } }),
  typeahead: null,
  smartMatch: null,
  groupBy: null,
  filter: Filter.TEXT_FILTER,
  chip: () => ({
    component: TextChip,
    getValues: (property, task) => ({
      value: task.properties[property.duid] ?? "",
      property,
    }),
  }),
  contextMenu: () => ({
    component: TextEditor,
    getValues: (property, _task, selectedTasks) => ({
      property,
      tasks: selectedTasks,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: FormTextInput,
    getValues: (property, _field, value, onUpdate) => ({
      property,
      value,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: TextEditor,
    getValues: (property, task) => ({ property, tasks: [task] }),
  }),
  listColumns: (property) => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: TextCellEditor,
    sortable: true,
    comparatorFn: stringComparator,
    suppressHeaderMenuButton: true,
    valueGetter: (params) => params.data?.properties[property.duid] ?? "",
    minWidth: 160,
    maxWidth: 160,
    resizable: false,
    getSearchValue: (_value, task) => (task.properties[property.duid] as string | undefined) ?? "",
  }),
});

const PROPERTY_NUMBER = createPropertyConfig(PropertyKind.NUMBER, {
  label: PropertyKind.NUMBER,
  isDefault: false,
  isNumeric: true,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: NumberFieldIcon,
  settingsComponent: () => AdtlNumber,
  getValue: (property, task) => (task.properties[property.duid] as number | undefined) ?? null,
  getValuePretty: (property, task) => (task.properties[property.duid] as number | undefined)?.toString() ?? "",
  getPartialTask: (property, task, value) => ({ properties: { ...task.properties, [property.duid]: value } }),
  typeahead: null,
  smartMatch: null,
  groupBy: null,
  filter: Filter.NUMBER_FILTER,
  chip: () => ({
    component: NumberChip,
    getValues: (property, task) => ({
      value: task.properties[property.duid] ?? null,
      format: property.adtl.format,
      property,
    }),
  }),
  contextMenu: () => ({
    component: NumberEditor,
    getValues: (property, _task, selectedTasks) => ({
      property,
      tasks: selectedTasks,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: FormNumberInput,
    getValues: (property, _field, value, onUpdate) => ({
      property,
      value,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: NumberEditor,
    getValues: (property, task) => ({ property, tasks: [task] }),
  }),
  listColumns: (property) => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: NumberCellEditor,
    sortable: true,
    comparatorFn: numberMaybeComparator,
    suppressHeaderMenuButton: true,
    valueGetter: (params) => params.data?.properties[property.duid] ?? null,
    minWidth: 80,
    maxWidth: 80,
    resizable: false,
    getSearchValue: (_value, task) => (task.properties[property.duid] as number | undefined)?.toString() ?? "",
  }),
});

const PROPERTY_CHECKBOX = createPropertyConfig(PropertyKind.CHECKBOX, {
  label: PropertyKind.CHECKBOX,
  isDefault: false,
  defaultHideForLayouts: [LayoutKind.CALENDAR, LayoutKind.ROADMAP],
  icon: CheckboxFieldIcon,
  getValue: (property, task) => (task.properties[property.duid] as boolean | undefined) ?? false,
  getValuePretty: (property, task) => (((task.properties[property.duid] as boolean | undefined) ?? false) ? "X" : ""),
  getPartialTask: (property, task, value) => ({ properties: { ...task.properties, [property.duid]: value } }),
  typeahead: Typeahead.CHECKBOX_TYPEAHEAD,
  smartMatch: SmartMatch.CHECKBOX_SMART_MATCH,
  groupBy: GroupBy.CHECKBOX_GROUP_BY,
  filter: Filter.CHECKBOX_FILTER,
  chip: () => ({
    component: CheckboxChip,
    getValues: (property, task) => ({
      value: task.properties[property.duid] ?? false,
      property,
    }),
  }),
  contextMenu: () => ({
    component: CheckboxEditor,
    getValues: (property, _task, selectedTasks) => ({
      property,
      tasks: selectedTasks,
      editorMode: EditorMode.CONTEXT_MENU,
    }),
  }),
  formField: () => ({
    component: CheckboxEditor,
    getValues: (property, _field, value, onUpdate) => ({
      property,
      tasks: [],
      editorMode: EditorMode.FORM,
      value,
      onUpdate,
    }),
  }),
  editor: () => ({
    component: CheckboxEditor,
    getValues: (property, task) => ({
      property,
      tasks: [task],
    }),
  }),
  listColumns: (property) => ({
    hide: pageStore.isNarrowScreen,
    cellRenderer: CheckboxCellEditor,
    sortable: true,
    comparatorFn: checkboxComparator,
    suppressHeaderMenuButton: true,
    valueGetter: (params) => params.data?.properties[property.duid] ?? false,
    minWidth: 80,
    maxWidth: 80,
    resizable: false,
    getSearchValue: (_value, task) => {
      const value = (task.properties[property.duid] as boolean | undefined) ?? false;
      return value ? "checked" : "unchecked";
    },
  }),
});

// All things property-related
const PROPERTY_KIND_TO_CONFIG_MAP: { [K in PropertyKind]: PropertyConfig<K> } = Object.fromEntries([
  PROPERTY_DEFAULT_KIND,
  PROPERTY_DEFAULT_TITLE,
  PROPERTY_DEFAULT_DESCRIPTION,
  PROPERTY_DEFAULT_DARTBOARD,
  PROPERTY_DEFAULT_STATUS,
  PROPERTY_DEFAULT_ASSIGNEES,
  PROPERTY_DEFAULT_DATES,
  PROPERTY_DEFAULT_PRIORITY,
  PROPERTY_DEFAULT_TAGS,
  PROPERTY_DEFAULT_SIZE,
  PROPERTY_DEFAULT_TIME_TRACKING,
  PROPERTY_DEFAULT_ATTACHMENTS,
  PROPERTY_DEFAULT_CREATED_AT,
  PROPERTY_DEFAULT_CREATED_BY,
  PROPERTY_DEFAULT_UPDATED_AT,
  PROPERTY_DEFAULT_UPDATED_BY,
  PROPERTY_SELECT,
  PROPERTY_MULTISELECT,
  PROPERTY_STATUS,
  PROPERTY_USER,
  PROPERTY_DATES,
  PROPERTY_TIME_TRACKING,
  PROPERTY_TEXT,
  PROPERTY_NUMBER,
  PROPERTY_CHECKBOX,
]);

// TODO move these helper functions out of this file
export const getProperty = (propertyDuid: string): Property | undefined => dataStore.getPropertyByDuid(propertyDuid);

export const getPropertyConfig = <K extends PropertyKind>(propertyKind: K): PropertyConfig<K> =>
  PROPERTY_KIND_TO_CONFIG_MAP[propertyKind];

export const getPropertyWithConfig = (propertyDuid: string): [Property, PropertyConfig] | undefined => {
  const property = dataStore.getPropertyByDuid(propertyDuid);
  if (!property) {
    return undefined;
  }
  return [property, getPropertyConfig(property.kind)];
};

export const getPropertyWithConfigList = (): [Property, PropertyConfig][] =>
  dataStore.propertyList.map((e) => [e, getPropertyConfig(e.kind)] satisfies [Property, PropertyConfig]);

export const getShownPropertyWithConfigList = (
  alwaysIncludeKinds: PropertyKind[] = [],
  alwaysIncludeDuids: string[] = []
): [Property, PropertyConfig][] => {
  const fullComparator = makePropertyComparator(appStore.propertyOrderDuids);
  const defaultComparator = (a: PropertyKind, b: PropertyKind) =>
    sign(FINAL_COLUMN_ORDER_OVERRIDES.get(a) ?? -1) - (FINAL_COLUMN_ORDER_OVERRIDES.get(b) ?? -1);
  const conditionalComparator = appStore.propertyOrderDuids.length
    ? (a: Property, b: Property) => fullComparator(a.duid, b.duid)
    : (a: Property, b: Property) => defaultComparator(a.kind, b.kind);
  const comparator = (a: Property, aPosition: Position | undefined, b: Property, bPosition: Position | undefined) =>
    positionComparator(aPosition, bPosition) || conditionalComparator(a, b);

  return getPropertyWithConfigList()
    .filter(([property, config]) => {
      if (alwaysIncludeKinds.includes(property.kind) || alwaysIncludeDuids.includes(property.duid)) {
        return true;
      }
      return appStore.isPropertyShownInLayout(property, config);
    })
    .sort((a, b) => comparator(a[0], a[1].lockPosition, b[0], b[1].lockPosition));
};

export const getPropertyValueFromTaskByDuid = (propertyDuid: string, task: Task) => {
  const propertyAndConfig = getPropertyWithConfig(propertyDuid);
  if (!propertyAndConfig) {
    return undefined;
  }
  const [property, propertyConfig] = propertyAndConfig;
  return propertyConfig.getValue(property, task);
};

export const getPropertyValueFromTask = <P extends Property>(property: P, task: Task) => {
  type Kind = P["kind"];
  const propertyConfig = getPropertyConfig<Kind>(property.kind);
  return propertyConfig.getValue(property, task);
};

export const getPropertyPartialTask = <P extends Property>(property: P, task: Partial<Task>, value: PropertyValue) => {
  const propertyConfig = getPropertyConfig(property.kind);
  return propertyConfig.getPartialTask(property, task, value);
};

// TODO remove this
export const getDefaultPropertiesToFieldsMap = () =>
  new Map<Property, keyof Task>([
    [dataStore.defaultKindProperty, "kindDuid"],
    [dataStore.defaultStatusProperty, "statusDuid"],
    [dataStore.defaultAssigneesProperty, "assigneeDuids"],
    [dataStore.defaultDatesProperty, "dueAt"],
    [dataStore.defaultPriorityProperty, "priority"],
    [dataStore.defaultTagsProperty, "tagDuids"],
    [dataStore.defaultSizeProperty, "size"],
    [dataStore.defaultTimeTrackingProperty, "timeTracking"],
  ]);

export const getPropertyTypeaheadOption = (
  propertyDuid: string,
  value: TypeaheadOption["value"]
): [Property, TypeaheadOption | null] | null => {
  const propertyWithConfig = getPropertyWithConfig(propertyDuid);
  if (!propertyWithConfig) {
    return null;
  }

  const [property, config] = propertyWithConfig;
  if (!config.typeahead) {
    return null;
  }

  // TODO really need to change this paradigm for typeaheads. should be able to construct an option from a value and not have to search through everything to find it. this will also allow us to remove the string part from all of the query: string | number weirdness
  const typeaheadOption = config
    .typeahead(property)
    .map((typeahead) => typeahead.getOptions(value))
    .flat()
    .find((option) => option.value === value);

  return [property, typeaheadOption ?? null];
};

export const getPropertyValueStr = (value: PropertyValue): string =>
  value === null || (Array.isArray(value) && value.length === 0) ? GroupBy.NO_VALUE_GROUP_BY_KEY : value.toString();
