<template>
  <svg fill="none" viewBox="0 0 17 18">
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M7.667 12.333H4.333m6.45-6.666h-6.45M12.667 9h-6.42M1 12.5v-7c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093C2.9 1.5 3.6 1.5 5 1.5h7c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.092 1.093C16 3.4 16 4.1 16 5.5v7c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092C14.1 16.5 13.4 16.5 12 16.5H5c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C1 14.6 1 13.9 1 12.5Z" />
  </svg>
</template>
