<script setup lang="ts">
import { computed, onUnmounted, ref } from "vue";

import { backendOld } from "~/api";
import SettingSection from "~/components/dumb/SettingSection.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { XIcon } from "~/icons";
import { goBackOrHome } from "~/router/common";
import { CommandId, Placement } from "~/shared/enums";

const STD_LATENCY = 100;
const EWMA_OLD_PROP = 0.8;

const dartLatencyEwma = ref<number | null>(null);

const dartLatencyDisplay = computed(() => Math.round(dartLatencyEwma.value || 0));
const dartLatencyDiffDisplay = computed(() => dartLatencyDisplay.value - STD_LATENCY);

const close = () => {
  goBackOrHome();
};

const pingDart = async () => {
  const start = Date.now();
  await backendOld.ping();
  const latency = Date.now() - start;
  if (dartLatencyEwma.value === null) {
    dartLatencyEwma.value = latency;
  }
  dartLatencyEwma.value = dartLatencyEwma.value * EWMA_OLD_PROP + latency * (1 - EWMA_OLD_PROP);
};
pingDart();

const pingDartInterval = setInterval(pingDart, 3000);

onUnmounted(() => {
  clearInterval(pingDartInterval);
});
</script>

<template>
  <div class="h-screen w-screen overflow-y-auto bg-std">
    <div class="flex justify-between app-drag">
      <div />
      <div class="m-5 flex flex-col app-drag-none">
        <Tooltip :placement="Placement.LEFT" :command-id="CommandId.RESET_VIEW_CLOSE">
          <button
            type="button"
            class="flex max-w-lg items-center rounded-full text-vlt focus-ring-std hover:text-vlt"
            @click="close">
            <span class="sr-only">Close latency page</span>
            <XIcon class="icon-lg" aria-hidden="true" />
          </button>
        </Tooltip>
      </div>
    </div>
    <div class="mx-auto max-w-5xl">
      <SettingSection
        :styles="{
          marginTop: 'mt-5',
          maxWidth: 'max-w-[50%]',
        }"
        title="Latency"
        subtitle="The latency between the backend and frontend without any work on the backend">
        <span class="text-lg font-normal text-md">{{ dartLatencyDisplay }}ms</span>
      </SettingSection>

      <SettingSection
        :styles="{
          maxWidth: 'max-w-[50%]',
        }"
        title="Latency Delta"
        subtitle="The difference between the latency now and the standard latency experienced by the developers">
        <span :class="[dartLatencyDiffDisplay > 0 ? 'text-danger-base' : 'text-success-base', 'text-lg font-normal']">
          {{ (dartLatencyDiffDisplay > 0 ? "+" : "") + dartLatencyDiffDisplay }}ms
        </span>
      </SettingSection>
    </div>
  </div>
</template>
