<template>
  <svg fill="none" viewBox="-30 -30 360 360">
    <path
      fill="currentColor"
      d="M66,245.27c-2.94,0-5.79-1.52-7.37-4.25L7.38,152.25c-1.52-2.63-1.52-5.87,0-8.5l68.25-118.2
    c1.52-2.63,4.32-4.25,7.36-4.25h136.5c4.01,0,7.51,2.79,8.35,6.72c0.83,3.92-1.16,7.87-4.81,9.5
    c-0.51,0.24-12.16,5.89-25.27,20.08c-7.82,8.46-14.62,18.27-20.24,29.16c-5.61,10.88-10.04,22.87-13.21,35.77l23.44-0.02
    c0,0,0,0,0.01,0c4.69,0,8.49,3.8,8.5,8.49c0,4.69-3.8,8.5-8.49,8.5l-33.98,0.02c0,0,0,0-0.01,0c-2.53,0-4.93-1.13-6.54-3.08
    s-2.28-4.52-1.81-7c3.53-18.63,9.36-35.82,17.33-51.11c6.42-12.32,14.24-23.43,23.24-33.02c2.42-2.58,4.81-4.92,7.11-7.03H87.89
    L24.55,148l48.81,84.53c2.35,4.06,0.95,9.26-3.11,11.61C68.91,244.91,67.45,245.27,66,245.27z" />
    <path
      fill="currentColor"
      d="M219.49,274.7H82.99c-4.01,0-7.51-2.79-8.35-6.72c-0.83-3.92,1.16-7.87,4.81-9.5
    c0.48-0.23,12.14-5.88,25.27-20.08c7.81-8.46,14.62-18.27,20.24-29.15c5.61-10.88,10.04-22.87,13.22-35.76h-23.44
    c-4.69,0-8.5-3.8-8.5-8.5c0-4.69,3.8-8.5,8.5-8.5h33.98c2.53,0,4.93,1.13,6.54,3.08c1.61,1.95,2.28,4.52,1.8,7
    c-3.53,18.62-9.36,35.81-17.33,51.09c-6.42,12.32-14.24,23.43-23.24,33.02c-2.42,2.58-4.8,4.92-7.1,7.02h105.19L277.93,148
    l-48.76-84.46c-2.34-4.06-0.95-9.26,3.11-11.61c4.06-2.35,9.26-0.95,11.61,3.11l51.22,88.7c1.52,2.63,1.52,5.87,0,8.5
    l-68.25,118.2C225.33,273.08,222.53,274.7,219.49,274.7z" />
  </svg>
</template>
