<script setup lang="ts">
import { capitalize, computed, nextTick, ref } from "vue";

import actions from "~/actions";
import AvatarGroup from "~/components/dumb/AvatarGroup.vue";
import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import PageIconPicker from "~/components/dumb/PageIconPicker.vue";
import TextInput from "~/components/dumb/TextInput.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { convertToMarkdown, convertToPlainText } from "~/components/text/transformers";
import { UNKNOWN_USER_LABEL } from "~/components/visualization/constants";
import { DotsHorizontalIcon, FavoriteIcon, PublicIcon, TaskIcon, UnfavoriteIcon, WorkspaceSettingsIcon } from "~/icons";
import { ButtonStyle, EditorMode, IconKind, IconSize, PageKind } from "~/shared/enums";
import type { Page } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { getDashboardLink, getDocLink, getViewLink, isLexicalStateEmpty } from "~/utils/common";
import { getEmojiRecommendation } from "~/utils/recommendation";

const props = defineProps<{
  page: Page;
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();
const tenantStore = useTenantStore();

const inputTitle = ref<InstanceType<typeof TextInput> | null>(null);
const inputDescription = ref<InstanceType<typeof TextInput> | null>(null);

// Doc
const relatedTasks = computed(() =>
  props.page.pageKind === PageKind.DOC ? dataStore.getTasksRelatedToDocOrdered(props.page.duid) : []
);
const relatedTasksText = computed(() => ["Linked tasks", ...relatedTasks.value.map((e) => `• ${e.title}`)]);
// View and dashboard
const isFavorited = computed(
  () =>
    (props.page.pageKind === PageKind.DASHBOARD || props.page.pageKind === PageKind.VIEW) &&
    props.page.favoritedByUserDuids.includes(userStore.duid)
);
const link = computed(() =>
  props.page.pageKind === PageKind.DASHBOARD
    ? getDashboardLink(props.page)
    : props.page.pageKind === PageKind.VIEW
      ? getViewLink(props.page)
      : props.page.pageKind === PageKind.DOC
        ? getDocLink(props.page)
        : ""
);

const pageName = computed(() =>
  props.page.pageKind === PageKind.DASHBOARD
    ? "dashboard"
    : props.page.pageKind === PageKind.VIEW
      ? "view"
      : props.page.pageKind === PageKind.DOC
        ? "doc"
        : "page"
);

const startEditingTitle = () => {
  inputTitle.value?.startEditing();
};

const isEditingDescription = ref(false);
const showDescriptionInput = computed(() => {
  if (props.page.pageKind !== PageKind.DOC) {
    return !!props.page.description || isEditingDescription.value;
  }
  return false;
});

const startEditingDescription = () => {
  isEditingDescription.value = true;
  nextTick(() => {
    inputDescription.value?.startEditing();
  });
};

const saveDescription = async (newDescription: string) => {
  isEditingDescription.value = false;
  if (props.page.pageKind === PageKind.DOC) {
    return;
  }

  const value = newDescription.trim();
  const pageIsDashboard = props.page.pageKind === PageKind.DASHBOARD;
  const pageIsView = props.page.pageKind === PageKind.VIEW;

  const update = { duid: props.page.duid, description: value };
  if (pageIsDashboard) {
    dataStore.updateDashboard(update);
  } else if (pageIsView) {
    dataStore.updateView(update);
  }
};

const finishEditingTitle = async (newTitle: string) => {
  const value = newTitle.trim();
  const pageIsDashboard = props.page.pageKind === PageKind.DASHBOARD;
  const pageIsView = props.page.pageKind === PageKind.VIEW;
  const pageIsDoc = props.page.pageKind === PageKind.DOC;

  if (value === "" && props.page.title === "") {
    // TODO Check if the dashboard's and view's filters are empty as well before deleting
    if (pageIsDashboard) {
      dataStore.deleteDashboard(props.page);
    } else if (pageIsView) {
      dataStore.deleteView(props.page);
    } else if (pageIsDoc && isLexicalStateEmpty(props.page.text)) {
      dataStore.trashDoc(props.page);
    }
    return;
  }

  if (props.page.iconKind !== IconKind.NONE) {
    const update = { duid: props.page.duid, title: value };
    if (pageIsDashboard) {
      dataStore.updateDashboard(update);
    } else if (pageIsView) {
      dataStore.updateView(update);
    } else if (pageIsDoc) {
      dataStore.updateDocs([update]);
    }
    return;
  }

  const updatedAt = new Date().toISOString();
  const emojiRecUpdate = await getEmojiRecommendation(props.page.duid, value);
  const emojiUpdate = { duid: props.page.duid, title: value, updatedAt, ...emojiRecUpdate };
  if (pageIsDashboard) {
    dataStore.updateDashboard(emojiUpdate);
  } else if (pageIsView) {
    dataStore.updateView(emojiUpdate);
  } else if (pageIsDoc) {
    dataStore.updateDocs([emojiUpdate]);
  }
};

const dropdownSections = computed(() =>
  props.page.pageKind === PageKind.DOC
    ? actions.context.doc(props.page, { startEditingTitle })
    : actions.context.page(props.page, true, (eventKind: string) => {
        switch (eventKind) {
          case "startEditingTitle": {
            startEditingTitle();
            break;
          }
          case "startEditingDescription": {
            startEditingDescription();
            break;
          }
          default: {
            throw new Error(`Unknown event kind: ${eventKind}`);
          }
        }
      })
);

const onContextMenu = (event: MouseEvent | KeyboardEvent) => {
  if (tenantStore.isDart && !pageStore.adminHidden && event.altKey) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, dropdownSections.value);
};

const toggleFavorite = () => {
  if (props.page.pageKind === PageKind.DASHBOARD) {
    dataStore.updateDashboardFavorite(props.page.duid, !isFavorited.value);
  } else if (props.page.pageKind === PageKind.VIEW) {
    dataStore.updateViewFavorite(props.page.duid, !isFavorited.value);
  }
};

defineExpose({
  startEditingTitle,
});
</script>

<template>
  <div
    class="group/link flex cursor-pointer items-center justify-between gap-2 rounded p-2 drag-none hover:bg-lt"
   
    @contextmenu="onContextMenu">
    <RouterLink :to="link" class="flex grow flex-row gap-1">
      <div class="dart-no-drag -ml-0.5 flex max-h-11 items-center gap-2">
        <PageIconPicker :page="page" class="h-max" @click.prevent @keydown.enter.prevent>
          <Tooltip text="Change icon">
            <span class="flex items-center justify-center rounded p-0.5 hover:bg-opposite/10">
              <PageIcon :page="page" large />
            </span>
          </Tooltip>
        </PageIconPicker>
        <div class="flex flex-col">
          <div class="-mx-0.5 flex max-w-80 flex-row items-center gap-1 text-md sm:max-w-[455px]">
            <TextInput
              ref="inputTitle"
              :text="page.title"
              :label="`${pageName} title`"
              editable
              click-to-edit
             
              text-base
              class="truncate"
              @save="finishEditingTitle" />
            <Tooltip
              v-if="page.pageKind !== PageKind.DOC"
              :text="`${isFavorited ? `Remove this ${pageName} from` : `Add this ${pageName} to`} favorites`">
              <Button
                :btn-style="ButtonStyle.SECONDARY"
                is-contrast
               
                :icon="isFavorited ? FavoriteIcon : UnfavoriteIcon"
                :icon-size="IconSize.S"
                :icon-args="{ class: '!text-vlt' }"
                borderless
                a11y-label="Toggle favorite"
                class="!p-px"
                :class="!isFavorited && 'hidden group-hover/link:flex'"
                @click.prevent
                @click="toggleFavorite" />
            </Tooltip>
          </div>
          <span
            v-if="page.pageKind === PageKind.DOC"
            class="max-w-80 select-none truncate text-sm text-vlt sm:max-w-[455px]">
            {{ convertToPlainText(convertToMarkdown(page.text)) }}
          </span>
          <TextInput
            v-else-if="showDescriptionInput"
            ref="inputDescription"
            :text="page.description"
            :label="`${pageName} title`"
            editable
            click-to-edit
            class="-mx-0.5 -mt-px max-w-80 select-none truncate text-vlt sm:max-w-[455px]"
            @save="saveDescription" />
        </div>
      </div>
    </RouterLink>
    <div v-if="page.pageKind === PageKind.DOC" class="flex flex-col items-end gap-2">
      <AvatarGroup
        :ai="page.editedByAi"
        :duids="page.editorDuids"
        :editor-mode="EditorMode.FOLDER"
        tooltip-bottom
        :unset-label="UNKNOWN_USER_LABEL" />
      <Tooltip v-if="relatedTasks.length > 0" :text="relatedTasksText">
        <button
          type="button"
          class="flex h-5 items-center justify-center gap-0.5 rounded border px-0.5 text-gray-900/30 border-oncolor focus-ring-none dark:text-white/20">
          <TaskIcon class="icon-xs" aria-hidden="true" />
          <span class="text-xs">{{ relatedTasks.length }}</span>
        </button>
      </Tooltip>
    </div>
    <div v-else class="flex items-center gap-2">
      <Tooltip
        v-if="'accessibleByTeam' in page"
        :disabled="!page.accessibleByTeam && !('public' in page && page.public)"
        :text="`Can be accessed by ${'public' in page && page.public ? 'people outside this workspace' : 'everyone in this workspace'}`">
        <PublicIcon v-if="'public' in page && page.public" class="outline-none text-vlt icon-md" />
        <WorkspaceSettingsIcon v-else-if="page.accessibleByTeam" class="outline-none text-vlt icon-md" />
        <AvatarGroup
          v-else
          :duids="page.accessibleByUserDuids"
          :editor-mode="EditorMode.FOLDER"
          tooltip-bottom
          :unset-label="UNKNOWN_USER_LABEL" />
      </Tooltip>
    </div>

    <DropdownMenu :sections="dropdownSections">
      <Tooltip :text="`${capitalize(page.pageKind)} options`">
        <Button
          :btn-style="ButtonStyle.SECONDARY"
          :icon="DotsHorizontalIcon"
          :icon-size="IconSize.S"
          borderless
         
          class="!p-px hover:bg-md"
          :a11y-label="`${capitalize(page.pageKind)} options`" />
      </Tooltip>
    </DropdownMenu>
  </div>
</template>
