<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";

import { backendOld } from "~/api";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import StatusEditor from "~/components/dumb/StatusEditor.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UpgradeConfirmationDialog from "~/components/dumb/UpgradeConfirmationDialog.vue";
import { UNSET_STATUS_PSUEDO_DUID } from "~/constants/status";
import {
  AlertIcon,
  CheckVerifiedIcon,
  DotsHorizontalIcon,
  GitBranchIcon,
  GithubBranchIcon,
  GithubIcon,
  GithubPullRequestDraftIcon,
  GithubPullRequestIcon,
  GithubPullRequestMergedIcon,
  OpenExternalLinkIcon,
} from "~/icons";
import { DropdownMenuItemKind, EditorMode, Entitlement, GithubIntegrationStatus, Placement } from "~/shared/enums";
import type { DropdownMenuItem, GithubEvents } from "~/shared/types";
import { useDataStore, useEnvironmentStore, useTenantStore } from "~/stores";

const EVENT_DEFINITIONS = {
  statusOnBranchLinkCopyDuid: {
    text: "the branch name is copied in Dart",
    icon: GitBranchIcon,
    iconColor: "text-lt",
  },
  statusOnBranchCreateDuid: {
    text: "the branch is created in GitHub",
    icon: GithubBranchIcon,
    iconColor: "text-success-base",
  },
  statusOnPrDraftDuid: {
    text: "the pull request is drafted",
    icon: GithubPullRequestDraftIcon,
    iconColor: "text-lt",
  },
  statusOnPrReadyDuid: {
    text: "the pull request is ready",
    icon: GithubPullRequestIcon,
    iconColor: "text-success-base",
  },
  statusOnPrMergeDuid: {
    text: "the pull request merges",
    icon: GithubPullRequestMergedIcon,
    iconColor: "text-primary-base",
  },
};

const router = useRouter();
const dataStore = useDataStore();
const environmentStore = useEnvironmentStore();
const tenantStore = useTenantStore();

const upgradeRequired = computed(() => !tenantStore.getEntitlementValue(Entitlement.GITHUB));

const queryOrig = router.currentRoute.value.query;
const { state } = queryOrig;
const installationId = queryOrig.installation_id;

// Recursive hack to make sure that it happens in case some other nav is also happening
const resetQuery = async () => {
  const query = { ...router.currentRoute.value.query };
  delete query.state;
  delete query.setup_action;
  delete query.installation_id;
  const result = await router.replace({ query });
  if (!result) {
    return;
  }
  setTimeout(resetQuery, 100);
};
resetQuery();

const integrationPending = computed(() => tenantStore.githubIntegration?.status === GithubIntegrationStatus.PENDING);
const integrationSuspended = computed(
  () => tenantStore.githubIntegration?.status === GithubIntegrationStatus.SUSPENDED
);
const integrationEnabled = computed(() => tenantStore.githubIntegration?.status === GithubIntegrationStatus.ENABLED);
const linkbackEnabled = computed(() => tenantStore.githubIntegration?.linkbackEnabled || false);
const autoAssign = computed(() => tenantStore.githubIntegration?.autoAssign || false);

// Link installation to tenant
if (integrationPending.value && state === tenantStore.duid && typeof installationId === "string") {
  backendOld.vcs.enableGithubIntegration(installationId);
}

const appName = computed(() => (environmentStore.isLocal ? "its-dart-dev" : "its-dart"));

// Create integration and redirect to github to install
const link = async () => {
  if (upgradeRequired.value) {
    return;
  }

  await backendOld.vcs.enableGithubIntegration(null);
  window.location.href = `https://github.com/apps/${appName.value}/installations/new?state=${tenantStore.duid}`;
};

const unlink = () => {
  backendOld.vcs.disableGithubIntegration();
};

const updateLinkback = (newLinkbackEnabled: boolean) => {
  if (!tenantStore || !tenantStore.githubIntegration) {
    return;
  }
  backendOld.vcs.updateGithubSettings([{ field: "linkbackEnabled", value: newLinkbackEnabled }]);
  tenantStore.githubIntegration.linkbackEnabled = newLinkbackEnabled;
};

const updateAutoAssign = (newAutoAssign: boolean) => {
  if (!tenantStore || !tenantStore.githubIntegration) {
    return;
  }
  backendOld.vcs.updateGithubSettings([{ field: "autoAssign", value: newAutoAssign }]);
  tenantStore.githubIntegration.autoAssign = newAutoAssign;
};

const onStatusChanged = (key: string, status: string) => {
  if (!tenantStore.githubIntegration) {
    return;
  }
  const newStatus = status === UNSET_STATUS_PSUEDO_DUID ? null : status;
  backendOld.vcs.updateGithubSettings([{ field: key, value: newStatus }]);

  tenantStore.githubIntegration[key as keyof GithubEvents] = newStatus;
};

const eventItems = computed(
  () =>
    new Map(
      Object.entries(EVENT_DEFINITIONS).map(([key, value]) => [
        key,
        {
          ...value,
          status: tenantStore.githubIntegration ? tenantStore.githubIntegration[key as keyof GithubEvents] : null,
        },
      ])
    )
);

const dropdownItems = computed(() => {
  const items: DropdownMenuItem[] = [
    {
      title: "Configure integration",
      kind: DropdownMenuItemKind.EXTERNAL_LINK,
      navigate: {
        to:
          tenantStore.githubIntegration?.installationLink ??
          `https://github.com/apps/${appName.value}/installations/new`,
        newTab: true,
      },
    },
  ];
  if (integrationEnabled.value) {
    items.push({
      title: "Disable integration",
      kind: DropdownMenuItemKind.BUTTON,
      onClick: () => unlink(),
    });
  }
  return [
    {
      title: "Configure",
      items,
    },
  ];
});
</script>

<template>
  <div class="h-full overflow-y-scroll">
    <div class="mx-16 flex flex-col gap-y-16 lg:mx-32">
      <div class="flex flex-col justify-center space-y-3">
        <div class="flex justify-between">
          <div class="flex items-center gap-4">
            <h2 class="select-none text-xl text-md">GitHub integration</h2>
            <UpgradeConfirmationDialog v-if="upgradeRequired" feature-action="use the GitHub integration" />
            <Tooltip
              v-if="tenantStore.githubIntegration && (integrationSuspended || integrationEnabled)"
              :disabled="integrationEnabled"
              text="Integration suspended in GitHub, click the button below to fix">
              <div
                class="flex h-[26px] select-none items-center gap-1 rounded px-1"
                :class="integrationEnabled ? 'bg-primary-base' : 'bg-warning-base'">
                <component :is="integrationEnabled ? CheckVerifiedIcon : AlertIcon" class="text-oncolor icon-sm" />
                <span class="text-xs text-oncolor">
                  {{ integrationSuspended ? "Suspended" : "Connected" }}
                </span>
              </div>
            </Tooltip>
          </div>
          <DropdownMenu v-if="integrationEnabled" :sections="dropdownItems" :placement="Placement.BOTTOM_RIGHT">
            <Tooltip text="Modify integration">
              <button
                type="button"
                class="flex items-center rounded p-0.5 text-base text-lt focus-ring-std hover:bg-md">
                <span class="sr-only">Modify integration</span>
                <DotsHorizontalIcon class="icon-sm" aria-hidden="true" />
              </button>
            </Tooltip>
          </DropdownMenu>
        </div>
        <p class="select-none text-sm/relaxed text-lt">
          Integrate GitHub with Dart to enable convenient automated linking between Dart tasks and GitHub PRs and
          branches, and automatic status updates in Dart when the code changes status.
        </p>
        <p v-if="integrationEnabled" class="select-none text-sm/relaxed text-lt">
          The integration works based on Dart task IDs in branch names. You can get the appropriate branch name for any
          task by clicking the branch icon in that task's header.
        </p>
      </div>

      <a
        v-if="integrationSuspended"
        :href="tenantStore.githubIntegration?.installationLink ?? ''"
        class="flex cursor-pointer select-none items-center justify-center gap-2 self-center rounded border px-3 py-2 text-center text-warning-base shadow-sm border-md focus-ring-std hover:bg-lt"
        target="_blank"
        rel="noopener noreferrer">
        Unsuspend Dart in GitHub
        <GithubIcon class="icon-md" />
      </a>

      <UpgradeConfirmationDialog feature-action="use the GitHub integration" :disabled="!upgradeRequired">
        <button
          v-if="!integrationEnabled && !integrationSuspended && !environmentStore.isStag"
          type="button"
          class="flex select-none items-center justify-center gap-2 self-center rounded border px-3 py-2 text-center text-base shadow-sm text-md border-md focus-ring-std hover:bg-lt"
          @click="link">
          <GithubIcon class="icon-md" />
          Link Dart and GitHub
          <OpenExternalLinkIcon class="icon-md" />
        </button>
      </UpgradeConfirmationDialog>

      <template v-if="integrationEnabled">
        <div class="flex flex-col gap-y-16">
          <Toggle
            :value="linkbackEnabled"
            label="Enable link comments"
            description="Dart will automatically comment on pull requests with a link to the corresponding task"
            @update="updateLinkback" />

          <Toggle
            :value="autoAssign"
            label="Assign tasks automatically"
            description="Dart will automatically assign unassigned tasks to whoever copies the branch link"
            @update="updateAutoAssign" />

          <div class="flex select-none flex-col">
            <span class="font-normal text-md">Update task statuses automatically</span>
            <span class="mb-3 text-xs text-lt">
              Dart will automatically update your task's statuses based on the events below. Configure which events will
              cause updates and what status they correspond to.
            </span>
            <div class="flex w-full flex-col gap-2">
              <div v-for="[key, event] in eventItems" :key="key">
                <div
                  :class="event.status ? 'border-lt' : 'border-dashed bg-transparent border-hvy dark:bg-transparent'"
                  class="relative flex h-10 w-full items-center gap-2 rounded border px-3 bg-lt text-lt">
                  <component :is="event.icon" :class="[event.iconColor, 'icon-md']" />
                  <div class="flex-1 shrink-0 truncate border border-transparent text-sm">
                    When {{ event.text }}, change the task's status to
                  </div>
                  <StatusEditor
                    :property="dataStore.defaultStatusProperty"
                    :tasks="[]"
                    :value="event.status || 'unchanged'"
                    :editor-mode="EditorMode.CHIP_GITHUB"
                    @select="(duid: string) => onStatusChanged(key, duid)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
