<script setup lang="ts">
import { computed, ref } from "vue";

import {
  FIELDS_ALWAYS_AVAILABLE_SET,
  NO_VALUE_APPLICABILITIES,
  ONE_VALUE_APPLICABILITIES,
  TWO_VALUE_APPLICABILITIES,
} from "~/common/filter";
import { getProperty } from "~/common/properties";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { AI_PROPERTY_PSUEDO_DUID } from "~/constants/property";
import { colorsByTheme } from "~/constants/style";
import { LockIcon, XIcon } from "~/icons";
import { filterHasApplicability, filterHasConnector } from "~/shared/common";
import { CommandId, FilterApplicability, type FilterConnector } from "~/shared/enums";
import type { Filter as IFilter, FilterDefinition, FilterValue } from "~/shared/types";
import { usePageStore } from "~/stores";

import Applicability from "./Applicability.vue";
import Connector from "./Connector.vue";
import Field from "./Field.vue";
import FilterContent from "./FilterContent.vue";

const NO_CHIP_APPLICABILITIES = new Set([
  ...NO_VALUE_APPLICABILITIES,
  FilterApplicability.CONTAINS,
  FilterApplicability.CONTAIN,
]);

const props = defineProps<{
  filter: IFilter;
  definition: FilterDefinition | undefined;
  openNewFilter: () => void;
  readOnly?: boolean;
}>();

const emit = defineEmits<{
  setFilter: [filter: IFilter];
  removeFilter: [field: string];
}>();

const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const valuesRef = ref<InstanceType<typeof FilterContent> | null>(null);
const applicabilityRef = ref<InstanceType<typeof Applicability> | null>(null);

const isAi = computed(() => props.filter.propertyDuid === AI_PROPERTY_PSUEDO_DUID);
const property = computed(() => getProperty(props.filter.propertyDuid));
const alwaysAvailable = computed(() => property.value && FIELDS_ALWAYS_AVAILABLE_SET.has(property.value.kind));
const hasValues = computed(
  () =>
    props.filter.values.length > 0 ||
    (filterHasApplicability(props.filter) && NO_VALUE_APPLICABILITIES.has(props.filter.applicability))
);

const updateConnector = (connector: FilterConnector) => {
  if (filterHasConnector(props.filter)) {
    emit("setFilter", { ...props.filter, connector });
  }
};

const updateApplicability = (applicability: FilterApplicability) => {
  if (!filterHasApplicability(props.filter)) {
    return;
  }
  let { values } = props.filter;
  if (NO_VALUE_APPLICABILITIES.has(applicability) && values.length > 0) {
    values = [];
  }
  if (ONE_VALUE_APPLICABILITIES.has(applicability) && values.length > 1) {
    values = values.slice(0, 1);
  }
  if (TWO_VALUE_APPLICABILITIES.has(applicability) && values.length > 2) {
    values = values.slice(0, 2);
  }
  emit("setFilter", {
    ...props.filter,
    applicability,
    // Clear values if applicability is changed to not set
    values,
  });
};

const updateValues = (values: FilterValue[]) => emit("setFilter", { ...props.filter, values });

const remove = () => emit("removeFilter", props.filter.propertyDuid);

const openDropdown = () => {
  valuesRef.value?.openDropdown();
};

const showApplicabilityOverride = ref(false);

const onAfterValuesClose = () => {
  if (props.filter.values.length > 0) {
    return;
  }
  showApplicabilityOverride.value = true;
  setTimeout(() => {
    showApplicabilityOverride.value = false;
    if (applicabilityRef.value?.isOpen) {
      return;
    }
    remove();
  }, 100);
};

const replaceFilter = () => {
  remove();
  setTimeout(() => {
    props.openNewFilter();
  }, 100);
};

const onAfterApplicabilityClose = () => {
  if (props.filter.values.length > 0) {
    return;
  }
  showApplicabilityOverride.value = true;
  openDropdown();
  setTimeout(() => {
    showApplicabilityOverride.value = false;
  }, 100);
};

const topRightIcon = computed(() => (props.filter.locked ? LockIcon : XIcon));

const working = computed(() => !!valuesRef.value?.working);

defineExpose({
  openDropdown,
  working,
});
</script>

<template>
  <div
    class="group/filter relative h-6 select-none rounded-l border-md"
    :class="{
      'border-y border-l': !isAi,
      'rounded-r-md border-r pr-px': hasValues,
      'hover:bg-lt': alwaysAvailable && !hasValues && !valuesRef?.editing,
      'rounded-r border-r':
        (filterHasApplicability(filter) && NO_CHIP_APPLICABILITIES.has(filter.applicability)) || alwaysAvailable,
    }"
    :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }">
    <FilterContent
      v-if="definition"
      ref="valuesRef"
      :filter="filter"
      :definition="definition"
      :read-only="readOnly"
      @select="updateValues"
      @after-close="onAfterValuesClose"
      @replace-filter="replaceFilter"
      @remove-filter="remove">
      <template #content="{ selectField }">
        <Tooltip :command-id="CommandId.CHANGE_FILTER_VALUES" :disabled="filter.locked">
          <Field
            :key="filter.propertyDuid"
            :absentee="alwaysAvailable && !hasValues && !valuesRef?.editing"
            :property-duid="filter.propertyDuid"
            :definition="definition"
            @click="selectField"
            @keydown.enter="selectField" />
        </Tooltip>
        <Applicability
          v-if="
            filterHasApplicability(filter) &&
            (hasValues || showApplicabilityOverride || applicabilityRef?.isOpen || valuesRef?.editing)
          "
          ref="applicabilityRef"
          :definition="definition"
          :applicability="filter.applicability"
          :disabled="filter.locked"
          @select="updateApplicability"
          @after-close="onAfterApplicabilityClose"
          @remove="remove" />
      </template>

      <template #connector>
        <Connector
          v-if="filterHasConnector(filter)"
          :definition="definition"
          :connector="filter.connector"
          :disabled="filter.locked"
          @select="updateConnector" />
        <span v-else class="px-[3px] text-sm text-md">or</span>
      </template>
    </FilterContent>

    <!-- dummy elements to help avoid overshooting the x -->
    <div class="absolute -top-1.5 right-0 hidden h-1.5 w-3 group-hover/filter:flex" />
    <div class="absolute -right-1.5 top-0 hidden h-3 w-1.5 group-hover/filter:flex" />
    <button
      v-if="hasValues || !alwaysAvailable"
      type="button"
      :disabled="filter.locked"
      class="absolute -right-1.5 -top-1.5 hidden items-center justify-center rounded-full border bg-lt border-md icon-xs group-hover/filter:flex"
      :class="filter.locked ? 'cursor-not-allowed' : 'cursor-pointer'"
      aria-label="Remove filter"
      @click="remove"
      @keydown.enter="remove">
      <Tooltip
        :command-id="filter.locked ? undefined : alwaysAvailable ? CommandId.CLEAR_FILTER : CommandId.REMOVE_FILTER"
        :text="filter.locked ? 'This filter is locked because of the view' : undefined">
        <component :is="topRightIcon" class="size-full text-lt focus:outline-none" />
      </Tooltip>
    </button>
  </div>
</template>
