<script setup lang="ts">
import { computed, onUnmounted, watchEffect } from "vue";
import { useRouter } from "vue-router";

import DragArea from "~/components/dumb/DragArea.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UpgradeConfirmationDialog from "~/components/dumb/UpgradeConfirmationDialog.vue";
import { PlusIcon } from "~/icons";
import { getQueryParam, makeLinkToTaskKindSettingsRef } from "~/router/common";
import { Entitlement } from "~/shared/enums";
import type { TaskKind } from "~/shared/types";
import { useDataStore, useTenantStore } from "~/stores";
import { getItemCountText, getNextTitleInSequence } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";

import TaskKindsSidebarItem from "./TaskKindsSidebarItem.vue";

const emit = defineEmits<{
  createTaskKind: [];
}>();

const router = useRouter();
const dataStore = useDataStore();
const tenantStore = useTenantStore();

const taskKinds = computed(() => dataStore.taskKindList);

const maxTaskKinds = computed(() => tenantStore.getEntitlementValue(Entitlement.MAX_TASK_KINDS));
const upgradeRequired = computed(() => taskKinds.value.length >= maxTaskKinds.value);

const createTaskKind = async () => {
  if (upgradeRequired.value) {
    return;
  }

  const taskKind = await dataStore.createTaskKind(
    getNextTitleInSequence(
      "Type",
      taskKinds.value.map((e) => e.title),
      { noCopy: true }
    ),
    getOrdersBetween(undefined, taskKinds.value[0]?.order)[0]
  );
  if (!taskKind) {
    return;
  }

  router.replace(makeLinkToTaskKindSettingsRef(taskKind.duid).value);

  setTimeout(() => {
    emit("createTaskKind");
  }, 100);
};

const getTaskKindProps = (taskKind: TaskKind) => ({ taskKind });

const moveTaskKind = (_: string, taskKind: TaskKind) => {
  dataStore.updateTaskKind({ duid: taskKind.duid, order: taskKind.order });
};

// Default to the first task kind if no task kind is selected
watchEffect(() => {
  const type = getQueryParam("type");
  if (!type || !dataStore.getTaskKindByDuid(type)) {
    setTimeout(() => {
      router.replace(makeLinkToTaskKindSettingsRef(dataStore.taskKindList[0]?.duid).value);
    });
  }
});

// Remove query param on unmount.
onUnmounted(() => {
  router.replace({ query: { ...router.currentRoute.value.query, type: undefined } });
});
</script>

<template>
  <div class="h-full w-56 overflow-y-auto border-r px-2.5 pb-6 pt-[18px] bg-std border-lt">
    <div class="mb-[3px] flex items-center justify-between">
      <span class="select-none px-2.5 text-xs font-semibold uppercase text-vlt">Types</span>
      <UpgradeConfirmationDialog
        :disabled="!upgradeRequired"
        :feature-action="`create more than ${getItemCountText(maxTaskKinds, 'type', { noSpecial: true })}`">
        <Tooltip text="Create a new type">
          <button
            type="button"
            class="items-center justify-center rounded p-0.5 focus-ring-lt hover:bg-md"
            aria-label="Create a new type"
            @click="createTaskKind">
            <PlusIcon class="text-vlt icon-sm" aria-hidden="true" />
          </button>
        </Tooltip>
      </UpgradeConfirmationDialog>
    </div>

    <DragArea
      v-if="taskKinds.length > 0"
      group="task-kinds"
      category="task-kinds"
      class="mt-1 !h-auto min-h-[20px] flex-1 gap-px rounded"
      drop-area-classes="bg-md/50"
      :items="taskKinds"
      :component="TaskKindsSidebarItem"
      :get-component-props="getTaskKindProps"
      @change="moveTaskKind" />
  </div>
</template>
