<script setup lang="ts">
import { type Component, computed, getCurrentInstance, nextTick, onMounted, onUnmounted, ref } from "vue";

import { backendOld } from "~/api";
import { getFilterDefinitionList } from "~/common/filter";
import { getPropertyWithConfigList } from "~/common/properties";
import Animated from "~/components/dumb/Animated.vue";
import Button from "~/components/dumb/Button.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import AddFilter from "~/components/filters/AddFilter.vue";
import Filter from "~/components/filters/Filter.vue";
import SortDropdown from "~/components/filters/SortDropdown.vue";
import { normalizeEmoji } from "~/constants/iconAndEmoji";
import { SaveViewIcon, XIcon } from "~/icons";
import {
  ButtonSize,
  ButtonStyle,
  FilterApplicability,
  FilterConnector,
  IconKind,
  IconSize,
  PageKind,
  PropertyKind,
  ViewKind,
} from "~/shared/enums";
import type { Filter as IFilter, Layout } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { makeDuid, makeUuid } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";

const DEFAULT_DARTBOARD_FILTER_PARTIAL = {
  id: "dartboard",
  applicability: FilterApplicability.IS,
  connector: FilterConnector.OR,
  values: [],
};
const DEFAULT_ASSIGNEES_FILTER_PARTIAL = {
  id: "assignee",
  applicability: FilterApplicability.IS,
  connector: FilterConnector.OR,
  values: [],
};
const DEFAULT_STATUS_FILTER_PARTIAL = {
  id: "status",
  applicability: FilterApplicability.IS,
  connector: FilterConnector.OR,
  values: [],
};

const props = defineProps<{
  filters?: IFilter[];
  isSearchMode?: boolean;
}>();

const emit = defineEmits<{
  setFilter: [filter: IFilter];
  removeFilter: [propertyDuid: string];
}>();

const currentInstance = getCurrentInstance();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const sortRef = ref<InstanceType<typeof SortDropdown> | null>(null);
const addFilterRef = ref<InstanceType<typeof AddFilter> | null>(null);

const filtersRef = new Map();
const assignFilterRef = (index: number, e: Component | null) => {
  if (!e) {
    return;
  }
  filtersRef.set(index, e);
};

const disabledAddButton = computed(() => [...filtersRef.values()].some((e) => e.working));
const onSearchPage = computed(() => appStore.currentPage?.kind === ViewKind.SEARCH);

const rawFilters = computed(() => props.filters ?? appStore.filters);
const displayedFilters = computed(() => {
  const remaining = [...rawFilters.value];
  const res: IFilter[] = [];

  const rearrangeForKind = (propertyKind: PropertyKind, defaultFilter: Omit<IFilter, "propertyDuid">) => {
    const property = getPropertyWithConfigList()
      .map((e) => e[0])
      .find((e) => e.kind === propertyKind);
    if (!property) {
      return;
    }
    const propertyDuid = property.duid;
    const index = remaining.findIndex((e) => e.propertyDuid === propertyDuid);
    if (index !== -1) {
      res.push(remaining[index]);
      remaining.splice(index, 1);
    } else {
      res.push({ ...defaultFilter, propertyDuid });
    }
  };

  if (appStore.currentPage?.pageKind !== PageKind.DARTBOARD || props.isSearchMode) {
    rearrangeForKind(PropertyKind.DEFAULT_DARTBOARD, DEFAULT_DARTBOARD_FILTER_PARTIAL);
  }

  rearrangeForKind(PropertyKind.DEFAULT_ASSIGNEES, DEFAULT_ASSIGNEES_FILTER_PARTIAL);
  rearrangeForKind(PropertyKind.DEFAULT_STATUS, DEFAULT_STATUS_FILTER_PARTIAL);

  return res.concat(remaining);
});
const filterDefinitions = computed(() => getFilterDefinitionList(getPropertyWithConfigList()));
const canSaveView = computed(() => onSearchPage.value && displayedFilters.value.length > 0);

const setFilter = (filter: IFilter) => {
  if (props.isSearchMode) {
    emit("setFilter", filter);
  } else {
    appStore.setFilter(filter);
  }
};

const removeFilter = (propertyDuid: string) => {
  if (props.isSearchMode) {
    emit("removeFilter", propertyDuid);
  } else {
    appStore.removeFilter(propertyDuid);
  }
};

const openFilter = (propertyDuid: string) => {
  const index = displayedFilters.value.findIndex((filter) => filter.propertyDuid === propertyDuid);
  if (index === -1) {
    return;
  }
  filtersRef.get(index).openDropdown();
};

const addFilter = (propertyDuid: string) => {
  const definition = filterDefinitions.value.find((e) => e.propertyDuid === propertyDuid);
  if (!definition) {
    return;
  }

  setFilter({
    id: makeUuid(),
    propertyDuid,
    connector: FilterConnector.OR,
    applicability:
      definition.config.applicabilities.length > 0 ? definition.config.applicabilities[0] : FilterApplicability.IS,
    values: [],
  });

  nextTick(() => openFilter(propertyDuid));
};

const onReset = () => {
  appStore.resetFilters();
  appStore.resetSorts();
};

const onSaveView = async () => {
  const { layoutDuid } = dataStore.getViewByKind(ViewKind.SEARCH);
  const searchLayout = dataStore.getLayoutByDuid(layoutDuid);

  // Create view & redirect to it.
  const partialLayout: Partial<Layout> = {
    ...JSON.parse(JSON.stringify(searchLayout)),
    duid: makeDuid(),
    filterGroup: {
      filters: displayedFilters.value,
      connector: FilterConnector.AND,
    },
  };

  const newViewOrder = getOrdersBetween(undefined, dataStore.viewList[0].order)[0];
  const newView = await dataStore.createView(newViewOrder, { title: "Saved search" }, partialLayout);
  appStore.setCurrentPage(newView);

  if (!pageStore.isOnline) {
    return;
  }

  const res = await backendOld.recommendations.getFiltersSummary(layoutDuid);
  const { summary, emoji } = res.data.item;

  dataStore.updateView({
    duid: newView.duid,
    title: summary,
    iconKind: IconKind.EMOJI,
    iconNameOrEmoji: normalizeEmoji(emoji),
  });
};

const newFilter = () => {
  addFilterRef.value?.openDropdown();
};

const newSort = () => {
  sortRef.value?.openDropdown();
};

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.filterArea = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
});

onUnmounted(() => {
  appStore.filterArea = null;
});

defineExpose({
  addFilter,
  newFilter,
  newSort,
  openFilter,
});
</script>

<template>
  <div class="flex items-center gap-3 border-t border-md">
    <template v-if="!isSearchMode">
      <SortDropdown ref="sortRef" />
      <div class="h-full w-px bg-md" />
    </template>
    <Animated class="flex flex-1 flex-wrap gap-1">
      <Filter
        v-for="(filter, index) in displayedFilters"
        :ref="(elem) => assignFilterRef(index, elem)"
        :key="filter.id"
        :filter="filter"
        :definition="filterDefinitions.find((e) => e.propertyDuid === filter.propertyDuid)"
        :open-new-filter="newFilter"
        @set-filter="setFilter"
        @remove-filter="removeFilter" />
      <AddFilter
        ref="addFilterRef"
        :hide-ai="isSearchMode"
        :disabled="disabledAddButton"
        :filters="displayedFilters"
        @select="addFilter" />
    </Animated>
    <div v-if="!isSearchMode" class="flex items-center">
      <Tooltip
        v-if="!appStore.usingDefaultFiltersAndSorts && !onSearchPage"
        text="Reset any active filters and sorts to their defaults">
        <Button
          :btn-style="ButtonStyle.SECONDARY"
          text="Reset"
          class="h-6 pl-1.5"
          :size="ButtonSize.CHIP"
          :icon-after="XIcon"
          :icon-size="IconSize.XS"
          @click="onReset" />
      </Tooltip>

      <Tooltip v-if="canSaveView" text="Save this search as a view for future use">
        <Button
          :btn-style="ButtonStyle.SECONDARY"
          text="Save view"
          class="h-6"
          :size="ButtonSize.CHIP"
          :icon="SaveViewIcon"
          :icon-size="IconSize.XS"
          @click="onSaveView" />
      </Tooltip>
    </div>
  </div>
</template>
