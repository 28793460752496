<script setup lang="ts">
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import { computed, ref } from "vue";
import { RecycleScroller } from "vue-virtual-scroller";

import ColorPicker from "~/components/dumb/ColorPicker.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Input from "~/components/dumb/Input.vue";
import PageIconFromSvg from "~/components/dumb/PageIconFromSvg.vue";
import SkinTonePicker from "~/components/dumb/SkinTonePicker.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import {
  DEFAULT_SKIN_TONE_EMOJI,
  EMOJI_ORDERED_LIST,
  EMOJI_TO_KEYWORD_MAP,
  emojiAllowsSkinTone,
  formatEmojiName,
  formatIconName,
  getEmojiWithSkinTone,
  ICON_NAME_ALTERNATES,
  removeSkinTone,
} from "~/constants/iconAndEmoji";
import { RecommendationIcon, ShuffleIcon } from "~/icons";
import { DropdownMenuItemKind, EmojiSkinTone, IconKind, Placement } from "~/shared/enums";
import { useAppStore, usePageStore } from "~/stores";
import { randomSample } from "~/utils/common";

const props = defineProps<{
  iconKind: IconKind;
  iconNameOrEmoji: string;
  colorHex: string;
  emojisOnly?: boolean;
  isContrast?: boolean;
  recommendFn?: (currentTab: IconKind) => Promise<string>;
}>();

const TABS = computed(() => (props.emojisOnly ? ["Emojis"] : ["Icons", "Emojis"]));

const emit = defineEmits<{
  selectIconKind: [iconKind: IconKind];
  selectColor: [colorHex: string];
  selectSkinTone: [skinTone: EmojiSkinTone];
  selectIconOrEmoji: [iconKind: IconKind, iconNameOrEmoji: string];
  afterSelect: [];
}>();

const appStore = useAppStore();
const pageStore = usePageStore();

const iconOrderedList = appStore.pageIconOptions.map((e) => e.name);
const iconToKeywordMap = new Map(
  iconOrderedList.map((e) => [e, [formatIconName(e), ...(ICON_NAME_ALTERNATES.get(e) ?? [])].join(" ")])
);

const currentTabIndex = ref(props.iconKind === IconKind.ICON || props.emojisOnly ? 0 : 1);
const currentTab = computed(() => (currentTabIndex.value === 0 && !props.emojisOnly ? IconKind.ICON : IconKind.EMOJI));
const searchInput = ref<InstanceType<typeof Input> | null>(null);
const generatingRecommendations = ref(false);

const changeTab = (tabIndex: number) => {
  searchInput.value?.clear();
  currentTabIndex.value = tabIndex;
};

const filteredIconOptions = computed(() => {
  let current: string[] = [];
  if (currentTab.value === IconKind.EMOJI || searchInput.value?.value.trim() === "") {
    current = iconOrderedList;
  } else {
    current = iconOrderedList.filter((e) => iconToKeywordMap.get(e)?.includes(searchInput.value?.value ?? ""));
  }
  return current.map((e) => ({ id: formatIconName(e), icon: e }));
});

const filteredEmojiOptions = computed(() => {
  let current: string[] = [];
  if (currentTab.value === IconKind.ICON || searchInput.value?.value.trim() === "") {
    current = EMOJI_ORDERED_LIST;
  } else {
    current = EMOJI_ORDERED_LIST.filter((e) => EMOJI_TO_KEYWORD_MAP.get(e)?.includes(searchInput.value?.value ?? ""));
  }
  return current.map((e) => ({ id: formatEmojiName(e), emoji: e }));
});

const getWithSkinTone = (iconOrEmoji: string) => {
  if (currentTab.value === IconKind.ICON) {
    return iconOrEmoji;
  }
  return getEmojiWithSkinTone(iconOrEmoji, appStore.skinTone);
};

const selectIconOrEmoji = (iconNameOrEmoji: string) => {
  if (currentTab.value === IconKind.ICON) {
    appStore.addToRecentIconStack(iconNameOrEmoji);
  } else {
    appStore.addToRecentEmojiStack(removeSkinTone(iconNameOrEmoji));
  }

  emit("selectIconOrEmoji", currentTab.value, getWithSkinTone(iconNameOrEmoji));
  emit("afterSelect");
};

const recommendIconOrEmoji = async () => {
  if (!props.recommendFn) {
    return;
  }

  generatingRecommendations.value = true;
  const res = await props.recommendFn(currentTab.value);

  generatingRecommendations.value = false;
  selectIconOrEmoji(getWithSkinTone(res));
};

const randomizeIconOrEmoji = () => {
  const iconOrEmojiName = randomSample(currentTab.value === IconKind.ICON ? iconOrderedList : EMOJI_ORDERED_LIST)[0];
  selectIconOrEmoji(getWithSkinTone(iconOrEmojiName));
};

const baseEmoji = computed(() =>
  emojiAllowsSkinTone(props.iconNameOrEmoji) ? removeSkinTone(props.iconNameOrEmoji) : DEFAULT_SKIN_TONE_EMOJI
);

const colorOrSkinToneMenu = ref<InstanceType<typeof DropdownMenu> | null>(null);

const colorOrSkinToneDropdownSections = computed(() => {
  if (currentTab.value === IconKind.ICON) {
    return [
      {
        title: "Modify",
        items: [
          {
            title: "Change color",
            kind: DropdownMenuItemKind.COMPONENT,
            noFocus: true,

            component: ColorPicker,
            componentArgs: {
              value: props.colorHex,
              isContrast: !props.isContrast,
              onSelect: (colorHex: string): void => {
                emit("selectColor", colorHex);
                // TODO add this back in when it doesn't also close the outer dropdown
                // colorOrSkinToneMenu.value.close();
              },
            },
          },
        ],
      },
    ];
  }
  return [
    {
      title: "Modify",
      items: [
        {
          title: "Change skin tone",
          kind: DropdownMenuItemKind.COMPONENT,
          noFocus: true,

          component: SkinTonePicker,
          componentArgs: {
            currentEmoji: props.iconNameOrEmoji,
            isContrast: !props.isContrast,
            onSelect: (skinTone: EmojiSkinTone): void => {
              emit("selectSkinTone", skinTone);
              // colorOrSkinToneMenu.value.close();
            },
          },
        },
      ],
    },
  ];
});
</script>

<template>
  <div class="flex w-full flex-col">
    <TabGroup :default-index="currentTabIndex" @change="changeTab">
      <TabList :class="TABS.length > 1 && 'border-b pt-1'" class="flex space-x-1 px-3 text-md border-md">
        <Tab v-for="tab in TABS" :key="tab" v-slot="{ selected }" as="template">
          <button
            v-if="TABS.length > 1"
            type="button"
            class="rounded-t px-3 py-1 text-sm"
            :class="selected ? 'bg-md hover:bg-hvy' : 'hover:bg-lt'">
            {{ tab }}
          </button>
          <div v-else />
        </Tab>
      </TabList>

      <div class="flex w-full items-center gap-2 px-3 py-2">
        <Input
          ref="searchInput"
          label="Search icons and emojis"
          hide-label
          :placeholder="`Search ${currentTab === IconKind.ICON ? 'icon' : 'emoji'}s`"
          class="w-full"
          input-classes="!px-2 !py-[3px] h-[28px]"
          @click.stop
          @keydown.stop />

        <!-- TODO remove feature flag and make tooltip text dynamic -->
        <Tooltip
          v-if="!!recommendFn && currentTab === IconKind.EMOJI && pageStore.isOnline"
          text="Use AI to recommend an emoji">
          <div
            class="flex size-7 items-center justify-center rounded bg-recommendation-base text-oncolor focus-ring-std focus-visible:!ring-recommendation-base"
            :class="[
              generatingRecommendations
                ? 'animate-pulse cursor-not-allowed opacity-25'
                : 'cursor-pointer hover-bg-recommendation',
              generatingRecommendations && 'animate-pulse',
            ]"
            @click="recommendIconOrEmoji"
            @keydown.enter="recommendIconOrEmoji">
            <RecommendationIcon class="icon-sm" aria-hidden="true" />
          </div>
        </Tooltip>

        <Tooltip :text="`Try a random ${currentTab === IconKind.ICON ? 'icon' : 'emoji'}`">
          <div
            class="flex size-7 cursor-pointer items-center justify-center rounded border text-lt border-hvy focus-ring-std"
            :class="isContrast ? 'hover:bg-md' : 'hover:bg-lt'"
            @click="randomizeIconOrEmoji"
            @keydown.enter="randomizeIconOrEmoji">
            <ShuffleIcon class="icon-sm" aria-hidden="true" />
          </div>
        </Tooltip>

        <DropdownMenu
          ref="colorOrSkinToneMenu"
          :sections="colorOrSkinToneDropdownSections"
          :placement="Placement.BOTTOM"
          :max-height-pixels="460"
          :is-contrast="!isContrast">
          <Tooltip :text="`Change ${currentTab === IconKind.ICON ? 'icon color' : 'emoji skin tone'}`">
            <div
              class="flex size-7 cursor-pointer items-center justify-center rounded border text-lt border-hvy focus-ring-std"
              :class="isContrast ? 'hover:bg-md' : 'hover:bg-lt'">
              <div
                v-if="currentTab === IconKind.ICON"
                class="rounded-full icon-sm"
                :style="{ backgroundColor: colorHex }" />
              <span v-else class="text-center font-emoji text-xl/6 text-lt">
                {{ getWithSkinTone(baseEmoji) }}
              </span>
            </div>
          </Tooltip>
        </DropdownMenu>
      </div>

      <TabPanels>
        <TabPanel v-if="!emojisOnly">
          <RecycleScroller
            :items="filteredIconOptions"
            :min-item-size="30"
            :item-size="30"
            :grid-items="12"
            key-field="id"
            item-class="flex items-center justify-center"
            class="max-h-[297px] justify-items-center overflow-y-scroll py-1 pl-2">
            <template #before>
              <!-- Recent Icons -->
              <div v-if="appStore.recentIconStack.length > 0" class="mt-2 flex flex-col gap-1 pb-3 pl-1">
                <span class="px-0.5 text-xs/normal font-semibold uppercase text-vlt">Recent</span>

                <div class="grid grid-cols-12 justify-items-center gap-1.5">
                  <div
                    v-for="iconOption in appStore.recentIconStack"
                    :key="iconOption"
                    :title="formatIconName(iconOption)"
                    class="flex cursor-pointer items-center justify-center rounded text-md icon-lg"
                    :class="isContrast ? 'hover:bg-md' : 'hover:bg-hvy'"
                    @click="selectIconOrEmoji(iconOption)"
                    @keydown.enter="selectIconOrEmoji(iconOption)">
                    <PageIconFromSvg class="icon-md" :name="iconOption" :style="{ color: colorHex }" />
                  </div>
                </div>
              </div>

              <!-- Other icons -->
              <div v-if="appStore.recentIconStack.length > 0" class="mb-1 flex flex-col pl-1">
                <span class="px-0.5 text-xs/normal font-semibold uppercase text-vlt">Icons</span>
              </div>
            </template>

            <template #default="{ item: { icon: iconOption } }">
              <div
                :title="formatIconName(iconOption)"
                class="flex cursor-pointer items-center justify-center rounded text-md icon-lg"
                :class="isContrast ? 'hover:bg-md' : 'hover:bg-hvy'"
                @click="selectIconOrEmoji(iconOption)"
                @keydown.enter="selectIconOrEmoji(iconOption)">
                <PageIconFromSvg class="icon-md" :name="iconOption" :style="{ color: colorHex }" />
              </div>
            </template>
          </RecycleScroller>
        </TabPanel>
        <TabPanel>
          <RecycleScroller
            :items="filteredEmojiOptions"
            :min-item-size="30"
            :item-size="30"
            :grid-items="12"
            key-field="id"
            item-class="flex items-center justify-center"
            class="max-h-[297px] justify-items-center overflow-y-scroll py-1 pl-2">
            <template #before>
              <div v-if="appStore.recentEmojiStack.length > 0" class="mt-2 flex flex-col gap-1 pb-3 pl-1">
                <span class="px-0.5 text-xs/normal font-semibold uppercase text-vlt">Recent</span>
                <div class="grid grid-cols-12 justify-items-center gap-1.5 text-center font-emoji text-xl/6 text-lt">
                  <div
                    v-for="emojiOption in appStore.recentEmojiStack"
                    :key="emojiOption"
                    :title="formatEmojiName(emojiOption)"
                    class="flex cursor-pointer items-center justify-center rounded font-emoji text-xl/6 text-lt icon-lg"
                    :class="isContrast ? 'hover:bg-md' : 'hover:bg-hvy'"
                    @click="selectIconOrEmoji(emojiOption)"
                    @keydown.enter="selectIconOrEmoji(emojiOption)">
                    {{ getWithSkinTone(emojiOption) }}
                  </div>
                </div>
              </div>

              <div v-if="appStore.recentEmojiStack.length > 0" class="mb-1 flex flex-col pl-1">
                <span class="px-0.5 text-xs/normal font-semibold uppercase text-vlt">Emojis</span>
              </div>
            </template>

            <template #default="{ item: { emoji: emojiOption } }">
              <div
                :title="formatEmojiName(emojiOption)"
                class="flex cursor-pointer items-center justify-center rounded font-emoji text-xl/6 text-lt icon-lg"
                :class="isContrast ? 'hover:bg-md' : 'hover:bg-hvy'"
                @click="selectIconOrEmoji(emojiOption)"
                @keydown.enter="selectIconOrEmoji(emojiOption)">
                {{ getWithSkinTone(emojiOption) }}
              </div>
            </template>
          </RecycleScroller>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>
