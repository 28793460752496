<script setup lang="ts">
import moment from "moment";
import { computed } from "vue";

import Button from "~/components/dumb/Button.vue";
import { ArrowLeftIcon, ArrowRightIcon } from "~/icons";
import { ButtonStyle, IconSize } from "~/shared/enums";
import type { TaskAbsenteeMaybe } from "~/shared/types";

import { getDiffPx, getPixelsTo } from "./common";
import { DEFAULT_BLOCK_WIDTH_PX, TASK_ROW_HEIGHT } from "./constants";
import type { RoadmapConfig, RoadmapValues } from "./shared";

const props = defineProps<{
  roadmapConfig: RoadmapConfig;
  roadmapValues: RoadmapValues;
  task: TaskAbsenteeMaybe;
  index: number;
}>();

const emit = defineEmits<{
  scrollTo: [x: number, y?: number];
}>();

/* Properties */
const startDate = computed(
  () =>
    props.task.startAt ??
    moment(props.task.dueAt)
      .subtract(Math.ceil(DEFAULT_BLOCK_WIDTH_PX / props.roadmapConfig.pxPerDay), "day")
      .toISOString()
);

const top = computed(() => props.index * TASK_ROW_HEIGHT);
const left = computed(() => getPixelsTo(props.roadmapConfig, startDate.value));
const height = computed(() => `${TASK_ROW_HEIGHT}px`);
const width = computed(() =>
  props.task.startAt && props.task.dueAt
    ? getDiffPx(props.roadmapConfig, props.task.startAt, props.task.dueAt)
    : DEFAULT_BLOCK_WIDTH_PX
);

/* Is left edge offscreen */
const isOffscreenLeft = computed(() => left.value < props.roadmapValues.scrollX);
/* Is right edge offscreen */
const isOffscreenRight = computed(
  () => left.value + width.value > props.roadmapValues.scrollX + props.roadmapValues.width
);
/* Is task fully offscreen either way */
const isOffscreenFully = computed(
  () =>
    left.value + width.value < props.roadmapValues.scrollX ||
    left.value > props.roadmapValues.scrollX + props.roadmapValues.width
);

const scrollToLeft = (): void => {
  emit(
    "scrollTo",
    isOffscreenFully.value
      ? left.value + width.value - props.roadmapValues.width / 5
      : left.value - props.roadmapValues.width / 5
  );
};

const scrollToRight = (): void => {
  emit(
    "scrollTo",
    isOffscreenFully.value
      ? left.value - props.roadmapValues.width / 5
      : left.value + width.value - props.roadmapValues.width / 5
  );
};

const wrapperStyle = "pointer-events-none sticky left-0 right-0 flex w-full select-none items-center px-2";
const buttonStyle = "bg-lt pointer-events-auto icon-md hover:bg-md";
</script>

<template>
  <!-- Left arrow -->
  <div
    v-if="isOffscreenLeft"
    :style="{
      minHeight: height,
      maxHeight: height,
      marginTop: `${top}px`,
      minWidth: `${roadmapValues.width}px`,
      maxWidth: `${roadmapValues.width}px`,
    }"
    :class="wrapperStyle"
    class="justify-start">
    <Button
      :btn-style="ButtonStyle.SECONDARY"
      :icon="ArrowLeftIcon"
      :icon-size="IconSize.S"
      :class="buttonStyle"
      a11y-label="Left arrow"
      @click.stop="scrollToLeft" />
  </div>
  <!-- Right arrow -->
  <div
    v-if="isOffscreenRight"
    :style="{
      minHeight: height,
      maxHeight: height,
      marginTop: `${top}px`,
      minWidth: `${roadmapValues.width}px`,
      maxWidth: `${roadmapValues.width}px`,
    }"
    :class="wrapperStyle"
    class="justify-end">
    <Button
      :btn-style="ButtonStyle.SECONDARY"
      :icon-after="ArrowRightIcon"
      :icon-size="IconSize.S"
      :class="buttonStyle"
      a11y-label="Right arrow"
      @click.stop="scrollToRight" />
  </div>
</template>
