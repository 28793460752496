<script setup lang="ts">
import { computed, ref } from "vue";
import { RouterLink } from "vue-router";

import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import DropdownMenuWithSelection from "~/components/dumb/DropdownMenuWithSelection.vue";
import DropTarget from "~/components/dumb/DropTarget.vue";
import FileUploadProgress from "~/components/dumb/FileUploadProgress.vue";
import { notify } from "~/components/notifications";
import { IMPORT_SOURCES, SOURCE_TO_INSTRUCTIONS_MAP } from "~/constants/import";
import { FileAddIcon, FileIcon } from "~/icons";
import { ButtonStyle } from "~/shared/enums";
import { checkFileSize } from "~/utils/api";
import { makeUuid } from "~/utils/common";

const inputId = ref(`input-${makeUuid()}`);

const selectedSource = ref<string | null>(null);
const sourceOptions = computed(() =>
  IMPORT_SOURCES.map((title) => ({
    title,
    selected: title === selectedSource.value,
    onClick: () => {
      selectedSource.value = title;
    },
  }))
);

const files = ref<File[]>([]);
const filenames = ref<string[]>([]);

const working = ref(false);
const submitButtonEnabled = computed(() => files.value?.length > 0 && selectedSource.value !== null);

const instructions = computed(() => SOURCE_TO_INSTRUCTIONS_MAP.get(selectedSource.value ?? "") ?? []);

const receiveFiles = (fileList: File[]) => {
  files.value = fileList.filter((file) => checkFileSize(file));
  if (files.value.length === 0) {
    return;
  }
  filenames.value = [];

  // Truncate filenames to avoid long filenames but include the extension
  for (let i = 0; i < files.value.length; i += 1) {
    const file = files.value[i];
    if (file.name.length < 10) {
      return;
    }

    const startStr: string = file.name.substring(0, 5);
    const endStr: string = file.name.substring(file.name.length - 5);

    filenames.value.push(`${startStr}...${endStr}`);
  }
};

const onInput = (e: Event) => {
  const input = e.target as HTMLInputElement;
  const fileList = input.files;
  receiveFiles(Array.from(fileList ?? []));
};

const uploadImportRecord = async (event: Event) => {
  event.preventDefault();
  if (selectedSource.value === null) {
    return;
  }
  working.value = true;
  await backendOld.workspace.import(selectedSource.value, files.value);
  notify({
    message: "Your data import has started, please check back later to see the new tasks",
    actions: [
      {
        label: "Close import",
        onClick: (dismiss) => dismiss(),
        component: RouterLink,
        componentArgs: {
          to: { name: "home" },
        },
      },
    ],
  });
};
</script>

<template>
  <div class="h-full overflow-y-auto">
    <div class="mx-auto w-full">
      <div class="mx-10">
        <form>
          <div class="mb-12">
            <h4 class="mb-2 select-none font-semibold text-md">Source</h4>
            <DropdownMenuWithSelection :options="sourceOptions" border>
              <div class="w-full select-none rounded px-3 py-2 text-left shadow-sm focus-ring-std hover:bg-lt">
                {{ selectedSource ?? "Select source" }}
              </div>
            </DropdownMenuWithSelection>
          </div>

          <div v-if="selectedSource !== null">
            <div class="mb-12 flex w-full flex-col">
              <h4 class="mb-2 select-none text-md">Instructions</h4>
              <ol class="ml-6 select-none list-decimal text-sm text-md">
                <template v-for="(content, index) in instructions" :key="index">
                  <li>
                    {{ Array.isArray(content) ? content[0] : content }}
                    <ol v-if="Array.isArray(content)" class="ml-6 list-decimal text-sm text-md">
                      <template v-for="(subContent, subIndex) in content.slice(1)" :key="subIndex">
                        <li>{{ subContent }}</li>
                      </template>
                    </ol>
                  </li>
                </template>
              </ol>
            </div>
            <h4 class="mb-2 select-none text-md">File</h4>
            <DropTarget styles="rounded" :data-types="['text/csv', 'application/json']" @drop="receiveFiles">
              <template #main>
                <div class="mb-12 flex items-center">
                  <FileUploadProgress duid="import">
                    <label
                      :for="inputId"
                      class="relative h-32 w-full cursor-pointer rounded border text-center shadow-sm bg-std text-md border-md focus-ring-std hover:bg-lt">
                      <span class="sr-only">Select files</span>
                      <input :id="inputId" type="file" class="sr-only h-full" accept=".csv,.json" @input="onInput" />
                      <div
                        class="absolute left-0 top-0 flex size-full flex-col items-center justify-center rounded bg-md/80">
                        <div v-if="files.length > 0" class="flex justify-center">
                          <div v-for="filename in filenames" :key="filename" class="mr-6">
                            <FileIcon class="icon-xl" aria-hidden="true" />
                            <span>{{ filename }}</span>
                          </div>
                        </div>
                        <FileAddIcon v-else class="icon-xl" aria-hidden="true" />
                        <span class="pointer-events-none select-none text-base text-hvy">
                          {{ `${files.length > 0 ? "Replace" : "Select"} file` }}
                        </span>
                      </div>
                    </label>
                  </FileUploadProgress>
                </div>
              </template>
              <template #dragOverlay>
                <div class="flex w-full items-center justify-center">
                  <span class="text-center text-2xl text-md">Drop to import</span>
                  <div />
                </div>
              </template>
            </DropTarget>

            <Button
              :btn-style="ButtonStyle.PRIMARY"
              text="Import"
              :disabled="!submitButtonEnabled"
              :working="working"
              block
              @click="uploadImportRecord" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
