<script setup lang="ts">
import { $isLinkNode } from "@lexical/link";
import { mergeRegister } from "@lexical/utils";
import { OnClickOutside } from "@vueuse/components";
import { useElementBounding } from "@vueuse/core";
import {
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  type BaseSelection,
  COMMAND_PRIORITY_EDITOR,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from "vue";

import { hasAsFirstClassSomewhereInHierarchy } from "~/utils/common";

import { getSelectedNode } from "../utils";
import LinkEditor from "./LinkEditor.vue";
import TextFormatOptions from "./TextFormatOptions.vue";

const TOOLBAR_WIDTH_WITHOUT_AI = 400;
const TOOLBAR_WIDTH_WITH_AT = 435;
const TOOLBAR_HEIGHT = 34;
const VERTICAL_OFFSET = 10;
const HORIZONTAL_OFFSET = 20;
const HORIZONTAL_PADDING = 4;
const IGNORED_CLASSES_FOR_STOPPING = new Set(["dart-editor"]);

const props = defineProps<{
  hideAiActionButton?: boolean;
  teleportKey: string;
}>();

const editor = useLexicalComposer();

const wrapper = ref<HTMLDivElement | null>(null);

const shown = ref(false);
const showLinkEditor = ref(false);

const width = computed(() => (props.hideAiActionButton ? TOOLBAR_WIDTH_WITHOUT_AI : TOOLBAR_WIDTH_WITH_AT));
const top = ref<string>("0px");
const left = ref<string>("0px");

const linkKey = ref<string | null>(null);
const linkUrl = ref<string | null>(null);

const teleportParentElement = ref<HTMLElement | null>(null);
const editorRootElement = ref<HTMLElement | null>(null);

const isMounted = ref(false);
const { top: rootTopRef } = useElementBounding(editorRootElement);

const setLocation = (nativeSelection: Selection, selectedElement?: HTMLElement) => {
  let rect;
  if (!teleportParentElement.value || !editorRootElement.value) {
    return;
  }
  if (selectedElement) {
    rect = selectedElement.getBoundingClientRect();
  } else if (nativeSelection.anchorNode !== editorRootElement.value) {
    const domRange = nativeSelection.getRangeAt(0);
    rect = domRange.getBoundingClientRect();
  } else {
    let inner = editorRootElement.value;
    while (inner.firstElementChild !== null) {
      inner = inner.firstElementChild as HTMLElement;
    }
    rect = inner.getBoundingClientRect();
  }

  const parentRect = teleportParentElement.value.getBoundingClientRect();
  const newTop = rect.top - parentRect.top + teleportParentElement.value.offsetTop - TOOLBAR_HEIGHT - VERTICAL_OFFSET;
  // TODO add in a calculation so the toolbar disappears if it is off the description, not off the parent
  if (newTop < 0 || newTop + TOOLBAR_HEIGHT > parentRect.height) {
    shown.value = false;
    return;
  }
  top.value = `${newTop}px`;

  const newLeft = Math.min(
    Math.max(
      HORIZONTAL_PADDING + teleportParentElement.value.offsetLeft - 1,
      rect.left - parentRect.left + teleportParentElement.value.offsetLeft - HORIZONTAL_OFFSET
    ),
    parentRect.width - width.value - HORIZONTAL_PADDING + 1 + teleportParentElement.value.offsetLeft
  );
  left.value = `${newLeft}px`;

  shown.value = true;
};

const getSelectedLinkNode = (selection: BaseSelection) => {
  if (!$isRangeSelection(selection)) {
    return null;
  }

  const node = getSelectedNode(selection);
  const parent = node.getParent();
  if ($isLinkNode(parent)) {
    return parent;
  }
  if ($isLinkNode(node)) {
    return node;
  }
  return null;
};

const updateToolbar = () => {
  shown.value = false;
  linkKey.value = null;
  linkUrl.value = null;
  showLinkEditor.value = false;

  const nativeSelection = window.getSelection();
  if (!nativeSelection) {
    return;
  }

  const { activeElement } = document;
  if (activeElement === wrapper.value) {
    return;
  }

  const selection = $getSelection();

  if (
    editorRootElement.value === null ||
    selection === null ||
    !editorRootElement.value.contains(nativeSelection.anchorNode)
  ) {
    return;
  }

  let selectedElement;

  const selectedLinkNode = getSelectedLinkNode(selection);
  if (selectedLinkNode !== null) {
    selectedElement = editor.getElementByKey(selectedLinkNode.getKey()) ?? undefined;
    linkKey.value = selectedLinkNode.getKey();
    linkUrl.value = selectedLinkNode.getURL();
    showLinkEditor.value = true;
  } else if (nativeSelection.isCollapsed) {
    return;
  }

  setLocation(nativeSelection, selectedElement);
};

const onSaveLinkUrl = (nodeKey: string, newLinkUrl: string | null) => {
  editor.update(() => {
    const lastLinkNode = $getNodeByKey(nodeKey);
    if (!$isLinkNode(lastLinkNode)) {
      return;
    }
    if (newLinkUrl !== null) {
      lastLinkNode.setURL(newLinkUrl);
      return;
    }
    lastLinkNode.getChildren().forEach((child) => {
      lastLinkNode?.insertBefore(child);
    });
    lastLinkNode.remove();
  });
};

const hideMaybe = (event: Event) => {
  const windowSelection = window.getSelection();
  const textSelected = windowSelection && windowSelection.rangeCount > 0 && !windowSelection.isCollapsed;

  if (!shown.value || hasAsFirstClassSomewhereInHierarchy(event, IGNORED_CLASSES_FOR_STOPPING) || textSelected) {
    return;
  }
  shown.value = false;
};

let unregisterListener: () => void;

onMounted(() => {
  nextTick(() => {
    teleportParentElement.value = document.querySelector(`[data-toolbar="${props.teleportKey}"]`);
    isMounted.value = true;
  });

  editorRootElement.value = editor.getRootElement();
  editor.getEditorState().read(() => {
    updateToolbar();
  });
  unregisterListener = mergeRegister(
    editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        updateToolbar();
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    )
  );
});

watch(rootTopRef, () => {
  if (shown.value) {
    editor.update(() => {
      updateToolbar();
    });
  }
});

onUnmounted(() => {
  unregisterListener?.();
});
</script>

<template>
  <Teleport v-if="isMounted" :to="teleportParentElement">
    <OnClickOutside @trigger="hideMaybe">
      <div
        v-if="isMounted"
        ref="wrapper"
        :class="!shown && 'hidden'"
        class="absolute rounded border shadow-sm bg-std border-md"
        :style="{ top, left, width: `${width}px`, height: `${TOOLBAR_HEIGHT}px` }">
        <TextFormatOptions v-if="!showLinkEditor" :hide-ai-action-button="hideAiActionButton" />
        <LinkEditor
          v-else-if="linkKey && linkUrl !== null"
          :key="linkKey"
          :url="linkUrl"
          :node-key="linkKey"
          @save="onSaveLinkUrl" />
      </div>
    </OnClickOutside>
  </Teleport>
</template>
