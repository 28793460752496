<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M 7.9999983,6.6666644 V 17.333332 M 16.000002,6.6666644 V 17.333332 M 9.3333322,6.6666644 H 6.6666644 M 16.000002,11.999998 H 7.9999983 m 1.3333339,5.333334 H 6.6666644 m 10.6666716,0 h -2.66667 m 2.66667,-10.6666676 h -2.66667"
      stroke="currentColor"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
