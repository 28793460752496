<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 20V4m0 0-6 6m6-6 6 6"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
