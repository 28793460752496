<script setup lang="ts">
import { TransitionRoot } from "@headlessui/vue";
import { computed, watch } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import { backend } from "~/api";
import Loading from "~/components/dumb/Loading.vue";
import TaskListAndDetail from "~/components/dumb/TaskListAndDetail.vue";
import { notify } from "~/components/notifications";
import TaskList from "~/components/visualization/TaskList.vue";
import { getQueryParam, goHomeNextTick } from "~/router/common";
import { EventKind, NotificationType, ViewKind } from "~/shared/enums";
import { useAppStore, useDataStore, usePageStore, useUserStore } from "~/stores";
import { getDartboardLink, getViewLink } from "~/utils/common";

const props = defineProps<{
  dartboardDuid?: string;
  viewDuid?: string;
  viewKind?: ViewKind.SEARCH | ViewKind.MY_TASKS | ViewKind.TRASH;
  taskDuidAndSlug?: string;
  commentDuid?: string;
  slug?: string;
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const isLoaded = computed(() => pageStore.isDartboardLoaded(props.dartboardDuid));
const transparentBg = pageStore.isDesktopApp && pageStore.isMac && pageStore.isVersionGreaterOrEqual("1.0.2");

const setSelectedPage = () => {
  if (props.dartboardDuid) {
    const dartboard = dataStore.getDartboardByDuid(props.dartboardDuid);
    if (!dartboard) {
      goHomeNextTick();
      return;
    }
    appStore.setCurrentPage(dartboard);

    if (!router.currentRoute.value.params.taskDuidAndSlug) {
      setTimeout(() => {
        router.replace({
          params: { slugSep: "-", slug: getDartboardLink(dartboard, dataStore.getSpaceByDuid).params.slug },
          query: { ...router.currentRoute.value.query },
        });
      });
    }
    return;
  }

  if (props.viewDuid) {
    const view = dataStore.getViewByDuid(props.viewDuid);
    if (!view) {
      goHomeNextTick();
      return;
    }
    appStore.setCurrentPage(view);

    if (!router.currentRoute.value.params.taskDuidAndSlug) {
      setTimeout(() => {
        router.replace({
          params: { slugSep: "-", slug: getViewLink(view).params?.slug },
          query: { ...router.currentRoute.value.query },
        });
      });
    }
    return;
  }

  if (props.viewKind) {
    const view = dataStore.getViewByKind(props.viewKind);
    appStore.setCurrentPage(view);
    if (props.viewKind === ViewKind.SEARCH) {
      backend.event.create(EventKind.USAGE_OPEN_SEARCH);
    }
    return;
  }

  throw new Error(`Invalid props for TaskListRootView: ${props}`);
};

const setDetailsOnFirstLoad = async () => {
  const requestedTask = props.taskDuidAndSlug
    ? (dataStore.getTaskByDuid(props.taskDuidAndSlug.substring(0, 12)) ?? null)
    : null;

  if (!requestedTask) {
    if (props.taskDuidAndSlug) {
      const params = { ...router.currentRoute.value.params };
      delete params.taskDuidAndSlug;
      const newRoute = { ...router.currentRoute.value, params };
      await router.push(newRoute);
    }
    return;
  }

  await actions.visualization.navigateToTask(requestedTask.duid, true);

  if (getQueryParam("unsubscribe") === "1") {
    const userDuid = userStore.duid;
    if (requestedTask.subscriberDuids.includes(userDuid)) {
      dataStore.removeSubscribers([requestedTask.duid], [userDuid], { noUndo: true });
      notify({
        message: `Unsubscribed from ${requestedTask.title}`,
        type: NotificationType.SUCCESS,
      });
    }
    const query = { ...router.currentRoute.value.query };
    delete query.unsubscribe;
    await router.replace({ query });
  }
};

setSelectedPage();

// TODO do this better, without setTimeout
setTimeout(() => {
  setDetailsOnFirstLoad();
}, 100);

watch([() => props.dartboardDuid, () => props.viewDuid, () => props.viewKind], setSelectedPage);

const onAfterPaneResize = () => {
  const listApi = appStore.list?.api;
  if (listApi && !listApi.isDestroyed()) {
    listApi.sizeColumnsToFit();
  }
  const roadmapApi = appStore.roadmap?.api;
  if (roadmapApi && !roadmapApi.isDestroyed()) {
    roadmapApi.sizeColumnsToFit();
  }
};
</script>

<template>
  <TaskListAndDetail @after-resize="onAfterPaneResize">
    <TaskList v-if="isLoaded" id="dartboard" />
    <TransitionRoot
      :show="!isLoaded"
      class="absolute inset-0"
      leave="ease-in duration-300"
      leave-from="opacity-100"
      leave-to="opacity-0">
      <Loading :show-timing="pageStore.showDebugInfo" :transparent-bg="transparentBg" />
    </TransitionRoot>
  </TaskListAndDetail>
</template>
