<script setup lang="ts">
import moment from "moment";
import { computed, ref, watch } from "vue";

import { backendOld } from "~/api";
import AuthToken from "~/components/AuthToken.vue";
import AvatarUpload from "~/components/AvatarUpload.vue";
import ChangePasswordModal from "~/components/ChangePasswordModal.vue";
import ColorPickerDropdown from "~/components/dumb/ColorPickerDropdown.vue";
import DropdownMenuWithSelection from "~/components/dumb/DropdownMenuWithSelection.vue";
import EditText from "~/components/dumb/EditText.vue";
import SettingSection from "~/components/dumb/SettingSection.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import { Theme } from "~/shared/enums";
import { useUserStore } from "~/stores";
import { validateIsNotEmpty } from "~/utils/common";

const userStore = useUserStore();

const selectedTheme = ref(userStore.theme);
const themeOptions = computed(() =>
  Object.values(Theme).map((title) => ({
    title,
    selected: title === selectedTheme.value,
    onClick: () => {
      selectedTheme.value = title;
    },
  }))
);

watch(
  () => userStore.theme,
  (newTheme) => {
    selectedTheme.value = newTheme;
  }
);
watch(
  () => selectedTheme.value,
  (newTheme) => {
    if (!newTheme) {
      return;
    }
    userStore.setTheme(newTheme);
  }
);

const firstDayOfWeekOptions = computed(() =>
  [1, 6, 0].map((day) => ({
    title: moment().weekday(day).format("dddd"),
    selected: userStore.firstDayOfWeek === day,
    onClick: () => {
      userStore.firstDayOfWeek = day;
      backendOld.profile.edit("firstDayOfWeek", day);
    },
  }))
);
const selectedFirstDayOfWeek = computed(() => firstDayOfWeekOptions.value.find((e) => e.selected)?.title);

const onNameChange = (newName: string) => {
  userStore.name = newName;
  backendOld.profile.edit("name", newName);
};

const onColorChange = (newColor: string) => {
  userStore.colorHex = newColor;
  backendOld.profile.edit("colorHex", newColor);
};

const onOpenInNativeAppChange = (newOpenInNativeApp: boolean) => {
  userStore.openInNativeApp = newOpenInNativeApp;
  backendOld.profile.edit("openInNativeApp", newOpenInNativeApp);
};
</script>

<template>
  <div class="h-full overflow-y-auto">
    <div class="mx-auto w-full">
      <SettingSection title="Name" subtitle="Your full name">
        <EditText :value="userStore.name" label="Your full name" :validate="validateIsNotEmpty" @save="onNameChange">
          <div
            :title="userStore.name"
            class="h-full w-48 select-none truncate rounded border px-3 py-2 text-left text-base shadow-sm text-md border-md hover:bg-lt">
            {{ userStore.name }}
          </div>
        </EditText>
      </SettingSection>
      <SettingSection title="Email" subtitle="Your email address">
        <span :title="userStore.email" class="select-none truncate text-lg font-normal text-md">
          {{ userStore.email }}
        </span>
      </SettingSection>
      <SettingSection
        title="Image"
        subtitle="A square photo for your profile"
        :styles="{
          alignItems: 'items-start',
        }">
        <AvatarUpload kind="profile" />
      </SettingSection>
      <SettingSection
        title="Color"
        subtitle="The color used to represent you"
        :styles="{
          alignItems: 'items-start',
        }">
        <ColorPickerDropdown :value="userStore.colorHex" @select="onColorChange" />
      </SettingSection>
      <SettingSection title="Theme" subtitle="Light mode or dark mode, your choice!">
        <DropdownMenuWithSelection :options="themeOptions" border>
          <div class="w-[166px] select-none rounded px-3 py-2 text-left shadow-sm focus-ring-std hover:bg-lt">
            {{ selectedTheme }}
          </div>
        </DropdownMenuWithSelection>
      </SettingSection>
      <div class="mx-10 my-24">
        <Toggle
          :value="userStore.openInNativeApp"
          label="Open links in app"
          description="When you click a link to Dart, open it in the app if possible"
          @update="onOpenInNativeAppChange" />
      </div>
      <SettingSection title="Start weeks on" subtitle="The first day of the week in your calendars">
        <DropdownMenuWithSelection :options="firstDayOfWeekOptions" border>
          <div class="w-[166px] select-none rounded px-3 py-2 text-left shadow-sm focus-ring-std hover:bg-lt">
            {{ selectedFirstDayOfWeek }}
          </div>
        </DropdownMenuWithSelection>
      </SettingSection>
      <SettingSection title="Password" subtitle="Change the password for your profile">
        <ChangePasswordModal />
      </SettingSection>
      <SettingSection title="Authentication token" subtitle="Manage your API key, a bearer authentication token">
        <AuthToken />
      </SettingSection>
    </div>
  </div>
</template>
