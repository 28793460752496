import type { App } from "vue";

import actions from "~/actions";
import * as properties from "~/common/properties";
import DocPreview from "~/components/docs/DocPreview.vue";
import Avatar from "~/components/dumb/Avatar.vue";
import CheckboxChip from "~/components/dumb/CheckboxChip.vue";
import CheckboxEditor from "~/components/dumb/CheckboxEditor.vue";
import DartboardEditor from "~/components/dumb/DartboardEditor.vue";
import DatesChip from "~/components/dumb/DatesChip.vue";
import DatesEditor from "~/components/dumb/DatesEditor.vue";
import FormAttachmentEditor from "~/components/dumb/FormAttachmentEditor.vue";
import LinkToRelationshipsDropdown from "~/components/dumb/LinkToRelationshipsDropdown.vue";
import MultiselectChip from "~/components/dumb/MultiselectChip.vue";
import NumberChip from "~/components/dumb/NumberChip.vue";
import NumberEditor from "~/components/dumb/NumberEditor.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import PriorityChip from "~/components/dumb/PriorityChip.vue";
import PriorityEditor from "~/components/dumb/PriorityEditor.vue";
import SelectEditor from "~/components/dumb/SelectEditor.vue";
import SizeEditor from "~/components/dumb/SizeEditor.vue";
import StatusChip from "~/components/dumb/StatusChip.vue";
import StatusEditor from "~/components/dumb/StatusEditor.vue";
import StatusIcon from "~/components/dumb/StatusIcon.vue";
import TaskKindEditor from "~/components/dumb/TaskKindEditor.vue";
import TextChip from "~/components/dumb/TextChip.vue";
import TextEditor from "~/components/dumb/TextEditor.vue";
import UserChip from "~/components/dumb/UserChip.vue";
import UserEditor from "~/components/dumb/UserEditor.vue";
import OptionEditDropdown from "~/components/options/OptionEditDropdown.vue";
import OptionsEditor from "~/components/options/OptionsEditor.vue";
import FormEditor from "~/components/text/FormEditor.vue";
import * as transformers from "~/components/text/transformers";
import { FULL_DART_EDITOR_NODES } from "~/components/text/utils";
import TimeTrackingEditor from "~/components/timeTracking/TimeTrackingEditor.vue";
import CheckboxCellEditor from "~/components/visualization/list/cellEditors/CheckboxCellEditor.vue";
import DartboardCellEditor from "~/components/visualization/list/cellEditors/DartboardCellEditor.vue";
import DatesCellEditor from "~/components/visualization/list/cellEditors/DatesCellEditor.vue";
import DuidCellEditor from "~/components/visualization/list/cellEditors/DuidCellEditor.vue";
import NumberCellEditor from "~/components/visualization/list/cellEditors/NumberCellEditor.vue";
import OptionCellEditor from "~/components/visualization/list/cellEditors/OptionCellEditor.vue";
import OrderCellEditor from "~/components/visualization/list/cellEditors/OrderCellEditor.vue";
import PriorityCellEditor from "~/components/visualization/list/cellEditors/PriorityCellEditor.vue";
import SelectCellEditor from "~/components/visualization/list/cellEditors/SelectCellEditor.vue";
import SizeCellEditor from "~/components/visualization/list/cellEditors/SizeCellEditor.vue";
import StatusCellEditor from "~/components/visualization/list/cellEditors/StatusCellEditor.vue";
import TaskKindCellEditor from "~/components/visualization/list/cellEditors/TaskKindCellEditor.vue";
import TextCellEditor from "~/components/visualization/list/cellEditors/TextCellEditor.vue";
import TimeTrackingCellEditor from "~/components/visualization/list/cellEditors/TimeTrackingCellEditor.vue";
import TitleCellEditor from "~/components/visualization/list/cellEditors/TitleCellEditor.vue";
import UserCellEditor from "~/components/visualization/list/cellEditors/UserCellEditor.vue";
import NewTaskHeaderRenderer from "~/components/visualization/list/headerRenderers/NewTaskHeaderRenderer.vue";
import StandardHeaderRenderer from "~/components/visualization/list/headerRenderers/StandardHeaderRenderer.vue";
import {
  DARTBOARD_CELL_EDITOR,
  DOC_PREVIEW,
  DUID_CELL_EDITOR,
  NEW_TASK_HEADER_RENDERER,
  ORDER_CELL_EDITOR,
  PRIORITY_CELL_EDITOR,
  SELECT_CELL_EDITOR,
  SIZE_CELL_EDITOR,
  STANDARD_HEADER_RENDERER,
  TASK_KIND_CELL_EDITOR,
  TIME_TRACKING_CELL_EDITOR,
  TITLE_CELL_EDITOR,
} from "~/constants/injection";
import { useDataStore } from "~/stores";
import AdtlDates from "~/views/settings/PropertiesView/AdtlDates.vue";
import AdtlNumber from "~/views/settings/PropertiesView/AdtlNumber.vue";
import AdtlSelect from "~/views/settings/PropertiesView/AdtlSelect.vue";
import AdtlStatus from "~/views/settings/PropertiesView/AdtlStatus.vue";
import AdtlUser from "~/views/settings/PropertiesView/AdtlUser.vue";

// eslint-disable-next-line import/prefer-default-export
export const provideComponents = (app: App) => {
  app.provide(DARTBOARD_CELL_EDITOR, DartboardCellEditor);
  app.provide(DUID_CELL_EDITOR, DuidCellEditor);
  app.provide(NEW_TASK_HEADER_RENDERER, NewTaskHeaderRenderer);
  app.provide(ORDER_CELL_EDITOR, OrderCellEditor);
  app.provide(PRIORITY_CELL_EDITOR, PriorityCellEditor);
  app.provide(SELECT_CELL_EDITOR, SelectCellEditor);
  app.provide(SIZE_CELL_EDITOR, SizeCellEditor);
  app.provide(STANDARD_HEADER_RENDERER, StandardHeaderRenderer);
  app.provide(TASK_KIND_CELL_EDITOR, TaskKindCellEditor);
  app.provide(TITLE_CELL_EDITOR, TitleCellEditor);
  app.provide(TIME_TRACKING_CELL_EDITOR, TimeTrackingCellEditor);

  app.provide(DOC_PREVIEW, DocPreview);

  actions.context.init(LinkToRelationshipsDropdown, OptionEditDropdown);

  transformers.init(FULL_DART_EDITOR_NODES);

  properties.init(
    useDataStore(),
    AdtlDates,
    AdtlNumber,
    AdtlSelect,
    AdtlStatus,
    AdtlUser,
    Avatar,
    CheckboxCellEditor,
    CheckboxChip,
    CheckboxEditor,
    DartboardEditor,
    DatesCellEditor,
    DatesChip,
    DatesEditor,
    FormAttachmentEditor,
    FormEditor,
    MultiselectChip,
    NumberCellEditor,
    NumberChip,
    NumberEditor,
    OptionCellEditor,
    OptionsEditor,
    PageIcon,
    PriorityCellEditor,
    PriorityChip,
    PriorityEditor,
    SelectCellEditor,
    SelectEditor,
    SizeCellEditor,
    SizeEditor,
    StatusCellEditor,
    StatusChip,
    StatusEditor,
    StatusIcon,
    TaskKindEditor,
    TaskKindCellEditor,
    TextCellEditor,
    TextChip,
    TextEditor,
    TimeTrackingEditor,
    TimeTrackingCellEditor,
    UserCellEditor,
    UserChip,
    UserEditor
  );
};
