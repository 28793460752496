<script setup lang="ts">
import { computed, ref, watch } from "vue";

import TaskAiDropdown from "~/components/dumb/TaskAiDropdown.vue";
import TutorialMessage from "~/components/dumb/TutorialMessage.vue";
import DuplicateDetectionHub from "~/components/task/tcm/DuplicateDetectionHub.vue";
import { EditorMode, Placement, TutorialName } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";

const props = defineProps<{
  task: Task | undefined;
  titleEmpty: boolean;
  generatingRecommendations: boolean;
}>();

const emit = defineEmits<{
  generatePropertyRecommendations: [];
  generateSubtaskRecommendations: [];
  improveDescription: [];
  reset: [];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const taskAiDropdown = ref<InstanceType<typeof TaskAiDropdown> | null>(null);
const recommendationButton = computed(() => taskAiDropdown.value?.button);

const assignToAi = () => {
  if (!props.task) {
    return;
  }

  dataStore.updateTasks([{ duid: props.task.duid, assignedToAi: true }]);
};

const generateSubtaskRecommendations = () => {
  const draft = dataStore.taskDraft;
  if (!draft || props.titleEmpty) {
    return;
  }

  emit("generateSubtaskRecommendations");
};

const createMoreEnabled = ref(appStore.createTaskCreateMore);
watch(
  () => createMoreEnabled.value,
  (newValue) => {
    appStore.setCreateTaskCreateMore(newValue);
  }
);

const duplicateDetectionHub = ref<InstanceType<typeof DuplicateDetectionHub> | null>(null);

const updateDuplicateDetectionHub = () => {
  duplicateDetectionHub.value?.update();
};

defineExpose({
  recommendationButton,
  updateDuplicateDetectionHub,
});
</script>

<template>
  <div v-if="pageStore.isOnline" class="flex items-center gap-2 px-4 pb-5 sm:pb-4" @click.stop @keydown.enter.stop>
    <TutorialMessage
      :name="TutorialName.CREATE_TASK_WITH_SUBTASK_RECS"
      :step="3"
      :disabled="generatingRecommendations"
      :placement="Placement.LEFT_TOP">
      <TaskAiDropdown
        ref="taskAiDropdown"
        :title-empty="titleEmpty"
        :generating-recommendations="generatingRecommendations"
        :editor-mode="EditorMode.TCM"
        @recommend-properties="emit('generatePropertyRecommendations')"
        @recommend-subtasks="generateSubtaskRecommendations"
        @improve-description="emit('improveDescription')"
        @do-task="assignToAi" />
    </TutorialMessage>

    <DuplicateDetectionHub ref="duplicateDetectionHub" :task="task ?? null" />

    <div class="flex-1" />
  </div>
  <div v-else class="flex-1" />
</template>
