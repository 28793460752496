<script setup lang="ts">
import { TransitionRoot } from "@headlessui/vue";
import { computed, watchEffect } from "vue";
import { useRouter } from "vue-router";

import Button from "~/components/dumb/Button.vue";
import EmptyState from "~/components/dumb/EmptyState.vue";
import Loading from "~/components/dumb/Loading.vue";
import FilterArea from "~/components/filters/FilterArea.vue";
import { doesTaskPass } from "~/components/filters/utils";
import TaskStatistics from "~/components/statistics/TaskStatistics.vue";
import { PremiumFeatureIcon } from "~/icons";
import { goHomeNextTick, makeLinkToSettingsRef } from "~/router/common";
import { ButtonSize, ButtonStyle } from "~/shared/enums";
import type { StatisticConfig } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";
import { checkout } from "~/utils/billing";

const props = defineProps<{
  dashboardDuid: string;
  slugSep?: string;
  slug?: string;
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const isLoaded = computed(() => pageStore.isEverythingLoaded());
const transparentBg = pageStore.isDesktopApp && pageStore.isMac && pageStore.isVersionGreaterOrEqual("1.0.2");
const dashboard = computed(() => dataStore.getDashboardByDuid(props.dashboardDuid));
watchEffect(() => {
  if (!dashboard.value) {
    goHomeNextTick();
    return;
  }

  appStore.setCurrentPage(dashboard.value);
});

const redirectToBilling = () => router.push(makeLinkToSettingsRef("billing").value);
const upgradePlan = () => {
  checkout(router.currentRoute.value.fullPath);
};

/* Task statistics */
const statisticTasks = computed(() =>
  dataStore.getTaskList().filter((task) => doesTaskPass(dataStore, task, appStore.filters))
);

const onUpdateConfigs = (configs: StatisticConfig[]) => {
  if (!dashboard.value) {
    return;
  }

  dataStore.updateDashboard({
    duid: dashboard.value.duid,
    charts: configs,
  });
};
</script>

<template>
  <div class="relative flex size-full flex-col items-center gap-3 pt-6">
    <FilterArea
      class="w-full max-w-[1048px] !border-0 px-[11px] bg-std"
      :class="{ 'pointer-events-none opacity-30 blur-sm': !tenantStore.isPremium }"
      is-search-mode
      :filters="appStore.filters"
      @set-filter="appStore.setFilter"
      @remove-filter="appStore.removeFilter" />
    <div class="flex size-full justify-center" :class="tenantStore.isPremium && 'overflow-y-scroll'">
      <div class="ml-[16px] size-full max-w-[1058px] extra-overscroll">
        <TaskStatistics
          :class="{ 'pointer-events-none opacity-30 blur-sm': !tenantStore.isPremium }"
          :tasks="statisticTasks"
          :statistics="dashboard?.charts ?? []"
          @update="onUpdateConfigs" />

        <EmptyState
          v-if="!tenantStore.isPremium"
          title="Upgrade to premium"
          description="Your workspace is on the free plan. To use dashboards and enjoy other benefits, upgrade to premium."
          :main-icon="PremiumFeatureIcon"
          :button-icon="PremiumFeatureIcon"
          button-text="Upgrade!"
          @click="upgradePlan">
          <template #extraButton>
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              text="See plans"
              :size="ButtonSize.SMALL"
              class="pointer-events-auto"
              @click="redirectToBilling" />
          </template>
        </EmptyState>
      </div>
    </div>
    <TransitionRoot
      :show="!isLoaded"
      class="absolute inset-0"
      leave="ease-in duration-300"
      leave-from="opacity-100"
      leave-to="opacity-0">
      <Loading :show-timing="pageStore.showDebugInfo" :transparent-bg="transparentBg" />
    </TransitionRoot>
  </div>
</template>
