<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M 21,12 H 9 M 21,6 H 9 M 21,18 H 9 M 5,12 c 0,1.333333 -2,1.333333 -2,0 0,-1.333333 2,-1.333333 2,0 z m 0,6 c 0,1.333333 -2,1.333333 -2,0 0,-1.333333 2,-1.333333 2,0 z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="m 2.9571164,3.6126264 2.5396799,1.6190514 c 0.2757143,0.2067771 0.4135657,0.3101714 0.4628571,0.4369143 0.043177,0.1110285 0.043177,0.2341942 0,0.3452114 C 5.910362,6.1405578 5.7725106,6.2439406 5.4967963,6.4507292 L 2.9571164,8.1840548"
      stroke="currentColor"
      stroke-width="1.71429"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
