<script setup lang="ts">
import { useElementSize, useScroll } from "@vueuse/core";
import { ref } from "vue";

import { isTargetEditable } from "~/utils/common";

const spaceDown = ref(false);
const panning = ref(false);
const wrapper = ref<HTMLDivElement | null>(null);
const { x: left, y: top } = useScroll(wrapper);
const { width, height } = useElementSize(wrapper);

let pos: { startX: number; startY: number } | undefined;

const onSpaceDown = (event: KeyboardEvent) => {
  if (event.target && isTargetEditable(event.target)) {
    return;
  }
  event.preventDefault();
  spaceDown.value = true;
};

const onMouseMove = (event: MouseEvent) => {
  if (!panning.value || !wrapper.value || !pos) {
    return;
  }
  wrapper.value.scrollLeft = pos.startX - event.clientX;
  wrapper.value.scrollTop = pos.startY - event.clientY;
};

const onMouseUp = () => {
  if (!panning.value) {
    return;
  }
  panning.value = false;
  pos = undefined;

  document.removeEventListener("mousemove", onMouseMove);
  document.removeEventListener("mouseup", onMouseUp);
};

const onMouseDown = (event: MouseEvent) => {
  if (!spaceDown.value || !wrapper.value) {
    return;
  }
  panning.value = true;
  pos = {
    startX: wrapper.value.scrollLeft + event.clientX,
    startY: wrapper.value.scrollTop + event.clientY,
  };

  document.addEventListener("mousemove", onMouseMove);
  document.addEventListener("mouseup", onMouseUp);
};

defineExpose({
  wrapper,
});
</script>

<template>
  <div
    ref="wrapper"
    :tabindex="-1"
    :class="{
      'cursor-default': !spaceDown && !panning,
      'cursor-grab': spaceDown && !panning,
      'cursor-grabbing select-none': panning,
    }"
    class="relative size-full overflow-auto outline-none"
    @keydown.space.stop="onSpaceDown"
    @keyup.space.stop="spaceDown = false"
    @mousedown="onMouseDown">
    <slot />
    <div
      v-if="spaceDown"
      class="absolute"
      :style="{
        left: `${left}px`,
        top: `${top}px`,
        minHeight: `${height}px`,
        maxHeight: `${height}px`,
        minWidth: `${width}px`,
        maxWidth: `${width}px`,
      }" />
  </div>
</template>
