<script setup lang="ts">
import { computed, ref } from "vue";

import actions from "~/actions";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { ChildRelationshipIcon } from "~/icons";
import type { Option } from "~/shared/types";
import { useDataStore } from "~/stores";
import { makeRandomColorHex } from "~/utils/color";
import { makeDuid } from "~/utils/common";

const props = defineProps<{
  option: Option;
  isSubitemDropdownMenu?: boolean;
}>();

const dataStore = useDataStore();

const property = computed(() => dataStore.getPropertyByDuid(props.option.propertyDuid));
const created = ref(false);
const workingSubitem = ref({
  duid: makeDuid(),
  title: "",
  propertyDuid: props.option.propertyDuid,
  parentDuid: props.option.duid,
  colorHex: makeRandomColorHex(),
});

const dropdownSections = computed(() => {
  if (!props.isSubitemDropdownMenu) {
    return actions.context.option(props.option);
  }
  return actions.context.option(
    workingSubitem.value,
    created.value
      ? undefined
      : {
          property: property.value,
          onRename: (newTitle) => {
            if (!workingSubitem.value || !property.value) {
              return;
            }
            workingSubitem.value.title = newTitle;
            dataStore.createOption(newTitle, property.value, workingSubitem.value);
            created.value = true;
          },
          onChangeColor: (newColorHex) => {
            if (!workingSubitem.value) {
              return;
            }
            workingSubitem.value.colorHex = newColorHex;
          },
        }
  );
});
</script>

<template>
  <DropdownMenu :sections="dropdownSections" :distance="0" :max-height-pixels="566" @click.stop @keydown.enter.stop>
    <DropdownMenuItemContent v-if="isSubitemDropdownMenu" :icon="ChildRelationshipIcon" title="Add sub-item" />
    <Tooltip v-else text="Modify option">
      <slot />
    </Tooltip>
  </DropdownMenu>
</template>
