<script setup lang="ts">
import { useMediaQuery, useWindowSize } from "@vueuse/core";
import { computed, nextTick, watch, watchEffect } from "vue";
import { RouterView, useRouter } from "vue-router";

import AgentDemoModal from "~/components/AgentDemoModal.vue";
import AppDownloadModal from "~/components/AppDownloadModal.vue";
import BrainstormModal from "~/components/BrainstormModal.vue";
import BroadcastBanner from "~/components/dumb/BroadcastBanner.vue";
import DeleteConfirmationDialog from "~/components/dumb/DeleteConfirmationDialog.vue";
import DraggableDivider from "~/components/dumb/DraggableDivider.vue";
import Modal from "~/components/dumb/Modal.vue";
import UpgradeConfirmationDialog from "~/components/dumb/UpgradeConfirmationDialog.vue";
import FeedbackModal from "~/components/FeedbackModal.vue";
import SearchModal from "~/components/filters/SearchModal/SearchModal.vue";
import InternalFormModal from "~/components/forms/InternalFormModal.vue";
import FullscreenMediaModal from "~/components/FullscreenMediaModal.vue";
import ImportTemplateModal from "~/components/ImportTemplateModal.vue";
import Leftbar from "~/components/leftbar/Leftbar.vue";
import LinkModal from "~/components/LinkModal.vue";
import MobileBottombar from "~/components/MobileBottombar.vue";
import { notify } from "~/components/notifications";
import NotionLinkModal from "~/components/NotionLinkModal.vue";
import OnboardingModal from "~/components/OnboardingModal.vue";
import PagePermissionsModal from "~/components/PagePermissionsModal.vue";
import PlanProjectModal from "~/components/PlanProjectModal.vue";
import RemapModal from "~/components/RemapModal.vue";
import ReminderModal from "~/components/ReminderModal.vue";
import ReportCreationModal from "~/components/ReportCreationModal.vue";
import ShortcutsModal from "~/components/ShortcutsModal.vue";
import SpaceEditModal from "~/components/spaceEditModal/SpaceEditModal.vue";
import TaskEditModal from "~/components/task/TaskEditModal.vue";
import Tcm from "~/components/task/Tcm.vue";
import Topbar from "~/components/topbar/Topbar.vue";
import TrialEndedModal from "~/components/TrialEndedModal.vue";
import TrialStartedModal from "~/components/TrialStartedModal.vue";
import { getQueryParam } from "~/router/common";
import { ModalPosition, ModalWidth } from "~/shared/enums";
import { useAppStore, useDataStore, usePageStore, useUserStore } from "~/stores";
import { callDesktop } from "~/utils/desktop";
import SettingsView from "~/views/SettingsView.vue";

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const transparentBg = pageStore.isDesktopApp && pageStore.isMac && pageStore.isVersionGreaterOrEqual("1.0.2");
const isPrinting = useMediaQuery("print");

const showMobileBottombar = computed(
  () => pageStore.isMobile && !appStore.taskOpenInDetail && !appStore.docOpenInFullscreen
);

const redirected = !!getQueryParam("redirected");
const query = { ...router.currentRoute.value.query };
delete query.redirected;
nextTick(() => router.replace({ query }));

if (redirected) {
  notify({
    message: "You were brought to the app from the website so you could get right to work",
    actions: [
      {
        label: "Go back",
        onClick: () => {},
        component: "a",
        componentArgs: {
          href: "https://www.itsdart.com/?nr=1",
        },
      },
    ],
  });
}

const onSavePaneSize = (width: number) => {
  userStore.setLeftbarWidthPx(width);
};

const onAfterPaneResize = () => {
  const api = appStore.list?.api;
  if (!api || api.isDestroyed()) {
    return;
  }
  api.sizeColumnsToFit();
};

watchEffect(() => {
  appStore.leftbarInCollapsibleMode = useWindowSize().width.value < 1024;
});

/* Send notification count to desktop app */
watch(
  () => dataStore.unreadNotificationCount,
  (count) => {
    callDesktop(() => DESKTOP.setNotificationCount(count));
  },
  { immediate: true }
);
</script>

<template>
  <div v-if="pageStore.isLoggedIn" class="relative flex size-full">
    <div class="flex size-full flex-col">
      <BroadcastBanner class="print:hidden" />
      <div class="flex size-full">
        <!-- Mobile sidebar -->
        <Modal
          :entity="appStore.mobileLeftbarOpen"
          title="Leftbar"
          hide-title
          :width="ModalWidth.XS"
          :position="ModalPosition.LEFT"
          custom-styles="overflow-hidden h-full !px-1 !pt-1 !pb-0 print:hidden"
          close-icon-styles="p-1"
          overflow-clip
         
          @close="appStore.setMobileLeftbarOpen(false)">
          <template #default>
            <Leftbar is-mobile-leftbar />
          </template>
        </Modal>
        <DraggableDivider
          :pane-width-px="userStore.leftbarWidthPx"
          :pane-min-px="200"
          :pane-max-px="400"
          :content-min-px="400"
          left
          :hide-pane="isPrinting || appStore.mobileLeftbarOpen || appStore.leftbarInCollapsibleMode"
          styles="flex h-full grow flex-col"
          @after-resize="onAfterPaneResize"
          @save="onSavePaneSize">
          <template #pane>
            <!-- Desktop sidebar -->
            <div class="flex size-full flex-col border-r border-md">
              <div
               
                class="group/leftbar flex grow flex-col overflow-y-auto"
                :class="transparentBg ? 'bg-gray-100 dark:bg-zinc-800/80' : 'bg-lt'">
                <Leftbar />
              </div>
            </div>
          </template>

          <template #default>
            <!-- Topbar and main -->
            <Topbar />
            <RouterView />
            <MobileBottombar v-if="showMobileBottombar" />
          </template>
        </DraggableDivider>

        <AgentDemoModal />
        <AppDownloadModal />
        <BrainstormModal />
        <DeleteConfirmationDialog />
        <FeedbackModal />
        <FullscreenMediaModal />
        <ImportTemplateModal />
        <InternalFormModal />
        <LinkModal />
        <NotionLinkModal />
        <PagePermissionsModal />
        <PlanProjectModal />
        <RemapModal />
        <ReminderModal />
        <ReportCreationModal />
        <SearchModal />
        <SettingsView />
        <ShortcutsModal />
        <SpaceEditModal />
        <TaskEditModal />
        <Tcm />
        <TrialEndedModal />
        <TrialStartedModal />
        <UpgradeConfirmationDialog is-global />

        <!-- should be on top -->
        <OnboardingModal />
      </div>
    </div>
  </div>
</template>
