<script setup lang="ts">
import type { LexicalNode, RangeSelection } from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { computed, onMounted } from "vue";

import { getShownPropertyWithConfigList } from "~/common/properties";
import {
  EVENT_CREATE_DOC_DOC_RELATIONSHIP,
  EVENT_CREATE_TASK_DOC_RELATIONSHIP,
  EVENT_CREATE_TASK_TASK_RELATES_TO_RELATIONSHIP,
  EVENT_SUBSCRIBE_USER,
} from "~/components/text/const";
import { $createDartLinkNode } from "~/components/text/nodes/DartLinkNode";
import { $createEntityNode, EntityNode } from "~/components/text/nodes/EntityNode";
import { UNASSIGNED_PSEUDO_ASSIGNEE_KEY } from "~/components/visualization/constants";
import { DART_AI_PSEUDO_USER_KEY } from "~/constants/user";
import { getDocUrl, getTaskUrl } from "~/router/common";
import { EntityName, TypeaheadSpecialEntity } from "~/shared/enums";
import type { Property, PropertyConfig, TypeaheadOption } from "~/shared/types";
import { useDataStore } from "~/stores";
import { makeStringComparator } from "~/utils/comparator";

import { removeConflictingEntitiesAndSmartMatches } from "./common";
import { getDocOptions, getTaskOptions } from "./links";
import TypeaheadMenuPlugin from "./TypeaheadMenuPlugin.vue";

const props = defineProps<{
  mentionsOnly?: boolean;
  includeSpecialMentions?: boolean;
  excludeDartLinks?: boolean;
  subscribeMentionsSeparately?: boolean;
}>();

const dataStore = useDataStore();
const editor = useLexicalComposer();

const isDoc = editor._config.namespace.startsWith("doc");

const insertNode = (node: LexicalNode, selection: RangeSelection) => {
  selection?.insertNodes([node]);
  selection?.insertRawText(" ");
};

const getTypeaheads = (properties: [Property, PropertyConfig][]) =>
  properties
    .filter(([, config]) => config.typeahead !== null)
    .map(([property, config]) => config.typeahead!(property))
    .flat();

const typeaheads = computed(() => {
  const properties = getShownPropertyWithConfigList();
  const assigneeProperty = properties.filter(([property]) => property.duid === dataStore.defaultAssigneesProperty.duid);
  const otherProperties = properties.filter(([property]) => property.duid !== dataStore.defaultAssigneesProperty.duid);
  const atTypeahead = getTypeaheads(assigneeProperty).map((e) => ({
    ...e,
    trigger: "@",
    getOptions: (query: string | boolean | number) => {
      const atOptions = e
        .getOptions(query)
        .filter(
          (v) =>
            props.includeSpecialMentions ||
            ![DART_AI_PSEUDO_USER_KEY, UNASSIGNED_PSEUDO_ASSIGNEE_KEY].includes(v.value.toString())
        );

      if (props.excludeDartLinks) {
        return atOptions;
      }

      atOptions.push(...getTaskOptions());
      atOptions.push(...getDocOptions());
      return atOptions;
    },
  }));

  if (props.mentionsOnly) {
    return atTypeahead;
  }

  const allTypeaheads = getTypeaheads(otherProperties).concat(atTypeahead);
  // Can't directly add to res because it infinite loops; this way the original array is separate and this returns a clone
  return allTypeaheads.concat([
    {
      trigger: "/",
      isMultiple: true,
      getOptions: (query: string | boolean | number) => {
        const options = allTypeaheads.map((e) => e.getOptions(query));
        return [
          ...options.map((e) => e.slice(0, 1)).flat(),
          ...options
            .map((e) => e.slice(1))
            .flat()
            .sort(makeStringComparator((e) => e.label)),
        ];
      },
    },
  ]);
});

const onSelect = (selection: RangeSelection, option: TypeaheadOption) => {
  // Handle links
  if (props.mentionsOnly && !props.excludeDartLinks && typeof option.value === "string") {
    if (option.specialEntity === TypeaheadSpecialEntity.TASK) {
      const task = dataStore.getTaskByDuid(option.value);
      if (task) {
        if (!isDoc) {
          editor.dispatchCommand(EVENT_CREATE_TASK_TASK_RELATES_TO_RELATIONSHIP, task.duid);
        } else {
          editor.dispatchCommand(EVENT_CREATE_TASK_DOC_RELATIONSHIP, {
            entityDuid: task.duid,
            specialEntity: TypeaheadSpecialEntity.TASK,
          });
        }

        insertNode($createDartLinkNode(getTaskUrl(task), EntityName.TASK, task.duid), selection);
        return;
      }
    }

    if (option.specialEntity === TypeaheadSpecialEntity.DOC) {
      const doc = dataStore.getDocByDuid(option.value);
      if (doc) {
        if (!isDoc) {
          editor.dispatchCommand(EVENT_CREATE_TASK_DOC_RELATIONSHIP, {
            entityDuid: doc.duid,
            specialEntity: TypeaheadSpecialEntity.DOC,
          });
        } else {
          editor.dispatchCommand(EVENT_CREATE_DOC_DOC_RELATIONSHIP, doc.duid);
        }

        insertNode($createDartLinkNode(getDocUrl(doc), EntityName.DOC, doc.duid), selection);
        return;
      }
    }
  }

  if (
    props.mentionsOnly &&
    typeof option.value === "string" &&
    option.value !== DART_AI_PSEUDO_USER_KEY &&
    option.value !== UNASSIGNED_PSEUDO_ASSIGNEE_KEY &&
    !props.subscribeMentionsSeparately
  ) {
    const user = dataStore.getUserByDuid(option.value);
    if (user) {
      editor.dispatchCommand(EVENT_SUBSCRIBE_USER, user);
    }
  }

  if (!option.property) {
    return;
  }

  const entityNode = $createEntityNode(option.property.duid, option.value);
  insertNode(entityNode, selection);

  if (!props.mentionsOnly || props.includeSpecialMentions) {
    removeConflictingEntitiesAndSmartMatches(editor, entityNode);
  }
};

onMounted(() => {
  if (!editor.hasNodes([EntityNode])) {
    throw new Error("EntityTypeaheadPlugin: EntityNode not registered on editor");
  }
});
</script>

<template>
  <TypeaheadMenuPlugin
    v-for="typeahead in typeaheads"
    :key="typeahead.trigger"
    :trigger="typeahead.trigger"
    :options="typeahead.getOptions"
    :large="!excludeDartLinks"
    @select="onSelect" />
</template>
