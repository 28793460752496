<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="m16 18 6-6-6-6M8 6l-6 6 6 6"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
