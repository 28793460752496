<script setup lang="ts">
import moment from "moment";
import { computed, onUnmounted, ref } from "vue";

import { useEnvironmentStore } from "~/stores";

const environmentStore = useEnvironmentStore();

const started = computed(() =>
  environmentStore.backendEnvironment ? moment(environmentStore.backendEnvironment.startedAt) : moment()
);
const backendAge = ref("");

const updateBackendAge = () => {
  backendAge.value = started.value.fromNow();
};

updateBackendAge();

const updateInterval = setInterval(updateBackendAge, 1000);

onUnmounted(() => clearInterval(updateInterval));
</script>

<template>
  <div class="flex flex-col py-1 pl-3 pr-2">
    <div class="flex flex-col gap-1 py-1">
      <p class="pb-1 text-xs text-md">Backend</p>
      <p class="text-xs text-lt">
        {{ environmentStore.backendEnvironment?.name }}
      </p>
      <p class="text-xs text-lt">{{ started.toISOString() }}</p>
      <p class="text-xs text-lt">{{ backendAge }}</p>
    </div>
    <div class="flex flex-col gap-1 py-1">
      <p class="pb-1 text-xs text-md">Commit</p>
      <p class="text-xs text-lt">
        <span class="font-mono">{{ environmentStore.backendEnvironment?.branch }}</span>
        {{ environmentStore.backendEnvironment?.changes ? "(changes)" : "" }}
      </p>
      <p v-if="environmentStore.backendEnvironment?.hash" class="font-mono text-xs text-lt">
        {{ environmentStore.backendEnvironment?.hash?.substring(0, 7) }}
      </p>
      <p v-if="environmentStore.backendEnvironment?.commitMessage" class="text-xs text-lt">
        {{ environmentStore.backendEnvironment?.commitMessage }}
      </p>
    </div>
  </div>
</template>
