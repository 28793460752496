<script setup lang="ts">
import { $generateJSONFromSelectedNodes } from "@lexical/clipboard";
import { mergeRegister } from "@lexical/utils";
import {
  $getSelection,
  $isNodeSelection,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_LOW,
  DecoratorNode,
  ElementNode,
} from "lexical";
import { useLexicalComposer } from "lexical-vue";
import { onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import { createNewTaskNotification } from "~/components/notifications/notification";
import { SERIALIZED_PARAGRAPH_NODE, SERIALIZED_ROOT_NODE } from "~/constants/lexical";
import { EntityName, RelationshipKindKind, TaskSourceType } from "~/shared/enums";
import type { Doc, Task } from "~/shared/types";
import { useAppStore, useDataStore, useEnvironmentStore } from "~/stores";
import { makeDuid, makeEmptyLexicalState } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";

import { EVENT_CONVERT_TO_TASKS, EVENT_SPLIT_TASK } from "../const";
import { $createDartLinkNode } from "../nodes/DartLinkNode";
import { isEmpty, trimLexicalState } from "../utils";

const props = defineProps<{
  task?: Task;
  doc?: Doc;
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const environmentStore = useEnvironmentStore();

const editor = useLexicalComposer();

const trim = () => {
  editor.update(() => {
    trimLexicalState();
  });
};

const splitTask = () => {
  const visualization = appStore.getBaseVisualization();

  if (!props.task) {
    return false;
  }

  const { task } = props;

  editor.update(() => {
    const selection = $getSelection();
    const removedBaseNodes = $generateJSONFromSelectedNodes(editor, selection).nodes;

    const nodes = selection?.getNodes();
    const firstNodeParent = nodes?.[0]?.getParent();
    const lastNodeParent = nodes?.[nodes.length - 1]?.getParent();

    if ($isRangeSelection(selection)) {
      selection.removeText();
    } else if ($isNodeSelection(selection)) {
      selection.getNodes().forEach((node) => node.remove());
    }
    if (firstNodeParent) {
      if (isEmpty(firstNodeParent)) {
        firstNodeParent.remove();
      }
    }
    if (lastNodeParent) {
      if (isEmpty(lastNodeParent)) {
        lastNodeParent.remove();
      }
    }
    editor.focus();

    let needsToBeWrapped = false;
    removedBaseNodes.forEach((node) => {
      const registeredNode = editor._nodes.get(node.type);
      if (registeredNode === undefined) {
        return;
      }
      const nodeClassPrototype = registeredNode.klass.prototype;
      if (nodeClassPrototype instanceof ElementNode || nodeClassPrototype instanceof DecoratorNode) {
        return;
      }
      needsToBeWrapped = true;
    });
    const children = needsToBeWrapped
      ? [{ ...SERIALIZED_PARAGRAPH_NODE, children: removedBaseNodes }]
      : removedBaseNodes;
    const newDescription = {
      root: {
        ...SERIALIZED_ROOT_NODE,
        children,
      },
    };

    const topTaskOrder = visualization.getAll()[0]?.order;
    const order = getOrdersBetween(undefined, topTaskOrder)[0];
    (async () => {
      const replicate = (
        await dataStore.replicateTasks([{ task, order, partialTask: { description: newDescription } }], {
          propertiesOnly: true,
          includeDuplicate: true,
          awaitBackend: true,
        })
      )[0];
      await actions.visualization.navigateToTask(replicate.duid);
      setTimeout(() => {
        appStore.taskDetail?.trimDescription();
      });
    })();
  });

  return true;
};

const convertToTasks = () => {
  editor.update(async () => {
    const selection = $getSelection();
    const dartboard = dataStore.defaultDartboard;
    if (!selection || !dartboard) {
      return;
    }

    const replacements: [string, string][] = [];
    selection.getNodes().forEach(async (node) => {
      if (!$isTextNode(node)) {
        return;
      }
      const title = node.getTextContent().replace(/\n/g, " ").trim();
      if (!title) {
        return;
      }

      const duid = makeDuid();
      const replacement = $createDartLinkNode(environmentStore.getTaskUrl({ duid, title }), EntityName.TASK, duid);
      node.replace(replacement);
      replacements.push([duid, title]);
    });
    editor.focus();

    if (props.task) {
      const visualization = appStore.getBaseVisualization();
      const { task } = props;
      const parentRelationshipKindDuid = dataStore.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid;

      const topTaskOrder = visualization.getAll()[0]?.order;
      const orders = getOrdersBetween(undefined, topTaskOrder, replacements.length);
      dataStore.replicateTasks(
        replacements.map(([duid, title], i) => ({
          task,
          order: orders[i],
          partialTask: {
            duid,
            title,
            description: makeEmptyLexicalState(),
            relationships: [
              { duid: makeDuid(), kindDuid: parentRelationshipKindDuid, targetDuid: task.duid, isForward: false },
            ],
          },
        })),
        { propertiesOnly: true }
      );
      return;
    }

    if (props.doc) {
      const docDuid = props.doc.duid;
      const dartboardDuid = dartboard.duid;
      const topTaskOrder = dataStore.getTasksByDartboardDuidOrdered(dartboardDuid)[0]?.order;
      const orders = getOrdersBetween(undefined, topTaskOrder, replacements.length);
      const statusDuid = dataStore.defaultDefaultUnstartedStatus.duid;
      const newTasks = await dataStore.createTasks(
        replacements.map(([duid, title], i) => ({
          duid,
          dartboardDuid,
          order: orders[i],
          title,
          statusDuid,
        })),
        TaskSourceType.APP_REPLICATE,
        { relatedDocDuid: docDuid }
      );

      createNewTaskNotification(
        newTasks,
        dartboard,
        dataStore.getSpaceByDuid,
        actions.visualization.navigateToTask,
        router
      );
    }
  });

  return true;
};

let unregisterListeners: () => void;

onMounted(() => {
  unregisterListeners = mergeRegister(
    editor.registerCommand(EVENT_SPLIT_TASK, splitTask, COMMAND_PRIORITY_LOW),
    editor.registerCommand(EVENT_CONVERT_TO_TASKS, convertToTasks, COMMAND_PRIORITY_LOW)
  );
});

onUnmounted(() => {
  unregisterListeners?.();
});

defineExpose({
  trim,
  convertToTasks,
});
</script>

<template>
  <slot />
</template>
