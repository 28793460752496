<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M 21,21 H 10.8 C 9.12,21 8.28,21 7.638,20.673 7.074,20.385 6.615,19.926 6.327,19.362 6,18.72 6,17.88 6,16.2 V 9 M 6,9 C 2,9 2,3 6,3 c 4,0 4,6 0,6 z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
