<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M13.3335 3.33335C14.1085 3.33335 14.496 3.33335 14.8139 3.41854C15.6766 3.64971 16.3505 4.32358 16.5816 5.18631C16.6668 5.50422 16.6668 5.89171 16.6668 6.66669V14.3334C16.6668 15.7335 16.6668 16.4336 16.3943 16.9683C16.1547 17.4387 15.7722 17.8212 15.3018 18.0609C14.767 18.3334 14.067 18.3334 12.6668 18.3334H7.3335C5.93336 18.3334 5.2333 18.3334 4.69852 18.0609C4.22811 17.8212 3.84566 17.4387 3.60598 16.9683C3.3335 16.4336 3.3335 15.7335 3.3335 14.3334V6.66669C3.3335 5.89171 3.3335 5.50422 3.41868 5.18631C3.64985 4.32358 4.32372 3.64971 5.18645 3.41854C5.50436 3.33335 5.89185 3.33335 6.66683 3.33335M8.00016 5.00002H12.0002C12.4669 5.00002 12.7002 5.00002 12.8785 4.90919C13.0353 4.8293 13.1628 4.70181 13.2427 4.54501C13.3335 4.36675 13.3335 4.1334 13.3335 3.66669V3.00002C13.3335 2.53331 13.3335 2.29995 13.2427 2.12169C13.1628 1.96489 13.0353 1.83741 12.8785 1.75751C12.7002 1.66669 12.4669 1.66669 12.0002 1.66669H8.00016C7.53345 1.66669 7.3001 1.66669 7.12184 1.75751C6.96504 1.83741 6.83755 1.96489 6.75766 2.12169C6.66683 2.29995 6.66683 2.53331 6.66683 3.00002V3.66669C6.66683 4.1334 6.66683 4.36675 6.75766 4.54501C6.83755 4.70181 6.96504 4.8293 7.12184 4.90919C7.3001 5.00002 7.53345 5.00002 8.00016 5.00002Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M11.6665 11.624H6.6665M8.33317 14.9573H6.6665M13.3332 8.29065H6.6665"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
