<template>
  <svg fill="none" viewBox="0 0 600 600">
    <path
      fill="currentColor"
      d="M 237.00,121.00
           C 242.83,116.24 248.64,111.48 255.00,107.44
             272.31,96.43 297.39,88.03 318.00,88.00
             318.00,88.00 337.00,88.00 337.00,88.00
             344.61,88.09 359.59,90.60 367.00,92.71
             380.22,96.47 392.27,101.67 403.00,110.46
             431.00,133.42 441.34,168.52 446.27,203.00
             446.27,203.00 449.00,230.00 449.00,230.00
             449.00,230.00 450.00,249.00 450.00,249.00
             450.00,249.00 450.00,269.00 450.00,269.00
             450.00,269.00 449.00,286.00 449.00,286.00
             448.90,294.35 446.11,312.47 444.40,321.00
             435.11,367.45 412.33,411.99 364.00,426.98
             353.99,430.08 339.44,432.87 329.00,433.00
             307.77,433.24 284.93,431.14 266.00,420.69
             266.00,420.69 246.00,406.00 246.00,406.00
             246.00,406.00 246.00,428.00 246.00,428.00
             246.00,428.00 247.00,445.00 247.00,445.00
             247.00,445.00 247.00,512.00 247.00,512.00
             247.00,512.00 163.00,512.00 163.00,512.00
             163.00,512.00 163.00,96.00 163.00,96.00
             163.00,96.00 172.00,95.09 172.00,95.09
             172.00,95.09 196.00,93.91 196.00,93.91
             196.00,93.91 206.00,93.01 206.00,93.01
             206.00,93.01 220.67,93.01 220.67,93.01
             220.67,93.01 226.30,101.00 226.30,101.00
             226.30,101.00 237.00,121.00 237.00,121.00 Z
           M 302.00,163.29
           C 302.00,163.29 291.00,164.29 291.00,164.29
             279.47,165.79 268.13,170.49 259.00,177.67
             250.83,184.09 247.02,187.52 247.00,198.00
             247.00,198.00 247.00,327.00 247.00,327.00
             247.02,337.09 251.30,341.86 259.01,348.19
             271.04,358.07 284.51,362.63 300.00,362.99
             305.16,363.10 313.99,362.07 319.00,360.87
             324.95,359.44 329.84,357.87 335.00,354.40
             345.34,347.45 350.80,337.40 354.95,326.00
             362.65,304.80 363.00,282.23 363.00,260.00
             363.00,260.00 363.00,238.00 363.00,238.00
             362.83,223.72 358.67,198.29 351.54,186.00
             342.34,170.16 329.35,165.30 312.00,163.92
             307.54,163.56 306.88,162.94 302.00,163.29 Z" />
  </svg>
</template>
