<script setup lang="ts">
import { computed, nextTick, onUnmounted, ref } from "vue";

import CollapsibleSection from "~/components/dumb/CollapsibleSection.vue";
import { copyAndNotify } from "~/components/notifications";
import NotionDescription from "~/components/task/description/NotionDescription.vue";
import DescriptionEditor from "~/components/text/DescriptionEditor.vue";
import { THROTTLE_MS } from "~/constants/app";
import { GITHUB_LINK_KINDS } from "~/constants/link";
import { CopyIcon, EditIcon, LinkFieldIcon, RefreshIcon, TrashIcon } from "~/icons";
import { DropdownMenuItemKind, EditorMode, SectionKind, TaskLinkKind } from "~/shared/enums";
import type { Task, TaskLink, TaskLinkCreate, TaskLinkUpdate } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";
import { getNotionPageUrl, isValidUrlIncludingLocal, makeDuid } from "~/utils/common";
import { stringComparator } from "~/utils/comparator";
import { getOrdersBetween } from "~/utils/orderManager";
import { ThrottleManager } from "~/utils/throttleManager";

const props = defineProps<{
  task: Task;
  editorMode: EditorMode;
}>();

const emit = defineEmits<{
  enter: [event: KeyboardEvent];
  addAttachments: [files: File[]];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const descriptionEditor = ref<InstanceType<typeof DescriptionEditor> | null>(null);

const collapsed = ref(props.editorMode === EditorMode.TCM && pageStore.isMobile);

const findLink = (task: Task, url: string) => task.links.find((taskLink: TaskLink) => taskLink.url === url);

const makeLinkObj = (taskDuid: string, url: string, order: string) => ({
  duid: makeDuid(),
  kind: TaskLinkKind.STANDARD,
  taskDuid,
  url,
  order,
  title: "",
});

const onLinkChangesFromDescription = (
  task: Task,
  creates: string[],
  destroys: string[],
  updates: { old: string; new: string }[]
) => {
  const linksToCreate: TaskLinkCreate[] = [];

  const allOrders = task.links.map((e) => e.order).sort(stringComparator);

  creates.forEach((url: string) => {
    if (!isValidUrlIncludingLocal(url) || findLink(task, url)) {
      return;
    }
    const order = getOrdersBetween(allOrders[allOrders.length - 1], undefined)[0];

    linksToCreate.push(makeLinkObj(task.duid, url, order));
    allOrders.push(order);
  });

  const linksToUpdate: TaskLinkUpdate[] = [];

  updates.forEach(({ old: prevUrl, new: url }) => {
    if (!isValidUrlIncludingLocal(url)) {
      return;
    }

    const link = findLink(task, prevUrl);
    if (!link) {
      const order = getOrdersBetween(allOrders[allOrders.length - 1], undefined)[0];

      linksToCreate.push(makeLinkObj(task.duid, url, order));
      allOrders.push(order);

      return;
    }

    if (GITHUB_LINK_KINDS.has(link.kind)) {
      return;
    }

    linksToUpdate.push({ duid: link.duid, url });
  });

  const linksDuidsToDelete = destroys
    .map((url: string) => {
      const link = findLink(task, url);
      if (!link || GITHUB_LINK_KINDS.has(link.kind)) {
        return "";
      }
      return link.duid;
    })
    .filter((duid: string) => !!duid);

  if (linksToCreate.length === 0 && linksToUpdate.length === 0 && linksDuidsToDelete.length === 0) {
    return;
  }
  dataStore.addUpdateAndDeleteLinks(task.duid, linksToCreate, linksToUpdate, linksDuidsToDelete);
};

const saveManager = new ThrottleManager(onLinkChangesFromDescription, THROTTLE_MS);

const updateLinksFromDescriptionThrottled = (
  creates: string[],
  destroys: string[],
  updates: { old: string; new: string }[]
) => saveManager.run(props.task, creates, destroys, updates);

const hasNotionDescription = computed(() => tenantStore.notionIntegration?.enabled && !!props.task.notionDocument);
const notionOptionsDropdown = computed(() => [
  {
    title: "Notion",
    items: [
      {
        title: "Copy document link",
        kind: DropdownMenuItemKind.BUTTON,
        icon: CopyIcon,
        onClick: () =>
          props.task.notionDocument &&
          copyAndNotify("Notion description URL", getNotionPageUrl(props.task.notionDocument.pageId)),
      },
      {
        title: "Convert to link",
        kind: DropdownMenuItemKind.BUTTON,
        icon: LinkFieldIcon,
        onClick: () => {
          const { task } = props;
          if (!task) {
            return;
          }
          const doc = task.notionDocument;
          if (!doc) {
            return;
          }
          dataStore.removeNotionDocument(task.duid);
          dataStore.addLink(task.duid, TaskLinkKind.STANDARD, getNotionPageUrl(doc.pageId), null);
        },
      },
      {
        title: "Change description",
        kind: DropdownMenuItemKind.BUTTON,
        icon: EditIcon,
        onClick: () => props.task.notionDocument && appStore.setTaskInNotionLinkModalDuid(props.task),
      },
      {
        title: "Get latest version",
        kind: DropdownMenuItemKind.BUTTON,
        icon: RefreshIcon,
        onClick: () => props.task && dataStore.refreshNotionDocument(props.task.duid),
      },
      {
        title: "Remove description",
        kind: DropdownMenuItemKind.BUTTON,
        icon: TrashIcon,
        onClick: () => props.task && dataStore.removeNotionDocument(props.task.duid),
      },
    ],
  },
]);

const focus = () => descriptionEditor.value?.focus();

const getTextContent = () => descriptionEditor.value?.getTextContent();

const trim = () => descriptionEditor.value?.trim();

const improveDescription = () => descriptionEditor.value?.openRecommendations();

const toggleShown = () => {
  collapsed.value = !collapsed.value;
  if (!collapsed.value) {
    nextTick(focus);
  }
};

onUnmounted(() => {
  saveManager.destroy();
});

defineExpose({
  focus,
  getTextContent,
  improveDescription,
  toggleShown,
  trim,
});
</script>

<template>
  <CollapsibleSection
    title="Description"
    :kind="
      editorMode === EditorMode.TCM
        ? SectionKind.DISABLED
        : hasNotionDescription
          ? SectionKind.DROPDOWN
          : SectionKind.DEFAULT
    "
    :dropdown-sections="notionOptionsDropdown"
    :init-collapsed="collapsed"
    tooltip="Notion options">
    <div class="mx-3.5 mt-1">
      <NotionDescription v-if="hasNotionDescription" :task="task" />
      <DescriptionEditor
        v-else
        ref="descriptionEditor"
        :key="task.duid"
        :task="task"
        :editor-mode="editorMode"
        @paste-files="(files: File[]) => emit('addAttachments', files)"
        @link-changes="updateLinksFromDescriptionThrottled"
        @action-enter="(event: KeyboardEvent) => emit('enter', event)" />
    </div>
  </CollapsibleSection>
</template>
