<script setup lang="ts">
import { computed, getCurrentInstance, onMounted, onUnmounted, watchEffect } from "vue";
import { useRouter } from "vue-router";

import { goHomeNextTick } from "~/router/common";
import { useAppStore, useDataStore } from "~/stores";
import { getDocLink, getFolderLink } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";

const props = defineProps<{
  folderDuid: string;
}>();

const currentInstance = getCurrentInstance();
const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();

const folder = computed(() => dataStore.getFolderByDuid(props.folderDuid));

watchEffect(() => {
  if (!folder.value) {
    goHomeNextTick();
    return;
  }
  appStore.setCurrentPage(folder.value);
  if (!router.currentRoute.value.params.docDuid) {
    setTimeout(() => {
      if (folder.value) {
        router.replace({
          params: { slugSep: "-", slug: getFolderLink(folder.value).params.slug },
          query: { ...router.currentRoute.value.query },
        });
      }
    });
  }
});

const docs = computed(() => dataStore.getDocsByFolderDuidOrdered(props.folderDuid));

const createDoc = async (open = true) => {
  if (!folder.value) {
    return null;
  }

  const topDocOrder = docs.value[0]?.order;
  const doc = await dataStore.createDoc("", folder.value.duid, getOrdersBetween(undefined, topDocOrder)[0]);

  if (open) {
    router.push(getDocLink(doc));
  }
  return doc.duid;
};

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.spaceOrFolder = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
});

onUnmounted(() => {
  appStore.spaceOrFolder = null;
});

defineExpose({
  createDoc,
});
</script>

<template>
  <main class="relative flex grow overflow-hidden">
    <RouterView :create-doc="createDoc" />
  </main>
</template>
