<script setup lang="ts">
import { onMounted, ref, watch } from "vue";

import { makeUuid } from "~/utils/common";

const props = defineProps<{
  text: string;
  label?: string;
  editable?: boolean;
  autoedit?: boolean;
  clickToEdit?: boolean;
  dblclickToEdit?: boolean;
  noCursor?: boolean;
  textBase?: boolean;
  borderless?: boolean;
  placeholder?: string;
  textSmall?: boolean;
  dataTestid?: string;
}>();

const emit = defineEmits<{
  update: [text: string];
  save: [text: string];
}>();

const isEditing = ref(false);
const wrapper = ref<HTMLDivElement | null>(null);
const textInput = ref<HTMLInputElement | null>(null);

const currentText = ref(props.text);

const updateText = () => emit("update", currentText.value);

const refreshText = () => {
  currentText.value = props.text;
};

const startEditing = (event?: MouseEvent | KeyboardEvent) => {
  if (!props.editable) {
    return;
  }
  if (event) {
    event.preventDefault();
  }

  if (isEditing.value) {
    if (event) {
      event.stopPropagation();
    }
    return;
  }

  isEditing.value = true;
  // TODO figure out why timeout with 0 time is needed
  setTimeout(() => {
    textInput.value?.focus();
    textInput.value?.select();
  });
};

const stopEditing = () => {
  if (!props.editable || !isEditing.value) {
    return;
  }
  isEditing.value = false;
  if (wrapper.value) {
    wrapper.value.scrollLeft = 0;
  }
  if (textInput.value) {
    textInput.value.blur();
  }
  emit("save", currentText.value);
};

const inputId = ref(`input-${makeUuid()}`);

watch(
  () => props.text,
  (newText) => {
    // set the value separately helps if the input is still initializing, not sure why. nextTick would also work
    setTimeout(() => {
      currentText.value = newText;
    });
  }
);

onMounted(() => {
  if (!props.autoedit) {
    return;
  }

  setTimeout(() => {
    startEditing();
  }, 100);
});

defineExpose({
  isEditing,
  refreshText,
  startEditing,
  stopEditing,
});
</script>

<template>
  <div
    ref="wrapper"
    class="flex h-full max-w-full items-center focus-ring-none focus:outline-none"
    :class="isEditing && 'dart-no-drag'">
    <label :for="inputId" class="sr-only">{{ label }}</label>
    <input
      :id="inputId"
      ref="textInput"
      v-model="currentText"
      v-autowidth="{ minWidth: '40px', comfortZone: '0px' }"
      type="text"
     
      :title="currentText"
      :readonly="!isEditing || !editable"
      :placeholder="placeholder"
      class="appearance-none truncate rounded border bg-transparent px-px py-0 focus-ring-none placeholder:text-vlt"
      :class="{
        'text-sm': !textBase,
        'text-base': textBase,
        'select-none': !isEditing,
        'border-transparent focus:border-transparent': !isEditing || borderless,
        'dart-no-drag': isEditing,
        'border-primary-base focus:border-primary-base': isEditing && !borderless,
        'hover:border-oncolor': clickToEdit && !isEditing,
        'cursor-text': isEditing || clickToEdit,
        'cursor-default': noCursor && !isEditing && !clickToEdit,
        'cursor-pointer': !noCursor && !isEditing && !clickToEdit,
      }"
      @click="(event) => clickToEdit && startEditing(event)"
      @dblclick="(event) => dblclickToEdit && startEditing(event)"
      @input="updateText"
      @keydown.enter.esc.stop.prevent="stopEditing"
      @blur="stopEditing" />
  </div>
</template>
