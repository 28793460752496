<template>
  <svg viewBox="-300 -300 600 600">
    <path
      d="M-62.855 140.64L-62.9 140.617C-88.465 129.178 -110.42 110.974 -126.394 87.968C-142.368 64.963 -151.755 38.031 -153.54 10.081C-153.675 7.386 -154.841 4.846 -156.796 2.986C-158.751 1.126 -161.346 0.088 -164.045 0.088L-173.792 -0.016C-185.681 -0.147 -197.046 -4.926 -205.456 -13.33L-212.482 -20.356C-213.921 -21.793 -215.744 -22.783 -217.733 -23.205C-219.722 -23.627 -221.791 -23.464 -223.689 -22.735C-225.588 -22.007 -227.234 -20.744 -228.43 -19.099C-229.626 -17.455 -230.32 -15.499 -230.427 -13.469V-13.45C-230.684 -8.976 -230.812 -4.466 -230.812 0.08C-230.812 91.623 -177.543 170.736 -100.318 208.072C-98.25 209.074 -96.165 210.045 -94.064 210.985L-93.346 211.308C-91.471 212.065 -89.418 212.266 -87.431 211.887C-85.445 211.508 -83.61 210.565 -82.145 209.171C-82.022 209.052 -81.907 208.937 -81.788 208.814L-59.272 186.294C-57.75 184.771 -56.894 182.706 -56.893 180.552V150.095C-56.898 148.118 -57.46 146.183 -58.515 144.511C-59.569 142.839 -61.073 141.497 -62.855 140.64Z"
      fill="currentColor" />
    <path
      d="M38.606 0.26C38.661 -9.686 34.85 -19.263 27.978 -26.453C27.952 -26.483 27.924 -26.511 27.894 -26.538L26.74 -27.691C26.714 -27.721 26.686 -27.749 26.656 -27.775L-2.486 -56.917L-56.912 -111.343V-152.68C-56.912 -165.296 -61.924 -177.395 -70.845 -186.316L-125.685 -241.156C-127.993 -243.465 -130.935 -245.038 -134.137 -245.675C-137.339 -246.313 -140.658 -245.986 -143.675 -244.736C-146.691 -243.487 -149.269 -241.37 -151.082 -238.655C-152.896 -235.94 -153.863 -232.748 -153.862 -229.483V-153.86H-229.481C-232.699 -153.861 -235.846 -152.921 -238.537 -151.156C-241.227 -149.392 -243.343 -146.879 -244.624 -143.928C-245.905 -140.976 -246.296 -137.715 -245.747 -134.544C-245.199 -131.374 -243.736 -128.433 -241.539 -126.083L-186.291 -70.835C-177.37 -61.914 -165.271 -56.902 -152.656 -56.902H-111.31L-56.896 -2.492L-27.8 26.604L-27.746 26.662L-26.44 27.969L-26.382 28.022C-19.198 34.863 -9.642 38.653 0.277 38.596C21.298 38.508 38.521 21.3 38.606 0.26Z"
      fill="currentColor" />
    <path
      d="M229.635 -25.593C227.306 -46.683 222.064 -67.348 214.057 -86.998C179.676 -171.365 96.827 -230.829 0.115 -230.829C-4.344 -230.829 -8.77 -230.701 -13.165 -230.445H-13.245C-15.243 -230.325 -17.165 -229.639 -18.786 -228.466C-20.407 -227.293 -21.66 -225.682 -22.398 -223.822C-23.137 -221.963 -23.329 -219.931 -22.954 -217.966C-22.578 -216.0 -21.651 -214.183 -20.279 -212.726L-13.203 -205.65C-4.676 -197.123 0.115 -185.557 0.115 -173.498V-163.774C0.182 -161.145 1.232 -158.638 3.057 -156.745C4.882 -154.852 7.35 -153.712 9.974 -153.55C10.024 -153.546 10.074 -153.546 10.124 -153.55C22.071 -152.787 33.889 -150.629 45.335 -147.119C67.344 -140.333 87.555 -128.707 104.487 -113.094C121.419 -97.482 134.643 -78.278 143.189 -56.891C144.726 -53.047 146.108 -49.124 147.332 -45.122C149.36 -38.53 150.935 -31.807 152.048 -25.001C153.408 -16.702 154.089 -8.307 154.085 0.102C154.093 11.743 152.783 23.348 150.181 34.694C146.651 50.046 140.774 64.762 132.758 78.323C119.493 100.764 100.773 119.491 78.336 132.763C64.775 140.777 50.059 146.652 34.707 150.183C26.617 152.037 18.391 153.237 10.108 153.769C7.419 153.913 4.887 155.083 3.035 157.038C1.182 158.993 0.15 161.584 0.149 164.277V199.957C0.148 202.501 -0.862 204.941 -2.66 206.741L-8.733 212.818C-8.765 212.844 -8.795 212.874 -8.821 212.906L-9.179 213.264C-9.21 213.291 -9.24 213.321 -9.267 213.352L-9.348 213.44L-14.279 218.372L-14.364 218.453L-14.687 218.775L-14.767 218.86C-15.678 219.863 -16.285 221.103 -16.52 222.438C-16.754 223.772 -16.606 225.145 -16.093 226.399C-15.579 227.652 -14.721 228.735 -13.618 229.521C-12.515 230.307 -11.211 230.764 -9.859 230.84H-9.763C-6.475 230.981 -3.171 231.052 0.149 231.052C12.199 231.056 24.232 230.124 36.137 228.265C71.4 222.736 104.891 209.071 133.957 188.353C167.313 164.618 193.781 132.467 210.667 95.173C219.217 76.291 225.165 56.337 228.348 35.855C230.183 24.028 231.101 12.078 231.096 0.11C231.082 -8.478 230.594 -17.059 229.635 -25.593ZM183.946 -56.887L183.977 -56.918V-56.887H183.946Z"
      fill="currentColor" />
  </svg>
</template>
