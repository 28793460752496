<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="m6 6 2-2m0 0L6 2m2 2H6a4 4 0 0 0-4 4m16 10-2 2m0 0 2 2m-2-2h2a4 4 0 0 0 4-4M10.189 6.5a6 6 0 1 1 7.311 7.311M14 16a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
