<script setup lang="ts">
import { useTextareaAutosize } from "@vueuse/core";
import { ref, watch } from "vue";

const props = defineProps<{
  title: string;
  borderless?: boolean;
  huge?: boolean;
}>();

const emit = defineEmits<{
  blur: [];
  change: [title: string];
  enter: [event: KeyboardEvent];
}>();

const titleInner = ref(props.title ?? "");
const { textarea } = useTextareaAutosize({ input: titleInner });

watch(
  () => props.title,
  (title) => {
    const newTitle = title ?? "";
    if (newTitle === titleInner.value) {
      return;
    }

    titleInner.value = newTitle;
  }
);

const updateTitle = () => {
  emit("change", titleInner.value);
};

const onTitleBlur = () => {
  emit("blur");
};

const onEnterPress = (event: KeyboardEvent) => {
  emit("enter", event);
  textarea.value?.blur();
};

const focus = () => textarea.value?.focus();

defineExpose({
  focus,
});
</script>

<template>
  <div class="relative flex items-center">
    <textarea
      ref="textarea"
      v-model="titleInner"
      aria-label="Doc title"
      placeholder="Doc title"
      class="w-full resize-none rounded border border-transparent bg-transparent px-1.5 py-[5px] font-semibold caret-gray-700 text-md focus-ring-none focus:border-transparent dark:caret-zinc-300"
      :class="{
        'text-base': !huge,
        'text-4xl': huge,
        'hover:border-md focus:border-md': !borderless,
      }"
      @input="updateTitle"
      @keydown.esc.stop.prevent="textarea?.blur()"
      @keydown.enter.stop.prevent="onEnterPress"
      @paste="updateTitle"
      @focusout="onTitleBlur" />
  </div>
</template>
