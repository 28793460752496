<script setup lang="ts">
import { computed, onUnmounted, ref } from "vue";

import { getPropertyConfig } from "~/common/properties";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import FormError from "~/components/dumb/FormError.vue";
import Input from "~/components/dumb/Input.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { UNSET_DARTBOARD_PSUEDO_DUID } from "~/constants/dartboard";
import { UNSET_KIND_PSUEDO_DUID } from "~/constants/kind";
import { UNSET_STATUS_PSUEDO_DUID } from "~/constants/status";
import { ChevronDownIcon, HideIcon, LockIcon, TrashIcon, VerticalDragHandleIcon } from "~/icons";
import { ButtonSize, DialogMode, PropertyKind } from "~/shared/enums";
import type { FormField, PropertyValue } from "~/shared/types";
import { useDataStore } from "~/stores";

const HIDE_CHEVRON_KINDS = new Set([
  PropertyKind.DEFAULT_TITLE,
  PropertyKind.DEFAULT_DESCRIPTION,
  PropertyKind.DEFAULT_ATTACHMENTS,
  PropertyKind.CHECKBOX,
  PropertyKind.NUMBER,
  PropertyKind.TEXT,
]);
const HAS_CUSTOM_STYLE_KINDS = new Set([
  PropertyKind.DEFAULT_TITLE,
  PropertyKind.DEFAULT_DESCRIPTION,
  PropertyKind.DEFAULT_ATTACHMENTS,
  PropertyKind.TEXT,
  PropertyKind.NUMBER,
]);
const REQUIRED_WHEN_NOT_SHOWN_KINDS = new Set([
  PropertyKind.DEFAULT_DARTBOARD,
  PropertyKind.DEFAULT_KIND,
  PropertyKind.DEFAULT_STATUS,
]);

const props = defineProps<{
  field: FormField;
  value?: PropertyValue;
  isSubmitMode?: boolean;
}>();

const emit = defineEmits<{
  expand: [duid?: string];
  update: [duid: string, value: PropertyValue];
}>();

const dataStore = useDataStore();

const label = ref(props.field.label);
const labelInput = ref<InstanceType<typeof Input> | null>(null);

const property = computed(() => dataStore.getPropertyByDuid(props.field.propertyDuid));
const propertyConfig = computed(() => getPropertyConfig(property.value?.kind as PropertyKind));

const updateField = (field: keyof FormField, value: PropertyValue | object) =>
  dataStore.updateFormField({
    duid: props.field.duid,
    [field]: value,
  });

const fieldConfig = computed(() => {
  const config = propertyConfig.value?.formField();
  if (!property.value) {
    return undefined;
  }

  return {
    component: config.component,
    values: config.getValues(
      property.value,
      props.field,
      props.value !== undefined ? props.value : props.field.default.value,
      (value) => {
        let actualValue;
        const kind = property.value?.kind;

        switch (kind) {
          case PropertyKind.DEFAULT_DARTBOARD: {
            actualValue = value === UNSET_DARTBOARD_PSUEDO_DUID ? null : value;
            break;
          }
          case PropertyKind.DEFAULT_KIND: {
            actualValue = value === UNSET_KIND_PSUEDO_DUID ? null : value;
            break;
          }
          case PropertyKind.DEFAULT_STATUS: {
            actualValue = value === UNSET_STATUS_PSUEDO_DUID ? null : value;
            break;
          }
          case PropertyKind.DEFAULT_TITLE:
          case PropertyKind.TEXT: {
            actualValue = !value?.toString()?.trim() ? null : value;
            break;
          }
          default: {
            actualValue = value;
          }
        }

        if (props.isSubmitMode) {
          emit("update", props.field.duid, actualValue);
          return;
        }

        updateField("default", {
          value: actualValue,
        });
      },
      props.isSubmitMode
    ),
  };
});

const isInvalidDefaultField = computed(() => {
  if (!property.value) {
    return false;
  }

  if (REQUIRED_WHEN_NOT_SHOWN_KINDS.has(property.value.kind)) {
    return props.field.hidden && !props.field.default.value;
  }

  if (property.value.kind === PropertyKind.DEFAULT_TITLE) {
    return props.field.hidden && !props.field.default.value?.toString()?.trim();
  }

  return false;
});

const deleteField = () => {
  dataStore.deleteFormField(props.field);
};

const expanded = ref(false);

const setExpanded = (newExpanded: boolean) => {
  if (expanded.value === newExpanded || (isInvalidDefaultField.value && !newExpanded) || props.isSubmitMode) {
    return;
  }

  expanded.value = newExpanded;
};

const expand = (newExpanded: boolean) => {
  setExpanded(newExpanded);
  emit("expand", expanded.value ? props.field.duid : undefined);
};

const focus = () => {
  labelInput.value?.focus();
};

onUnmounted(() => {
  if (!isInvalidDefaultField.value) {
    return;
  }
  updateField("hidden", false);
});

defineExpose({
  duid: props.field.duid,
  setExpanded,
  focus,
});
</script>

<template>
  <div v-if="property" class="group/form-field-card relative flex w-full">
    <span
      v-if="!isSubmitMode"
      class="dart-handle absolute -left-7 top-0 flex h-10 w-7 items-center justify-center opacity-0 group-hover/form-field-card:opacity-100">
      <VerticalDragHandleIcon class="cursor-move text-vlt icon-md" />
    </span>

    <div class="w-full" :class="!isSubmitMode && 'bg-gray-100/30 dark:bg-zinc-800/30'" @click.stop>
      <div
        v-if="!expanded"
        type="button"
        class="flex w-full flex-col items-center p-3"
        :tabindex="isSubmitMode ? -1 : 0"
        :class="[isSubmitMode ? 'cursor-default' : 'cursor-pointer rounded-lg border border-md hover:bg-lt']"
        @click.stop="expand(true)"
        @keydown.enter="expand(true)">
        <div class="flex w-full items-center justify-between">
          <div class="flex items-center gap-2 truncate pl-1 text-sm text-md">
            <component :is="propertyConfig.icon" class="text-lt icon-sm" />
            <span :title="field.label" class="select-none truncate">
              {{ field.label.trim() === "" ? property.title : field.label }}
            </span>
            <span class="select-none pr-4">{{ field.required ? "*" : "" }}</span>
          </div>

          <div v-if="!isSubmitMode" class="flex items-center gap-2 pr-1 text-vlt">
            <span class="select-none text-xs">{{ propertyConfig.label }}</span>
            <HideIcon v-if="field.hidden" class="icon-sm" />
            <LockIcon v-if="field.locked" class="icon-sm" />
          </div>
        </div>

        <div
          class="relative mt-1 flex w-full items-center justify-between rounded text-md"
          :class="{
            'hover:bg-lt': isSubmitMode && !HAS_CUSTOM_STYLE_KINDS.has(property.kind),
            'pointer-events-none mt-1 bg-std': !isSubmitMode,
            'h-8 border border-md': !HAS_CUSTOM_STYLE_KINDS.has(property.kind),
          }">
          <component :is="fieldConfig?.component" v-bind="fieldConfig?.values" />
          <ChevronDownIcon
            v-if="!HIDE_CHEVRON_KINDS.has(property.kind)"
            class="pointer-events-none absolute right-2 text-vlt icon-sm" />
        </div>
      </div>

      <div
        v-show="expanded && !isSubmitMode"
        class="flex flex-col rounded-lg border border-primary-base/50 pb-3 pl-4 pr-3 pt-2.5 bg-std">
        <div class="mb-3 flex justify-between">
          <div class="flex items-center gap-2 truncate text-sm text-md">
            <component :is="propertyConfig.icon" class="text-lt icon-sm" />
            <span class="select-none truncate">{{ property.title }}</span>
          </div>
          <div
            class="flex items-center justify-end gap-0.5 border-0 outline-none text-vlt focus-ring-none focus-within:outline-none hover:pointer-events-auto focus:outline-none">
            <Tooltip v-if="field.locked" text="This is a default field so it cannot be made optional or deleted">
              <LockIcon class="m-1 icon-sm focus:outline-none" />
            </Tooltip>
            <ConfirmationDialog
              v-else
              title="Delete field"
              description="This action cannot be undone. Are you sure you want to proceed?"
              confirm-text="Delete"
              cancel-text="Cancel"
              :mode="DialogMode.DELETE"
              @confirm="deleteField"
              @cancel="expand(false)">
              <Tooltip text="Delete field">
                <span class="cursor-pointer rounded p-1 hover:bg-lt">
                  <TrashIcon class="icon-sm" />
                </span>
              </Tooltip>
            </ConfirmationDialog>
          </div>
        </div>
        <table class="w-full table-fixed">
          <colgroup>
            <col span="1" style="width: 25%" />
            <col span="1" style="width: 75%" />
          </colgroup>
          <tbody>
            <tr>
              <td class="select-none py-2 text-sm text-md">Label</td>
              <td class="text-sm text-md">
                <Input
                  ref="labelInput"
                  :init-value="label"
                  label="Label"
                  hide-label
                  is-form
                  :placeholder="property.title"
                  @change="(value) => updateField('label', value)" />
              </td>
            </tr>
            <tr>
              <td class="relative text-sm text-md">
                <div class="absolute top-3.5 select-none">Default</div>
              </td>
              <td class="flex w-full flex-col items-start gap-1 py-2 text-sm text-md">
                <div
                  class="flex size-full min-h-8 items-center rounded"
                  :class="!HAS_CUSTOM_STYLE_KINDS.has(property.kind) && 'border border-md hover:bg-lt'">
                  <component :is="fieldConfig?.component" v-bind="fieldConfig?.values" />
                </div>
                <FormError v-if="isInvalidDefaultField" msg="This field is required when it's not shown" show />
              </td>
            </tr>
            <tr>
              <td class="select-none py-2 text-sm text-md">Shown</td>
              <td class="text-sm text-md">
                <div class="flex items-center justify-start">
                  <Toggle
                    :value="!field.hidden"
                    hide-label
                    label="Shown"
                    :size="ButtonSize.SMALL"
                    @update="(value) => updateField('hidden', !value)" />
                </div>
              </td>
            </tr>
            <tr v-if="!field.hidden">
              <td class="select-none py-2 text-sm text-md">Required</td>
              <td class="text-sm text-md">
                <div class="flex items-center justify-start">
                  <Toggle
                    :value="field.required"
                    :disabled="field.locked"
                    hide-label
                    label="Required"
                    :size="ButtonSize.SMALL"
                    @update="(value) => updateField('required', value)" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
