<script setup lang="ts">
import { computed } from "vue";

import Template from "~/components/dumb/Template.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { CheckIcon } from "~/icons";
import { EditorMode } from "~/shared/enums";
import type { Property } from "~/shared/types";

const props = defineProps<{
  value: boolean;
  property?: Property;
  editorMode: EditorMode;
}>();

const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);
</script>

<template>
  <component :is="isBoardMode ? Tooltip : Template" v-if="value" :text="property?.title">
    <div class="flex items-center justify-center rounded border text-md border-oncolor icon-md">
      <CheckIcon class="icon-xs" aria-hidden="true" />
    </div>
  </component>
</template>
