<script lang="ts" setup>
import { computed, nextTick } from "vue";
import { useRouter } from "vue-router";

import { UNGROUPED_PSEUDO_GROUP_BY } from "~/common/groupBy";
import { getShownPropertyWithConfigList } from "~/common/properties";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import {
  convertDocToMarkdown,
  convertPageToMarkdown,
  convertPageToTable,
  convertTableToCsv,
  convertTaskToMarkdown,
  saveAsCsv,
  saveAsHtml,
  saveAsMarkdown,
  saveAsPdf,
} from "~/components/text/transformers";
import { ExportIcon } from "~/icons";
import { DropdownMenuItemKind, Placement, PropertyKind } from "~/shared/enums";
import type { Doc, Page, Task } from "~/shared/types";
import { useAppStore, useDataStore } from "~/stores";
import { getPageLink } from "~/utils/common";

const WIDE_PROPERTY_KINDS = [PropertyKind.DEFAULT_TITLE, PropertyKind.DEFAULT_DESCRIPTION];
const WIDE_MULTIPLIER = 3;

const props = defineProps<{
  task?: Task;
  doc?: Doc;
  page?: Page;
}>();

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();

const normalizeTables = (html: string) => {
  const properties = getShownPropertyWithConfigList(WIDE_PROPERTY_KINDS);
  const total =
    properties.length +
    (WIDE_MULTIPLIER - 1) * properties.filter(([property]) => WIDE_PROPERTY_KINDS.includes(property.kind)).length;
  const wide = (100 * WIDE_MULTIPLIER) / total;
  const normal = 100 / total;
  const origFrags: string[] = [];
  const newFrags: string[] = [];
  properties.forEach(([property]) => {
    origFrags.push(`<th>${property.title}</th>`);
    newFrags.push(
      `<th style="width: ${WIDE_PROPERTY_KINDS.includes(property.kind) ? wide : normal}%;">${property.title}</th>`
    );
  });
  return html.replaceAll(origFrags.join("\n"), newFrags.join("\n"));
};

const getPageMarkdown = (page: Page) => {
  const groupedTasks: { title: string; tasks: Task[] }[] = [];
  const collapsedColumnIds = new Set(appStore.collapsedGroups);
  const { groupByDefinition } = appStore;
  if (groupByDefinition.property.duid === UNGROUPED_PSEUDO_GROUP_BY) {
    groupedTasks.push({ title: "All", tasks: appStore.filteredAndSortedTasksInPage });
  } else {
    groupByDefinition.groups.forEach((group) => {
      if (collapsedColumnIds.has(group.id)) {
        return;
      }
      groupedTasks.push({
        title: group.title,
        tasks: appStore.groupByValueToTasksMap.get(group.id) ?? [],
      });
    });
  }
  return convertPageToMarkdown(page, groupedTasks);
};

const exportAsPdf = async () => {
  if (props.task) {
    saveAsPdf(convertTaskToMarkdown(props.task), props.task.title);
    return;
  }
  if (props.doc) {
    saveAsPdf(convertDocToMarkdown(props.doc), props.doc.title);
    return;
  }
  if (props.page) {
    await router.push(getPageLink(props.page, dataStore.getSpaceByDuid));
    await nextTick();
    saveAsPdf(getPageMarkdown(props.page), props.page.title, {
      postprocessHtml: normalizeTables,
      pdfOptions: { pageOrientation: "landscape" },
    });
  }
};

const exportAsCsv = async () => {
  if (props.page) {
    await router.push(getPageLink(props.page, dataStore.getSpaceByDuid));
    await nextTick();
    saveAsCsv(convertTableToCsv(convertPageToTable(appStore.filteredAndSortedTasksInPage)), props.page.title);
  }
};

const exportAsHtml = async () => {
  if (props.task) {
    saveAsHtml(convertTaskToMarkdown(props.task), props.task.title);
    return;
  }
  if (props.doc) {
    saveAsHtml(convertDocToMarkdown(props.doc), props.doc.title);
    return;
  }
  if (props.page) {
    await router.push(getPageLink(props.page, dataStore.getSpaceByDuid));
    await nextTick();
    saveAsHtml(getPageMarkdown(props.page), props.page.title, {
      postprocessHtml: normalizeTables,
    });
  }
};

const exportAsMarkdown = async () => {
  if (props.task) {
    saveAsMarkdown(convertTaskToMarkdown(props.task), props.task.title);
    return;
  }
  if (props.doc) {
    saveAsMarkdown(convertDocToMarkdown(props.doc), props.doc.title);
  }
};

const sections = computed(() => [
  {
    title: "Export as",
    items: [
      {
        title: "PDF",
        kind: DropdownMenuItemKind.BUTTON,
        onClick: exportAsPdf,
      },
      {
        title: "CSV",
        hidden: !props.page,
        kind: DropdownMenuItemKind.BUTTON,
        onClick: exportAsCsv,
      },
      {
        title: "HTML",
        kind: DropdownMenuItemKind.BUTTON,
        onClick: exportAsHtml,
      },
      {
        title: "Markdown",
        hidden: !props.task && !props.doc,
        kind: DropdownMenuItemKind.BUTTON,
        onClick: exportAsMarkdown,
      },
    ],
  },
]);
</script>

<template>
  <DropdownMenu :sections="sections" :placement="Placement.RIGHT_TOP" :width-pixels="120" show-on-hover>
    <DropdownMenuItemContent :icon="ExportIcon" title="Export as" is-submenu />
  </DropdownMenu>
</template>
