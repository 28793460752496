<script lang="ts" setup>
import { computed } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import EntityDropdownItem from "~/components/dumb/EntityDropdownItem.vue";
import MultiselectDropdownMenu from "~/components/dumb/MultiselectDropdownMenu.vue";
import { MergeIcon } from "~/icons";
import { Placement, RelationshipKindKind, StatusKind } from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useDataStore } from "~/stores";
import { getTaskLink } from "~/utils/common";

const props = defineProps<{
  targetTask: Task;
  selectedTasks: Task[];
  onAfterClose?: () => void;
}>();

const router = useRouter();
const dataStore = useDataStore();

const taskOptions = computed(() =>
  dataStore
    .getTaskList()
    .filter((task) => task.duid !== props.targetTask.duid)
    .map((task) => ({
      value: task.duid,
      label: task.title,
      selected: props.selectedTasks.some((selectedTask) => selectedTask.duid === task.duid),
      disabled: false,
      component: EntityDropdownItem,
      componentArgs: {
        showOpen: true,
        onOpen: () => {
          router.push(getTaskLink(task));
        },
      },
    }))
);

const onSelect = (taskDuid: string) => {
  const task = dataStore.getTaskByDuid(taskDuid);
  if (!task) {
    return;
  }

  const canceledStatusMaybe = dataStore.getStatusesByKinds([StatusKind.CANCELED], dataStore.defaultStatusProperty)[0];
  const newStatus = canceledStatusMaybe ?? dataStore.defaultDefaultFinishedStatus;
  const relatesToRelationshipKindDuid = dataStore.getRelationshipKindByKind(RelationshipKindKind.DUPLICATES).duid;

  // TODO transactionify
  dataStore.createRelationship(props.targetTask.duid, task.duid, relatesToRelationshipKindDuid);
  dataStore.updateTasks([{ duid: props.targetTask.duid, statusDuid: newStatus.duid }]);

  actions.visualization.navigateToTask(taskDuid);
};
</script>

<template>
  <MultiselectDropdownMenu
    :items="taskOptions"
    placeholder="Merge into task..."
    :placement="Placement.RIGHT_TOP"
    show-on-hover
    :width-pixels="448"
    :on-after-close="onAfterClose"
    close-on-select
    @add="onSelect">
    <DropdownMenuItemContent :icon="MergeIcon" title="Merge into other task" is-submenu />
  </MultiselectDropdownMenu>
</template>
