<script setup lang="ts">
import { computed, nextTick, ref } from "vue";

import type { TaskAbsenteeMaybe } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { getOrdersBetween } from "~/utils/orderManager";

import { TASK_ROW_HEIGHT } from "./constants";
import NewOrSetTaskRow from "./NewOrSetTaskRow.vue";
import OffscreenArrow from "./OffscreenArrow.vue";
import RoadmapTaskCard from "./RoadmapTaskCard.vue";
import type { PreviewRange, RoadmapConfig, RoadmapValues } from "./shared";

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const props = defineProps<{
  roadmapConfig: RoadmapConfig;
  roadmapValues: RoadmapValues;
}>();

const emit = defineEmits<{
  activeChange: [activeDuid: string | null];
  scrollTo: [x: number, y?: number];
  updatePreviewRange: [previewRange: PreviewRange];
}>();

const taskRefs = ref<InstanceType<typeof RoadmapTaskCard>[]>([]);
const height = computed(() => `${props.roadmapValues.tasks.length * TASK_ROW_HEIGHT}px`);

const deselectAll = () => {
  appStore.getActiveVisualization().deselectAll();
};

const onChange = (
  task: TaskAbsenteeMaybe,
  index: number,
  startAt: string | null,
  dueAt: string | null,
  ended: boolean
) => {
  const { tasks } = props.roadmapValues;

  const itemAboveDest = index === 0 ? undefined : tasks[index - 1]?.order;
  const itemBelowDest = index < tasks.length - 1 ? tasks[index + 1]?.order : undefined;
  const newOrder = getOrdersBetween(itemAboveDest, itemBelowDest)[0];

  dataStore.updateTasks([{ duid: task.duid, order: newOrder, startAt, dueAt }], { noBackend: !ended });
};

/* Focus new task */
const onTaskCreated = () => {
  nextTick(() => {
    const lastTask = taskRefs.value[taskRefs.value.length - 1];
    lastTask.startEditingTitle();
  });
};
</script>

<template>
  <div
    class="absolute inset-0 size-full"
    :style="{
      minHeight: height,
      maxHeight: height,
    }"
    @click="deselectAll"
    @keydown.enter="deselectAll">
    <!-- Tasks -->
    <div class="flex size-full">
      <RoadmapTaskCard
        v-for="(task, index) in roadmapValues.tasks"
        :key="task.duid"
        ref="taskRefs"
        :roadmap-config="roadmapConfig"
        :roadmap-values="roadmapValues"
        :task="task"
        :index="index"
        @active-change="(e) => emit('activeChange', e)"
        @change="(i, startAt, dueAt, ended) => onChange(task, i, startAt, dueAt, ended)"
        @update-preview-range="(e) => emit('updatePreviewRange', e)"
        @scroll-to="(x, y) => emit('scrollTo', x, y)" />
    </div>

    <!-- Offscreen arrows -->
    <div class="pointer-events-none absolute inset-0 flex size-full">
      <OffscreenArrow
        v-for="(task, index) in roadmapValues.tasks"
        :key="task.duid"
        :roadmap-config="roadmapConfig"
        :roadmap-values="roadmapValues"
        :task="task"
        :index="index"
        @scroll-to="(x, y) => emit('scrollTo', x, y)" />
    </div>

    <!-- Add new task -->
    <NewOrSetTaskRow
      v-if="!pageStore.isPublicView"
      :roadmap-config="roadmapConfig"
      :roadmap-values="roadmapValues"
      :index="roadmapValues.tasks.length"
      @task-created="onTaskCreated"
      @update-preview-range="(e) => emit('updatePreviewRange', e)" />
  </div>
</template>
