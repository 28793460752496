import moment from "moment";
import { nextTick } from "vue";

import { TSHIRT_SIZE_TO_NUMBER_SIZE } from "~/constants/size";
import {
  EditorMode,
  Priority,
  RelationshipKindKind,
  type StatusKind,
  TaskSourceType,
  type TshirtSize,
  ViewKind,
} from "~/shared/enums";
import type { PseudoRows, Task, TaskAndUpdate, TaskUpdate } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";
import { getPageDisplayName, makeDuid, makeEmptyLexicalState, unionOfAllSets } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";

import type { Actions } from "..";

type ValueCreatedByCommandBar = { _createdByCommandBar: boolean; value: string };

/** Task edit related actions */
export default (actions: Actions) => ({
  refreshByRow: (rows: PseudoRows, fields: string[]) => {
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();

    if (actions.visualization.currentRowIsCreateModal(rows)) {
      return;
    }

    visualization.refresh({
      tasks: rows.map((e) => e.data).filter((e): e is Task => !!e),
      fields,
    });
  },
  addTask: (taskTitle: string, partialTask: Partial<Task> = {}) => {
    const appStore = useAppStore();
    const dataStore = useDataStore();
    const visualization = appStore.getActiveVisualization();

    const dartboard = appStore.currentDartboardOrDefault;
    if (!dartboard) {
      return undefined;
    }

    const tasks = visualization.getAll();
    const topTaskOrder = tasks.length === 0 ? "" : tasks[0].order;
    const order = getOrdersBetween(undefined, topTaskOrder)[0];
    const task = dataStore.createTask(taskTitle, dartboard.duid, order, TaskSourceType.APP_QUICK_ADD, partialTask);
    appStore.cachedNewTaskInput = makeEmptyLexicalState();
    // TODO normalize filters at this point so that someone can see the task that they just made
    return task;
  },
  recommendProperties: () => {
    const pageStore = usePageStore();

    if (!pageStore.isOnline) {
      return;
    }

    const appStore = useAppStore();

    const currents = actions.visualization.getCurrentRows(true);
    if (currents.length !== 1) {
      return;
    }

    const current = currents[0];
    const task = current.data;
    if (!task) {
      return;
    }

    if ("createModal" in current && current.createModal) {
      appStore.tcm?.generatePropertyRecommendations();
      return;
    }

    appStore.setTaskDetailOpen(true);

    // TODO Find a way to do this without timeouts
    setTimeout(() => {
      appStore.taskDetail?.generatePropertyRecommendations();
    }, 300);
  },
  recommendSubtasks: () => {
    const pageStore = usePageStore();

    if (!pageStore.isOnline) {
      return;
    }
    const appStore = useAppStore();

    const currents = actions.visualization.getCurrentRows(true);
    if (currents.length !== 1) {
      return;
    }

    const current = currents[0];
    const task = current.data;
    if (!task) {
      return;
    }

    if ("createModal" in current && current.createModal) {
      appStore.tcm?.generateSubtaskRecommendations();
      return;
    }

    appStore.setTaskDetailOpen(true);

    // TODO Find a way to do this without timeouts
    setTimeout(() => {
      appStore.taskDetail?.generateSubtaskRecommendations();
    }, 300);
  },
  createSubtask: async (target?: Task) => {
    const appStore = useAppStore();
    const dataStore = useDataStore();

    const currents = actions.visualization.getCurrentRows(true);
    if (currents.length !== 1 && !target) {
      return;
    }

    const current = currents[0];
    const parentTask = target ?? current.data;
    if (!parentTask) {
      return;
    }

    if ("createModal" in current && current.createModal) {
      await appStore.tcm?.createSubtask();
      return;
    }

    if (appStore.taskDetailOpen) {
      appStore.taskDetail?.createSubtask();
      return;
    }

    const parentRelationshipKindDuid = dataStore.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid;

    const partialTask: Partial<Task> = {
      drafterDuid: parentTask.drafterDuid,
      relationships: [
        {
          duid: makeDuid(),
          kindDuid: parentRelationshipKindDuid,
          targetDuid: parentTask.duid,
          isForward: false,
        },
      ],
    };

    actions.visualization.createTaskUnderneath(partialTask, { makeSubtask: true });
  },
  convertToNormalTask: (subtask: Task) => {
    const appStore = useAppStore();
    const dataStore = useDataStore();
    const visualization = appStore.getBaseVisualization();

    const parentTask = dataStore.getParentTask(subtask);
    if (!parentTask) {
      return;
    }

    const relationshipDuid = subtask.relationships.find((e) => e.targetDuid === parentTask.duid)?.duid;
    if (!relationshipDuid) {
      return;
    }

    dataStore.deleteRelationship(subtask.duid, relationshipDuid);

    visualization.refresh({ tasks: [parentTask, subtask] });
  },
  addAttachments: () => {
    const appStore = useAppStore();

    const currents = actions.visualization.getCurrentRows(true);
    if (currents.length !== 1) {
      return;
    }

    const current = currents[0];
    const task = current.data;
    if (!task) {
      return;
    }

    if ("createModal" in current && current.createModal) {
      appStore.tcm?.openFilePicker();
      return;
    }

    appStore.setTaskDetailOpen(true);

    // TODO Find a way to do this without timeouts
    setTimeout(() => {
      appStore.taskDetail?.openFilePicker();
    }, 300);
  },
  addLink: () => {
    const appStore = useAppStore();

    const currents = actions.visualization.getCurrentRows(true);
    if (currents.length !== 1) {
      return;
    }

    const current = currents[0];
    const task = current.data;
    if (!task) {
      return;
    }

    if ("createModal" in current && current.createModal) {
      return;
    }

    appStore.setTaskDetailOpen(true);
    appStore.setLinkOpenInModal({ taskDuid: task.duid });
  },
  addAssignees: (assigneeDuids: string[]) => {
    const dataStore = useDataStore();

    const currents = actions.visualization.getCurrentRows();
    if (currents.length === 0) {
      return;
    }

    currents.forEach((current) => {
      if (!current?.data) {
        return;
      }
      dataStore.addUsers(dataStore.defaultAssigneesProperty, [current.data.duid], assigneeDuids);
    });
  },
  addTags: (newTagObjs: (ValueCreatedByCommandBar | string)[]) => {
    const dataStore = useDataStore();

    const tagsProperty = dataStore.defaultTagsProperty;
    const newTagDuids: string[] = [];
    newTagObjs.forEach(async (tagObj) => {
      if (typeof tagObj === "string") {
        newTagDuids.push(dataStore.getOptionByTitle(tagObj, tagsProperty)?.duid || "");
        return;
      }
      const tag = await dataStore.createOption(tagObj.value, tagsProperty);
      if (tag) {
        newTagDuids.push(tag.duid);
      }
    });

    const currents = actions.visualization.getCurrentRows();
    if (currents.length === 0) {
      return;
    }
    currents.forEach((current) => {
      if (!current?.data) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      current.data.tagDuids = current.data.tagDuids.concat(newTagDuids);
      dataStore.addOptions(dataStore.defaultTagsProperty, [current.data.duid], newTagDuids);
    });
    actions.visualization.refreshByRow(currents, ["tagDuids"]);
  },
  removeAssignees: (assigneeDuids: string[]) => {
    const dataStore = useDataStore();

    const currents = actions.visualization.getCurrentRows();
    if (currents.length === 0) {
      return;
    }

    currents.forEach((current) => {
      if (!current?.data) {
        return;
      }
      dataStore.removeUsers(dataStore.defaultAssigneesProperty, [current.data.duid], assigneeDuids);
    });
  },
  removeTags: (tagTitles: string[]) => {
    const dataStore = useDataStore();

    const currents = actions.visualization.getCurrentRows();
    if (currents.length === 0) {
      return;
    }

    const tagDuids = tagTitles
      .map((title) => dataStore.getOptionByTitle(title, dataStore.defaultTagsProperty)?.duid)
      .filter((duid): duid is string => !!duid);

    currents.forEach((current) => {
      if (!current?.data) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      current.data.tagDuids = current.data.tagDuids.filter((e: string) => !tagDuids.includes(e));
      dataStore.removeOptions(dataStore.defaultTagsProperty, [current.data.duid], tagDuids);
    });
    actions.visualization.refreshByRow(currents, ["tagDuids"]);
  },
  setAssignee: (newAssigneeDuid: string | null) => {
    const dataStore = useDataStore();

    const currents = actions.visualization.getCurrentRows();
    if (currents.length === 0) {
      return;
    }

    const newAssigneeDuids = newAssigneeDuid === null ? [] : [newAssigneeDuid];
    currents.forEach((current) => {
      if (!current?.data) {
        return;
      }
      dataStore.replaceUsers(dataStore.defaultAssigneesProperty, [current.data.duid], newAssigneeDuids);
    });
  },
  setStartAt: (startAt: Date) => {
    const dataStore = useDataStore();

    const tasks = actions.visualization
      .getCurrentRows()
      .map((e) => e.data)
      .filter((e): e is Task => !!e);
    if (tasks.length === 0) {
      return;
    }

    dataStore.updateTasks(
      tasks.map((e) => ({
        duid: e.duid,
        startAt: startAt.toISOString(),
      }))
    );
  },
  setDueAt: (dueAt: Date) => {
    const dataStore = useDataStore();

    const tasks = actions.visualization
      .getCurrentRows()
      .map((e) => e.data)
      .filter((e): e is Task => !!e);
    if (tasks.length === 0) {
      return;
    }

    dataStore.updateTasks(
      tasks.map((e) => ({
        duid: e.duid,
        dueAt: dueAt.toISOString(),
      }))
    );
  },
  setPriority: (priority: Priority | null) => {
    const dataStore = useDataStore();

    const tasks = actions.visualization
      .getCurrentRows()
      .map((e) => e.data)
      .filter((e): e is Task => !!e);
    if (tasks.length === 0) {
      return;
    }

    dataStore.updateTasks(
      tasks.map((e) => ({
        duid: e.duid,
        priority,
      }))
    );
  },
  setSize: (size: string | null) => {
    const dataStore = useDataStore();

    const tasks = actions.visualization
      .getCurrentRows()
      .map((e) => e.data)
      .filter((e): e is Task => !!e);
    if (tasks.length === 0) {
      return;
    }

    const sizeNum = TSHIRT_SIZE_TO_NUMBER_SIZE.get(size as TshirtSize) ?? null;

    dataStore.updateTasks(
      tasks.map((e) => ({
        duid: e.duid,
        size: sizeNum,
      }))
    );
  },
  setRemindAt: (remindAt: Date) => {
    const dataStore = useDataStore();

    const currents = actions.visualization.getCurrentRows();
    const tasks = currents.map((e) => e.data).filter((e): e is Task => !!e);
    if (currents.length === 0) {
      return;
    }

    const newRemindAt = moment(remindAt).toISOString();

    dataStore.updateTasks(
      tasks.map((e) => ({
        duid: e.duid,
        remindAt: newRemindAt,
      }))
    );

    actions.visualization.refreshByRow(currents, ["title"]);
  },
  setStatusWithDuid: (currents: PseudoRows, newStatusDuid: string) => {
    const dataStore = useDataStore();

    const tasks = currents.map((e) => e.data).filter((e): e is Task => !!e && e.statusDuid !== newStatusDuid);
    if (currents.length === 0) {
      return;
    }

    dataStore.updateTasks(
      tasks.map((e) => ({
        duid: e.duid,
        statusDuid: newStatusDuid,
      }))
    );

    actions.visualization.refreshByRow(currents, ["statusDuid"]);
  },
  cycleStatuses: (statusKind: StatusKind) => {
    const dataStore = useDataStore();

    const currents = actions.visualization.getCurrentRows();
    if (currents.length === 0) {
      return;
    }
    const statusesWithKind = dataStore.getStatusesByKinds([statusKind], dataStore.defaultStatusProperty);
    if (statusesWithKind.length === 0) {
      return;
    }
    const currentStatusDuids = Array.from(
      new Set(currents.map((e) => e?.data?.statusDuid).filter((e): e is string => !!e))
    );
    const newStatusIndex =
      currentStatusDuids.length === 1
        ? (statusesWithKind.findIndex((e) => e.duid === currentStatusDuids[0]) + 1) % statusesWithKind.length
        : 0;
    const newStatusDuid = statusesWithKind[newStatusIndex].duid;
    actions.visualization.setStatusWithDuid(currents, newStatusDuid);
  },
  setStatus: (newStatusTitle: string) => {
    const dataStore = useDataStore();

    const currents = actions.visualization.getCurrentRows();
    if (currents.length === 0) {
      return;
    }
    const newStatusDuid = dataStore.getStatusByTitle(newStatusTitle, dataStore.defaultStatusProperty)?.duid;
    if (!newStatusDuid) {
      return;
    }
    actions.visualization.setStatusWithDuid(currents, newStatusDuid);
  },
  setStatusDone: () => {
    const dataStore = useDataStore();

    const currents = actions.visualization.getCurrentRows();
    if (currents.length === 0) {
      return;
    }
    const newStatusDuid = dataStore.defaultDefaultFinishedStatus?.duid;
    if (!newStatusDuid) {
      return;
    }
    actions.visualization.setStatusWithDuid(currents, newStatusDuid);
  },
  replicateTasks: async () => {
    const appStore = useAppStore();
    const dataStore = useDataStore();
    const visualization = appStore.getActiveVisualization();

    const selectedTasks = actions.visualization
      .getCurrentRows()
      .map((e) => e.data)
      .filter((e): e is Task => !!e);

    const tasks = visualization.getAll();

    const lastTask = selectedTasks[selectedTasks.length - 1];
    const lastTaskIndex = tasks.findIndex((e: Task) => e.duid === lastTask.duid);
    const belowTaskOrder = tasks[lastTaskIndex + 1]?.order;
    const replicatesOrders = getOrdersBetween(lastTask.order, belowTaskOrder, selectedTasks.length);

    const tasksToReplicate: TaskAndUpdate[] = selectedTasks.map((task, index) => ({
      task,
      order: replicatesOrders[index] ?? "",
    }));

    const replicatedParents = await dataStore.replicateTasks(tasksToReplicate, { awaitBackend: true });
    const allReplicatedTaskDuids = [
      ...unionOfAllSets(replicatedParents.map((e) => new Set([e.duid, ...dataStore.getDescendantDuids(e)]))),
    ];
    visualization.refresh({ tasks: dataStore.getTasksByDuidsOrdered(allReplicatedTaskDuids) });

    if (replicatedParents.length > 1) {
      return;
    }

    actions.visualization.navigateToTask(replicatedParents[0].duid);
  },
  trashTasks: () => {
    const appStore = useAppStore();
    const dataStore = useDataStore();
    const visualization = appStore.getBaseVisualization();

    const currents = actions.visualization.getCurrentRows();
    const tasks: Task[] = currents.map((e) => e.data).filter((e): e is Task => !!e);
    if (tasks.length === 0) {
      return;
    }

    const descendants = tasks.flatMap((e) => dataStore.getTasksByDuids(dataStore.getDescendantDuids(e)));

    // Delete empty tasks
    const tasksToTrash: Task[] = [];
    const tasksToDelete: Task[] = [];

    tasks.forEach((task) => {
      if (dataStore.isTaskEmpty(task)) {
        tasksToDelete.push(task);
      } else {
        tasksToTrash.push(task);
      }
    });

    dataStore.deleteTasks(tasksToDelete);

    dataStore.updateTasks(
      tasksToTrash.map((e) => ({
        duid: e.duid,
        inTrash: true,
      })),
      {
        makeDescription: (isActive, content) => `mov${isActive ? "ing" : "ed"} ${content} to the trash`,
      }
    );

    setTimeout(() => {
      visualization.refresh({ tasks: descendants, force: true });
    });
  },
  restoreTasks: () => {
    const dataStore = useDataStore();

    const currents = actions.visualization.getCurrentRows();
    const tasks = currents.map((e) => e.data).filter((e): e is Task => !!e);
    if (tasks.length === 0) {
      return;
    }

    dataStore.updateTasks(
      tasks.map((e) => ({
        duid: e.duid,
        inTrash: false,
      })),
      {
        makeDescription: (isActive, content) => `${isActive ? "restoring" : "restored"} ${content} from the trash`,
      }
    );
  },
  deleteTasks: () => {
    const dataStore = useDataStore();

    const tasks = actions.visualization
      .getCurrentRows()
      .map((e) => e.data)
      .filter((e): e is Task => !!e);
    if (tasks.length === 0) {
      return;
    }
    dataStore.deleteTasks(tasks);
  },
  moveTasksToDartboard: (dartboardDuid: string) => {
    const appStore = useAppStore();
    const dataStore = useDataStore();
    const visualization = appStore.getActiveVisualization();

    const dartboard = dataStore.getDartboardByDuid(dartboardDuid);
    if (!dartboard) {
      return;
    }

    const currents = actions.visualization.getCurrentRows();
    const tasks = currents.map((e) => e.data).filter((e): e is Task => !!e);
    if (tasks.length === 0) {
      return;
    }

    const otherDartboardTasks = dataStore.getTasksByDartboardDuidOrdered(dartboardDuid, {
      includeTrashed: true,
      includeDraft: true,
    });
    const firstOrder = otherDartboardTasks.length === 0 ? "" : otherDartboardTasks[0].order;
    const orders = getOrdersBetween(undefined, firstOrder, tasks.length);
    dataStore.updateTasks(
      tasks.map((e, i) => {
        const update: TaskUpdate = {
          duid: e.duid,
          dartboardDuid,
          order: orders[i],
        };
        if (e.inTrash) {
          update.inTrash = false;
        }
        return update;
      }),
      {
        makeDescription: (isActive, content) =>
          `mov${isActive ? "ing" : "ed"} ${content} to ${getPageDisplayName(dartboard, dataStore.getSpaceByDuid)}`,
      }
    );

    const descendants = dataStore.getTasksByDuids(tasks.flatMap((e) => dataStore.getDescendantDuids(e)));
    nextTick(() => {
      visualization.refresh({ tasks, force: true });
      visualization.refresh({ tasks: descendants });
    });
  },
  moveTasksToDartboardOrTrash: (pageDuid: string) => {
    const dataStore = useDataStore();
    if (pageDuid === dataStore.getViewByKind(ViewKind.TRASH)?.duid) {
      actions.visualization.trashTasks();
      return;
    }

    actions.visualization.moveTasksToDartboard(pageDuid);
  },
  createTaskUnderneath: async (
    partialTask?: Partial<Task>,
    options?: { makeSubtask?: boolean; preventStartEditing?: boolean }
  ) => {
    const appStore = useAppStore();
    const dataStore = useDataStore();
    const tenantStore = useTenantStore();
    const visualization = appStore.getActiveVisualization();

    const { makeSubtask = false, preventStartEditing = false } = options ?? {};

    const currents = actions.visualization.getCurrentRows();
    if (currents.length !== 1) {
      return undefined;
    }
    const task = currents[0].data;
    if (!task) {
      return undefined;
    }
    const taskRow = visualization.getRow(task.duid);
    if (!taskRow || taskRow.rowIndex === null) {
      return undefined;
    }

    const taskBelow = visualization.getRowByIndex(taskRow.rowIndex + 1)?.data;
    let orderOfTaskBelow = taskBelow?.order;

    const parentRelationship = dataStore.getRelationshipsByKindKind(task, RelationshipKindKind.PARENT_OF, false)[0];
    const parent = makeSubtask
      ? task
      : parentRelationship
        ? dataStore.getTaskByDuid(parentRelationship.targetDuid)
        : undefined;

    const partialTaskNorm: Partial<Task> = partialTask ?? {};
    if (parent) {
      partialTaskNorm.drafterDuid = parent.drafterDuid;

      if (taskBelow && parent.duid !== dataStore.getParentTask(taskBelow)?.duid) {
        orderOfTaskBelow = undefined;
      }
    }

    const orderOfNewTask = getOrdersBetween(task.order, orderOfTaskBelow)[0];

    let newTask: Task;
    if (parent && tenantStore.copyParentFieldsOnCreate) {
      partialTaskNorm.title = "";
      partialTaskNorm.description = makeEmptyLexicalState();
      newTask = (
        await dataStore.replicateTasks(
          [
            {
              task,
              order: orderOfNewTask,
              partialTask: partialTaskNorm,
            },
          ],
          { propertiesOnly: true, awaitBackend: true }
        )
      )[0];
    } else {
      if (parent) {
        partialTaskNorm.relationships = [{ ...parentRelationship, duid: makeDuid() }];
      }
      newTask = await dataStore.createTask(
        "",
        task.dartboardDuid,
        orderOfNewTask,
        TaskSourceType.APP_ENTER,
        partialTaskNorm
      );
    }

    setTimeout(() => {
      visualization.selectAndScrollTo(newTask.duid);
      if (preventStartEditing) {
        return;
      }
      visualization.editTitle(newTask.duid);
    });

    return newTask;
  },
  subtaskIndent: (partialTask?: Partial<Task>) => {
    const appStore = useAppStore();
    const dataStore = useDataStore();
    const visualization = appStore.getActiveVisualization();

    const currents = actions.visualization.getCurrentRows();
    if (currents.length !== 1) {
      return;
    }
    const task = currents[0].data;
    if (!task) {
      return;
    }

    const oldParent = dataStore.getParentTask(task);
    const siblings = visualization.getChildren(oldParent?.duid ?? null);
    const taskIndex = siblings.findIndex((e: Task) => e.duid === task.duid);
    if (taskIndex <= 0) {
      return;
    }

    const newParent = siblings[taskIndex - 1];
    const newSiblings = dataStore.getTasksByDuidsOrdered(
      dataStore.getRelationshipsByKindKind(newParent, RelationshipKindKind.PARENT_OF, true).map((e) => e.targetDuid)
    );
    const newOrder =
      newSiblings.length !== 0 ? getOrdersBetween(newSiblings[newSiblings.length - 1].order, undefined)[0] : task.order;

    dataStore.changeParent([{ task, order: newOrder, partialTask }], newParent);
  },
  subtaskOutdent: (partialTask?: Partial<Task>) => {
    const appStore = useAppStore();
    const dataStore = useDataStore();
    const visualization = appStore.getActiveVisualization();

    const currents = actions.visualization.getCurrentRows();
    if (currents.length !== 1) {
      return;
    }
    const task = currents[0].data;
    if (!task) {
      return;
    }
    const parent = dataStore.getParentTask(task);
    if (!parent) {
      return;
    }

    const grandparent = dataStore.getParentTask(parent);
    const newSiblings = visualization.getChildren(grandparent?.duid ?? null);
    const parentIndex = newSiblings.findIndex((e) => e.duid === parent.duid);
    const newOrder = getOrdersBetween(
      parent.order,
      parentIndex !== -1 ? newSiblings[parentIndex + 1]?.order : undefined,
      1
    )[0];

    dataStore.changeParent([{ task, order: newOrder, partialTask }], grandparent ?? null);
  },
  showFeedbackTooltipForTask: (
    taskDuids: string[],
    recommendationDuids: string[],
    editorMode: EditorMode,
    tooltipNode?: Element | null
  ) => {
    let htmlNode: Element | null = tooltipNode ?? null;
    const appStore = useAppStore();
    const visualization = appStore.getActiveVisualization();

    if (editorMode === EditorMode.CONTEXT_MENU) {
      // For the context menu we want the tooltip to show on the last selected task
      htmlNode = visualization.getHtml(taskDuids[taskDuids.length - 1]);
    }

    if (!htmlNode) {
      return;
    }
    appStore.showFeedbackTooltip(htmlNode, recommendationDuids);
  },
});
