<script setup lang="ts">
import { computed } from "vue";

import Button from "~/components/dumb/Button.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import Template from "~/components/dumb/Template.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { DEFAULT_DARTBOARD_LABEL } from "~/constants/dartboard";
import { DartboardIcon } from "~/icons";
import { ButtonSize, ButtonStyle, EditorMode, IconSize } from "~/shared/enums";
import { useDataStore } from "~/stores";
import { getPageDisplayName } from "~/utils/common";

const props = defineProps<{
  selectedDartboardDuid?: string;
  editorMode: EditorMode;
}>();

const dataStore = useDataStore();

const isChipMode = computed(
  () => props.editorMode === EditorMode.CHIP || props.editorMode === EditorMode.CHIP_RECOMMENDATION
);
const isListMode = computed(() => props.editorMode === EditorMode.LIST);
const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);

const page = computed(() =>
  props.selectedDartboardDuid ? dataStore.getDartboardByDuid(props.selectedDartboardDuid) : undefined
);
const isDefault = computed(() => isFormMode.value && !!props.selectedDartboardDuid && !page.value);
const title = computed(() =>
  isDefault.value ? DEFAULT_DARTBOARD_LABEL : getPageDisplayName(page.value, dataStore.getSpaceByDuid)
);
</script>

<template>
  <component :is="isBoardMode ? Tooltip : Template" text="Dartboard">
    <Button
      :btn-style="isTaskDetailMode ? ButtonStyle.SECONDARY : ButtonStyle.CHIP"
      :size="ButtonSize.CHIP"
      :text="title"
      :icon="isDefault ? DartboardIcon : PageIcon"
      :icon-args="isDefault ? undefined : { page, size: isBoardMode ? IconSize.S : undefined }"
      :icon-size="isBoardMode ? IconSize.XS : undefined"
      :disable-hover="isListMode || isFormMode || isBoardMode"
      :borderless="!isChipMode && !isBoardMode"
      :break-words="isTaskDetailMode"
      class="gap-2 text-md"
      a11y-label="Dartboard"
      :class="{
        'h-5 !gap-1 truncate !py-0 pl-1 pr-1.5 text-xs border-oncolor': isBoardMode,
        'truncate px-2.5': isListMode,
        'py-0.5': !isListMode,
        'max-w-xs truncate': isChipMode,
        'overflow-hidden pl-1.5 pr-7': isFormMode,
        'w-full !justify-start !px-2 py-[3px] text-left': isTaskDetailMode,
      }" />
  </component>
</template>
