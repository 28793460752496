<script setup lang="ts">
import { computed } from "vue";

import Template from "~/components/dumb/Template.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { PRIORITY_COLOR_MAP } from "~/constants/priority";
import { PriorityFieldIcon, PriorityIcon, XIcon } from "~/icons";
import { EditorMode, type Priority } from "~/shared/enums";
import type { Property } from "~/shared/types";

const props = defineProps<{
  value: Priority | null;
  showIfEmpty?: boolean;
  showBorder?: boolean;
  showIcon?: boolean;
  property?: Property;
  editorMode: EditorMode;
}>();

const emit = defineEmits<{
  accept: [];
  reject: [];
}>();

const isChipRecommendationMode = computed(() => props.editorMode === EditorMode.CHIP_RECOMMENDATION);
const isBaseChipMode = computed(() => props.editorMode === EditorMode.CHIP);
const isChipMode = computed(() => isBaseChipMode.value || isChipRecommendationMode.value);
const isDetailRecommendationMode = computed(() => props.editorMode === EditorMode.DETAIL_RECOMMENDATION);
const isTaskDetailMode = computed(() => props.editorMode === EditorMode.DETAIL || isDetailRecommendationMode.value);
const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);
const isRecommendationMode = computed(() => isChipRecommendationMode.value || isDetailRecommendationMode.value);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);

const rejectRecommendation = () => {
  emit("reject");
};
</script>

<template>
  <component :is="isBoardMode ? Tooltip : Template" v-if="value || showIfEmpty" :text="property?.title">
    <div
      class="flex select-none items-center justify-center rounded text-md"
      :class="{
        'border border-recommendation-base/50 bg-recommendation-base/30 hover:bg-recommendation-base/40':
          isChipRecommendationMode,
        'hover:bg-opposite/10': isBaseChipMode,
        'border border-oncolor': showBorder && !isChipRecommendationMode,
        'h-[26px] gap-1 py-0.5 text-sm': isChipMode,
        'gap-2': !isChipMode,
        'h-5 text-xs': !isChipMode && !isTaskDetailMode && !isFormMode,
        'px-2 text-sm hover:bg-lt': isFormMode || isTaskDetailMode,
        'px-1': !isFormMode && !isTaskDetailMode,
      }">
      <PriorityFieldIcon v-if="isChipMode && !value" class="icon-sm" />
      <PriorityIcon
        v-else-if="value && showIcon"
        :class="isChipMode || isFormMode ? 'icon-sm' : 'icon-xs'"
        :style="{
          color: PRIORITY_COLOR_MAP.get(value),
        }"
        aria-hidden="true" />
      <span :class="((isTaskDetailMode && !value) || (isFormMode && !value)) && 'text-vlt'">
        {{ value ?? (isChipMode ? "Priority" : isFormMode ? "Set priority" : "None") }}
      </span>
      <Tooltip v-if="isRecommendationMode" text="Clear AI recommendation">
        <span
          class="rounded hover:bg-recommendation-base/30 dark:hover:bg-recommendation-base/40"
          @click.stop="rejectRecommendation"
          @keydown.enter.stop="rejectRecommendation">
          <XIcon class="icon-xs" />
        </span>
      </Tooltip>
    </div>
  </component>
</template>
