<script setup lang="ts">
import equal from "deep-equal";
import { computed, onUnmounted, ref, watch } from "vue";

import actions from "~/actions";
import { backendOld } from "~/api";
import Animated from "~/components/dumb/Animated.vue";
import AvatarGroup from "~/components/dumb/AvatarGroup.vue";
import Modal from "~/components/dumb/Modal.vue";
import StatusIcon from "~/components/dumb/StatusIcon.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import FilterArea from "~/components/filters/FilterArea.vue";
import SearchItem from "~/components/filters/SearchModal/SearchItem.vue";
import { THROTTLE_MS } from "~/constants/app";
import { QUERY_PROPERTY_PSUEDO_DUID } from "~/constants/property";
import { SearchIcon, XIcon } from "~/icons";
import { EditorMode, ModalPosition, ModalWidth, ViewKind } from "~/shared/enums";
import type { Filter as FilterType, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore } from "~/stores";
import { deepCopy, makeDuid } from "~/utils/common";
import { ThrottleManager } from "~/utils/throttleManager";

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();

const userFilters = ref<FilterType[]>([]);
const filterRecommendations = ref<FilterType[]>([]);

const mergedFilters = computed(() =>
  [...userFilters.value].concat(
    filterRecommendations.value.filter((f) => !userFilters.value.some((uf) => uf.propertyDuid === f.propertyDuid))
  )
);

const queryRecommendation = ref<string>("");
const searchText = ref(appStore.search ?? "");

const recentSearches = computed(() =>
  appStore.recentSearchStack.filter((s) => s.text.toLowerCase().includes(searchText.value)).slice(0, 5)
);
const recentTasks = computed(() =>
  dataStore
    .getTasksByDuids(appStore.recentTaskStack)
    .filter((t) => Object.values(t).some((v) => v?.toString().toLowerCase().includes(searchText.value.toLowerCase())))
    .slice(0, 5)
);

const showUserFilters = computed(
  () => searchText.value.length > 0 || !appStore.areDefaultSearchFilters(userFilters.value)
);
const showFilterRecs = computed(
  () =>
    (queryRecommendation.value.length > 0 && queryRecommendation.value !== searchText.value) ||
    !appStore.areFiltersEqual(mergedFilters.value, userFilters.value)
);

const setFilter = (filter: FilterType) => {
  const index = userFilters.value.findIndex((e) => e.propertyDuid === filter.propertyDuid);

  userFilters.value[index === -1 ? userFilters.value.length : index] = filter;
};

const removeFilter = (propertyDuid: string) => {
  const index = userFilters.value.findIndex((e) => e.propertyDuid === propertyDuid);
  if (index === -1) {
    return;
  }
  userFilters.value.splice(index, 1);
};

const recommendFilters = async () => {
  if (!pageStore.isOnline) {
    return;
  }
  if (searchText.value.trim().length === 0) {
    queryRecommendation.value = "";
    filterRecommendations.value = [];
    return;
  }

  const res = await backendOld.recommendations.getFilters(searchText.value, true);
  const recommendations: (FilterType & { recommendationDuid: string })[] = res.data.items;

  let query = "";
  const index = recommendations.findIndex((e) => e.propertyDuid === QUERY_PROPERTY_PSUEDO_DUID);
  if (index !== -1) {
    query = recommendations[index].values[0] as string;
    recommendations.splice(index, 1);
  }

  filterRecommendations.value = recommendations;
  queryRecommendation.value = query;
};

const recommendFiltersManager = new ThrottleManager(recommendFilters, THROTTLE_MS);

const updateAndGoToSearch = (filters: FilterType[]) => {
  const searchView = dataStore.getViewByKind(ViewKind.SEARCH);
  const layout = dataStore.getLayoutByDuid(searchView.layoutDuid);
  if (!searchView || !layout) {
    return;
  }

  dataStore.updateLayout({
    duid: searchView.layoutDuid,
    filterGroup: {
      ...layout.filterGroup,
      filters,
    },
  });
  appStore.$router.push({ name: "search" });
};

const reset = () => {
  userFilters.value = deepCopy(appStore.defaultSearchFilters);
  filterRecommendations.value = [];
  searchText.value = "";
};

const saveSearchAndClose = (text: string, filters: FilterType[]) => {
  if (text.trim().length > 0 || filters.length > 0) {
    const recentSearchDuid = appStore.recentSearchStack.find((s) => s.text === text && equal(s.filters, filters))?.duid;
    if (recentSearchDuid) {
      appStore.removeRecentSearch(recentSearchDuid);
    }

    appStore.addRecentSearch({
      duid: makeDuid(),
      text,
      filters,
    });
  }

  reset();
  appStore.setSearch(text.trim() ?? null);
  updateAndGoToSearch(filters);
  appStore.setSearchModalOpen(false);
};

const handleRecentTaskClick = (task: Task) => {
  actions.visualization.navigateToTask(task.duid);
  appStore.setSearchModalOpen(false);
};

let indexOfFocusedItem = -1;
const searchInput = ref<HTMLInputElement | null>(null);

const recommendationItemRefs = new Map<number, HTMLButtonElement>();
const searchItemRefs = new Map<number, HTMLButtonElement>();
const taskItemRefs = new Map<number, HTMLButtonElement>();

const assignItemRef = (idx: number, elem: HTMLButtonElement | null, map: Map<number, HTMLButtonElement>) => {
  if (!elem) {
    return;
  }

  map.set(idx, elem);
};

const moveUpOrDown = (isUp: boolean): boolean => {
  const recommendationSize = recommendationItemRefs.size;
  const searchSize = recentSearches.value.length;
  const taskSize = recentTasks.value.length;

  const totalItemSize = recommendationSize + searchSize + taskSize;
  if ((isUp && indexOfFocusedItem === -1) || (!isUp && indexOfFocusedItem === totalItemSize - 1)) {
    return false;
  }
  indexOfFocusedItem += isUp ? -1 : 1;
  if (indexOfFocusedItem === -1) {
    searchInput.value?.focus();
    return true;
  }

  let element: HTMLButtonElement | undefined;
  if (indexOfFocusedItem < recommendationSize) {
    element = recommendationItemRefs.get(indexOfFocusedItem);
  } else if (indexOfFocusedItem < recommendationSize + searchSize) {
    element = recommendationItemRefs.get(indexOfFocusedItem - recommendationSize);
  } else {
    element = taskItemRefs.get(indexOfFocusedItem - recommendationSize - searchSize);
  }
  element?.focus();

  return true;
};

const closeModal = () => {
  appStore.setSearchModalOpen(false);
};

const onKeydown = (event: KeyboardEvent) => {
  const { key } = event;
  switch (key) {
    case "Enter":
    case "Shift":
    case "Meta":
    case "Control":
    case "Option":
    case "Alt": {
      break;
    }
    case "ArrowUp":
    case "ArrowDown": {
      const success = moveUpOrDown(key === "ArrowUp");
      if (success) {
        event.preventDefault();
      }
      break;
    }
    case "Tab": {
      event.preventDefault();
      moveUpOrDown(event.shiftKey);
      break;
    }
    case "Escape": {
      closeModal();
      break;
    }
    default: {
      break;
    }
  }
};

watch(
  () => appStore.searchModalOpen,
  (newValue) => {
    if (!newValue) {
      recommendFiltersManager.cancel();
      return;
    }

    setTimeout(() => {
      searchInput.value?.focus();
    }, 100);

    if (appStore.currentPage?.kind === ViewKind.SEARCH) {
      userFilters.value = deepCopy(appStore.filters);
      searchText.value = appStore.search ?? "";
      // Don't recommend filters if we just opened the search modal, from the search page
      recommendFiltersManager.cancel();
      return;
    }

    reset();
  }
);

onUnmounted(() => {
  recommendFiltersManager.destroy();
});
</script>

<template>
  <Modal
    :entity="appStore.searchModalOpen"
    title="Search"
    hide-title
    :width="ModalWidth.XL"
    :position="ModalPosition.TOP"
    custom-styles="!p-0"
    @close="closeModal">
    <div tabindex="0" class="mb-4 mt-2 flex w-full flex-col focus-ring-none" @keydown.stop="onKeydown">
      <div class="mx-5 flex flex-col flex-wrap gap-3">
        <div class="flex w-full items-center">
          <SearchIcon class="text-lt icon-md" aria-hidden="true" />
          <input
            ref="searchInput"
            v-model="searchText"
            placeholder="Search"
            class="w-full border-0 bg-transparent pr-4 text-md focus-ring-none placeholder:text-vlt"
            @keydown.enter="saveSearchAndClose(searchText, userFilters)"
            @input="recommendFiltersManager.run()" />
        </div>

        <FilterArea
          class="w-full !border-0"
          is-search-mode
          :filters="userFilters"
          @set-filter="setFilter"
          @remove-filter="removeFilter" />

        <!-- Divider -->
        <hr
          v-if="recentSearches.length > 0 || recentTasks.length > 0 || showUserFilters || showFilterRecs"
          class="-ml-5 w-[800px] border-lt" />
      </div>

      <!-- Search options area. Only rendered when there are options -->
      <div v-if="showUserFilters || showFilterRecs" class="mt-3 flex w-full flex-col gap-2">
        <!-- User search -->
        <SearchItem
          v-if="showUserFilters"
          :filters="userFilters"
          :query="searchText"
          @save="saveSearchAndClose(searchText, userFilters)" />

        <!-- Recommended search -->
        <SearchItem
          v-if="showFilterRecs"
          :filters="mergedFilters"
          :query="queryRecommendation"
          is-ai
          @save="saveSearchAndClose(queryRecommendation, mergedFilters)" />
      </div>

      <!-- Recent searches -->
      <Animated
        v-if="recentSearches.length > 0"
        class="flex flex-col gap-1"
        :class="showUserFilters || showFilterRecs ? 'pt-5' : 'pt-3'">
        <span class="ml-5 select-none text-xs text-lt">Recent searches</span>

        <SearchItem
          v-for="(search, i) in recentSearches"
          :key="search.duid"
          :ref="(elem) => assignItemRef(i, elem as HTMLButtonElement, searchItemRefs)"
          :filters="search.filters"
          :query="search.text"
          is-recent-search-mode
          @save="saveSearchAndClose(search.text, search.filters)"
          @remove="appStore.removeRecentSearch(search.duid)" />
      </Animated>

      <!-- Recent Tasks -->
      <Animated v-if="recentTasks.length > 0" class="flex w-full flex-col gap-1 pt-5">
        <span class="ml-5 select-none text-xs text-lt">Recent tasks</span>
        <button
          v-for="(task, idx) in recentTasks"
          :key="task?.duid"
          :ref="(elem) => assignItemRef(idx, elem as HTMLButtonElement, taskItemRefs)"
          type="button"
          class="group/recent-task flex w-full items-center justify-between py-1 pl-5 pr-3 text-md focus-ring-none hover:bg-lt focus:bg-lt"
          @click="handleRecentTaskClick(task)">
          <div class="flex w-full items-center justify-between gap-2.5 truncate pr-1">
            <div class="pointer-events-none truncate break-words text-left text-sm">{{ task.title }}</div>
            <div class="flex items-center gap-2.5">
              <div v-if="tenantStore.assigneeEnabled" class="flex w-[60px] shrink-0 items-center justify-center">
                <AvatarGroup
                  :ai="tenantStore.aiAssignmentEnabled && task.assignedToAi"
                  show-ai-working
                  :duids="task.assigneeDuids"
                  :limit="3"
                  :first-user-only="!tenantStore.multipleAssigneesEnabled"
                  :editor-mode="EditorMode.TCM" />
              </div>
              <StatusIcon :duid="task.statusDuid" class="icon-md" />
            </div>
          </div>

          <Tooltip text="Remove from recent tasks" class="opacity-0 group-hover/recent-task:opacity-100">
            <button
              type="button"
              class="rounded p-0.5 focus-ring-none hover:bg-md"
              @click.stop="appStore.removeRecentTask(task.duid)">
              <XIcon class="icon-xs" />
            </button>
          </Tooltip>
        </button>
      </Animated>
    </div>
  </Modal>
</template>
