import { RECOMMENDATION_COLOR } from "~/constants/style";
import { EventActor, TaskDetailMode, Theme, UserRole, UserStatus } from "~/shared/enums";
import type { User } from "~/shared/types";

const STANDARD_PSEUDO_USER_PARTIAL = {
  status: UserStatus.ACTIVE,
  role: UserRole.MEMBER,
  theme: Theme.SYSTEM_DEFAULT,
  sections: { expanded: [], collapsed: [] },
  layout: { rightbarWidthPx: 650, leftbarWidthPx: 225, fullscreenRightbarWidthPx: 600 },
  imageUrl: null,
  tutorialStatusMap: new Map(),
  isAdmin: false,
  authToken: null,
  googleData: null,
};

export const DART_AI_PSEUDO_USER_KEY = "@DartAI";
export const DART_AI_DISPLAY_VALUE = EventActor.DART_AI;
export const DART_AI_PSEUDO_USER: User = {
  duid: DART_AI_PSEUDO_USER_KEY,
  email: DART_AI_PSEUDO_USER_KEY,
  name: DART_AI_DISPLAY_VALUE,
  abrev: "DA",
  colorHex: RECOMMENDATION_COLOR,
  taskDetailMode: TaskDetailMode.RIGHTBAR,
  ...STANDARD_PSEUDO_USER_PARTIAL,
};

export const DART_AGENT_HANDLE = "dart";
export const FIGMA_AGENT_HANDLE = "figma";
export const DEVIN_AGENT_HANDLE = "devin";
export const GITHUB_AGENT_HANDLE = "github";
