<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.675 11.732C12.675 11.9656 12.675 12.0824 12.6283 12.1382C12.5877 12.1866 12.5266 12.213 12.4636 12.2093C12.391 12.2052 12.3059 12.1252 12.1358 11.9651L11.1269 11.016C11.034 10.9286 10.9876 10.885 10.9544 10.8332C10.9249 10.7874 10.9031 10.737 10.8899 10.6841C10.875 10.6245 10.875 10.5607 10.875 10.4333L10.875 6.41953L9.26427 7.99874C8.90934 8.34671 8.33952 8.34108 7.99154 7.98615C7.64356 7.63123 7.64919 7.06139 8.00412 6.71342L11.1449 3.63411C11.4974 3.28857 12.0624 3.29136 12.4114 3.64037L18.4114 9.64037C18.7629 9.99184 18.7629 10.5617 18.4114 10.9132C18.0599 11.2646 17.4901 11.2646 17.1386 10.9132L12.675 6.44956L12.675 11.732Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5529 21.5232C11.0559 21.5232 10.6529 21.1203 10.6529 20.6232L10.6529 14.535C10.6529 14.038 11.0559 13.635 11.5529 13.635C12.05 13.635 12.4529 14.038 12.4529 14.535L12.4529 20.6232C12.4529 21.1203 12.05 21.5232 11.5529 21.5232Z"
      fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4062 21.0515C18.0556 21.4038 17.4858 21.4052 17.1334 21.0546L4.69014 8.67161C4.33782 8.321 4.33643 7.75115 4.68705 7.39882C5.03767 7.0465 5.60751 7.04511 5.95984 7.39573L18.4031 19.7787C18.7554 20.1293 18.7568 20.6992 18.4062 21.0515Z"
      fill="currentColor" />
  </svg>
</template>
