<script setup lang="ts">
import { type EditorState, INSERT_PARAGRAPH_COMMAND, type LexicalEditor } from "lexical";
import {
  LexicalAutoLinkPlugin,
  LexicalCheckListPlugin,
  LexicalLinkPlugin,
  LexicalListPlugin,
  LexicalMarkdownShortcutPlugin,
  LexicalTabIndentationPlugin,
} from "lexical-vue";
import { computed, nextTick, ref, watch } from "vue";

import Button from "~/components/dumb/Button.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { SendIcon } from "~/icons";
import { ButtonSize, ButtonStyle, CommandId } from "~/shared/enums";
import type { Comment, Task } from "~/shared/types";
import { useDataStore } from "~/stores";
import { areLexicalStatesSame } from "~/utils/common";

import { TASK_DETAIL_TELEPORT_KEY, URL_MATCHERS } from "./const";
import AttachmentPlugin from "./plugins/AttachmentPlugin.vue";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin.vue";
import CodeSelectorPlugin from "./plugins/CodeSelectorPlugin.vue";
import CommentSavePlugin from "./plugins/CommentSavePlugin.vue";
import EnterPlugin from "./plugins/EnterPlugin.vue";
import FloatingToolbar from "./plugins/FloatingToolbar.vue";
import ManageTextFormatPlugin from "./plugins/ManageTextFormatPlugin.vue";
import OnChangePlugin from "./plugins/OnChangePlugin.vue";
import PasteUrlPlugin from "./plugins/PasteUrlPlugin.vue";
import PlaceholderPlugin from "./plugins/PlaceholderPlugin.vue";
import ShortcutsPlugin from "./plugins/ShortcutsPlugin.vue";
import SubscribeEventPlugin from "./plugins/SubscribeEventPlugin.vue";
import { EntityTypeaheadPlugin } from "./plugins/typeahead";
import TextEditor from "./TextEditor.vue";
import { TRANSFORMERS } from "./transformers";
import { PARTIAL_DART_EDITOR_NODES } from "./utils";

const props = defineProps<{
  task: Task;
  editing?: boolean;
  comment?: Comment;
  threadComment?: Comment;
  editorClasses?: string;
}>();

const emit = defineEmits<{
  endEdit: [];
  editLast: [];
  delete: [];
  afterCreate: [];
}>();

const dataStore = useDataStore();

const textEditor = ref<InstanceType<typeof TextEditor> | null>(null);
const savePlugin = ref<InstanceType<typeof CommentSavePlugin> | null>(null);

const initialState = computed(() => props.comment?.text);
const isComposer = computed(() => !props.comment && !props.editing);
const isEditable = computed(() => props.editing || isComposer.value);
const inThread = computed(() => !!props.threadComment);
const namespace = computed(
  () =>
    `comment-${isEditable.value}-${props.task.duid}-${props.comment?.duid ?? props.threadComment?.duid ?? "composer"}`
);

const isEmpty = computed(() => savePlugin?.value?.isEmpty);

const onEditorChange = (newEditorState: EditorState) => {
  const value = newEditorState.toJSON();

  // If the comment is not a draft and the text has changed (checkbox), edit the comment
  if (props.comment && !props.comment.isDraft && !props.editing && !areLexicalStatesSame(value, props.comment.text)) {
    dataStore.updateComment(props.comment.duid, value);
  }
};

watch(
  () => props.comment?.text,
  (newText) => {
    if (!newText) {
      return;
    }
    textEditor.value?.setEditorState(newText);
  }
);

const focus = () => {
  textEditor.value?.focus();
};

const saveComment = () => {
  savePlugin.value?.save();
  nextTick(focus);
  emit("afterCreate");
};
const saveEdit = () => {
  if (!savePlugin.value?.saveEdit()) {
    emit("delete");
    return;
  }
  emit("endEdit");
};
const cancelEdit = () => {
  savePlugin.value?.cancelEdit();
  emit("endEdit");
};

const onEnter = (event: KeyboardEvent, editor: LexicalEditor) => {
  if (event.shiftKey) {
    editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND, undefined);
    return;
  }
  if (!!props.comment && props.editing) {
    saveEdit();
    return;
  }
  if (isComposer.value) {
    // TODO figure out why this timeout is needed to not duplicate comments
    setTimeout(() => saveComment());
  }
};

defineExpose({
  focus,
});
</script>

<template>
  <TextEditor
    ref="textEditor"
    :namespace="namespace"
    :nodes="PARTIAL_DART_EDITOR_NODES"
    :initial-state="initialState"
    :placeholder="`Add a ${inThread ? 'reply' : 'comment'}`"
    :disabled="!isEditable"
    extra-rounded
    :top-margin-px="isEditable ? 2 : undefined"
    :left-margin-px="!isEditable ? 0 : undefined"
    :max-height-px="isEditable ? 300 : undefined"
    :always-borderless="!isEditable"
    :editor-classes="editorClasses">
    <template #default>
      <!-- lexical-vue plugins -->
      <LexicalAutoLinkPlugin :matchers="URL_MATCHERS" />
      <LexicalCheckListPlugin />
      <LexicalLinkPlugin />
      <LexicalListPlugin />
      <LexicalMarkdownShortcutPlugin :transformers="TRANSFORMERS" />
      <LexicalTabIndentationPlugin />
      <!-- custom plugins -->
      <AttachmentPlugin />
      <CodeHighlightPlugin />
      <CodeSelectorPlugin :editable="isEditable" />
      <CommentSavePlugin
        v-if="(!!comment && editing) || isComposer"
        ref="savePlugin"
        :task="task"
        :thread-comment="threadComment"
        :comment="comment"
        :editing="!!editing"
        :has-focus="!!textEditor?.hasFocus" />
      <EnterPlugin @enter="onEnter" />
      <EntityTypeaheadPlugin mentions-only subscribe-mentions-separately />
      <FloatingToolbar v-if="isEditable" :teleport-key="TASK_DETAIL_TELEPORT_KEY" hide-ai-action-button />
      <ManageTextFormatPlugin />
      <OnChangePlugin :has-focus="!!textEditor?.hasFocus" @change="onEditorChange" />
      <PasteUrlPlugin />
      <PlaceholderPlugin v-if="isEditable" no-ai />
      <!-- TODO add a version of this that only makes the relationships on comment save, not when they are added to the body -->
      <!-- <RelationshipPlugin :task="task" /> -->
      <ShortcutsPlugin @edit-last="emit('editLast')" />
      <SubscribeEventPlugin :task="task" />
    </template>
    <template v-if="(isComposer && !isEmpty) || (!!comment && editing)" #after>
      <div
        class="flex w-full cursor-text items-center justify-end gap-2 pb-2.5 pr-2.5 pt-1"
        @click="focus"
        @keydown.enter="focus">
        <Tooltip v-if="isComposer" :disabled="isEmpty" :command-id="CommandId.SAVE_COMMENT">
          <Button
            :btn-style="ButtonStyle.PRIMARY"
            :icon="SendIcon"
            :size="ButtonSize.SMALL"
            a11y-label="Send a comment"
            @click="saveComment" />
        </Tooltip>
        <template v-else>
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            text="Cancel"
            :size="ButtonSize.SMALL"
            is-contrast
            @click="cancelEdit" />
          <Button :btn-style="ButtonStyle.PRIMARY" text="Save" :size="ButtonSize.SMALL" @click="saveEdit" />
        </template>
      </div>
    </template>
  </TextEditor>
</template>
