<script setup lang="ts">
import moment from "moment";
import { computed } from "vue";

import { getPropertyValueFromTask } from "~/common/properties";
import { ChartAggregation } from "~/shared/enums";
import type { StatisticConfigNumber, Task, TimeTrackingEntry } from "~/shared/types";
import { useDataStore } from "~/stores";
import { getDurationText, getTimeTrackingDuration } from "~/utils/time";

import { TIME_TRACKING_KINDS } from "../common";

const props = defineProps<{
  tasks: Task[];
  config: StatisticConfigNumber;
}>();

const dataStore = useDataStore();

const property = computed(() =>
  props.config.adtl.propertyDuid ? dataStore.getPropertyByDuid(props.config.adtl.propertyDuid) : null
);
const isTimeTracking = computed(() => property.value && TIME_TRACKING_KINDS.has(property.value.kind));

const value = computed<null | number>(() => {
  const { aggregation, propertyDuid } = props.config.adtl;
  if (!property.value) {
    return propertyDuid === null ? props.tasks.length : null;
  }

  switch (true) {
    case aggregation === ChartAggregation.SUM: {
      return props.tasks.reduce((acc, task) => {
        if (!property.value) {
          return acc;
        }
        const taskValue = getPropertyValueFromTask(property.value, task);
        if (isTimeTracking.value) {
          return acc + getTimeTrackingDuration(taskValue as TimeTrackingEntry[]).asSeconds();
        }

        return acc + (typeof taskValue === "number" ? taskValue : 0);
      }, 0);
    }
    case aggregation === ChartAggregation.AVG: {
      return (
        props.tasks.reduce((acc, task) => {
          if (!property.value) {
            return acc;
          }
          const taskValue = getPropertyValueFromTask(property.value, task);
          if (isTimeTracking.value) {
            return acc + getTimeTrackingDuration(taskValue as TimeTrackingEntry[]).asSeconds();
          }

          return acc + (typeof taskValue === "number" ? taskValue : 0);
        }, 0) / props.tasks.length
      );
    }
    case aggregation === ChartAggregation.COUNT: {
      return props.tasks.length;
    }
    default: {
      return null;
    }
  }
});
const valueNorm = computed(() =>
  value.value === null
    ? "-"
    : isTimeTracking.value && props.config.adtl.aggregation !== ChartAggregation.COUNT
      ? getDurationText(moment.duration(value.value, "seconds"))
      : value.value.toFixed(0)
);
</script>

<template>
  <div class="flex size-full grow select-none items-center justify-center overflow-hidden">
    <svg width="100%" height="100%" :viewBox="`0 0 ${valueNorm.length * 10} 20`" preserveAspectRatio="xMidYMid meet">
      <text
        class="fill-gray-700 font-semibold dark:fill-zinc-300"
        x="50%"
        y="55%"
        dominant-baseline="middle"
        text-anchor="middle">
        {{ valueNorm }}
      </text>
    </svg>
  </div>
</template>
