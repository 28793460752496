<script setup lang="ts">
import { Tooltip, type TriggerEvent } from "floating-vue";
import { type Component, computed, ref } from "vue";

import ShortcutStrokes from "~/components/dumb/ShortcutStrokes.vue";
import { COMMANDS_MAP, getStrokesForPlatform } from "~/constants/command";
import { type CommandId, Placement } from "~/shared/enums";
import { usePageStore } from "~/stores";

const props = defineProps<{
  disabled?: boolean;
  placement?: Placement;
  commandId?: CommandId;
  commandIds?: CommandId[];
  hideStrokes?: boolean;
  text?: string[] | string;
  component?: Component;
  componentArgs?: object;
  block?: boolean;
  heightBlock?: boolean;
  showDelay?: number;
  info?: boolean;
  disableClickHide?: boolean;
  skidding?: number;
  referenceNode?: Component;
  sections?: { title: string; items: string[] }[];
}>();

const pageStore = usePageStore();

const tooltip = ref<InstanceType<typeof Tooltip> | null>(null);

const content = computed(() => (props.text === undefined ? [] : Array.isArray(props.text) ? props.text : [props.text]));
const commands = computed(() => [
  ...(props.commandId === undefined ? [] : [props.commandId]),
  ...(props.commandIds ?? []),
]);

const placementDefinite = computed(() => props.placement ?? Placement.BOTTOM);

const show = () => {
  if (props.disabled) {
    return;
  }

  tooltip.value?.show();
};

const hide = () => {
  tooltip.value?.hide();
};

defineExpose({
  show,
  hide,
});
</script>

<template>
  <Tooltip
    ref="tooltip"
    :reference-node="() => referenceNode as Element"
    :disabled="disabled || pageStore.isMobile"
    :placement="placementDefinite"
    :skidding="skidding"
    :hide-triggers="
      (events: TriggerEvent[]) => [
        ...(disableClickHide ? events.filter((x) => x !== 'click') : events),
        ...(info || disableClickHide ? [] : (['click'] satisfies TriggerEvent[])),
      ]
    "
    :popper-triggers="info ? ['hover'] : []"
    :delay="{ show: showDelay ?? 500, hide: 0 }"
    :theme="`tooltip-${pageStore.theme}`"
    class="flex"
    :class="{
      'max-w-full grow': block,
      'h-full': heightBlock,
    }">
    <slot />

    <template #popper>
      <div
        class="flex gap-1 rounded border px-1.5 py-1 text-xs bg-lt text-lt border-md"
        :class="info ? 'max-w-[240px]' : 'max-w-xs'">
        <div v-if="commands.length || content.length" class="flex w-full flex-col gap-1">
          <div v-for="(command, index) in commands" :key="index" class="flex w-full items-center justify-between gap-2">
            {{ COMMANDS_MAP.get(command)?.title }}
            <ShortcutStrokes v-if="!hideStrokes" :strokes="getStrokesForPlatform(command, pageStore.isMac)" />
          </div>
          <span v-for="(line, index) in content" :key="index" class="min-h-[16px] hyphens-auto break-words">
            {{ line }}
          </span>
        </div>
        <div v-else-if="sections" class="flex w-full flex-col gap-3 px-1">
          <div
            v-for="(section, index) in sections"
            :key="index"
            class="flex w-full select-none flex-col justify-between gap-1 text-xs">
            <div class="font-semibold text-vlt">{{ section.title }}</div>
            <ul class="flex list-inside list-disc flex-col gap-1">
              <li v-for="(line, itemIndex) in section.items" :key="itemIndex" class="hyphens-auto break-words">
                {{ line }}
              </li>
            </ul>
          </div>
        </div>
        <component :is="component" v-if="component" v-bind="componentArgs" />
      </div>
    </template>
  </Tooltip>
</template>

<style>
.v-popper__popper {
  z-index: 10010;
}
.v-popper--theme-tooltip .v-popper__wrapper {
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.v-popper--theme-tooltip .v-popper__inner {
  padding: 0px;
  border-radius: 4px;
  background-color: transparent;
}
.v-popper--theme-tooltip-light .v-popper__arrow-outer {
  border-color: #e5e7eb;
}
.v-popper--theme-tooltip-dark .v-popper__arrow-outer {
  border-color: #3f3f46;
}
</style>
