import moment from "moment";
import { type Ref, ref, watch } from "vue";

import type { IntervalUnit } from "~/shared/types";

import { BASE_RANGE_DEFINITION, RANGE_DEFINITION_STOPS } from "./constants";
import type { Interval, RangeDefinition, RoadmapConfig } from "./shared";

const NOW_REFRESH_MS = 1000 * 60;

export const getDefinition = (pxPerDay: number): RangeDefinition => {
  let res = { ...BASE_RANGE_DEFINITION };
  RANGE_DEFINITION_STOPS.forEach(([stop, definition]) => {
    if (pxPerDay <= stop) {
      res = { ...res, ...definition };
    }
  });
  return res;
};

export const getRangeOrigin = (intervalUnit: IntervalUnit): string =>
  moment().startOf(intervalUnit).startOf("day").toISOString();

export const getDiffPx = (roadmapConfig: RoadmapConfig, start: string, end: string, precise = false): number =>
  moment(end).diff(moment(start), "day", precise) * roadmapConfig.pxPerDay;

export const getIntervalPx = (roadmapConfig: RoadmapConfig, date: string, interval: Interval): number =>
  getDiffPx(roadmapConfig, date, moment(date).add(interval[0], interval[1]).toISOString());

export const getPixelsTo = (roadmapConfig: RoadmapConfig, date: string, precise = false): number =>
  getDiffPx(roadmapConfig, roadmapConfig.startDate, date, precise);

/* Get amount pixels from start of range to today */
export const getPixelsToNow = (roadmapConfig: RoadmapConfig): number =>
  getPixelsTo(roadmapConfig, moment().toISOString(), true);

/* Create array with start N units + end N units, around current date. With interval and interval units */
export const getDatesForInterval = (roadmapConfig: RoadmapConfig, interval: Interval): string[] => {
  const startDate = moment(roadmapConfig.startDate);
  const endDate = moment(roadmapConfig.endDate);
  const [intervalAmount, intervalUnit] = interval;

  const res = [getRangeOrigin(intervalUnit)];

  /* Units before start unit */
  for (let i = 0; i < 10000; i += 1) {
    const next = moment(res[0]).subtract(intervalAmount, intervalUnit);
    if (next.isSameOrBefore(startDate, "day")) {
      break;
    }
    res.unshift(next.toISOString());
  }

  /* Units after start unit */
  for (let i = 0; i < 10000; i += 1) {
    const next = moment(res[res.length - 1]).add(intervalAmount, intervalUnit);
    if (next.isAfter(endDate, "day")) {
      break;
    }
    res.push(next.toISOString());
  }

  return res;
};

export const makeTimeForPixelsToNowRef = (
  roadmapConfig: Ref<RoadmapConfig>
): Ref<{ pixels: number; destroy: () => void }> => {
  let timeout: ReturnType<typeof setTimeout> | undefined;

  const res = ref({
    pixels: 0,
    destroy: () => {
      clearTimeout(timeout);
    },
  });

  const resetValuesAndTimeout = () => {
    clearTimeout(timeout);

    res.value.pixels = getPixelsToNow(roadmapConfig.value);
    timeout = setTimeout(resetValuesAndTimeout, NOW_REFRESH_MS);
  };

  resetValuesAndTimeout();

  watch(() => roadmapConfig.value, resetValuesAndTimeout, { deep: true });

  return res;
};
