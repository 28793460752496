<script setup lang="ts">
import { capitalize, type Component, computed } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import Avatar from "~/components/dumb/Avatar.vue";
import StatusIcon from "~/components/dumb/StatusIcon.vue";
import TaskOrDocPreviewTooltip from "~/components/dumb/TaskOrDocPreviewTooltip.vue";
import PageLink from "~/components/text/plugins/PageLink.vue";
import { COMPLETED_STATUS_KINDS } from "~/components/visualization/constants";
import { DART_AI_PSEUDO_USER, DART_AI_PSEUDO_USER_KEY } from "~/constants/user";
import {
  CalendarIcon,
  CommentIcon,
  DartIcon,
  FormIcon,
  GithubIcon,
  MailIcon,
  PropertiesIcon,
  RecurrenceIcon,
  ReminderIcon,
  ReportsIcon,
  SlackIcon,
} from "~/icons";
import { EntityName, EventActor } from "~/shared/enums";
import type { Page } from "~/shared/types";
import { useDataStore, useTenantStore } from "~/stores";
import { getDocLink, getPageLink } from "~/utils/common";

const ENTITY_NAMES_WITH_PAGE_ICON = new Set([
  EntityName.DARTBOARD,
  EntityName.VIEW,
  EntityName.SPACE,
  EntityName.FOLDER,
  EntityName.FORM,
  EntityName.BRAINSTORM,
]);

const EVENT_ACTORS = new Set<string>(Object.values(EventActor));
const EVENT_ACTOR_TO_ICON = new Map([
  [EventActor.DART_DUE_DATE_BOT, CalendarIcon],
  [EventActor.DART_GITHUB_BOT, GithubIcon],
  [EventActor.DART_RECURRING_TASK_BOT, RecurrenceIcon],
  [EventActor.DART_REMINDER_BOT, ReminderIcon],
  [EventActor.DART_REPORT_BOT, ReportsIcon],
  [EventActor.DART_SLACK_BOT, SlackIcon],
  [EventActor.FORM_USER, FormIcon],
  [EventActor.EMAIL_USER, MailIcon],
]);
const EVENT_NAME_TO_ICON = new Map<string, Component>([
  [EntityName.COMMENT, CommentIcon],
  [EntityName.PROPERTY, PropertiesIcon],
  [EntityName.STATUS, StatusIcon],
  [EntityName.TENANT, Avatar],
]);

const props = defineProps<{
  name: EntityName;
  link?: string;
  duid?: string;
  isNotificationMode?: boolean;
}>();

const emit = defineEmits<{
  open: [];
}>();

const router = useRouter();
const dataStore = useDataStore();
const tenantStore = useTenantStore();

const entity = computed(() => (props.duid ? dataStore.getEntity(props.duid, props.name) : undefined));

const task = computed(() =>
  props.name === EntityName.TASK && props.duid ? dataStore.getTaskByDuid(props.duid) : undefined
);
const isCompletedTask = computed(() => {
  if (!task.value) {
    return undefined;
  }
  const statusKind = dataStore.getStatusByDuid(task.value.statusDuid)?.kind;
  if (!statusKind) {
    return undefined;
  }
  return COMPLETED_STATUS_KINDS.has(statusKind);
});

const doc = computed(() =>
  props.name === EntityName.DOC && props.duid ? dataStore.getDocByDuid(props.duid) : undefined
);

const user = computed(() => {
  if (props.name !== EntityName.USER || !props.duid) {
    return undefined;
  }

  if (props.duid === EventActor.DART_AI) {
    return DART_AI_PSEUDO_USER;
  }

  return dataStore.getUserByDuid(props.duid);
});

const otherEntityTitle = computed(
  () =>
    entity.value?.title ??
    (props.name === EntityName.USER && props.duid && EVENT_ACTORS.has(props.duid)
      ? props.duid
      : props.name !== EntityName.UNKNOWN
        ? capitalize(props.name)
        : "Dart")
);
const otherEntityIcon = computed(() => {
  if (props.name === EntityName.USER) {
    return EVENT_ACTOR_TO_ICON.get(props.duid as EventActor) ?? DartIcon;
  }
  return EVENT_NAME_TO_ICON.get(props.name) ?? DartIcon;
});
const otherEntityIconArgs = computed(() => {
  if (props.name === EntityName.PROPERTY || props.name === EntityName.STATUS) {
    return { duid: props.duid };
  }
  if (props.name === EntityName.TENANT && entity.value) {
    return {
      abrev: tenantStore.name[0] ?? "D",
      imageUrl: tenantStore.imageUrl,
      class: "icon-sm",
    };
  }
  return {};
});

const clickTask = () => {
  if (props.isNotificationMode) {
    emit("open");
  }

  if (!task.value) {
    return;
  }

  actions.visualization.navigateToTask(task.value.duid);
};

const clickOther = () => {
  if (props.isNotificationMode && props.name === EntityName.COMMENT) {
    emit("open");
  }

  if (props.link) {
    router.push(new URL(props.link).pathname);
    return;
  }
  if (!entity.value) {
    return;
  }

  const link = dataStore.getEntityLink(entity.value, props.name);
  if (link) {
    router.push(link);
  }
};
</script>

<template>
  <TaskOrDocPreviewTooltip v-if="task" :task="task" class="!inline-flex align-middle">
    <PageLink
      :title="task.title"
      :icon="!isNotificationMode ? StatusIcon : undefined"
      :icon-args="!isNotificationMode ? { duid: task.statusDuid } : undefined"
      :strike-through="isCompletedTask"
      :is-notification-mode="isNotificationMode"
      @click="clickTask" />
  </TaskOrDocPreviewTooltip>

  <TaskOrDocPreviewTooltip v-else-if="doc" :doc="doc" class="!inline-flex align-middle">
    <PageLink
      :is-notification-mode="isNotificationMode"
      :page="doc"
      :title="doc.title"
      @click="router.push(getDocLink(doc))" />
  </TaskOrDocPreviewTooltip>

  <PageLink
    v-else-if="user"
    :title="user.name || user.email"
    :icon="Avatar"
    :icon-args="{
      abrev: user.abrev,
      circle: true,
      colorHex: user.colorHex,
      isAi: user.duid === DART_AI_PSEUDO_USER_KEY,
      imageUrl: user.imageUrl,
      imgBorder: true,
      class: 'icon-sm',
    }"
    :is-notification-mode="isNotificationMode"
    class="!inline-flex align-middle"
    @click="clickOther" />

  <PageLink
    v-else-if="ENTITY_NAMES_WITH_PAGE_ICON.has(name) && entity"
    :title="entity.title"
    :page="entity as Page"
    :is-notification-mode="isNotificationMode"
    class="!inline-flex align-middle"
    @click="router.push(getPageLink(entity as Page, dataStore.getSpaceByDuid))" />

  <PageLink
    v-else
    :title="otherEntityTitle"
    :icon="otherEntityIcon"
    :icon-args="otherEntityIconArgs"
    :is-notification-mode="isNotificationMode"
    class="!inline-flex align-middle"
    @click="clickOther" />
</template>
