<script setup lang="ts">
import { usePrevious } from "@vueuse/core";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import { getPropertyConfig, getShownPropertyWithConfigList } from "~/common/properties";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Input from "~/components/dumb/Input.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import { DotsHorizontalIcon, TrashIcon } from "~/icons";
import { getQueryParam, makeLinkToPropertySettingsRef } from "~/router/common";
import { ButtonSize, DialogMode, Placement, PropertyKind } from "~/shared/enums";
import type { Property, PropertyConfig } from "~/shared/types";
import { useDataStore } from "~/stores";

const UNHIDABLE_PROPERTY_KINDS = new Set([PropertyKind.DEFAULT_STATUS]);

const router = useRouter();
const dataStore = useDataStore();

const dialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);

const titleInput = ref<InstanceType<typeof Input> | null>(null);

const property = computed<Property | undefined>(() => {
  const param = getQueryParam("property");
  if (!param) {
    return undefined;
  }
  return dataStore.getPropertyByDuid(param);
});
const previousProperty = usePrevious(property);
const propertyConfig = computed<PropertyConfig | null>(() =>
  property.value ? getPropertyConfig(property.value.kind) : null
);

const togglePropertyVisibility = (isVisible: boolean) => {
  if (!property.value) {
    return;
  }
  dataStore.updateProperty({ duid: property.value.duid, hidden: !isVisible });
};

const onDelete = () => {
  const currProperty = property.value ?? previousProperty.value;
  if (!currProperty) {
    return;
  }

  dataStore.deleteProperty(currProperty);
  router.replace(makeLinkToPropertySettingsRef(getShownPropertyWithConfigList()[0][0].duid).value);
};

const propertySections = computed(() => (property.value ? actions.context.property(property.value, dialog.value) : []));

const isValidName = (value: string) => ({ isValid: value !== "", error: "Property name can't be empty" });

const updateTitle = (event: UIEvent) => {
  const title = (event.target as HTMLInputElement).value;
  if (!property.value || title === property.value.title) {
    return;
  }
  if (!isValidName(title).isValid) {
    return;
  }
  dataStore.updateProperty({ duid: property.value.duid, title });
};

const updateDescription = (event: UIEvent) => {
  const description = (event.target as HTMLInputElement).value;
  if (!property.value || description === property.value.description) {
    return;
  }
  dataStore.updateProperty({ duid: property.value.duid, description });
};

const focusTitle = () => {
  if (!titleInput.value) {
    return;
  }
  titleInput.value.focus();
  titleInput.value.select();
};

defineExpose({
  focusTitle,
});
</script>

<template>
  <div v-if="property && propertyConfig" class="mt-10 flex flex-1 flex-col gap-10 overflow-y-auto px-8 pb-72 lg:px-16">
    <div class="flex items-center gap-2">
      <component :is="propertyConfig.icon" class="text-lt icon-sm" />
      <span class="flex-1 select-none text-base text-md">
        Edit {{ propertyConfig.isDefault ? "property" : "custom property" }}
      </span>

      <ConfirmationDialog
        ref="dialog"
        :mode="DialogMode.DELETE"
        :title="`Delete ${property.title}`"
        description="Are you sure you want to delete this property? This action cannot be undone."
        confirm-text="Delete"
        cancel-text="Keep"
        :icon="TrashIcon"
        @confirm="onDelete" />
      <DropdownMenu :sections="propertySections" :placement="Placement.BOTTOM_RIGHT" :distance="0">
        <div class="rounded text-lt hover:bg-lt">
          <span class="sr-only">Manage property</span>
          <DotsHorizontalIcon class="icon-md" aria-hidden="true" />
        </div>
      </DropdownMenu>
    </div>

    <div class="flex flex-col gap-6">
      <!-- Name -->
      <form onsubmit="return false" autocomplete="off" class="flex flex-col gap-1">
        <Input
          ref="titleInput"
          :init-value="property.title"
          placeholder="Property name"
          label="Name"
          :validate="isValidName"
          @focusout="updateTitle" />

        <!-- Description -->
        <Input
          :init-value="property.description"
          placeholder="Property description"
          label="Description"
          @focusout="updateDescription" />
      </form>

      <!-- Type -->
      <div class="relative flex w-full flex-col">
        <span class="absolute -top-2 left-1.5 select-none px-1 text-xs transition-opacity bg-std text-lt">Type</span>
        <div class="flex w-full items-center gap-2 rounded border bg-transparent px-3 py-2 text-sm text-md border-md">
          <component :is="propertyConfig.icon" class="text-lt icon-md" />
          <span class="select-none">
            {{ propertyConfig.label }}
          </span>
        </div>
      </div>

      <!-- Visibility -->
      <Toggle
        v-if="!UNHIDABLE_PROPERTY_KINDS.has(property.kind)"
        class="w-full"
        :size="ButtonSize.SMALL"
        :value="!property.hidden"
        label="Show"
        text-sm
        text-lt
        description="Show this property by default, although this can be overridden on individual pages"
        @update="togglePropertyVisibility" />

      <!-- Adtl -->
      <component
        :is="propertyConfig.settingsComponent()"
        v-if="propertyConfig.settingsComponent"
        :property="property"
        :property-config="propertyConfig" />
    </div>
  </div>
</template>
