<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M19 4h-9m4 16H5M15 4 9 20"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
