<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M16 10H3m17-4H3m17 8H3m13 4H3"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
