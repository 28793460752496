<script setup lang="ts">
import { computed, onUnmounted, watchEffect } from "vue";
import { useRouter } from "vue-router";

import DragArea from "~/components/dumb/DragArea.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UpgradeConfirmationDialog from "~/components/dumb/UpgradeConfirmationDialog.vue";
import { PlusIcon } from "~/icons";
import { getQueryParam, makeLinkToFormsSettingsRef } from "~/router/common";
import { Entitlement } from "~/shared/enums";
import type { Form } from "~/shared/types";
import { useDataStore, useTenantStore } from "~/stores";
import { getItemCountText, getNextTitleInSequence } from "~/utils/common";
import { getOrdersBetween } from "~/utils/orderManager";

import FormsSidebarItem from "./FormsSidebarItem.vue";

const emit = defineEmits<{
  createForm: [];
}>();

const router = useRouter();
const dataStore = useDataStore();
const tenantStore = useTenantStore();

const forms = computed(() => dataStore.formList);

const maxForms = computed(() => tenantStore.getEntitlementValue(Entitlement.MAX_FORMS));
const upgradeRequired = computed(() => forms.value.length >= maxForms.value);

const createForm = async () => {
  if (upgradeRequired.value) {
    return;
  }

  const form = await dataStore.createForm(getOrdersBetween(undefined, forms.value[0]?.order)[0], {
    title: getNextTitleInSequence(
      "Form",
      forms.value.map((e) => e.title),
      { noCopy: true }
    ),
  });

  router.replace(makeLinkToFormsSettingsRef(form.duid).value);

  setTimeout(() => {
    emit("createForm");
  }, 100);
};

const getFormProps = (form: Form) => ({ form });

const moveForm = (_: string, form: Form) => {
  dataStore.updateForm({ duid: form.duid, order: form.order });
};

// Default to the first form if no form is selected
watchEffect(() => {
  const form = getQueryParam("form");
  if (!form || !dataStore.getFormByDuid(form)) {
    setTimeout(() => {
      router.replace(makeLinkToFormsSettingsRef(dataStore.formList[0]?.duid).value);
    });
  }
});

// Remove query param on unmount.
onUnmounted(() => {
  router.replace({ query: { ...router.currentRoute.value.query, form: undefined } });
});
</script>

<template>
  <div class="h-full w-56 overflow-y-auto border-r px-2.5 pb-6 pt-[18px] bg-std border-lt">
    <div class="mb-[3px] flex items-center justify-between">
      <span class="select-none px-2.5 text-xs font-semibold uppercase text-vlt">Forms</span>
      <UpgradeConfirmationDialog
        :disabled="!upgradeRequired"
        :feature-action="`create more than ${getItemCountText(maxForms, 'form', { noSpecial: true })}`">
        <Tooltip text="Create a new form">
          <button
            type="button"
            class="items-center justify-center rounded p-0.5 focus-ring-lt hover:bg-md"
            aria-label="Create a new form"
            @click="createForm">
            <PlusIcon class="text-vlt icon-sm" aria-hidden="true" />
          </button>
        </Tooltip>
      </UpgradeConfirmationDialog>
    </div>

    <DragArea
      v-if="forms.length > 0"
      group="forms"
      category="forms"
      class="mt-1 !h-auto min-h-[20px] flex-1 gap-px rounded"
      drop-area-classes="bg-md/50"
      :items="forms"
      :component="FormsSidebarItem"
      :get-component-props="getFormProps"
      @change="moveForm" />
  </div>
</template>
