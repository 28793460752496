<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="m7 15 5 5 5-5M7 9l5-5 5 5"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
