<script setup lang="ts" generic="Kind extends PageKind = PageKind, SecondaryKind extends PageKind = PageKind">
import { computed, nextTick, ref } from "vue";
import type { ComponentExposed } from "vue-component-type-helpers";

import actions from "~/actions";
import Animated from "~/components/dumb/Animated.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import DragArea from "~/components/dumb/DragArea.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import PageIconFromSvg from "~/components/dumb/PageIconFromSvg.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import PageTab from "~/components/leftbar/PageTab.vue";
import { SPRINT_DARTBOARD_KINDS_SET } from "~/components/visualization/constants";
import { DARTBOARD_KIND_TO_ICON_INFO_MAP } from "~/constants/dartboard";
import { ARCHIVE_PSEUDO_SECTION_KEY_SUFFIX, FAVORITES_PSEUDO_SECTION_KEY } from "~/constants/page";
import { colorsByTheme } from "~/constants/style";
import {
  ChevronDownIcon,
  DartboardIcon,
  DocIcon,
  DotsHorizontalIcon,
  FolderIcon,
  LockIcon,
  PlusIcon,
  ShowIcon,
  SprintIcon,
  SprintRolloverIcon,
  UndoIcon,
} from "~/icons";
import { DartboardKind, DropdownMenuItemKind, FolderKind, PageKind, Placement, SpaceKind } from "~/shared/enums";
import type { DartboardTabConfig, PageTabConfig, Space } from "~/shared/types";
import { useAppStore, useEnvironmentStore, usePageStore, useTenantStore, useUserStore } from "~/stores";

const ARCHIVE_CONFIG = DARTBOARD_KIND_TO_ICON_INFO_MAP[DartboardKind.FINISHED];

const props = defineProps<{
  pageKind: Kind;
  pages: PageTabConfig<Kind>[];
  secondaryPageKind?: SecondaryKind;
  secondaryPages?: PageTabConfig<SecondaryKind>[];
  parent?: Space;
}>();

const emit = defineEmits<{
  rollover: [];
  reverseRollover: [];
  editSpace: [space: Space];
  addPage: [isPrimary: boolean];
  movePage: [isPrimary: boolean, category: string, pageConfig: PageTabConfig<Kind | SecondaryKind>];
}>();

const appStore = useAppStore();
const environmentStore = useEnvironmentStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const title = computed(() => props.parent?.title ?? "Favorites");
const sectionKey = computed(() => props.parent?.duid ?? FAVORITES_PSEUDO_SECTION_KEY);
const showPages = computed(() => userStore.getSectionExpanded(sectionKey.value));
const category = computed(() => props.parent?.duid ?? "favorites");

const group = computed(() => `${props.pageKind}s`);
const dragArea = ref<ComponentExposed<typeof DragArea<PageTabConfig<Kind>, typeof PageTab>> | null>(null);

const secondaryGroup = computed(() => `${props.secondaryPageKind}s`);
const secondaryDragArea = ref<ComponentExposed<typeof DragArea<PageTabConfig<SecondaryKind>, typeof PageTab>> | null>(
  null
);

const spaceOptionsRef = ref<InstanceType<typeof DropdownMenu> | null>(null);
const createSubpageRef = ref<InstanceType<typeof DropdownMenu> | null>(null);

const toggleShowPages = (event?: UIEvent) => {
  event?.stopPropagation();
  event?.preventDefault();

  userStore.setSectionExpanded(sectionKey.value, !showPages.value);
};

const reset = () => {
  dragArea.value?.reset();
  secondaryDragArea.value?.reset();
};

const getComponentProps = (pageConfig: PageTabConfig<Kind | SecondaryKind>) => ({
  pageConfig,
  narrowLeft: !!props.parent,
});

const focusNewestPage = async (isPrimary: boolean) => {
  await nextTick();
  const itemRefs = (isPrimary ? dragArea : secondaryDragArea).value?.itemRefs ?? [];
  if (itemRefs.length === 0) {
    return;
  }

  itemRefs[itemRefs.length - 1].startEditingTitle();
};

const placeholderColor = computed(() => colorsByTheme[pageStore.theme].highlight);

const rolloverDialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);

// TODO a lot of this is duplicate from PagePropertiesEditor
const resetToActive = ref(false);
const dialogTitle = computed(() => (resetToActive.value ? "Reset to Active" : "Start next sprint"));
const dialogDescription = computed(() =>
  resetToActive.value
    ? "Resetting this dartboard to active will move its tasks back to active and the current active tasks back to next. This can't be undone."
    : "Starting the next sprint will move next tasks to active and active tasks into archive, but tasks that are not finished or canceled stay in active. This can't be undone."
);

const openRolloverDialog = (newResetToActive: boolean) => {
  resetToActive.value = newResetToActive;
  rolloverDialog.value?.openModal();
};

const rollover = () => {
  if (resetToActive.value) {
    emit("reverseRollover");
    return;
  }
  emit("rollover");
};

const standardPages = computed(() =>
  props.pages.filter((page) => page.pageKind !== PageKind.DARTBOARD || page.kind === DartboardKind.CUSTOM)
);

const nonarchivedSprintDartboardConfigs = computed(() =>
  props.pages.filter(
    (page) =>
      page.pageKind === PageKind.DARTBOARD &&
      SPRINT_DARTBOARD_KINDS_SET.has(page.kind as DartboardKind) &&
      page.kind !== DartboardKind.FINISHED
  )
);
const archivedSprintDartboardConfigs = computed(() =>
  props.pages
    .filter((page) => page.pageKind === PageKind.DARTBOARD && page.kind === DartboardKind.FINISHED)
    .sort((a, b) => ((b as DartboardTabConfig).index ?? 0) - ((a as DartboardTabConfig).index ?? 0))
);

const nonreportFolders = computed(() =>
  props.secondaryPages ? props.secondaryPages.filter((page) => page.kind !== FolderKind.REPORTS) : undefined
);

const defaultFolder = computed(() =>
  nonreportFolders.value ? nonreportFolders.value.find((page) => page.kind === FolderKind.DEFAULT) : undefined
);

const standardFolders = computed(() =>
  nonreportFolders.value ? nonreportFolders.value.filter((page) => page.kind === FolderKind.OTHER) : undefined
);

const sprintsSectionHeightPx = computed(() => {
  const numSprintDartboards =
    nonarchivedSprintDartboardConfigs.value.length + (archivedSprintDartboardConfigs.value.length > 0 ? 1 : 0);
  if (numSprintDartboards === 0) {
    return undefined;
  }
  return `${numSprintDartboards * 29 - 1}px`;
});

const archiveHeightPx = computed(() => {
  const numArchiveDartboards = archivedSprintDartboardConfigs.value.length;
  if (numArchiveDartboards === 0) {
    return undefined;
  }
  return `${numArchiveDartboards * 29 - 1}px`;
});

const standardSectionHeightPx = computed(() => {
  const numStandardDartboards = standardPages.value.length;
  if (numStandardDartboards === 0) {
    return undefined;
  }
  return `${numStandardDartboards * 29 - 1}px`;
});

const folderSectionHeightPx = computed(() => {
  const numNonreportFolders = nonreportFolders.value?.length ?? 0;
  if (numNonreportFolders === 0) {
    return undefined;
  }
  return `${numNonreportFolders * 29 - 1}px`;
});

const sectionArchiveKey = computed(() => `${props.parent?.duid ?? ""}${ARCHIVE_PSEUDO_SECTION_KEY_SUFFIX}`);
const showArchive = computed(() => userStore.getSectionExpanded(sectionArchiveKey.value));

const toggleShowArchive = () => {
  userStore.setSectionExpanded(sectionArchiveKey.value, !showArchive.value);
};

const addPage = (isPrimary: boolean) => {
  if (!showPages.value) {
    toggleShowPages();
  }
  emit("addPage", isPrimary);
};

const spaceAddDropdownSections = computed(() => [
  {
    title: "Create",
    items: [
      {
        title: "Create a dartboard",
        kind: DropdownMenuItemKind.BUTTON,
        dataTestid: "dropdown-create-dartboard",
        icon: DartboardIcon,
        onClick: () => addPage(true),
      },
      {
        title: "Create a doc folder",
        kind: DropdownMenuItemKind.BUTTON,
        icon: FolderIcon,
        onClick: () => addPage(false),
      },
    ],
  },
]);

const spaceMetaDropdownSections = computed(() =>
  props.parent
    ? actions.context.page(props.parent, false, (eventKind: string) => {
        switch (eventKind) {
          case "startNextSprint": {
            openRolloverDialog(false);
            break;
          }
          case "startEditingSpace": {
            emit("editSpace", props.parent as Space);
            break;
          }
          default: {
            throw new Error(`Unknown event kind: ${eventKind}`);
          }
        }
      })
    : []
);

const onContextMenu = (event: MouseEvent) => {
  if ((tenantStore.isDart && !pageStore.adminHidden && event.altKey) || !props.parent) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, spaceMetaDropdownSections.value);
};

defineExpose({
  focusNewestPage,
  reset,
});
</script>

<template>
  <div class="group/space">
    <ConfirmationDialog
      ref="rolloverDialog"
      cancel-text="Cancel"
      confirm-text="Proceed"
      :icon="resetToActive ? UndoIcon : SprintRolloverIcon"
      :title="dialogTitle"
      :description="dialogDescription"
      @confirm="rollover" />

    <!-- Header -->
    <button
      type="button"
      aria-label="Toggle section"
     
      class="group/page-tab flex w-full cursor-pointer items-center justify-between rounded pl-3.5 pr-2.5 text-sm font-medium transition-colors drag-none text-lt focus-ring-lt hover:bg-md"
      :class="[parent ? 'h-7' : 'h-5']"
      @click="(e: UIEvent) => toggleShowPages(e)"
      @keydown.enter="(e: UIEvent) => toggleShowPages(e)"
      @contextmenu="onContextMenu">
      <div class="flex items-center gap-1.5 overflow-hidden text-vlt">
        <div v-if="parent" class="m-0.5 flex items-center justify-center rounded icon-md hover:bg-opposite/10">
          <PageIcon :page="parent" class="flex group-hover/page-tab:hidden" />
          <ChevronDownIcon
            :class="!showPages && '-rotate-90'"
            class="hidden rounded transition-transform text-lt icon-md group-hover/page-tab:flex"
            @click.stop.prevent="toggleShowPages()"
            @keydown.enter.stop.prevent="toggleShowPages()" />
        </div>
        <div class="flex overflow-hidden group-hover/page-tab:gap-0.5">
          <span :title="title" class="truncate text-xs/5 font-semibold uppercase group-hover/page-tab:text-lt">
            {{ title }}
          </span>
          <Tooltip
            v-if="parent && (parent.kind === SpaceKind.WORKSPACE || parent.kind === SpaceKind.PERSONAL)"
            text="This is a default space so it cannot be edited, moved, or deleted">
            <div class="-mt-px hidden items-center justify-center p-0.5 group-hover/page-tab:flex">
              <LockIcon class="icon-sm" />
            </div>
          </Tooltip>
          <Tooltip
            v-if="parent && !parent.accessibleByTeam"
            :text="`Only you${
              parent.accessibleByUserDuids.length > 1 ? ' and specific teammates' : ''
            } have access to this page`">
            <div class="-mt-px hidden items-center justify-center p-0.5 group-hover/page-tab:flex">
              <ShowIcon class="icon-sm" />
            </div>
          </Tooltip>
        </div>
      </div>
      <div
        v-if="parent"
        class="items-center gap-0.5 pl-0.5"
        :class="spaceOptionsRef?.isOpen || createSubpageRef?.isOpen ? 'flex' : 'hidden group-hover/page-tab:flex'"
        @click.stop.prevent
        @keydown.enter.stop.prevent>
        <DropdownMenu
          ref="spaceOptionsRef"
          :sections="spaceMetaDropdownSections"
          :placement="Placement.RIGHT_TOP"
          :distance="0">
          <Tooltip text="Space options">
            <component
              :is="environmentStore.isPlaywright ? 'div' : 'button'"
              type="button"
             
              class="-mt-px items-center justify-center rounded p-0.5 focus-ring-lt hover:bg-opposite/10 focus:outline-none"
              aria-label="Space options">
              <DotsHorizontalIcon class="text-lt icon-sm" aria-hidden="true" />
            </component>
          </Tooltip>
        </DropdownMenu>
        <DropdownMenu
          ref="createSubpageRef"
          :sections="spaceAddDropdownSections"
          :placement="Placement.RIGHT_TOP"
          :distance="0">
          <Tooltip text="Create a dartboard or a doc folder">
            <component
              :is="environmentStore.isPlaywright ? 'div' : 'button'"
              type="button"
             
              class="-mt-px items-center justify-center rounded p-0.5 focus-ring-lt hover:bg-opposite/10 focus:outline-none"
              aria-label="Create a dartboard or doc folder">
              <PlusIcon class="text-lt icon-sm" aria-hidden="true" />
            </component>
          </Tooltip>
        </DropdownMenu>
      </div>
    </button>

    <div v-if="showPages" class="flex pt-px">
      <Animated v-if="parent" class="ml-5 mr-1 flex h-full w-3 shrink-0 flex-col gap-px">
        <div
          v-if="sprintsSectionHeightPx"
          class="flex flex-col items-center gap-0.5 py-1"
          :style="{ height: sprintsSectionHeightPx }">
          <div class="w-px grow bg-hvy" />
          <Tooltip info text="Sprints">
            <SprintIcon class="ml-px size-3 text-vlt focus:outline-none" aria-hidden="true" />
          </Tooltip>
          <div class="w-px grow bg-hvy" />
        </div>
        <div v-if="showArchive && archiveHeightPx" :style="{ height: archiveHeightPx }" />
        <div
          v-if="standardSectionHeightPx"
          class="flex flex-col items-center gap-0.5 py-1"
          :style="{ height: standardSectionHeightPx }">
          <div class="w-px grow bg-hvy" />
          <Tooltip info text="Dartboards">
            <DartboardIcon class="ml-px size-3 text-vlt focus:outline-none" aria-hidden="true" />
          </Tooltip>
          <div class="w-px grow bg-hvy" />
        </div>
        <div
          v-if="folderSectionHeightPx"
          class="flex flex-col items-center gap-0.5 py-1"
          :style="{ height: folderSectionHeightPx }">
          <div class="w-px grow bg-hvy" />
          <Tooltip info text="Doc folders">
            <DocIcon class="ml-px size-3 text-vlt focus:outline-none" aria-hidden="true" />
          </Tooltip>
          <div class="w-px grow bg-hvy" />
        </div>
      </Animated>
      <div class="flex min-w-0 flex-1 flex-col gap-px">
        <!-- Sprint DBs -->
        <Animated
          v-if="nonarchivedSprintDartboardConfigs.length > 0 || archivedSprintDartboardConfigs.length > 0"
          class="flex flex-col gap-px">
          <PageTab
            v-for="dartboard in nonarchivedSprintDartboardConfigs"
            :key="dartboard.duid"
            :page-config="dartboard"
            :narrow-left="!!parent" />

          <template v-if="archivedSprintDartboardConfigs.length > 0">
            <button
              type="button"
              class="group/page-tab flex h-7 w-full items-center rounded pl-0.5 pr-1.5 text-sm font-medium text-lt focus-ring-std hover:bg-md"
              @click="toggleShowArchive">
              <div class="mr-2 flex items-center justify-center rounded icon-lg hover:bg-opposite/10">
                <PageIconFromSvg
                  :name="ARCHIVE_CONFIG.iconNameOrEmoji"
                  class="flex icon-md group-hover/page-tab:hidden"
                  :style="{ color: ARCHIVE_CONFIG.colorHex }" />
                <ChevronDownIcon
                  :class="!showArchive && '-rotate-90'"
                  class="hidden cursor-pointer rounded transition-transform text-lt icon-md group-hover/page-tab:flex" />
              </div>
              <span class="flex-1 text-start">Archive</span>
            </button>

            <div v-if="showArchive" class="flex w-full">
              <div class="ml-2 mr-0.5 flex w-3 shrink-0 flex-col items-center gap-0.5 self-stretch py-1">
                <div class="w-px flex-1 grow bg-hvy" />
              </div>
              <div v-if="showArchive" class="flex grow flex-col gap-px overflow-x-hidden">
                <PageTab
                  v-for="dartboard in archivedSprintDartboardConfigs"
                  :key="dartboard.duid"
                  :page-config="dartboard"
                  :narrow-left="!!parent"
                  @reverse-rollover="openRolloverDialog(true)" />
              </div>
            </div>
          </template>
        </Animated>

        <!-- Standard pages -->
        <DragArea
          ref="dragArea"
          :group="group"
          :category="category"
          class="!h-fit min-h-px gap-px rounded"
          :class="standardPages.length === 0 && '-mt-px'"
          drop-area-classes="bg-md/50"
          :placeholder-color="placeholderColor"
          :items="standardPages"
          :component="PageTab"
          :get-component-props="getComponentProps"
          @change="(category, item) => emit('movePage', true, category, item)" />

        <PageTab v-if="defaultFolder" :key="defaultFolder.duid" :page-config="defaultFolder" narrow-left />

        <!-- Folders -->
        <DragArea
          v-if="standardFolders !== undefined"
          ref="secondaryDragArea"
          :group="secondaryGroup"
          :category="category"
          class="!h-fit min-h-px gap-px rounded"
          :class="standardPages.length === 0 && '-mt-px'"
          drop-area-classes="bg-md/50"
          :placeholder-color="placeholderColor"
          :items="standardFolders"
          :component="PageTab"
          :get-component-props="getComponentProps"
          @change="(category, item) => emit('movePage', false, category, item)" />
      </div>
    </div>
  </div>
</template>
