import type { Column, GridApi, IRowNode, RefreshCellsParams, SelectionEventSourceType } from "ag-grid-community";

import type TitleCellEditor from "~/components/visualization/list/cellEditors/TitleCellEditor.vue";
import type { Task, TaskOrGroup, TaskWithGroup } from "~/shared/types";
import type DashboardsRootView from "~/views/DashboardsRootView.vue";
import type FolderRootView from "~/views/FolderRootView.vue";
import type InboxView from "~/views/InboxView.vue";
import type SpaceRootView from "~/views/SpaceRootView.vue";
import type ViewsRootView from "~/views/ViewsRootView.vue";

type RowNodeEventType = Parameters<IRowNode["addEventListener"]>[0];

export type Visualization = {
  count(): number;
  getAll(): Task[];
  getChildren(duid: string | null): Task[];
  scrollTo(duid: string): void;
  selectAndScrollTo(duid: string, options?: { deselectOthers?: boolean }): void;
  getHtml(duid: string): Element | null;
  selectAll(): void;
  deselectAll(): void;
  editTitle(duid: string): void;
  refresh(options?: { tasks?: Task[]; fields?: string[]; force?: boolean }): void;
  // TODO still fully row-related - remove?
  getRow(duid: string): IRowNode<Task> | undefined;
  getRowByIndex(index: number): IRowNode<Task> | undefined;
  getSelectedRows(): IRowNode<Task>[];
  getAllRows(): IRowNode<Task>[];
};

class SelectedTaskRowNode implements IRowNode<Task> {
  id = undefined;

  data: Task;

  displayed = false;

  rowPinned = undefined;

  selectable = true;

  rowHeight = undefined;

  rowTop = null;

  group = undefined;

  firstChild = false;

  lastChild = false;

  childIndex = -1;

  level = -1;

  uiLevel = -1;

  parent = null;

  stub = false;

  failedLoad = false;

  sourceRowIndex = -1;

  rowIndex = null;

  quickFilterAggregateText = null;

  master = false;

  detail = false;

  field = null;

  key = null;

  groupData = null;

  aggData = undefined;

  rowGroupColumn = null;

  rowGroupIndex = null;

  expanded = false;

  leafGroup = false;

  allLeafChildren = [];

  allChildrenCount = null;

  childrenAfterGroup = null;

  childrenAfterSort = null;

  childrenAfterFilter = null;

  footer = false;

  sibling: IRowNode<Task>;

  constructor(task: Task) {
    this.data = task;
    this.sibling = this;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  setSelected(newValue: boolean, clearSelection?: boolean, source?: SelectionEventSourceType) {}

  // eslint-disable-next-line class-methods-use-this
  isSelected() {
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  isRowPinned() {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  isExpandable() {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  setExpanded(expanded: boolean, e?: MouseEvent | KeyboardEvent) {}

  // eslint-disable-next-line class-methods-use-this
  isFullWidthCell() {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  isHovered() {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types, class-methods-use-this, @typescript-eslint/no-unused-vars
  addEventListener(eventType: RowNodeEventType, listener: Function) {}

  // eslint-disable-next-line @typescript-eslint/ban-types, class-methods-use-this, @typescript-eslint/no-unused-vars
  removeEventListener(eventType: RowNodeEventType, listener: Function) {}

  // eslint-disable-next-line class-methods-use-this
  resetQuickFilterAggregateText() {}

  depthFirstSearch(callback: (rowNode: IRowNode<Task>) => void) {
    callback(this);
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  setRowHeight(rowHeight: number | undefined | null, estimated?: boolean) {}

  setData(data: Task) {
    this.data = data;
  }

  updateData(data: Task) {
    this.data = data;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  setDataValue(colKey: string | Column, newValue: any, eventSource?: string) {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  getRoute() {
    return undefined;
  }
}

const isTaskNotGroup = (e: TaskOrGroup | undefined): e is TaskWithGroup => !!e && !e.isRoot;

const isRowTaskNotGroup = (e: IRowNode<TaskOrGroup> | undefined): e is IRowNode<TaskWithGroup> =>
  !!e && isTaskNotGroup(e.data);

export const getListVisualization = (getApi: () => GridApi<TaskOrGroup> | null): Visualization => ({
  count() {
    return getApi()?.getDisplayedRowCount() ?? 0;
  },
  getAll() {
    const res: TaskOrGroup[] = [];
    getApi()?.forEachNode((e) => e.data && res.push(e.data));
    return res.filter(isTaskNotGroup);
  },
  getChildren(duid) {
    const row = duid ? this.getRow(duid) : this.getRowByIndex(0)?.parent;
    return row?.childrenAfterFilter?.map((e) => e.data).filter((e): e is Task => !!e) ?? [];
  },
  selectAll() {
    getApi()?.selectAllFiltered();
  },
  deselectAll() {
    getApi()?.deselectAll();
  },
  scrollTo(duid) {
    const row = this.getRow(duid);
    if (!row) {
      return;
    }

    getApi()?.ensureNodeVisible(row as IRowNode<TaskOrGroup>);
  },
  selectAndScrollTo(duid, options = {}) {
    const { deselectOthers = true } = options;
    const row = this.getRow(duid);
    if (!row) {
      return;
    }
    row.setSelected(true, deselectOthers);

    this.scrollTo(duid);
  },
  getHtml(duid) {
    return document.querySelector(`[row-id="${duid}"]`);
  },
  editTitle(duid) {
    const row = this.getRow(duid);
    if (!row) {
      return;
    }

    const renderer = getApi()?.getCellRendererInstances({
      rowNodes: [row as IRowNode<TaskOrGroup>],
      columns: ["title", "ag-Grid-AutoColumn"],
    })[0];
    if (!renderer) {
      return;
    }

    (renderer as unknown as { $root: typeof TitleCellEditor }).$root.focus();
  },
  refresh(options = {}) {
    const { tasks: origTasks, fields, force } = options;
    const api = getApi();
    if (!api) {
      return;
    }

    let tasks = origTasks;
    if (!tasks) {
      tasks = this.getAll();
    } else if (!fields || fields.includes("subtasks")) {
      const queue = tasks.map((e) => this.getRow(e.duid));
      const visited = new Set<string>();
      tasks = [];
      while (queue.length !== 0) {
        const row = queue.shift();
        if (!row?.data || visited.has(row.data.duid)) {
          continue;
        }
        tasks.push(row.data);
        visited.add(row.data.duid);
        const children = row.childrenAfterFilter;
        if (!children || children.length === 0) {
          continue;
        }
        queue.push(...children);
      }
    }

    api.applyTransaction({ update: tasks as TaskOrGroup[] });
    if (!force) {
      return;
    }

    const rowNodes = tasks.map((e) => this.getRow(e.duid))?.filter((e): e is IRowNode<TaskWithGroup> => !!e);
    if (!fields) {
      api.redrawRows({ rowNodes });
      return;
    }

    if (fields.includes("title")) {
      fields.push("ag-Grid-AutoColumn");
    }
    const params: RefreshCellsParams<TaskOrGroup> = {
      rowNodes,
      columns: fields,
      force,
    };
    api.refreshCells(params);
  },
  getRow(duid) {
    const row = getApi()?.getRowNode(duid);
    return isRowTaskNotGroup(row) ? row : undefined;
  },
  getRowByIndex(index) {
    const row = getApi()?.getDisplayedRowAtIndex(index);
    return isRowTaskNotGroup(row) ? row : undefined;
  },
  getSelectedRows() {
    const nodes = getApi()?.getSelectedNodes() ?? [];
    return nodes.filter(isRowTaskNotGroup);
  },
  getAllRows() {
    const res: IRowNode<TaskWithGroup>[] = [];
    getApi()?.forEachNode((e) => {
      if (!isRowTaskNotGroup(e)) {
        return;
      }
      res.push(e);
    });
    return res;
  },
});

export const getSpaceFolderOrViewsVisualization = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getSpaceFolderOrViewsRoot: () =>
    | InstanceType<typeof DashboardsRootView>
    | InstanceType<typeof FolderRootView>
    | InstanceType<typeof SpaceRootView>
    | InstanceType<typeof ViewsRootView>
    | null
): Visualization => ({
  count() {
    throw Error("Not implemented");
  },
  getAll() {
    throw Error("Not implemented");
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getChildren(duid) {
    throw Error("Not implemented");
  },
  selectAll() {},
  deselectAll() {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollTo(duid) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectAndScrollTo(duid, options) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getHtml(duid) {
    throw Error("Not implemented");
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  editTitle(duid) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refresh(options) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getRow(duid) {
    throw Error("Not implemented");
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getRowByIndex(index) {
    throw Error("Not implemented");
  },
  getSelectedRows() {
    throw Error("Not implemented");
  },
  getAllRows() {
    throw Error("Not implemented");
  },
});

export const getInboxVisualization = (getInbox: () => InstanceType<typeof InboxView> | null): Visualization => ({
  count() {
    throw Error("Not implemented");
  },
  getAll() {
    throw Error("Not implemented");
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getChildren(duid) {
    throw Error("Not implemented");
  },
  selectAll() {},
  deselectAll() {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollTo(duid) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectAndScrollTo(duid, options) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getHtml(duid) {
    throw Error("Not implemented");
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  editTitle(duid) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refresh(options) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getRow(duid) {
    throw Error("Not implemented");
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getRowByIndex(index) {
    throw Error("Not implemented");
  },
  getSelectedRows() {
    return (
      getInbox()
        ?.getSelectedTasks()
        ?.map((e) => new SelectedTaskRowNode(e)) ?? []
    );
  },
  getAllRows() {
    throw Error("Not implemented");
  },
});
