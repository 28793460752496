<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M11.6939 3.02784L5.36993 11.2475L1.63372 8.373"
      stroke="currentColor"
      stroke-width="1.33"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M13.9354 4.75251L7.61144 12.9722"
      stroke="currentColor"
      stroke-width="1.33"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
