<script setup lang="ts">
import { computed, ref } from "vue";

import { backendOld } from "~/api";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UpgradeConfirmationDialog from "~/components/dumb/UpgradeConfirmationDialog.vue";
import { CheckVerifiedIcon, DotsHorizontalIcon, OpenExternalLinkIcon, SlackIcon } from "~/icons";
import { DropdownMenuItemKind, Entitlement, Placement } from "~/shared/enums";
import type { DropdownMenuItem } from "~/shared/types";
import { useTenantStore } from "~/stores";

const tenantStore = useTenantStore();

const upgradeRequired = computed(() => !tenantStore.getEntitlementValue(Entitlement.SLACK));

const integrationEnabled = computed(() => tenantStore.slackIntegrationEnabled);
const logoLoadFailed = ref(false);

// Unlink integration
const unlink = () => {
  backendOld.slack.disableIntegration();
};

// Create integration and redirect to Slack to install
const link = async () => {
  if (upgradeRequired.value) {
    return;
  }

  await backendOld.slack.enableIntegration();
  window.location.href = `${window.location.origin}${backendOld.slack.getInstallationLink()}`;
};

const dropdownItems = computed(() => {
  const items: DropdownMenuItem[] = [
    {
      title: "Disable integration",
      kind: DropdownMenuItemKind.BUTTON,
      onClick: () => unlink(),
    },
  ];
  return [
    {
      title: "Configure",
      items,
    },
  ];
});
</script>

<template>
  <div class="h-full overflow-y-scroll">
    <div class="mx-16 flex flex-col gap-y-16 lg:mx-32">
      <div class="flex flex-col justify-center space-y-3">
        <div class="flex justify-between">
          <div class="flex items-center gap-4">
            <h2 class="select-none text-xl text-md">Slack integration</h2>
            <UpgradeConfirmationDialog v-if="upgradeRequired" feature-action="use the Slack integration" />
            <div
              v-if="integrationEnabled"
              class="flex h-[26px] select-none items-center gap-1 rounded bg-primary-base px-1">
              <CheckVerifiedIcon class="text-oncolor icon-sm" />
              <span class="text-xs text-oncolor">Connected</span>
            </div>
          </div>
          <DropdownMenu v-if="integrationEnabled" :sections="dropdownItems" :placement="Placement.BOTTOM_RIGHT">
            <Tooltip text="Configure integration">
              <button type="button" class="flex items-center rounded p-0.5 text-lt focus-ring-std hover:bg-md">
                <span class="sr-only">Configure integration</span>
                <DotsHorizontalIcon class="icon-sm" aria-hidden="true" />
              </button>
            </Tooltip>
          </DropdownMenu>
        </div>
        <p class="select-none text-sm/relaxed text-lt">
          Integrate Slack with Dart to enable task creation, notifications, convenient updates, and more without having
          to switch out of Slack.
        </p>
        <p v-if="integrationEnabled" class="select-none text-sm/relaxed text-lt">
          You can now create a Dart task from Slack with
          <code class="rounded py-0.5 pl-1.5 pr-1 bg-md">/dart [title]</code>
          , click the three dots to the right of a message and search for Dart to make a task, and more, from that
          message, and get notifications about Dart updates in Slack.
        </p>
      </div>

      <UpgradeConfirmationDialog feature-action="use the Slack integration" :disabled="!upgradeRequired">
        <button
          v-if="!integrationEnabled"
          type="button"
          class="flex select-none items-center justify-center gap-2 self-center rounded border px-3 py-2 text-center text-base shadow-sm text-md border-md focus-ring-std hover:bg-lt"
          @click="link">
          <SlackIcon class="icon-md" />
          Link Dart and Slack
          <OpenExternalLinkIcon class="icon-md" />
        </button>
      </UpgradeConfirmationDialog>

      <template v-if="integrationEnabled">
        <div class="flex items-center gap-2">
          <div class="flex flex-1 grow select-none flex-col">
            <span class="font-normal text-md">Workspace</span>
            <span as="span" class="text-xs text-lt">The Slack workspace that is connected</span>
          </div>
          <img
            v-if="tenantStore.slackIntegration?.workspaceIcon && !logoLoadFailed"
            :src="tenantStore.slackIntegration.workspaceIcon"
            class="select-none rounded-sm border-none object-cover icon-lg"
            alt="Slack workspace icon"
            @error="logoLoadFailed = true" />
          <span class="text-lg font-normal text-md">
            {{ tenantStore.slackIntegration?.workspaceName }}
          </span>
        </div>
      </template>
    </div>
  </div>
</template>
