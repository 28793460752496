<script setup lang="ts">
import { computed, onUnmounted, ref } from "vue";

import actions from "~/actions";
import { getPropertyPartialTask, getPropertyValueFromTask } from "~/common/properties";
import AiSpinner from "~/components/dumb/AiSpinner.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import PriorityChip from "~/components/dumb/PriorityChip.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { notify } from "~/components/notifications";
import {
  PRIORITY_COLOR_MAP,
  PRIORITY_OPTIONS,
  PRIORITY_SHORTCUT_MAP,
  UNPRIORITIZED_PRIORITY_LABEL,
} from "~/constants/priority";
import { colorsByTheme } from "~/constants/style";
import { PriorityFieldIcon, PriorityIcon } from "~/icons";
import { CommandId, DropdownMenuItemKind, EditorMode, NotificationType, Placement } from "~/shared/enums";
import type { PropertyDefaultPriority, PropertyValueForKind, Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { generateFieldRecommendation } from "~/utils/recommendation";

type Value = PropertyValueForKind<PropertyDefaultPriority>;

const props = defineProps<{
  property: PropertyDefaultPriority;
  tasks: Task[];
  defaultValue?: Value;
  editorMode: EditorMode;
  value?: Value;
}>();

const emit = defineEmits<{
  update: [value?: Value];
  reject: [];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const feedbackTooltipNode = ref<HTMLDivElement | null>(null);

const selectedPriority = computed(() => {
  if (props.editorMode === EditorMode.FORM) {
    return props.value ?? null;
  }

  const firstTaskPriority = getPropertyValueFromTask(props.property, props.tasks[0]);
  return props.tasks.every((task) => getPropertyValueFromTask(props.property, task) === firstTaskPriority)
    ? firstTaskPriority
    : null;
});

const loading = computed(() => appStore.isLoadingTasksProperty(props.tasks, props.property.duid));

const isChipRecommendationMode = computed(() => props.editorMode === EditorMode.CHIP_RECOMMENDATION);
const isChipMode = computed(() => props.editorMode === EditorMode.CHIP || isChipRecommendationMode.value);
const isListMode = computed(() => props.editorMode === EditorMode.LIST);
const isDetailBaseMode = computed(() => props.editorMode === EditorMode.DETAIL);
const isDetailRecommendationMode = computed(() => props.editorMode === EditorMode.DETAIL_RECOMMENDATION);
const isTaskDetailMode = computed(() => isDetailBaseMode.value || isDetailRecommendationMode.value);
const isContextMenuMode = computed(() => props.editorMode === EditorMode.CONTEXT_MENU);
const isFormMode = computed(() => props.editorMode === EditorMode.FORM);

const selectedTasks = computed(() =>
  isChipMode.value ? props.tasks : dataStore.getTasksByDuidsOrdered([...appStore.selectedTaskDuids])
);
const selectedTaskDuids = computed(() => selectedTasks.value.map((e) => e.duid));
const updatePriority = (priority: Value) =>
  dataStore.updateTasks(
    selectedTasks.value.map((task) => ({ duid: task.duid, ...getPropertyPartialTask(props.property, task, priority) }))
  );

const generateRecommendation = async () => {
  if (selectedTaskDuids.value.length > 100) {
    notify({ message: "Dart AI cannot fill out more than 100 tasks at once", type: NotificationType.ERROR });
    return;
  }

  appStore.startLoadingAiTaskProperties(selectedTaskDuids.value, props.property.duid);
  const [taskUpdates, recDuids] = await generateFieldRecommendation(
    selectedTasks.value,
    "priority",
    props.defaultValue ?? null
  );

  dataStore.updateTasks(taskUpdates);
  appStore.finishLoadingAiTaskProperties(selectedTaskDuids.value, props.property.duid);
  actions.visualization.showFeedbackTooltipForTask(
    selectedTaskDuids.value,
    recDuids,
    props.editorMode,
    feedbackTooltipNode.value ?? null
  );
};

const rejectRecommendation = () => {
  updatePriority(props.defaultValue ?? null);
  emit("update");
  emit("reject");
};

const dropdownSections = computed(() => {
  const firstTaskPriority = isFormMode.value ? props.value : getPropertyValueFromTask(props.property, props.tasks[0]);
  const priorityOfSelectedTasks = isFormMode.value
    ? props.value
    : selectedTasks.value.every((task) => getPropertyValueFromTask(props.property, task) === firstTaskPriority)
      ? firstTaskPriority
      : undefined;

  return [
    {
      title: props.property.title,
      items: PRIORITY_OPTIONS.map((priority) => {
        const hasPriority = priority !== null;
        return {
          title: hasPriority ? priority.toString() : UNPRIORITIZED_PRIORITY_LABEL,
          kind: DropdownMenuItemKind.BUTTON,
          icon: hasPriority ? PriorityIcon : PriorityFieldIcon,
          iconArgs: {
            class: "icon-sm",
            style: hasPriority ? { color: PRIORITY_COLOR_MAP.get(priority) } : {},
          },
          disabled: priority === priorityOfSelectedTasks,
          commandId: PRIORITY_SHORTCUT_MAP.get(priority),
          onClick: () => {
            if (isFormMode.value) {
              emit("update", priority);
              return;
            }

            updatePriority(priority);
            emit("update");
          },
        };
      }),
    },
  ];
});

onUnmounted(() => {
  appStore.showFeedbackTooltip(null, []);
});
</script>

<template>
  <DropdownMenu
    :container="isChipMode ? '#dart-task-creation-modal-wrapper' : isFormMode ? '#dart-form-wrapper' : undefined"
    :sections="dropdownSections"
    :block="isListMode || isFormMode"
    :height-block="isListMode || isFormMode"
    :skidding="isFormMode ? -1 : undefined"
    :distance="isFormMode ? 2 : undefined"
    :show-on-hover="isContextMenuMode"
    :show-recommendation-button="!isFormMode"
    :cover="!isContextMenuMode && !isTaskDetailMode && !isFormMode"
    :placement="isContextMenuMode ? Placement.RIGHT_TOP : Placement.BOTTOM_LEFT"
    :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }"
    @recommend="generateRecommendation">
    <DropdownMenuItemContent
      v-if="isContextMenuMode"
      :icon="PriorityFieldIcon"
      :title="`Change ${property.title.toLowerCase()}`"
      is-submenu />
    <Tooltip
      v-else
      :disabled="isChipRecommendationMode || isFormMode"
      :command-id="CommandId.CHANGE_PRIORITY"
      :block="isListMode || isFormMode"
      :height-block="isListMode || isFormMode"
      :class="isTaskDetailMode && 'w-full'">
      <div
        ref="feedbackTooltipNode"
        class="group/priority flex size-full max-w-full items-center text-sm"
        :class="{
          'px-1.5': isListMode,
          'justify-start rounded py-1': isTaskDetailMode,
          'hover:bg-lt': isDetailBaseMode,
          'bg-recommendation-base/10 hover:bg-recommendation-base/20 dark:hover:bg-recommendation-base/20':
            isDetailRecommendationMode,
        }">
        <div v-if="loading" class="flex items-center py-0.5">
          <AiSpinner class="icon-sm" />
        </div>
        <PriorityChip
          v-else
          :value="selectedPriority"
          show-icon
          :show-if-empty="isTaskDetailMode || isChipMode || isFormMode"
          :show-border="isChipMode"
          :editor-mode="editorMode"
          @accept="emit('update')"
          @reject="rejectRecommendation" />
        <div
          v-if="isListMode && selectedPriority === null"
          class="flex size-full items-center justify-center text-transparent group-hover/priority:text-vlt">
          <PriorityFieldIcon class="icon-xs" aria-hidden="true" />
        </div>
      </div>
    </Tooltip>
  </DropdownMenu>
</template>
