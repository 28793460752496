export {
  BrainstormState,
  ChartAggregation,
  DartboardKind,
  EntityName,
  EventActor,
  EventKind,
  FilterApplicability,
  FilterConnector,
  FolderKind,
  GithubIntegrationTenantExtensionStatus as GithubIntegrationStatus,
  IconKind,
  LayoutKind,
  NotionIntegrationTenantExtensionStatus as NotionIntegrationStatus,
  OperationKind,
  OperationModelKind,
  PieChartDisplayMetric,
  Priority,
  PropertyKind,
  RelationshipKindKind,
  ReportKind,
  SamlConfigTenantExtensionStatus as SamlConfigStatus,
  SlackIntegrationTenantExtensionStatus as SlackIntegrationStatus,
  SpaceKind,
  SprintMode,
  ChartType as StatisticType,
  StatusKind,
  Subscription,
  SubtaskDisplayMode,
  SummaryStatisticKind,
  TaskDetailMode,
  TaskKindKind,
  TaskLinkKind,
  TaskSourceType,
  Theme,
  TransactionKind,
  UserRole,
  UserStatus,
  ViewKind,
} from "~/api/generated";

export enum PageKind {
  DARTBOARD = "dartboard",
  DASHBOARD = "dashboard",
  DASHBOARDS_ROOT = "dashboards",
  DOC = "doc",
  FOLDER = "folder",
  FORM = "form",
  HOME = "home",
  INBOX = "inbox",
  SPACE = "space",
  VIEW = "view",
  VIEWS_ROOT = "views",
}

export enum DocKind {
  DOC = "Doc",
}

export enum DashboardKind {
  DASHBOARD = "Dashboard",
}

export enum FormKind {
  FORM = "Form",
}

export enum PagePseudoKind {
  HOME = "Home",
  INBOX = "Inbox",
  DASHBOARD = "Dashboard",
  DASHBOARDS = "Dashboards",
  VIEWS = "Views",
}

export enum TshirtSize {
  EXTRA_SMALL = "XS",
  SMALL = "S",
  MEDIUM = "M",
  LARGE = "L",
  EXTRA_LARGE = "XL",
}

export enum DropdownMenuItemKind {
  INTERNAL_LINK,
  EXTERNAL_LINK,
  BUTTON,
  COMPONENT,
}

export enum FilterType {
  AI = "AI",
  DATE = "DATE",
  INPUT = "INPUT",
  NUMBER = "NUMBER",
  VALUE = "VALUE",
}

export enum AvailabilityFilterType {
  IS_ONLINE,
  TENANT_IS_DART,
  TENANT_HAS_BACKLOG_ENABLED,
  TENANT_HAS_MULTIPLE_ASSIGNEES_ENABLED,
  TENANT_HAS_MULTIPLE_ASSIGNEES_DISABLED,
  TENANT_HAS_PRIORITY_ENABLED,
  TENANT_HAS_START_DATE_ENABLED,
  TENANT_HAS_DUE_DATE_ENABLED,
  TENANT_HAS_SIZE_ENABLED,
  PAGE_IS_VIEW,
  LAYOUT_SUBTASKS_NOT_FLAT,
}

export enum CommandId {
  ADD_ASSIGNEES,
  ADD_ATTACHMENT,
  ADD_FILTER,
  ADD_LINK,
  ADD_TAGS,
  CHANGE_ASSIGNEE_TO_SELF,
  CHANGE_ASSIGNEE_TO_UNASSIGNED,
  CHANGE_ASSIGNEE,
  CHANGE_DARTBOARD,
  CHANGE_DUE_DATE,
  CHANGE_FILTER_APPLICABILITY,
  CHANGE_FILTER_CONNECTOR,
  CHANGE_FILTER_VALUES,
  CHANGE_PARENT,
  CHANGE_PRIORITY_TO_CRITICAL,
  CHANGE_PRIORITY_TO_HIGH,
  CHANGE_PRIORITY_TO_LOW,
  CHANGE_PRIORITY_TO_MEDIUM,
  CHANGE_PRIORITY_TO_UNPRIORITIZED,
  CHANGE_PRIORITY,
  CHANGE_SIZE,
  CHANGE_START_DATE,
  CHANGE_STATUS_TO_DEFAULT_FINISHED,
  CHANGE_STATUS,
  CLEAR_FILTER,
  COPY_BRANCH,
  COPY_LINK,
  CREATE_DARTBOARD,
  CREATE_DASHBOARD,
  CREATE_DOC,
  CREATE_FOLDER,
  CREATE_REPORT,
  CREATE_SPACE,
  CREATE_SUBTASKS,
  CREATE_TASK_QUICKLY,
  CREATE_TASK_UNDERNEATH,
  CREATE_TASK,
  CREATE_VIEW,
  CYCLE_BLOCKED_STATUSES,
  CYCLE_CANCELED_STATUSES,
  CYCLE_FINISHED_STATUSES,
  CYCLE_STARTED_STATUSES,
  CYCLE_UNSTARTED_STATUSES,
  DELETE_TASK,
  EDIT_DESCRIPTION,
  EDIT_TITLE,
  EDITOR_ADD_LINK,
  EDITOR_AI_ACTIONS,
  EDITOR_BOLD,
  EDITOR_ITALICIZE,
  EDITOR_SET_BULLETED_LIST,
  EDITOR_SET_CHECKLIST,
  EDITOR_SET_CODE_BLOCK,
  EDITOR_SET_CODE_SNIPPET,
  EDITOR_SET_HORIZONTAL_RULE,
  EDITOR_SET_LARGE_HEADER,
  EDITOR_SET_MEDIUM_HEADER,
  EDITOR_SET_NUMBERED_LIST,
  EDITOR_SET_QUOTE,
  EDITOR_SET_SMALL_HEADER,
  EDITOR_SET_STANDARD_TEXT,
  EDITOR_SET_TOGGLE_BLOCK,
  EDITOR_STRIKETHROUGH,
  EDITOR_TOGGLE_CHECKED,
  EDITOR_UNDERLINE,
  GO_DOWN,
  GO_LEFT,
  GO_RIGHT,
  GO_TO_BOTTOM_OF_DARTBOARD,
  GO_TO_DARTBOARD,
  GO_TO_DASHBOARD,
  GO_TO_DASHBOARDS,
  GO_TO_DOC,
  GO_TO_FOLDER,
  GO_TO_HOME,
  GO_TO_INBOX,
  GO_TO_MEDIA_NEXT,
  GO_TO_MEDIA_PREVIOUS,
  GO_TO_MY_TASKS,
  GO_TO_REPORTS,
  GO_TO_SETTINGS,
  GO_TO_TASK,
  GO_TO_TOP_OF_DARTBOARD,
  GO_TO_TRASH,
  GO_TO_VIEW,
  GO_TO_VIEWS,
  GO_UP,
  HIDE_SUBTASKS,
  INDENT_TO_SUBTASK,
  LOG_OUT,
  MARKDOWN_ADD_LINK,
  MARKDOWN_BOLD,
  MARKDOWN_ITALICIZE,
  MARKDOWN_SET_BULLETED_LIST,
  MARKDOWN_SET_CHECKLIST,
  MARKDOWN_SET_CODE_BLOCK,
  MARKDOWN_SET_CODE_SNIPPET,
  MARKDOWN_SET_HORIZONTAL_RULE,
  MARKDOWN_SET_LARGE_HEADER,
  MARKDOWN_SET_MEDIUM_HEADER,
  MARKDOWN_SET_NUMBERED_LIST,
  MARKDOWN_SET_QUOTE,
  MARKDOWN_SET_SMALL_HEADER,
  MARKDOWN_SET_TOGGLE_BLOCK,
  MARKDOWN_STRIKETHROUGH,
  MARKDOWN_UNDERLINE,
  MOVE_TASK_DOWN,
  MOVE_TASK_TO_BOTTOM,
  MOVE_TASK_TO_TOP,
  MOVE_TASK_UP,
  MOVE_TO_ACTIVE,
  MOVE_TO_BACKLOG,
  MOVE_TO_DARTBOARD,
  MOVE_TO_NEXT,
  NAV_BACK,
  NAV_FORWARD,
  NAV_REFRESH,
  OPEN_TASK_IN_RIGHTBAR,
  OUTDENT_TO_UNSUBTASK,
  QC_IGNORE_SUGGESTION,
  QC_PREFIX_ASSIGNEE,
  QC_PREFIX_ASSIGNEES,
  QC_PREFIX_DARTBOARD,
  QC_PREFIX_DUE_DATE,
  QC_PREFIX_GENERAL,
  QC_PREFIX_PRIORITY,
  QC_PREFIX_SIZE,
  QC_PREFIX_STATUS,
  QC_PREFIX_TAG,
  QC_UNPREFIX_ASSIGNEE,
  QC_UNPREFIX_DARTBOARD,
  QC_UNPREFIX_DUE_DATE,
  QC_UNPREFIX_PRIORITY,
  QC_UNPREFIX_SIZE,
  QC_UNPREFIX_STATUS,
  QC_UNPREFIX_TAG,
  RECOMMEND_PROPERTIES,
  RECOMMEND_SUBTASKS,
  REDO,
  REMOVE_ASSIGNEES,
  REMOVE_FILTER,
  REMOVE_TAGS,
  REPLICATE_TASK,
  RESET_VIEW_CLOSE,
  RESET_VIEW,
  RESUME_DRAFT,
  SAVE_COMMENT,
  SAVE_TASK_OPEN_IN_CREATION_MODAL_AND_CREATE_ANOTHER,
  SAVE_TASK_OPEN_IN_CREATION_MODAL,
  SAVE_TASK,
  SELECT_ALL_ABOVE,
  SELECT_ALL_BELOW,
  SELECT_ALL,
  SELECT_NEXT_ABOVE,
  SELECT_NEXT_BELOW,
  SELECT_TASK,
  SET_LAYOUT_TO_BOARD,
  SET_LAYOUT_TO_CALENDAR,
  SET_LAYOUT_TO_LIST,
  SET_LAYOUT_TO_NEXT,
  SET_LAYOUT_TO_PREVIOUS,
  SET_LAYOUT_TO_ROADMAP,
  SET_REMINDER,
  SHARE_FEEDBACK,
  SHOW_SUBTASKS,
  START_NEXT_SPRINT,
  START_TIMER,
  SWAP_ASSIGNEE,
  SWAP,
  TOGGLE_COMMAND_CENTER,
  TOGGLE_FILTERS_AND_SORTS_TO_HIDDEN,
  TOGGLE_FILTERS_AND_SORTS,
  TOGGLE_FILTERS_TO_SHOWN,
  TOGGLE_KEYBOARD_SHORTCUTS,
  TOGGLE_SEARCH,
  TOGGLE_SORTS_TO_SHOWN,
  TOGGLE_TASK_OPEN_IN_DETAIL,
  TOGGLE_THEME,
  UNDO,
  UNKNOWN,
  UPDATE_DART,
  VIEW_ATTACHMENTS,
  VIEW_COMMENTS,
  VIEW_LINKS,
  ZOOM_IN,
  ZOOM_OUT,
}

export enum KeyboardShortcutStrokeKind {
  KEY,
  MARKDOWN,
  SEPARATOR,
  TEXT,
}

export enum Placement {
  AUTO = "auto",
  BOTTOM = "bottom",
  BOTTOM_LEFT = "bottom-start",
  BOTTOM_RIGHT = "bottom-end",
  LEFT = "left",
  LEFT_TOP = "left-start",
  LEFT_BOTTOM = "left-end",
  RIGHT = "right",
  RIGHT_TOP = "right-start",
  RIGHT_BOTTOM = "right-end",
  TOP = "top",
  TOP_LEFT = "top-start",
  TOP_RIGHT = "top-end",
}

export enum ModalPosition {
  BOTTOM,
  LEFT,
  RIGHT,
  TOP,
  CENTER,
}

export enum ModelType {
  TASK = "task",
}

export enum AnimationDuration {
  SHORT = 100,
  MEDIUM = 200,
  LONG = 300,
}

export enum ModalKind {
  AGENT_DEMO,
  APP_DOWNLOAD,
  BRAINSTORM,
  COMMAND_CENTER,
  CREATE_SPACE,
  CREATE_TASK,
  FEEDBACK,
  FULLSCREEN_MEDIA,
  INTERNAL_FORM,
  LINK,
  MOBILE_LEFTBAR,
  NOTION_LINK,
  ONBOARDING,
  PAGE_PERMISSIONS,
  PLAN_PROJECT,
  REMAP_MODAL,
  REMINDER,
  REPORT_CREATION,
  SEARCH,
  SETTINGS,
  SHORTCUTS,
  UPGRADE_REQUIRED,
}

export enum EditorMode {
  BOARD,
  CALENDAR,
  CHIP_GITHUB,
  CHIP_RECOMMENDATION,
  CHIP,
  CONTEXT_MENU,
  DOC,
  FILTER,
  FOLDER,
  FORM,
  LIST_MINI_ROADMAP,
  LIST_MINI_TCM,
  LIST_MINI,
  LIST,
  NLP,
  DETAIL_RECOMMENDATION,
  DETAIL,
  ROADMAP,
  TCM,
}

export enum RecommendationKind {
  ACTION_ITEMS = "action-items",
  BRAINSTORM = "brainstorm",
  CONVERT_TO_TASKS = "convert-to-tasks",
  IMPROVE = "improve",
  PROPERTIES = "properties",
  SIMPLIFY = "simplify",
  SPLIT = "split",
  SUBTASKS = "subtasks",
  TRANSLATE = "translate",
  WRITE = "write",
}

export enum RecommendationAction {
  DISCARD,
  KEEP,
  REPLACE,
}

export enum TutorialName {
  CREATE_TASK_WITH_SUBTASK_RECS,
  MAKE_A_TASK,
  SET_A_PROFILE_PICTURE,
  BRAINSTORM_WITH_AI,
  DOWNLOAD_THE_APPS,
  INVITE_TEAMMATES,
  CHECKLIST_COMPLETED,
  TRIAL_STARTED,
  TRIAL_ENDED,
  PLAN_WITH_AI,
}

export enum ButtonStyle {
  PRIMARY,
  SECONDARY,
  DANGER,
  SECONDARY_DANGER,
  RECOMMENDATION,
  SECONDARY_RECOMMENDATION,
  CHIP,
}

export enum ButtonSize {
  CHIP,
  SMALL,
  LARGE,
}

export enum SectionKind {
  COMPONENT,
  CREATE,
  DEFAULT,
  DISABLED,
  DROPDOWN_CREATE,
  DROPDOWN,
}

// TODO remove because this hardcodes relationship types
export enum RelationshipDropdownKind {
  BLOCKED,
  BLOCKING,
  CREATE_SUBTASK,
  DUPLICATE,
  MERGED_INTO,
  RELATES_TO,
  SET_PARENT,
  SET_SUBTASK,
}

export enum DialogMode {
  STANDARD,
  SECONDARY_ACTION,
  DELETE,
}

export enum IconSize {
  XS,
  S,
  M,
  L,
  XL,
}

export enum IconMode {
  PRIMARY,
  DELETE,
  NONE,
}

export enum Entitlement {
  TRIAL_DAYS = "trialDays",
  MAX_USERS = "maxUsers",
  ROLES = "roles",
  STATUSES = "statuses",
  MAX_CUSTOM_PROPERTIES = "maxCustomProperties",
  MAX_FORMS = "maxForms",
  MAX_TASK_KINDS = "maxTaskKinds",
  DISCORD = "discord",
  GITHUB = "github",
  SLACK = "slack",
  WEBHOOKS = "webhooks",
  ZAPIER = "zapier",
}

export enum GoogleLoginAction {
  LOGIN = "login",
  SIGN_UP = "signup",
}

export enum GoogleLoginPlatform {
  WEB = "web",
  DESKTOP = "desktop",
  MOBILE = "mobile",
}

export enum BillingPeriod {
  MONTHLY = "month",
  ANNUALLY = "year",
}

export enum ActivityKind {
  CREATED = "Created",
  UPDATED = "Updated",
}

export enum TypeaheadSpecialEntity {
  DOC = "doc",
  TASK = "task",
}

export enum EmojiSkinTone {
  NONE = "",
  LIGHT = "🏻",
  MEDIUM_LIGHT = "🏼",
  MEDIUM = "🏽",
  MEDIUM_DARK = "🏾",
  DARK = "🏿",
}

export enum Language {
  ENGLISH = "English",
  MANDARIN_CHINESE = "Mandarin Chinese",
  HINDI = "Hindi",
  SPANISH = "Spanish",
  ARABIC = "Arabic",
  FRENCH = "French",
  BENGALI = "Bengali",
  RUSSIAN = "Russian",
  PORTUGUESE = "Portuguese",
  SWAHILI = "Swahili",
  INDONESIAN = "Indonesian",
  URDU = "Urdu",
  JAPANESE = "Japanese",
  GERMAN = "German",
  PUNJABI = "Punjabi",
  FARSI_PERSIAN = "Farsi/Persian",
  JAVANESE = "Javanese",
  WU_CHINESE = "Wu Chinese",
  VIETNAMESE = "Vietnamese",
  ITALIAN = "Italian",
  TURKISH = "Turkish",
  MARATHI = "Marathi",
  TELUGU = "Telugu",
  TAMIL = "Tamil",
  KOREAN = "Korean",
  JIN_CHINESE = "Jin Chinese",
  HAUSA = "Hausa",
  THAI = "Thai",
  YUE_CHINESE_CANTONESE = "Yue Chinese/Cantonese",
  GUJARATI = "Gujarati",
  BHOJPURI = "Bhojpuri",
}

export enum FeedbackRating {
  GOOD = "Good",
  BAD = "Bad",
}

export enum RecurrenceKind {
  DAILY = "Daily",
  WEEKLY = "Weekly",
  MONTHLY = "Monthly",
  YEARLY = "Yearly",
}

export enum UserDataMode {
  ALL = "all",
  NONE = "none",
  AUTO = "auto",
  EXTRA = "extra",
  SPECIFIC = "specific",
}

export enum StoreDataPreservationMode {
  NONE,
  RECENT,
  ALL,
}

export enum InboxFilter {
  UNREAD,
  NONE,
}

export enum NotificationType {
  INFO,
  SUCCESS,
  WARNING,
  ERROR,
}

export enum NumberFormat {
  INTEGER = "Integer",
  PERCENTAGE = "Percentage",
  DOLLARS = "Dollars",
}

export enum Feature {
  SIGNUP_QUESTIONS = 0,
  REVERSE_TRIAL = 1,
}

export enum ModalWidth {
  XS,
  S,
  M,
  L,
  XL,
  XXL,
  XXXL,
  FULL,
}

export enum ColorHex {
  SOFT_BLUE = "#6366f1",
  SOFT_VIOLET = "#8b5cf6",
  BRIGHT_MAGENTA = "#d946ef",
  SOFT_PINK = "#ec4899",
  BRIGHT_RED = "#f43f5e",
  VIVID_RED = "#f02424",
  BRIGHT_BLUE = "#3b82f6",
  VIVID_BLUE = "#0ea5e9",
  STRONG_CYAN = "#2dd4bf",
  SOFT_CYAN = "#4ade80",
  VIVID_ORANGE = "#fa9e15",
  AMBER = "#f97316",
  MODERATE_ORANGE = "#b97d37",
  CAMEL = "#cb985c",
  LIME_GREEN = "#10b981",
  STRONG_GREEN = "#84cc16",
  VIVID_YELLOW = "#fff615",
  PURE_YELLOW = "#ffcc00",
}
