<script setup lang="ts">
import { computed } from "vue";

import Template from "~/components/dumb/Template.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { EditorMode, NumberFormat } from "~/shared/enums";
import type { Property } from "~/shared/types";

const props = defineProps<{
  value: number | null;
  format: NumberFormat;
  property?: Property;
  editorMode: EditorMode;
}>();

const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);

const isDollars = computed(() => props.format === NumberFormat.DOLLARS);
const isPercentage = computed(() => props.format === NumberFormat.PERCENTAGE);
</script>

<template>
  <component :is="isBoardMode ? Tooltip : Template" v-if="value !== null" :text="property?.title">
    <div
      class="flex min-h-5 items-center justify-center hyphens-auto break-words rounded border px-1 text-xs text-md border-oncolor">
      <span v-if="isDollars" class="pr-1">$</span>
      {{ value }}
      <span v-if="isPercentage" class="pl-1">%</span>
    </div>
  </component>
</template>
