import type { ICommandFromClientType } from "commandbar/build/internal/src/middleware/types";

export const CHANGE_ASSIGNEE_KEY = "change_assignee";
export const ADD_ASSIGNEES_KEY = "add_assignees";
export const REMOVE_ASSIGNEES_KEY = "remove_assignees";

const STANDARD_AVAILABILITY_RULES: ICommandFromClientType["availability_rules"] = [
  {
    type: "element",
    operator: "idOnPage",
    value: "dartboard",
    reason: "You're not on a page that has task assignees",
  },
  {
    type: "context",
    field: "hasSelection",
    operator: "isTrue",
    reason: "You don't have a task selected",
  },
];

const ASSIGNEE_SHARED_FIELDS = {
  tags: ["person", "dri", "user", "member", "reassign", "teammate"],
  category: 2327,
  icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 20C5.33579 17.5226 8.50702 16 12 16C15.493 16 18.6642 17.5226 21 20M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
};

const CHANGE_ASSIGNEE_SHORTCUT = ["a"];
export const CHANGE_ASSIGNEE_COMMAND: ICommandFromClientType = {
  name: CHANGE_ASSIGNEE_KEY,
  text: "Change assignee",
  template: {
    type: "callback",
    value: "setAssignee",
  },
  availability_rules: STANDARD_AVAILABILITY_RULES,
  arguments: {
    assignee: {
      type: "context",
      value: "possibleAssignees",
      order_key: 0,
      label: "New assignee",
    },
  },
  shortcut_mac: CHANGE_ASSIGNEE_SHORTCUT,
  shortcut_win: CHANGE_ASSIGNEE_SHORTCUT,
  ...ASSIGNEE_SHARED_FIELDS,
};

const ADD_ASSIGNEES_SHORTCUT = ["a a"];
export const ADD_ASSIGNEES_COMMAND: ICommandFromClientType = {
  name: ADD_ASSIGNEES_KEY,
  text: "Add assignees",
  template: {
    type: "callback",
    value: "addAssignees",
  },
  availability_rules: STANDARD_AVAILABILITY_RULES,
  arguments: {
    assignees: {
      type: "context",
      input_type: "multi-select",
      value: "possibleAssignees",
      order_key: 0,
      label: "New assignees",
    },
  },
  shortcut_mac: ADD_ASSIGNEES_SHORTCUT,
  shortcut_win: ADD_ASSIGNEES_SHORTCUT,
  ...ASSIGNEE_SHARED_FIELDS,
};

const REMOVE_ASSIGNEES_SHORTCUT = ["a r"];
export const REMOVE_ASSIGNEES_COMMAND: ICommandFromClientType = {
  name: REMOVE_ASSIGNEES_KEY,
  text: "Remove assignees",
  template: {
    type: "callback",
    value: "removeAssignees",
  },
  availability_rules: STANDARD_AVAILABILITY_RULES,
  arguments: {
    assignees: {
      type: "context",
      input_type: "multi-select",
      value: "currentAssignees",
      order_key: 0,
      label: "Assignees",
    },
  },
  shortcut_mac: REMOVE_ASSIGNEES_SHORTCUT,
  shortcut_win: REMOVE_ASSIGNEES_SHORTCUT,
  ...ASSIGNEE_SHARED_FIELDS,
};
