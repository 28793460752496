<script setup lang="ts">
import { usePrevious } from "@vueuse/core";
import { computed, nextTick, ref, watch } from "vue";
import type { ComponentExposed } from "vue-component-type-helpers";
import { useRouter } from "vue-router";

import actions from "~/actions";
import { getPropertyConfig, getPropertyWithConfigList } from "~/common/properties";
import Button from "~/components/dumb/Button.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import DragArea from "~/components/dumb/DragArea.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import EmptyState from "~/components/dumb/EmptyState.vue";
import Input from "~/components/dumb/Input.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import PageIconPicker from "~/components/dumb/PageIconPicker.vue";
import RadioCardGroup from "~/components/dumb/RadioCardGroup.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import FormFieldCard from "~/components/forms/FormFieldCard.vue";
import {
  DotsHorizontalIcon,
  HideIcon,
  InternalIcon,
  LinkIcon,
  PlusIcon,
  PublicIcon,
  TrashIcon,
  UnsetFormIcon,
} from "~/icons";
import { getQueryParam, makeLinkToFormsSettingsRef } from "~/router/common";
import { ButtonStyle, DialogMode, DropdownMenuItemKind, IconKind, Placement } from "~/shared/enums";
import { type Form, type FormField, type Property } from "~/shared/types";
import { useDataStore, useTenantStore } from "~/stores";
import { getOrdersBetween } from "~/utils/orderManager";
import { getEmojiRecommendation } from "~/utils/recommendation";

const HIDDEN_VALUE = "hidden";
const PUBLIC_VALUE = "public";
const INTERNAL_VALUE = "internal";

const router = useRouter();
const dataStore = useDataStore();
const tenantStore = useTenantStore();

const dragArea = ref<ComponentExposed<typeof DragArea<FormField & { title: string }, typeof FormFieldCard>> | null>(
  null
);
const dialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);

const form = computed<Form | undefined>(() => dataStore.getFormByDuid(getQueryParam("form") ?? ""));
const previousForm = usePrevious(form);
const formFields = computed<FormField[]>(() => dataStore.getFormFieldRelatedToFormOrdered(form.value?.duid ?? ""));
const dragItems = computed(() => formFields.value.map((formField) => ({ ...formField, title: formField.label })));

const propertyDuidSet = computed<Set<string>>(
  () => new Set(formFields.value.map((formField) => formField.propertyDuid))
);

const title = ref(form.value?.title ?? "");
const titleInput = ref<InstanceType<typeof Input> | null>(null);

const isValidName = (value: string) => ({ isValid: value.trim() !== "", error: "Form name cannot be empty" });

const finalizeTitleAndEmoji = async () => {
  if (!form.value || !titleInput.value) {
    return;
  }

  const formDuid = form.value.duid;

  if (!isValidName(titleInput.value.value).isValid) {
    title.value = form.value?.title ?? "";
    return;
  }

  if (form.value.iconKind !== IconKind.NONE) {
    dataStore.updateForm({ duid: formDuid, title: titleInput.value.value });
    return;
  }

  const emojiRecUpdate = await getEmojiRecommendation(formDuid, titleInput.value.value);
  dataStore.updateForm({
    duid: formDuid,
    title: titleInput.value.value,
    ...emojiRecUpdate,
  });
};

const focusTitle = () => {
  if (!titleInput.value) {
    return;
  }
  titleInput.value.focus();
  titleInput.value.select();
};

const createForm = async () => {
  const newForm = await dataStore.createForm(getOrdersBetween(undefined, dataStore.formList[0]?.order)[0]);

  router.replace(makeLinkToFormsSettingsRef(newForm.duid).value);

  setTimeout(() => {
    focusTitle();
  }, 100);
};

const updateSharing = (value: string) => {
  if (!form.value) {
    return;
  }

  dataStore.updateForm({ duid: form.value.duid, hidden: value === HIDDEN_VALUE, public: value === PUBLIC_VALUE });
};

const sharingOptions = computed(() => [
  {
    title: "Hidden",
    description: "This form cannot be used yet",
    value: HIDDEN_VALUE,
    selected: !!form.value?.hidden,
    icon: HideIcon,
    isDefault: true,
  },
  {
    title: "Internal",
    description: `Everyone at ${tenantStore.name} can access this form`,
    value: INTERNAL_VALUE,
    selected: !form.value?.public && !form.value?.hidden,
    icon: InternalIcon,
  },
  {
    title: "Public",
    description: "Anyone with the link can access this form",
    value: PUBLIC_VALUE,
    selected: !!form.value?.public,
    icon: PublicIcon,
  },
]);

const copyLink = () => {
  if (!form.value) {
    return;
  }

  actions.form.copyLink(form.value);
};

const collapseFormFields = (exceptFieldDuid?: string) => {
  const itemRefs = dragArea.value?.itemRefs ?? [];
  itemRefs.forEach((itemRef) => {
    if (itemRef.duid !== exceptFieldDuid) {
      itemRef.setExpanded(false);
    }
  });
};

const expandAndFocusField = (formFieldDuid: string) => {
  nextTick(() => {
    const itemRefs = dragArea.value?.itemRefs ?? [];
    const itemRef = itemRefs.find((e) => e.duid === formFieldDuid);
    if (!itemRef) {
      return;
    }

    itemRef.setExpanded(true);
    nextTick(() => {
      itemRef.focus();
    });
  });
};

const createFormField = async (property: Property) => {
  if (!form.value) {
    return;
  }

  const formField = await dataStore.createFormField(
    form.value,
    property,
    getOrdersBetween(formFields.value[formFields.value.length - 1]?.order, undefined)[0]
  );
  expandAndFocusField(formField.duid);
};

const getFieldProps = (field: FormField) => ({
  field,
  value: undefined,
  onExpand: collapseFormFields,
});

const moveField = (_: string, field: FormField) => {
  dataStore.updateFormField({ duid: field.duid, order: field.order });
};

const onDelete = () => {
  const currForm = form.value ?? previousForm.value;
  if (!currForm) {
    return;
  }

  dataStore.deleteForm(currForm);
  router.replace(makeLinkToFormsSettingsRef(dataStore.formList[0]?.duid).value);
};

const formSections = computed(() => (form.value ? actions.context.form(form.value, dialog.value) : []));

const propertyDropdownSections = computed(() => [
  {
    title: "Properties",
    items: getPropertyWithConfigList()
      .filter(([property, config]) => !propertyDuidSet.value.has(property.duid) && !config.readOnly)
      .map(([property]) => {
        const propertyConfig = getPropertyConfig(property.kind);
        return {
          title: property.title,
          kind: DropdownMenuItemKind.BUTTON,
          icon: propertyConfig.icon,
          onClick: () => createFormField(property),
        };
      }),
  },
]);

watch(
  () => form.value,
  (newForm) => {
    title.value = newForm?.title ?? "";
  }
);

defineExpose({
  collapseFormFields,
  focusTitle,
});
</script>

<template>
  <div v-if="dataStore.formList.length === 0" class="relative flex w-full">
    <EmptyState
      title="No forms"
      description="This workspace doesn't have any forms yet. Get started by creating a new one."
      :main-icon="UnsetFormIcon"
      button-text="Create form"
      :button-icon="PlusIcon"
      @click="createForm" />
  </div>
  <div v-else-if="form" class="mt-10 flex flex-1 flex-col gap-10 overflow-y-auto px-8 pb-72 lg:px-16">
    <div class="flex items-center gap-2">
      <span class="flex-1 select-none text-base text-md">Edit form</span>

      <div class="flex items-center gap-2">
        <Tooltip text="Copy link">
          <button
            type="button"
            class="flex cursor-pointer items-center rounded p-0.5 hover:bg-lt focus:outline-none"
            aria-label="Copy link"
            @click="copyLink">
            <LinkIcon class="text-lt icon-sm" aria-hidden="true" />
          </button>
        </Tooltip>

        <ConfirmationDialog
          ref="dialog"
          :mode="DialogMode.DELETE"
          :title="`Delete ${form.title}`"
          description="Are you sure you want to delete this form? This action cannot be undone."
          confirm-text="Delete"
          cancel-text="Keep"
          :icon="TrashIcon"
          @confirm="onDelete" />
        <DropdownMenu :sections="formSections" :placement="Placement.BOTTOM_RIGHT" :distance="0" :width-pixels="180">
          <div class="rounded text-lt hover:bg-lt">
            <span class="sr-only">Manage form</span>
            <DotsHorizontalIcon class="icon-md" aria-hidden="true" />
          </div>
        </DropdownMenu>
      </div>
    </div>

    <!-- Form name & Sharing  -->
    <div class="flex flex-col text-sm text-md">
      <div class="flex flex-col">
        <div class="flex w-full items-center gap-1">
          <PageIconPicker :page="form">
            <Tooltip text="Change icon">
              <span
                class="mb-5 flex items-center justify-center rounded border p-1 bg-std border-hvy hover:bg-opposite/10">
                <PageIcon :page="form" />
              </span>
            </Tooltip>
          </PageIconPicker>
          <Input
            ref="titleInput"
            :init-value="title"
            class="w-full"
            is-form
            placeholder="Form name"
            label="Name"
            :validate="isValidName"
            @focusout="finalizeTitleAndEmoji" />
        </div>
      </div>

      <!-- Sharing -->
      <div class="flex flex-col">
        <span class="select-none text-sm text-lt">Sharing</span>
        <RadioCardGroup :items="sharingOptions" @select="updateSharing" />
      </div>
    </div>

    <!-- Form fields -->
    <div class="flex flex-col">
      <span class="select-none text-sm text-lt">Form fields</span>
      <DragArea
        ref="dragArea"
        group="fields"
        category="fields"
        class="mb-2 flex !h-auto min-h-[20px] w-full flex-1 flex-col gap-3 rounded py-1"
        drop-area-classes="bg-md/50"
        drag-handle
        :items="dragItems"
        :component="FormFieldCard"
        :get-component-props="getFieldProps"
        @change="moveField" />

      <DropdownMenu :sections="propertyDropdownSections" :distance="2" class="w-fit">
        <Button text="Add a property" :icon="PlusIcon" :btn-style="ButtonStyle.SECONDARY" class="w-max pl-2" />
      </DropdownMenu>
    </div>
  </div>
</template>
