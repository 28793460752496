<script setup lang="ts">
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

import Tooltip from "~/components/dumb/Tooltip.vue";
import { ArrowLeftIcon, ArrowRightIcon, RefreshIcon } from "~/icons";
import { CommandId } from "~/shared/enums";

defineProps<{
  isMobileLeftbar?: boolean;
}>();

const router = useRouter();

const backEnabled = ref(!!router.options.history.state.back);
const forwardEnabled = ref(!!router.options.history.state.forward);

watch(
  () => router.currentRoute.value,
  () => {
    backEnabled.value = !!router.options.history.state.back;
    forwardEnabled.value = !!router.options.history.state.forward;
  },
  { deep: true }
);

const back = () => {
  if (!backEnabled.value) {
    return;
  }
  router.back();
};

const forward = () => {
  if (!forwardEnabled.value) {
    return;
  }
  router.forward();
};

const refresh = () => window.location.reload();
</script>

<template>
  <div class="flex items-center">
    <Tooltip :disabled="!backEnabled" :command-id="CommandId.NAV_BACK">
      <button
        type="button"
        class="flex items-center justify-center rounded p-0.5 app-drag-none focus-ring-std icon-lg"
        :class="backEnabled ? 'cursor-pointer text-lt hover:bg-md' : 'cursor-not-allowed text-vlt'"
        aria-label="Back"
        @click="back"
        @keydown.enter="back">
        <ArrowLeftIcon class="icon-sm" aria-hidden="true" />
      </button>
    </Tooltip>

    <Tooltip :disabled="!forwardEnabled" :command-id="CommandId.NAV_FORWARD">
      <button
        type="button"
        class="flex items-center justify-center rounded p-0.5 app-drag-none focus-ring-std icon-lg"
        :class="forwardEnabled ? 'cursor-pointer text-lt hover:bg-md' : 'cursor-not-allowed text-vlt'"
        aria-label="Forward"
        @click="forward"
        @keydown.enter="forward">
        <ArrowRightIcon class="icon-sm" aria-hidden="true" />
      </button>
    </Tooltip>

    <Tooltip :command-id="CommandId.NAV_REFRESH">
      <button
        type="button"
        class="flex items-center justify-center rounded p-0.5 app-drag-none focus-ring-std icon-lg hover:bg-md"
        aria-label="Refresh"
        @click="refresh"
        @keydown.enter="refresh">
        <RefreshIcon class="text-lt icon-sm" aria-hidden="true" />
      </button>
    </Tooltip>
  </div>
</template>
