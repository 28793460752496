<script setup lang="ts">
import { computed, ref } from "vue";

import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import {
  ChildRelationshipIcon,
  ExecuteIcon,
  RecommendationIcon,
  RecommendPropertiesIcon,
  SlashSquareIcon,
} from "~/icons";
import { ButtonSize, ButtonStyle, CommandId, DropdownMenuItemKind, EditorMode, IconSize } from "~/shared/enums";

const props = defineProps<{
  editorMode: EditorMode;
  titleEmpty?: boolean;
  generatingRecommendations?: boolean;
}>();

const emit = defineEmits<{
  recommendProperties: [];
  recommendSubtasks: [];
  improveDescription: [];
  doTask: [];
}>();

const buttonRef = ref<InstanceType<typeof Button> | null>(null);
const button = computed(() => buttonRef.value?.$el);

const dropdownSections = computed(() => [
  {
    title: "Task AI",
    items: [
      {
        title: "Fill out properties",
        kind: DropdownMenuItemKind.BUTTON,
        icon: RecommendPropertiesIcon,
        disabled: props.titleEmpty,
        disabledReason: "Fill out the task title first",
        commandId: CommandId.RECOMMEND_PROPERTIES,
        onClick: () => emit("recommendProperties"),
      },
      {
        title: "Break into subtasks",
        kind: DropdownMenuItemKind.BUTTON,
        icon: ChildRelationshipIcon,
        disabled: props.titleEmpty,
        disabledReason: "Fill out the task title first",
        commandId: CommandId.RECOMMEND_SUBTASKS,
        onClick: () => emit("recommendSubtasks"),
      },
      {
        title: `${props.titleEmpty ? "Write" : "Improve"} description`,
        kind: DropdownMenuItemKind.BUTTON,
        icon: SlashSquareIcon,
        onClick: () => emit("improveDescription"),
      },
      {
        title: "Do this task",
        kind: DropdownMenuItemKind.BUTTON,
        icon: ExecuteIcon,
        disabled: props.titleEmpty,
        disabledReason: "Fill out the task title first",
        onClick: () => emit("doTask"),
      },
    ],
  },
]);

defineExpose({
  button,
});
</script>

<template>
  <DropdownMenu :sections="dropdownSections" :distance="2" :width-pixels="240">
    <Tooltip text="Use AI to improve this task">
      <Button
        ref="buttonRef"
        text="Task AI"
        :btn-style="ButtonStyle.SECONDARY_RECOMMENDATION"
        :size="ButtonSize.CHIP"
        :icon="RecommendationIcon"
        :icon-size="IconSize.XS"
        :working="generatingRecommendations"
        :class="editorMode === EditorMode.TCM ? 'h-8 px-2' : 'h-6'" />
    </Tooltip>
  </DropdownMenu>
</template>
