<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M21 12L9 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M21 6L9 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M21 18L9 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M5.58423 7.99316C5.58423 8.43262 5.33813 8.62598 4.97778 8.62598C4.60864 8.62598 4.36255 8.43262 4.36255 7.99316V5.65527H4.34497L3.94946 5.91895C3.80005 6.02441 3.73853 6.04199 3.62427 6.04199C3.41333 6.05078 3.22876 5.88379 3.22876 5.6377C3.22876 5.43555 3.31665 5.3125 3.51001 5.18945L4.13403 4.75879C4.37134 4.60938 4.57349 4.49512 4.89868 4.49512C5.30298 4.49512 5.58423 4.74121 5.58423 5.13672V7.99316Z"
      fill="currentColor" />
    <path
      d="M5.8479 14.0137H3.53638C3.22876 14.0137 3.0354 13.8027 3.0354 13.5127C3.0354 13.3018 3.1145 13.1436 3.29028 12.9941L4.45923 12.0449C4.91626 11.667 5.02173 11.5 5.02173 11.2891C5.02173 11.0518 4.84595 10.8936 4.59985 10.8936C4.37134 10.8936 4.21313 10.999 4.0813 11.2012C3.94946 11.3945 3.80005 11.4648 3.5979 11.4648C3.28149 11.4648 3.08813 11.3066 3.08813 11.0078C3.08813 10.4365 3.71216 9.99707 4.58228 9.99707C5.56665 9.99707 6.18188 10.4805 6.18188 11.21C6.18188 11.7461 5.927 12.0273 5.32056 12.5195L4.60864 13.0908V13.1084H5.8479C6.11157 13.1084 6.30493 13.2842 6.30493 13.5654C6.30493 13.8379 6.10278 14.0137 5.8479 14.0137Z"
      fill="currentColor" />
    <path
      d="M6.32251 18.3291C6.32251 19.085 5.6897 19.5596 4.59985 19.5596C3.85278 19.5596 3.00903 19.2168 3.00903 18.6104C3.00903 18.3115 3.21997 18.1357 3.52759 18.1357C3.69458 18.1357 3.79126 18.2148 3.89673 18.3115C4.06372 18.4609 4.28345 18.6367 4.59985 18.6367C4.8811 18.6367 5.10083 18.4785 5.10083 18.2412C5.09204 17.9775 4.88989 17.8545 4.51196 17.8545H4.38892C4.13403 17.8545 3.97583 17.7139 3.97583 17.459C3.97583 17.1865 4.14282 17.0547 4.38892 17.0547H4.50317C4.85474 17.0547 5.0481 16.9316 5.0481 16.6943C5.0481 16.4395 4.85474 16.3164 4.59106 16.3164C4.38013 16.3164 4.21313 16.3779 4.0022 16.5977C3.87915 16.7295 3.78247 16.8086 3.58032 16.8086C3.29907 16.8086 3.10571 16.624 3.10571 16.3604C3.10571 15.7539 3.88794 15.4375 4.59106 15.4375C5.47876 15.4375 6.16431 15.8066 6.16431 16.501C6.16431 17.0195 5.80396 17.3271 5.35571 17.415V17.4414C5.97974 17.4941 6.32251 17.8281 6.32251 18.3291Z"
      fill="currentColor" />
  </svg>
</template>
