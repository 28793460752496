<script setup lang="ts">
import { computed, getCurrentInstance, nextTick, onMounted, onUnmounted, ref } from "vue";

import Button from "~/components/dumb/Button.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import NavigationButtons from "~/components/dumb/NavigationButtons.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import TextInput from "~/components/dumb/TextInput.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import TutorialMessage from "~/components/dumb/TutorialMessage.vue";
import FilterArea from "~/components/filters/FilterArea.vue";
import { EXCLUDED_VIEW_KINDS, PAGES_PARTIALLY_EDITABLE } from "~/constants/page";
import { EmptyTrashIcon, MenuIcon, PublicIcon, ReportsIcon, SearchIcon, SlashIcon, TrashIcon, XIcon } from "~/icons";
import { goBackOrHome } from "~/router/common";
import {
  ButtonStyle,
  CommandId,
  DialogMode,
  FolderKind,
  IconSize,
  PageKind,
  TutorialName,
  ViewKind,
} from "~/shared/enums";
import { useAppStore, useDataStore, useEnvironmentStore, usePageStore, useUserStore } from "~/stores";
import { getPageDisplayName, getPageLink } from "~/utils/common";

import BrainstormTimer from "./BrainstormTimer.vue";
import DocTopbarInfo from "./DocTopbarInfo.vue";
import InboxTopbarControls from "./InboxTopbarControls.vue";
import InboxTopbarTabs from "./InboxTopbarTabs.vue";
import LayoutEditor from "./LayoutEditor.vue";
import PageTitle from "./PageTitle.vue";
import ProjectAiDropdown from "./ProjectAiDropdown.vue";
import ShowFilterAreaToggle from "./ShowFilterAreaToggle.vue";

const EMPTY_TRASH_DIALOG_DESCRIPTION =
  "Emptying the trash will permanently delete everything in it. This can't be undone. Are you sure you want to proceed?";

const currentInstance = getCurrentInstance();
const appStore = useAppStore();
const dataStore = useDataStore();
const environmentStore = useEnvironmentStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const inputDescription = ref<InstanceType<typeof TextInput> | null>(null);
const layoutEditor = ref<InstanceType<typeof LayoutEditor> | null>(null);

const isEditingDescription = ref(false);

const currentPage = computed(() => appStore.currentPage);
const sharablePage = computed(() =>
  (currentPage.value && currentPage.value?.pageKind === PageKind.VIEW && currentPage.value?.kind === ViewKind.CUSTOM) ||
  currentPage.value?.pageKind === PageKind.DASHBOARD
    ? currentPage.value
    : null
);
const pageKind = computed(() => currentPage.value?.pageKind);
const showDescriptionInput = computed(
  () =>
    isEditingDescription.value ||
    (!!currentPage.value && "description" in currentPage.value && !!currentPage.value.description)
);

const isDoc = computed(() => !!appStore.docOpenInFullscreen);
const isDashboard = computed(() => currentPage.value?.pageKind === PageKind.DASHBOARD);
const isDashboardsPage = computed(() => currentPage.value?.pageKind === PageKind.DASHBOARDS_ROOT);
const isReports = computed(() => currentPage.value?.pageKind === PageKind.SPACE);
const isHome = computed(() => currentPage.value?.pageKind === PageKind.HOME);
const isInbox = computed(() => currentPage.value?.pageKind === PageKind.INBOX);
const isViewsPage = computed(() => currentPage.value?.pageKind === PageKind.VIEWS_ROOT);
const isSpace = computed(() => currentPage.value?.pageKind === PageKind.SPACE);
const pageIsEditable = computed(
  () =>
    !isDashboardsPage.value &&
    !isDoc.value &&
    !isHome.value &&
    !isInbox.value &&
    !isSpace.value &&
    !isViewsPage.value &&
    !!currentPage.value &&
    !EXCLUDED_VIEW_KINDS.has(currentPage.value.kind as ViewKind)
);
const dartboardIsPartiallyEditable = computed(
  () => !!currentPage.value && PAGES_PARTIALLY_EDITABLE.has(currentPage.value.kind)
);

const onTrash = computed(() => currentPage.value?.kind === ViewKind.TRASH);
const trashedTasks = computed(() => dataStore.tasksInTrash);

const onSearch = computed(() => currentPage.value?.kind === ViewKind.SEARCH);

const onDartboard = computed(() => !!appStore.currentDartboardDuid && !onSearch.value && !onTrash.value);
const onFolderOrDoc = computed(() => currentPage.value?.pageKind === PageKind.FOLDER);
const hideMenusAndFilters = computed(
  () =>
    currentPage.value &&
    (currentPage.value.pageKind === PageKind.SPACE ||
      currentPage.value.pageKind === PageKind.FOLDER ||
      isHome.value ||
      isInbox.value ||
      isViewsPage.value ||
      isDashboard.value ||
      isDashboardsPage.value)
);
const displayPage = computed(() =>
  currentPage.value && currentPage.value.pageKind === PageKind.FOLDER && currentPage.value.kind === FolderKind.REPORTS
    ? dataStore.getSpaceByDuid(currentPage.value.spaceDuid)
    : currentPage.value
);
const displayPageTitle = computed(() => getPageDisplayName(displayPage.value, dataStore.getSpaceByDuid));

const startEditingDescription = () => {
  isEditingDescription.value = true;

  nextTick(() => {
    inputDescription.value?.startEditing();
  });
};

const updatePageDescription = (value: string) => {
  if (!currentPage.value) {
    return;
  }
  isEditingDescription.value = false;

  dataStore.updatePage({ duid: currentPage.value.duid, description: value.trim() }, currentPage.value.pageKind);
};

const deselectAll = () => appStore.getActiveVisualization().deselectAll();

const emptyTrash = () => dataStore.deleteTasks(trashedTasks.value);

const openLayoutEditor = () => layoutEditor.value?.open();

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.topbar = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
});

onUnmounted(() => {
  appStore.topbar = null;
});

defineExpose({
  openLayoutEditor,
});
</script>

<template>
  <header
    class="flex shrink-0 flex-col app-drag bg-std"
    :class="!onFolderOrDoc && !userStore.getShowFilters() && 'border-b border-md'"
    aria-label="Top navigation">
    <div
      class="flex"
      :class="{
        'pl-20': appStore.leftbarInCollapsibleMode && pageStore.isDesktopApp && pageStore.isMac,
      }">
      <TutorialMessage
        :name="TutorialName.CREATE_TASK_WITH_SUBTASK_RECS"
        :step="1"
        :alternate="1"
        :disabled="
          pageStore.isMobile ||
          pageKind === PageKind.SPACE ||
          pageKind === PageKind.FOLDER ||
          !!dataStore.taskDraft ||
          !appStore.leftbarInCollapsibleMode ||
          appStore.mobileLeftbarOpen
        "
        :skidding="10"
        shadow-styles="rounded-none"
        class="app-drag-none print:hidden">
        <button
          type="button"
         
          class="px-4 text-lt border-md focus-ring-std hover:bg-lt lg:hidden"
          :class="!onFolderOrDoc && (pageStore.isDesktopApp && pageStore.isMac ? 'border-x' : 'border-r')"
          @click="appStore.setMobileLeftbarOpen(true)">
          <span class="sr-only">Open sidebar</span>
          <div class="relative">
            <MenuIcon class="icon-lg" aria-hidden="true" />
          </div>
        </button>
      </TutorialMessage>

      <div
        class="relative flex flex-1 items-center justify-between gap-2 truncate py-3 pl-2 pr-4"
        @click="deselectAll"
        @keydown.enter.stop="deselectAll">
        <!-- Left buttons -->
        <div
          class="flex min-w-20 truncate"
          :class="{ 'min-w-44': showDescriptionInput, 'w-1/2': !onFolderOrDoc }"
          @click.stop
          @keydown.enter.stop>
          <NavigationButtons v-if="pageStore.isDesktopApp && !pageStore.isMac" class="mr-1.5 print:hidden" />
          <div v-if="currentPage" class="flex items-center gap-1 truncate app-drag-none">
            <div v-if="pageIsEditable" class="flex items-center truncate">
              <PageTitle
                :page="currentPage"
                :editable="dartboardIsPartiallyEditable"
                show-description-option
                label="Page title"
                class="truncate"
                @start-editing-description="startEditingDescription" />
              <div
                v-if="!pageStore.isMobile && showDescriptionInput && 'description' in currentPage"
                class="w-full min-w-20 flex-1 truncate border-l pl-[5px] pr-1 text-sm text-vlt border-md">
                <TextInput
                  ref="inputDescription"
                  :text="currentPage.description"
                  label="Page description"
                  editable
                  dblclick-to-edit
                  no-cursor
                  @save="updatePageDescription" />
              </div>
            </div>
            <div
              v-else-if="isDoc && appStore.docOpenInFullscreen && displayPage"
              class="flex w-full items-center truncate">
              <template v-if="!pageStore.isMobile">
                <RouterLink
                  :to="getPageLink(displayPage, dataStore.getSpaceByDuid)"
                  class="ml-1 mr-0.5 flex min-h-[26px] items-center gap-2 rounded px-1 py-0.5 text-sm text-md hover:bg-lt">
                  <PageIcon :page="displayPage" />
                  <span :title="displayPageTitle" class="max-w-40 select-none truncate">
                    {{ displayPageTitle }}
                  </span>
                </RouterLink>
                <SlashIcon class="text-lt icon-sm" aria-hidden="true" />
              </template>
              <PageTitle :page="appStore.docOpenInFullscreen" editable label="Doc title" class="max-w-72 truncate" />
            </div>
            <div
              v-else
              class="ml-1 mr-0.5 flex min-h-[26px] w-full select-none items-center gap-2 truncate rounded px-1 py-0.5 text-sm text-md">
              <ReportsIcon v-if="isReports" class="text-lt icon-md" />
              <PageIcon v-else :page="currentPage" />
              <span>
                {{ isReports ? "Reports" : getPageDisplayName(currentPage, dataStore.getSpaceByDuid) }}
              </span>
            </div>
          </div>
          <InboxTopbarTabs
            v-if="isInbox && appStore.inbox"
            :inbox="appStore.inbox"
            class="app-drag-none print:hidden" />
        </div>

        <!-- Search bar -->
        <div
          v-if="!onFolderOrDoc && !pageStore.isMobile"
          class="top-[13px] items-center justify-center app-drag-none print:hidden">
          <Tooltip :command-id="CommandId.TOGGLE_SEARCH">
            <div
              v-if="!pageStore.isMobile"
              class="-my-px flex w-48 cursor-text items-center justify-between gap-1 rounded border px-1 py-px text-md lg:w-80 xl:w-[448px]"
              :class="
                !(onSearch && appStore.search) ? 'border-transparent bg-md hover:bg-hvy' : 'border-md hover:border-hvy'
              "
              @click="appStore.setSearchModalOpen(true)"
              @keydown.enter="appStore.setSearchModalOpen(true)">
              <div class="flex items-center gap-2 truncate px-1 py-0.5">
                <SearchIcon class="icon-sm" />
                <span v-if="onSearch && appStore.search" class="truncate text-sm text-md">{{ appStore.search }}</span>
                <span v-else class="select-none text-sm text-lt">Search</span>
              </div>
            </div>
            <div
              v-else
              class="flex items-center justify-center rounded border p-[3px] text-sm font-normal text-lt border-hvy focus-ring-std hover:bg-lt"
              @click="appStore.setSearchModalOpen(true)"
              @keydown.enter="appStore.setSearchModalOpen(true)">
              <SearchIcon class="icon-sm" />
            </div>
          </Tooltip>
        </div>

        <!-- Right buttons -->
        <div
          v-if="!onSearch"
          class="flex min-w-fit items-center justify-end gap-3.5 print:hidden"
          :class="!onFolderOrDoc && 'w-1/2'"
          @click.stop
          @keydown.enter.stop>
          <span
            v-if="!environmentStore.isProd && !pageStore.adminHidden"
            class="select-none text-sm text-md"
            @dblclick="pageStore.adminHidden = true">
            {{ environmentStore.prettyName }}
          </span>
          <div v-if="!hideMenusAndFilters || isDashboard" class="flex items-center gap-2 app-drag-none">
            <ConfirmationDialog
              v-if="onTrash"
              :mode="DialogMode.DELETE"
              title="Empty trash"
              :description="EMPTY_TRASH_DIALOG_DESCRIPTION"
              confirm-text="Proceed"
              cancel-text="Cancel"
              :disabled="trashedTasks.length === 0"
              :icon="TrashIcon"
              @confirm="emptyTrash">
              <Button
                class="h-6 pl-1 pr-1.5"
                :btn-style="ButtonStyle.SECONDARY"
                text="Empty"
                :icon="trashedTasks.length > 0 ? TrashIcon : EmptyTrashIcon"
                :icon-size="IconSize.S"
                :disabled="trashedTasks.length === 0" />
            </ConfirmationDialog>
            <template v-if="onDartboard">
              <BrainstormTimer v-if="appStore.currentActiveBrainstorm" />
              <ProjectAiDropdown v-else-if="!pageStore.isMobile && pageStore.isOnline" />
            </template>
            <Tooltip v-if="sharablePage" text="Manage sharing">
              <Button
                class="h-6"
                :class="pageStore.isMobile ? 'w-6' : '!px-1.5'"
                :btn-style="ButtonStyle.SECONDARY"
                :text="!pageStore.isMobile ? 'Share' : undefined"
                :icon="pageStore.isMobile ? PublicIcon : undefined"
                :icon-size="IconSize.S"
                a11y-label="Manage sharing"
                @click="appStore.setPageInPermissionsModal(sharablePage)" />
            </Tooltip>
            <template v-if="!hideMenusAndFilters">
              <ShowFilterAreaToggle />
              <LayoutEditor ref="layoutEditor" />
            </template>
          </div>
          <InboxTopbarControls v-else-if="isInbox && appStore.inbox" :inbox="appStore.inbox" />
          <DocTopbarInfo v-else-if="isDoc && appStore.docOpenInFullscreen" :doc="appStore.docOpenInFullscreen" />
        </div>
        <div v-else class="flex w-1/2 justify-end print:hidden">
          <Tooltip text="Close">
            <button
              type="button"
              class="flex items-center rounded p-1 text-lt focus-ring-std hover:bg-md"
              aria-label="Close"
              @click="goBackOrHome"
              @keydown.enter="goBackOrHome">
              <XIcon class="icon-sm" aria-hidden="true" />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>

    <FilterArea
      v-if="onSearch || (!hideMenusAndFilters && userStore.getShowFilters())"
      class="px-4 py-2 app-drag-none print:hidden" />
  </header>
</template>
