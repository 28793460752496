<script setup lang="ts">
import { computed } from "vue";

import StatusIcon from "~/components/dumb/StatusIcon.vue";
import Template from "~/components/dumb/Template.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { EditorMode } from "~/shared/enums";
import type { PropertyAnyStatus } from "~/shared/types";
import { useDataStore } from "~/stores";

const props = defineProps<{
  value: string | null;
  property?: PropertyAnyStatus;
  editorMode: EditorMode;
}>();

const dataStore = useDataStore();
const isBoardMode = computed(() => props.editorMode === EditorMode.BOARD);
const status = computed(() => dataStore.getStatusByDuid(props.value ?? ""));
</script>

<template>
  <component :is="isBoardMode ? Tooltip : Template" v-if="value" :text="property?.title">
    <div
      v-if="value"
      class="flex h-5 items-center justify-center gap-1 rounded border px-1 text-xs text-md border-oncolor">
      <StatusIcon :duid="value" class="icon-xs" aria-hidden="true" />
      <div v-if="property?.adtl.isNameShown" class="truncate">{{ status?.title }}</div>
    </div>
  </component>
</template>
