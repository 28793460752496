<script setup lang="ts">
import { ref } from "vue";

import { backendOld } from "~/api";
import Button from "~/components/dumb/Button.vue";
import Input from "~/components/dumb/Input.vue";
import Modal from "~/components/dumb/Modal.vue";
import { ButtonSize, ButtonStyle, ModalWidth } from "~/shared/enums";
import { validatePassword } from "~/utils/common";

const open = ref(false);

const inputRef = ref<InstanceType<typeof Input> | null>(null);

const changePassword = async () => {
  if (!inputRef.value) {
    return;
  }
  open.value = false;
  await backendOld.profile.changePassword(inputRef.value.value);
};
</script>

<template>
  <div class="h-10 w-48">
    <Button
      :btn-style="ButtonStyle.SECONDARY"
      text="Change password"
      block
      height-block
      :size="ButtonSize.LARGE"
      text-style="text-base text-md"
      @click="open = true" />
  </div>
  <Modal :entity="open" title="Change password" :width="ModalWidth.S" @close="open = false">
    <template #default>
      <form class="mb-1 mt-4" onsubmit="return false">
        <Input
          ref="inputRef"
          input-type="password"
          required
          placeholder="New password"
          label="New password"
          hide-label
          :validate="validatePassword" />
      </form>
    </template>

    <template #actions>
      <div class="-mt-2 mb-1 w-full">
        <Button
          :btn-style="ButtonStyle.PRIMARY"
          text="Change"
          :disabled="inputRef?.isValid !== true"
          block
          @click="changePassword" />
      </div>
    </template>
  </Modal>
</template>
